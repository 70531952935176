


import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, TextField } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import util from '../../../utils/miniUtils';
import moment from 'moment';
import { EMPLOYEE_ROLE } from '../../../../constants';


const EmployeeView = ( { employee_id } ) => {
    const [employee, setEmployee] = useState( { loading: true } ); // Employee state
    const [employeeBase, setEmployeeBase] = useState( { loading: true } ); // base Data for the employee form to compare that is the data is changed or not
    const [departmentList, setDepartmentList] = useState( [] ); // Departmnent list for the department selector
    const [groupList, setGroupList] = useState( [] ); // Group List for group selector
    const [hrManagers, setHrManagers] = useState( [] );
    const [active, setActive] = useState( false );
   
    const [isInfoModified, setIsInfoModified] = useState( false );
    const [managers, setManagers] = useState( [] );
    const [access, setAccess] = useState( false );
    const [showMore, setShowMore] = useState( false );

    const toggleContent = () => {
        setShowMore( ( prevShowMore ) => !prevShowMore );
    };
    function isActiveEmploye( termination_date ) {
        if( !termination_date ) {
            return true;
        }

        let date1 = moment().format( "YYYY-MM-DD" ).split( "-" );
        let date2 = termination_date.split( "-" );
        let result = false;
      
        if ( Number( date1[0] ) < Number( date2[0] ) ) { // Check if year2 is greater than year1
            return result = true;
        } else if ( Number( date1[0] ) === Number( date2[0] ) ) { // Check if year2 equals year1
        // Check if month2 is greater than month1
            if ( Number( date1[1] ) < Number( date2[1] ) ) { 
                return result = true;
            } else if ( Number( date1[1] ) === Number( date2[1] ) ) { // Check if month2 equals month1
                // Check if day2 is greater than day1
                if ( Number( date1[2] ) < Number( date2[2] ) ) {
                    return result = true;
                }
            }
        }
        return result;
    }

    const getEmployeeData = async () => {
        try {
            const request = await util.axios.get( `/employee/detail/${employee_id}` );
            const { error, msg, data, employees, access } = request.data;
            if ( error ) throw msg;
            setEmployeeBase( { ...employee, loading:false, ...data } );
            setEmployee( { ...employee, loading:false, ...data } );
            setManagers( employees );
            setAccess( access );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getMemberDepartment = async ( id ) => {
        try {
            const request = await util.axios.get( `/department/list/${id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setDepartmentList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    }; 
    
    const getMemberGroup = async ( id ) => {
        try {
            const request = await util.axios.get( `/get_groups_by_employee/${id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setGroupList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getHRmanagers = async () => {
        try {
            const request = await util.axios.get( `/get_groups_members_by_group_name?groupName=${encodeURIComponent( "HR Manager" )}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setHrManagers( data );
        } catch ( error ) {
            util.handleError( error ); 
        }
    };

    // const saveChanges = async () => {
    //     try {
    //         const request = await util.axios.put( `/employee/update/${employee.employee_id}`, employee );
    //         const { error, msg } = request.data;
    //         if ( error ) throw msg;
    //         updateGroupandDepartment();
    //         getEmployeeData(); 
    //         setIsInfoModified( false );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }

    // };

    const handleChange = ( e ) => {
        let { name, value, checked } = e.target;
        if ( name === "elegible_for_bonus" || name === "is_billable" ) {
            value = checked ? 1:0; 
        } else if( value === "" || value === "--" ) {
            value = null;
        }
        setEmployee( { ...employee, [name]: value } );
    };

    // const handleEmergencyContact = ( e ) => {
    //     let { name, value } = e.target;
    //     let temp = {
    //         "emergency_contact_name": employee.emergency_contact ? employee.emergency_contact.split( ":" )[0] : "",
    //         "emergency_contact_number": employee.emergency_contact ? employee.emergency_contact.split( ":" )[1] : "",
    //     };
    //     if( name === "emergency_contact_name" ) {
    //         setEmployee( { ...employee, "emergency_contact": `${value}:${temp.emergency_contact_number}` } );
    //     } else {
    //         setEmployee( { ...employee, "emergency_contact": `${temp.emergency_contact_name}:${value}` } );
    //     }
    // };

    // const handleMultiSelect = ( e ) => {
    //     const { name, value, checked } = e.target;
    //     if( name === "department" ) {
    //         setDepartmentList( departmentList.map( item => {
    //             if( item.id == value ) {
    //                 return {
    //                     ...item,
    //                     checked: checked
    //                 };
    //             } else {
    //                 return {
    //                     ...item,
    //                 }; 
    //             }
    //         } ) );
    //     } else if( name === "group" ) {
    //         setGroupList( groupList.map( item => {
    //             if( item.id == value ) {
    //                 return {
    //                     ...item,
    //                     checked: checked
    //                 };
    //             } else {
    //                 return {
    //                     ...item,
    //                 }; 
    //             }
    //         } ) );
    //     }
    //     setIsInfoModified( true );
    // };

    // const updateGroupandDepartment = async () => {
    //     try {
    //         let temp = { departmentList: departmentList, groupList: groupList };
    //         const request = await util.axios.put( `/employee/update/department/group/${employee.employee_id}`, temp );
    //         const { error, msg } = request.data;
    //         if ( error ) throw msg;
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    // };
    const checkedItems = departmentList.filter( ( department ) => department.checked === true );

    const checkedGroupItems = groupList.filter( ( group ) => group.checked === true );

    useEffect( ()=>{
        const { engagement_type, annual_bonus, elegible_for_bonus,evaluation_frequency, hr_manager,is_billable, job_class, hr_notes,
            job_role, joining_date, l1_manager, l2_manager, l3_manager, probation_period, salary, tax_id, termination_date, time_zone, emergency_contact, job_id } = employee;
            
        if ( engagement_type !== employeeBase.engagement_type  ||
            annual_bonus !== employeeBase.annual_bonus  ||
            elegible_for_bonus !== employeeBase.elegible_for_bonus  ||
            evaluation_frequency !== employeeBase.evaluation_frequency  ||
            hr_manager !== employeeBase.hr_manager  ||
            is_billable !== employeeBase.is_billable  ||
            job_class !== employeeBase.job_class  ||
            job_role !== employeeBase.job_role  ||
            joining_date !== employeeBase.joining_date  ||
            l1_manager !== employeeBase.l1_manager  ||
            l2_manager !== employeeBase.l2_manager  ||
            l3_manager !== employeeBase.l3_manager  ||
            probation_period !== employeeBase.probation_period  ||
            tax_id !== employeeBase.tax_id  ||
            salary !== employeeBase.salary  ||
            termination_date !== employeeBase.termination_date  ||
            time_zone !== employeeBase.time_zone ||
            emergency_contact !== employeeBase.emergency_contact ||
            hr_notes !== employeeBase.hr_notes || job_id !== employeeBase.job_id ) {
            setIsInfoModified( true );
        }else{
            setIsInfoModified( false );
        }

        setActive( isActiveEmploye( termination_date ) );
    },[employee] );

    useEffect( () => {
        getEmployeeData();
        getHRmanagers();
        getMemberDepartment( employee_id );
        getMemberGroup( employee_id );
    },[] );

    if ( employee.loading ) {
        return "loading...";
    }
    // const emergencyContactArray = employee && employee.emergency_contact ? employee.emergency_contact.split( ":" ) : [];
    // const name = emergencyContactArray.length >= 1 ? emergencyContactArray[0].trim() : 'N/A';
    // const phone = emergencyContactArray.length >= 2 ? emergencyContactArray[1].trim() : 'N/A';
    return ( 
        <div style={{ backgroundColor: "#F4F4F4" }} className='mt-3'>
            <b className='custom-title ml-3 mt-5'>Employee</b>
            <div className="d-flex mt-3">
                <div style={{ width: "50%" }}>
                    <div className='d-flex flex-column'>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Job Position:</label>
                            {employee.job ? (
                                <span className="employeeValue">
                                    {util.zeroPad(employee.job.id, 5)} - {employee.job.job_title}
                                </span>
                            ) : (
                                <span>No Job Information Available</span>
                            )}
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Change Job Position:</label>
                            <span className="employeeValue">{employee.job_id}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Time Zone:</label>
                            <span className="employeeValue">{employee.time_zone}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Engagement Type:</label>
                            <span className="employeeValue">{employee.engagement_type}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Job Role:</label>
                            <span className="employeeValue">{employee.job_role}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Salary:</label>
                            <span className="employeeValue">{employee.salary}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Joining Date:</label>
                            <span className="employeeValue">{employee.joining_date}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Termination Date:</label>
                            <span className="employeeValue">{employee.termination_date}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Tax ID:</label>
                            <span className="employeeValue">{employee.tax_id}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Evaluation Frequency:</label>
                            <span className="employeeValue">{employee.evaluation_frequency}</span>
                        </div>
                        <div className="employeeView_label">
                            <label className="employeeRes_label">Emergency Contact:</label>
                            <span className='emspanloyeeValue'>{employee.emergency_contact}</span>
                        </div>
                    </div>
                    {access ? (
                        <div className="row p-1">
                            <div className="col-md-12">
                                <b className='isCursor likeLink'>Employee Log</b>
                                <div>
                                    {employee.emp_log !== null ? (
                                        <React.Fragment>
                                            {employee.emp_log.split("|").map((l, i) => (
                                                <div className='fontSize12' key={i}>{l}</div>
                                            ))}
                                        </React.Fragment>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : ""}
                </div>
                <div style={{ width: "46%" }}>
                    <div className='employeeViewThree'>
                        <div className='employeeViewThree'>
                            <div className="form-check employeeCheck">
                                <input type="checkbox" className="form-check-input" id="isActive" name="is_active" checked={active} value={active} />
                                <label className="form-check-label" htmlFor="isActive">Active</label>
                            </div>
                            <div className="form-check employeeCheck">
                                <input type="checkbox" className="form-check-input" id="isBillable" name="is_billable" checked={employee.is_billable === 1} value={employee.is_billable === 1} onChange={handleChange} />
                                <label className="form-check-label" htmlFor="isBillable">Billable</label>
                            </div>
                            <div className="form-check employeeCheck">
                                <input type="checkbox" className="form-check-input" id="elegible_for_bonus" name="elegible_for_bonus" checked={employee.elegible_for_bonus === 1} value={employee.elegible_for_bonus === 1} onChange={handleChange} />
                                <label className="form-check-label" htmlFor="elegible_for_bonus">Eligible for bonus</label>
                            </div>
                        </div>
                        <div className='ViewBox'>
                            <div>
                                <div className="mb-4">
                                    <label>Department:</label>
                                    {checkedItems.length && checkedItems.map((item) => (
                                        <div key={item.id} style={{ backgroundColor: "white", borderRadius: "12px", width: "150px", marginLeft: "10px", marginTop: '0.5rem', paddingLeft: '4px' }}>
                                            <small style={{ marginLeft: "2px" }}>{item.name}</small>
                                        </div>
                                    ))}
                                </div>
                                <div className="mb-4">
                                    <label>Groups:</label>
                                    {checkedGroupItems.length && checkedGroupItems.map((item) => (
                                        <div key={item.id} style={{ backgroundColor: "white", borderRadius: "12px", width: "130px", marginLeft: "10px", marginTop: '0.5rem', paddingLeft: '4px' }}>
                                            <small style={{ padding: "5px", marginLeft: "10px" }}>{item.name}</small>
                                        </div>
                                    ))}
                                </div>
                                <label style={{ fontSize: '14px', marginBottom: '5px' }}>HR Notes:</label>
                                <textarea
                                    style={{
                                        backgroundColor: '#f2f2f2',
                                        padding: '10px',
                                        borderRadius: '8px',
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                        fontSize: '16px',
                                        lineHeight: '1.5',
                                        width: '100%',
                                    }}
                                    value={employee.hr_notes}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <LevelManagerSelector data={managers} handleChange={handleChange} id="l1ManagerID" name="l1_manager" title="L1 Manager" value={employee.l1_manager} />
                <LevelManagerSelector data={managers} handleChange={handleChange} id="l2ManagerID" name="l2_manager" title="L2 Manager" value={employee.l2_manager} />
                <LevelManagerSelector data={managers} handleChange={handleChange} id="l3ManagerID" name="l3_manager" title="L3 Manager" value={employee.l3_manager} />
                <LevelManagerSelector data={hrManagers} handleChange={handleChange} id="hrManagerID" name="hr_manager" title="HR Manager" value={employee.hr_manager} />
            </div>
        </div>
    );
};
 
export default EmployeeView;


// const Selector = ( { col=3, id, title, name, value, handleChange, data, disabled=false } ) => {
//     return (
//         <div className={`col-md-${col} mt-2`}>
//             {/* <label className='fontSize11' htmlFor={id}>{title}</label>
//             <select
//                 id={id}
//                 className="form-control"
//                 value={value}
//                 name={name}
//                 onChange={handleChange}
//                 disabled={disabled}
//             >
//                 <option value={null}> -- </option>;
//                 {
//                     data.map( ( item,i )=>{
//                         return <option key={i} value={item.account_id}>{item.name}</option>;
//                     } )
//                 }
//             </select> */}
//             <TextField
//                 select
//                 label={title}
//                 value={value}
//                 name={name}
//                 onChange={handleChange}
//                 disabled={disabled}
//                 variant="outlined"
//                 size='small'
//                 fullWidth
//             >
//                 {data.map( ( item,i ) => (
//                     <MenuItem key={i} value={item.account_id}>
//                         {item.name}
//                     </MenuItem>
//                 ) )}
//             </TextField>
//         </div>
//     );
// };

const LevelManagerSelector = ( { col=3, id, title, name, value, handleChange, data } ) => {
    return (
        <div className={`col-md-${col}`}>
            <label className='fontSize11' htmlFor={id}>{title}</label>
            <select
                id={id}
                className="form-control"
                value={value}
                name={name}
                onChange={handleChange}
                disabled
            >
                <option value={null}> -- </option>;
                {
                    data.map( ( item,i )=>{
                        return <option key={i} value={item.employee_id}>{item.name}</option>;
                    } )
                }
            </select>
            {/* <TextField
                disabled
                select
                label={title}
                value={value}
                name={name}
                onChange={handleChange}
                variant="standard"
                size='small'
                fullWidth
            >
                {data.map( ( item,i ) => (
                    <MenuItem key={i} value={item.employee_id}>
                        {item.name}
                    </MenuItem>
                ) )}
            </TextField> */}
        </div>
    );
};

// const MultiSelect = ( { label, list, onChange, name } ) => {
   
//     return (
//         <div className='col-md-3'>
//             {/* <label>{label}</label>
//             <div className='employeeSettings_multiSelect form-control'>
//                 {list.map( item => <div key={item.id}>
//                     <input type="checkbox" name={name} className="mr-1" value={item.id} checked={item.checked ? item.checked : false} onChange={onChange} />
//                     <label>{item.name}</label>
//                 </div> )}
//             </div> */}
//             <FormControl component="fieldset" className='employeeSettings_multiSelect' style={{ border:'1px solid lightGray' ,borderRadius:'5px' }}>
//                 <FormGroup>
//                     <label className='p-2'>{label}</label>
//                     {list.map( ( item ) => (
//                         <FormControlLabel
//                             key={item.id}
//                             control={
//                                 <Checkbox
//                                     className='ml-1'
//                                     name={name}
//                                     checked={item.checked ? item.checked : false}
//                                     value={item.id}
//                                     onChange={onChange}
//                                     color='primary'
//                                 />
//                             }
//                             label={item.name}
//                         />
//                     ) )}
//                 </FormGroup>
//             </FormControl>
//         </div>
//     );
// };