import React, { Fragment, useEffect, useState } from 'react';
import { Collapse, TextField, Tooltip } from "@material-ui/core";
import util from '../../utils/miniUtils';
import ImageComponent from "../../utils/ImageComponent";

import { PositionsStore } from '../../positions/stores/PositionsStore';

const JobSearchFilterCheckboxSelector = ( { dataArray = [], title = "", name, isOpenByDeafult = null } ) => {
    const OPEN = 1;
    const CLOSED = 0;
    const filter = PositionsStore.useState( s => s.filterState );

    const [open, setOpen] = useState( isOpenByDeafult ? OPEN : CLOSED );
    const [selectAll, setSelectAll] = useState( false );
    const list = PositionsStore.useState( s => s.filterState[name] );
    
    // Simple validation if the checkbox is checked or not
    const collapseExpand = ( opc ) => setOpen( opc === open ? CLOSED : opc );

    // Utility to change icon on Collapse, depending if is open or closed
    const caretIcon = ( opc, open ) => {
        return open === opc ? (
            <img height={13} width={13} className="mr-4 color-change" src="./images/newup.webp" alt=""  />
        ) : (
            <img height={22} width={22}  className="mr-3 color-change" src="./images/newdown.webp" alt="" />
        );
    };

    const quickSearch = async ( e ) => {
        let temp = [];
        let value = e.target.value;
        if ( value === "" ) {
            return;
        }
        for ( let i = 0; i < dataArray.length; i++ ) {
            if (
        dataArray[i].value?.toLowerCase().includes( value.toLowerCase() ) ||
        dataArray[i].name?.toLowerCase().includes( value.toLowerCase() )
            ) {
                temp.push( dataArray[i] );
            }
        }
    };

    const handleSelectAll = () => {
        let currentList = [...list];
        currentList = currentList.map( el => {
            return  { ...el, checked: !selectAll }; 
        } );
        PositionsStore.update( s => {
            s.filterState[name] = currentList;
        } );
    };

    const handleReset = () => {
        let currentList = [...list];
        currentList = currentList.map( el => {
            return  { ...el, checked: false }; 
        } );
        PositionsStore.update( s => {
            s.filterState[name] = currentList;
        } );
    };

    // Utility, just to add class of scroll if result are more than 10
    const needToUseScroll = ( info ) => {
        if ( info.length > 10 ) return "defaultScroll350";
        return "";
    };

    const handleChecked = ( e, index ) => {
        let currentList = [...list];
        let selectedObj = { ...currentList[index] };
        if( selectedObj.checked ) {
            selectedObj.checked = false;
        } else {
            selectedObj.checked = e.target.checked;
        }
        currentList.splice( index, 1, selectedObj );

        let currentFilterState = { ...filter };
        currentFilterState[name] = [...currentList];
        PositionsStore.update( s => {
            s.filterState = currentFilterState;
        } );
    };

    // useEffect for select all
    useEffect( () => {
        let selectAllFlag = true;
        list.forEach( el => {
            if( !el.checked ){
                selectAllFlag = false;
            }
        } );
        setSelectAll( selectAllFlag );
    },[list] );

    return (
        <div className="w-100">
            <div
                className="isPointer d-flex justify-content-between"
                onClick={() => collapseExpand( 1 )}
            >
                <span>
                    <h5 style={{ color: '#848484' }}>{title}</h5>

                </span>
                <span>{caretIcon( OPEN, open )}</span>
            </div>
            <Collapse in={open === 1}>
                <div className="d-flex fontSize13 align-items-center align-content-center justify-content-between isCursor text-black-50 p-0">
                    <div className="d-flex pt-0 pb-0">
                        <input
                            className="form-control-sm"
                            type="checkbox"
                            checked={selectAll}
                            id={`${title.split( " " ).join( "_" )}_select_all`}
                            onChange={ () => null }
                            onClick={( e ) => handleSelectAll( e )}
                        />
                        <label
                            htmlFor={`${title.split( " " ).join( "_" )}_select_all`}
                            className="pl-2  d-flex align-items-center mb-0 isCursor"
                        >
                Select All
                        </label>
                    </div>
                    <div>
                        <div className="isPointer mr-3" onClick={handleReset}>
                Reset
                        </div>
                    </div>
                </div>
                <hr className="pt-1 pb-0 m-0" />
                <TextField
                    variant='outlined'
                    onChange={quickSearch}
                    style={{ width:"85%" }}
                    margin="dense"
                />

                <div className={`${needToUseScroll( list )}`}>
                    {list.map( ( single, index ) => {
                        // Create UNIQUE ID using the id of the single data
                        const element_id = `element_id_${util.randomStringPassword()}`;
                        return (
                            <Fragment key={index}>
                                <div>
                                    <div className="d-flex fontSize13 align-items-center align-content-center isCursor hoverSelectOption text-black-50">
                                        <input
                                            type="checkbox"
                                            id={element_id}
                                            value={single.value}
                                            checked={single.checked ? single.checked : false}
                                            onChange={( e ) => handleChecked( e, index )}
                                        />
                                        {
                                            single.name === undefined ?
                                                <label htmlFor={element_id} className="p-0 m-0 pl-2 isCursor">
                                                    <span>{single.value}</span>
                                                </label>
                                                :
                                                <Tooltip title={`${single.name} - ${single.email}`}>
                                                    <label htmlFor={element_id} className="p-0 m-0 pl-2">
                                                        <span>{single.value}</span>
                                                    </label>
                                                </Tooltip>
                                        }
                                    </div>
                                </div>
                                <div className="border-darken-1" />
                            </Fragment>
                        );
                    } )}
                </div>
            </Collapse>
            

            <hr className="p-2 m-0 mb-2" />
        </div>
    );
};

export default JobSearchFilterCheckboxSelector;