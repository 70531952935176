import util from "./miniUtils";

let selector = {};

selector.engagement_type = [
    { engagement_type: "" },
    { engagement_type: "Fulltime Employee" },
    { engagement_type: "Contractor" },
    { engagement_type: "Corp to Corp" }
];

selector.evaluationFrequency = [
    "Monthly", "Quarterly", "Half Yearly", "Yearly"
];

selector.AvailabilityToStart = [
    "0 Weeks",
    "1 Weeks",
    "2 Weeks",
    "3 Weeks",
    "4 Weeks",
    "5 Weeks",
    "6 Weeks",
    "7 Weeks",
    "8 Weeks",
    "9 Weeks",
    "10 Weeks",
    "11 Weeks",
    "12 Weeks",
];

selector.AvailabilityToStartObj = [
    { noticePeriod: "0 Weeks" },
    { noticePeriod: "1 Weeks" },
    { noticePeriod: "2 Weeks" },
    { noticePeriod: "3 Weeks" },
    { noticePeriod: "4 Weeks" },
    { noticePeriod: "5 Weeks" },
    { noticePeriod: "6 Weeks" },
    { noticePeriod: "7 Weeks" },
    { noticePeriod: "8 Weeks" },
    { noticePeriod: "9 Weeks" },
    { noticePeriod: "10 Weeks" },
    { noticePeriod: "11 Weeks" },
    { noticePeriod: "12 Weeks" },
];

selector.job_position_base = () => {
    return {
        latitude: "",
        longitude: "",
        positionTitle: "",
        partnerName: "",
        partnerContact: "",
        end_client_name: "",
        sell_rate: "0",
        sell_rate_type: "",
        status: "Received Request",
        margin: "",
        reporting_manager: "",
        reporting_manager_phone: "",
        reporting_manager_address: "",
        sales_lead: "",
        lead_recruiter: "",
        secondary_recruiter: "",
        restricted_to: "None",
        job_title: "",
        job_description: "",
        role: "Technician",
        scope: "Project Support",
        level: "Experienced",
        deal_type: "Channel Partner",
        primary_skill: "",
        secondary_skill: "",
        location: "",
        city: "",
        state: "",
        country: util.user.country ? util.user.country : "",
        index: null,
        zipCode: "",
        buy_rate: "0",
        buy_from: "0",
        buy_to: "0",
        duration: "",
        priority: "Mid",
        education: "University",
        certification: [],
        employment_type: "Any",
        recruiter: "",
        client: null,
        partner: null,
        remote: null,
        skills: []
    };
};

selector.restricted_to = () => {
    return [
        { restricted_to: "None" },
        { restricted_to: "US Citizen/ GC Only" },
        { restricted_to: "Security Clearance Required" },
    ];
};
selector.dealType = () => {
    return [{ deal_type: "Channel Partner" }, { deal_type: "Direct Client" }];
};
selector.owner_office = () => {
    return [
        { owner_office: "eJAmerica" },
        { owner_office: "eJAustralia" },
        { owner_office: "eJIndia" },
    ];
};

selector.salaryType = () => {
    return [{ salary_type: "Twice a month" }];
};

selector.sell_rate_type = [
    { value: "Daily", salary_mode: "Daily" },
    { value: "Hourly", salary_mode: "Hourly" },
    { value: "Monthly", salary_mode: "Monthly" },
    { value: "Weekly", salary_mode: "Weekly" },
    { value: "Yearly", salary_mode: "Yearly" },
];

selector.datesSelector = [
    { postedDaysAgo: "3 days", value: 3 },
    { postedDaysAgo: "1 Week", value: 7 },
    { postedDaysAgo: "2 Weeks", value: 14 },
    { postedDaysAgo: "1 Month", value: 31 },
    { postedDaysAgo: "3 Months", value: 90 },
];
selector.experience = [
    { experience: "Junior (1+)", value: 1 },
    { experience: "Mid Level (3+)", value: 3 },
    { experience: "Senior (5+)", value: 5 },
    { experience: "Expert (8+)", value: 8 },
    { experience: "Architect (12+)", value: 12 },
];

selector.sell_rate_type2 = [
    { value: "Daily", sell_rate_type: "Daily" },
    { value: "Hourly", sell_rate_type: "Hourly" },
    { value: "Monthly", sell_rate_type: "Monthly" },
    { value: "Weekly", sell_rate_type: "Weekly" },
    { value: "Yearly", sell_rate_type: "Yearly" },
];

selector.sell_rate_type3 = [
    { sell_rate_type: "Daily" },
    { sell_rate_type: "Hourly" },
    { sell_rate_type: "Monthly" },
    { sell_rate_type: "Weekly" },
    { sell_rate_type: "Yearly" }
];

selector.priority = () => {
    return [
        { priority: "" },
        { priority: "Low" },
        { priority: "Mid" },
        { priority: "High" },
        { priority: "Urgent" },
    ];
};

selector.sales_lead = () => {
    return [
        { sales_lead: "Andy Sims" },
        { sales_lead: "Avinash G." },
        { sales_lead: "Brayan B." },
        { sales_lead: "Juan P." },
    ];
};

selector.lead_recruiter = () => {
    return [{ lead_recruiter: "Jyoti M." }, { lead_recruiter: "Arturo Pacheco" }];
};

selector.secondary_recruiter = () => {
    return [
        { secondary_recruiter: "Jyoti M." },
        { secondary_recruiter: "Arturo Pacheco" },
    ];
};

selector.restricted_to = () => {
    return [
        { restricted_to: "None" },
        { restricted_to: "US Citizen/ GC Only" },
        { restricted_to: "Security Clearance Required" },
    ];
};

selector.role = () => {
    return [
        { value: "Administrator", role: "Administrator" },
        { value: "Advisory", role: "Advisory" },
        { value: "Analyst", role: "Analyst" },
        { value: 'Architect', role: "Architect" },
        { value: "Consultant", role: "Consultant" },
        { value: "Developer", role: "Developer" },
        { value: "Egineer", role: "Engineer " },
        { value: "Project/Program Leader", role: "Project/Program Leader" },
        { value: 'Technician', role: "Technician" },
    ];
};

selector.education = () => {
    return [
        { education: "High School" },
        { education: "Bachelor's Degrees" },
        { education: "Master's Degree" },
        { education: "Education Specialist Degree" },
        { education: "Doctorate" },
        { education: "University" },
    ];
};

selector.country = () => {
    return [
        { country: null, id: null, abbreviation: null },
        { country: "United States", id: 1, abbreviation: "US" },
        { country: "Canada", id: 2, abbreviation: "CA" },
        { country: "Mexico", id: 3, abbreviation: "MX" },
        { country: "India", id: 4, abbreviation: "IN" },
        { country: "Australia", id: 5, abbreviation: "AU" },
    ];
};

selector.location = () => {
    return [
        { location: "United States", id: 1, abbreviation: "US" },
        { location: "Canada", id: 2, abbreviation: "CA" },
        { location: "México", id: 3, abbreviation: "MX" },
        { location: "India", id: 4, abbreviation: "IN" },
        { location: "Australia", id: 5, abbreviation: "AU" },
        { country: "Netherlands", id: 6, abbreviation: "NL" },

    ];
};

selector.scope = () => {
    return [
        { scope: "Internal Operations (Managing internal processes and activities)" },
        { scope: "Internal Project (Internal projects and initiatives)" },
        { scope: "Client Billable (Subcon) Operations (Client-related operational tasks)" },
        { scope: "Client Billable (Subcon) Project Work (Client-specific project work)" },
        { scope: "Client Lateral Hiring (Recruiting for client roles)" },
        { scope: "Other" },
    ];
};

selector.level = () => {
    return [
        { level: "Basic (L1)" },
        { level: "Experienced (L2)" },
        { level: "Senior (L3)" },
        { level: "Expert (L4)" },
        { level: "Niche (L5)" },
    ];
};

selector.employment_type = () => {
    return [
        { employment_type: "Fulltime" },
        { employment_type: "Contractor" },
        { employment_type: "Any" },
    ];
};
selector.deal_type = () => {
    return [
        { deal_type: "Deal Type" },
        { deal_type: "Any" },
        { deal_type: "Anyaaaa" },
    ];
};

selector.status = () => {
    return [
        { status: "Received Request" },
        { status: "Submitted Resumes" },
        { status: "Customer Reviewed and Feedback Received" },
        { status: "Candidate Selected" },
        { status: "Contracting Process" },
        { status: "Fully Executed Contract" },
    ];
};

selector.states = ( country_id, countryName ) => {
    let array = [];
    for ( let x = 0; x < states.length; x++ ) {
        if ( states[x].country_id === country_id ) {
            array.push( states[x] );
        }
    }
    return array;
};

selector.statesByCountry = ( countryName ) => {
    if ( countryName === "" ) return [];
    let country_id;
    const c = selector.country();
    for ( let x = 0; x < c.length; x++ ) {
        if ( c[x].country === countryName ) {
            country_id = c[x].id;
        }
    }
    let array = [];
    for ( let x = 0; x < states.length; x++ ) {
        if ( states[x].country_id === country_id ) {
            array.push( states[x] );
        }
    }
    return array;
};

selector.getStateAbbreviation = ( state ) => {
    for ( let x = 0; x < states.length; x++ ) {
        if ( state === states[x].state ) {
            return states[x].abbreviation;
        }
    }
};

selector.getCountryAbbreviation = ( country ) => {
    let countries = selector.country();
    for ( let x = 0; x < countries.length; x++ ) {
        if ( country === countries[x].country ) {
            return countries[x].abbreviation;
        }
    }
};

const states = [
    { country_id: 1, state: "Alabama", abbreviation: "AL" },
    { country_id: 1, state: "Alaska", abbreviation: "AK" },
    { country_id: 1, state: "Arizona", abbreviation: "AZ" },
    { country_id: 1, state: "Arkansas", abbreviation: "AR" },
    { country_id: 1, state: "California", abbreviation: "CA" },
    { country_id: 1, state: "Colorado", abbreviation: "CO" },
    { country_id: 1, state: "Connecticut", abbreviation: "CT" },
    { country_id: 1, state: "Delaware", abbreviation: "DE" },
    { country_id: 1, state: "Florida", abbreviation: "FL" },
    { country_id: 1, state: "Georgia", abbreviation: "GA" },
    { country_id: 1, state: "Hawaii", abbreviation: "HI" },
    { country_id: 1, state: "Idaho", abbreviation: "ID" },
    { country_id: 1, state: "Illinois", abbreviation: "IL" },
    { country_id: 1, state: "Indiana", abbreviation: "IN" },
    { country_id: 1, state: "Iowa", abbreviation: "IA" },
    { country_id: 1, state: "Kansas", abbreviation: "KS" },
    { country_id: 1, state: "Kentucky", abbreviation: "KY" },
    { country_id: 1, state: "Louisiana", abbreviation: "LA" },
    { country_id: 1, state: "Maine", abbreviation: "ME" },
    { country_id: 1, state: "Maryland", abbreviation: "MD" },
    { country_id: 1, state: "Massachusetts", abbreviation: "MA" },
    { country_id: 1, state: "Michigan", abbreviation: "MI" },
    { country_id: 1, state: "Minnesota", abbreviation: "MN" },
    { country_id: 1, state: "Mississippi", abbreviation: "MS" },
    { country_id: 1, state: "Missouri", abbreviation: "MO" },
    { country_id: 1, state: "Montana", abbreviation: "MT" },
    { country_id: 1, state: "Nebraska", abbreviation: "NE" },
    { country_id: 1, state: "Nevada", abbreviation: "NV" },
    { country_id: 1, state: "New Hampshire", abbreviation: "NH" },
    { country_id: 1, state: "New Jersey", abbreviation: "NJ" },
    { country_id: 1, state: "New Mexico", abbreviation: "NM" },
    { country_id: 1, state: "New York", abbreviation: "NY" },
    { country_id: 1, state: "North Carolina", abbreviation: "NC" },
    { country_id: 1, state: "North Dakota", abbreviation: "ND" },
    { country_id: 1, state: "Ohio", abbreviation: "OH" },
    { country_id: 1, state: "Oklahoma", abbreviation: "OK" },
    { country_id: 1, state: "Oregon", abbreviation: "OR" },
    { country_id: 1, state: "Pennsylvania", abbreviation: "PA" },
    { country_id: 1, state: "Rhode Island", abbreviation: "RI" },
    { country_id: 1, state: "South Carolina", abbreviation: "SC" },
    { country_id: 1, state: "South Dakota", abbreviation: "SD" },
    { country_id: 1, state: "Tennessee", abbreviation: "TN" },
    { country_id: 1, state: "Texas", abbreviation: "TX" },
    { country_id: 1, state: "Utah", abbreviation: "UT" },
    { country_id: 1, state: "Vermont", abbreviation: "VT" },
    { country_id: 1, state: "Virginia", abbreviation: "VA" },
    { country_id: 1, state: "Washington", abbreviation: "WA" },
    { country_id: 1, state: "West Virginia", abbreviation: "WV" },
    { country_id: 1, state: "Wisconsin", abbreviation: "WI" },
    { country_id: 1, state: "Wyoming", abbreviation: "WY" },
    { country_id: 2, state: "Alberta", abbreviation: "AB" },
    { country_id: 2, state: "British Columbia", abbreviation: "BC" },
    { country_id: 2, state: "Manitoba", abbreviation: "MB" },
    { country_id: 2, state: "New Brunswick", abbreviation: "NB" },
    { country_id: 2, state: "Newfoundland and Labrador", abbreviation: "NL" },
    { country_id: 2, state: "Northwest Territories", abbreviation: "NT" },
    { country_id: 2, state: "Nova Scotia", abbreviation: "NS" },
    { country_id: 2, state: "Nunavut", abbreviation: "NU" },
    { country_id: 2, state: "Ontario", abbreviation: "ON" },
    { country_id: 2, state: "Prince Edward Island", abbreviation: "PE" },
    { country_id: 2, state: "Quebec", abbreviation: "QC" },
    { country_id: 2, state: "Saskatchewan", abbreviation: "SK" },
    { country_id: 2, state: "Yukon", abbreviation: "YT" },
    { country_id: 3, state: "Aguascalientes", abbreviation: "AG" },
    { country_id: 3, state: "Baja California", abbreviation: "BC" },
    { country_id: 3, state: "Baja California Sur", abbreviation: "BS" },
    { country_id: 3, state: "Campeche", abbreviation: "CM" },
    { country_id: 3, state: "Chiapas", abbreviation: "CS" },
    { country_id: 3, state: "Chihuahua", abbreviation: "CH" },
    { country_id: 3, state: "Coahuila", abbreviation: "CO" },
    { country_id: 3, state: "Colima", abbreviation: "CL" },
    { country_id: 3, state: "Durango", abbreviation: "DG" },
    { country_id: 3, state: "Guanajuato", abbreviation: "GT" },
    { country_id: 3, state: "Guerrero", abbreviation: "GR" },
    { country_id: 3, state: "Hidalgo", abbreviation: "HG" },
    { country_id: 3, state: "Jalisco", abbreviation: "JA" },
    { country_id: 3, state: "Mexico", abbreviation: "EM" },
    { country_id: 3, state: "Michoacan", abbreviation: "MI" },
    { country_id: 3, state: "Morelos", abbreviation: "MO" },
    { country_id: 3, state: "Nayarit", abbreviation: "NA" },
    { country_id: 3, state: "Nuevo Leon", abbreviation: "NL" },
    { country_id: 3, state: "Oaxaca", abbreviation: "OA" },
    { country_id: 3, state: "Puebla", abbreviation: "PU" },
    { country_id: 3, state: "Queretaro", abbreviation: "QT" },
    { country_id: 3, state: "Quintana Roo", abbreviation: "QR" },
    { country_id: 3, state: "San Luis Potosi", abbreviation: "SL" },
    { country_id: 3, state: "Sinaloa", abbreviation: "SI" },
    { country_id: 3, state: "Sonora", abbreviation: "SO" },
    { country_id: 3, state: "Tabasco", abbreviation: "TB" },
    { country_id: 3, state: "Tamaulipas", abbreviation: "TL" },
    { country_id: 3, state: "Tlaxcala", abbreviation: "TM" },
    { country_id: 3, state: "Veracruz", abbreviation: "VE" },
    { country_id: 3, state: "Yucatan", abbreviation: "YU" },
    { country_id: 3, state: "Zacatecas", abbreviation: "ZA" },
    { country_id: 4, state: "Andhra Pradesh", abbreviation: "AD" },
    { country_id: 4, state: "Arunachal Pradesh", abbreviation: "AR" },
    { country_id: 4, state: "Assam", abbreviation: "AS" },
    { country_id: 4, state: "Bihar", abbreviation: "BR" },
    { country_id: 4, state: "Chattisgarh", abbreviation: "CG" },
    { country_id: 4, state: "Delhi", abbreviation: "DL" },
    { country_id: 4, state: "Goa", abbreviation: "GA" },
    { country_id: 4, state: "Gujarat", abbreviation: "GJ" },
    { country_id: 4, state: "Haryana", abbreviation: "HR" },
    { country_id: 4, state: "Himachal Pradesh", abbreviation: "HP" },
    { country_id: 4, state: "Jammu and Kashmir", abbreviation: "JK" },
    { country_id: 4, state: "Jharkhand", abbreviation: "JH" },
    { country_id: 4, state: "Karnataka", abbreviation: "KA" },
    { country_id: 4, state: "Kerala", abbreviation: "KL" },
    { country_id: 4, state: "Lakshadweep Islands", abbreviation: "LD" },
    { country_id: 4, state: "Madhya Pradesh", abbreviation: "MP" },
    { country_id: 4, state: "Maharashtra", abbreviation: "MH" },
    { country_id: 4, state: "Manipur", abbreviation: "MN" },
    { country_id: 4, state: "Meghalaya", abbreviation: "ML" },
    { country_id: 4, state: "Mizoram", abbreviation: "MZ" },
    { country_id: 4, state: "Nagaland", abbreviation: "NL" },
    { country_id: 4, state: "Odisha", abbreviation: "OD" },
    { country_id: 4, state: "Pondicherry", abbreviation: "PY" },
    { country_id: 4, state: "Punjab", abbreviation: "PB" },
    { country_id: 4, state: "Rajasthan", abbreviation: "RJ" },
    { country_id: 4, state: "Sikkim", abbreviation: "SK" },
    { country_id: 4, state: "Tamil Nadu", abbreviation: "TN" },
    { country_id: 4, state: "Telangana", abbreviation: "TS" },
    { country_id: 5, state: "New South Wales", abbreviation: "NS" },
    { country_id: 5, state: "Queensland", abbreviation: "QL" },
    { country_id: 5, state: "South Australia", abbreviation: "SA" },
    { country_id: 5, state: "Tasmania", abbreviation: "TS" },
    { country_id: 5, state: "Victoria", abbreviation: "VI" },
    { country_id: 5, state: "Western Australia", abbreviation: "WA" },
];

selector.aHr = () => {
    return [
        { aHr: "Angel" },
        { aHr: "Melwin" },
        { aHr: "Alejandro" },
        { aHr: "Mohit" },
        { aHr: "Ankita" },
    ];
};
selector.aEvaluator1 = () => {
    return [
        { aEvaluator1: "Angel Canales" },
        { aEvaluator1: "Shahin" },
        { aEvaluator1: "Mohit" },
        { aEvaluator1: "Ankita" },
        { aEvaluator1: "Priyanka" },

    ];
};
selector.aEvaluator2 = () => {
    return [
        { aEvaluator2: "Gigi" },
        { aEvaluator2: "Shahin" },
        { aEvaluator2: "Priyanka" },
        { aEvaluator2: "Ankita" },
        { aEvaluator2: "Pallavi" },

    ];
};

selector.aEvaluator3 = () => {
    return [
        { aEvaluator3: "Alejandro" },
        { aEvaluator3: "Shahin" },
        { aEvaluator3: "Mohit" },
        { aEvaluator3: "Ankita" },
        { aEvaluator3: "Priyanka" },
    ];
};

selector.wDepartment = () => {
    return [
        { wDepartment: "" },
        { wDepartment: "Finance" },
        { wDepartment: "Sales" },
        { wDepartment: "HR" },
        { wDepartment: "IT" },
        { wDepartment: "Management" },
        { wDepartment: "Marketing" },
        { wDepartment: "Legal" },
    ];
};

selector.wCategory = () => {
    return [
        { wCategory: "" },
        { wCategory: "IT Incident" },
        { wCategory: "IT Service Request" },
        { wCategory: "IT Problem" },
        { wCategory: "IT Project" },
        { wCategory: "IT Application Bug" },
        { wCategory: "BAG" },
        { wCategory: "Accounting Ticket" },
        { wCategory: "Benefits Ticket" },
        { wCategory: "Insurance Ticket" },
        { wCategory: "Marketing Request" },
        { wCategory: "Travel Request" },
        { wCategory: "Payroll Ticket" },
        { wCategory: "Innovation Idea" },
    ];
};

selector.wStatus = ( category ) => {
    switch ( category ) {
        case "IT Incident":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
            ];
        case "IT Service Request":
            return [
                { wStatus: "Open" },
                { wStatus: "PendingApproval" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "SRCompleted" },
                { wStatus: "SRClosed" },
            ];
        case "IT Problem":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        case "IT Project":
            return [
                { wStatus: "Open" },
                { wStatus: "PendingApproval" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "PRJTCompleted" },
                { wStatus: "SRClosed" },
            ];
        case "IT Application Bug":
            return [
                { wStatus: "New" },
                { wStatus: "Feedback" },
                { wStatus: "Acknowledged" },
                { wStatus: "Confirmed" },
                { wStatus: "Resolved" },
                { wStatus: "Closed" },
                { wStatus: "COMPLETED" },
            ];
        case "BAG":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        case "Accounting Ticket":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        case "Benefits Ticket":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        case "Insurance Ticket":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        case "Marketing Request":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        case "Travel Request":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        case "Payroll Ticket":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        case "Innovation Idea":
            return [
                { wStatus: "Open" },
                { wStatus: "Working" },
                { wStatus: "WaitingOnCustomer" },
                { wStatus: "WaitingOnOthers" },
                { wStatus: "WorkCompleted" },
                { wStatus: "TicketClosed" },
                { wStatus: "ReOpen" },
            ];
        default:
            return [{ wStatus: "Please select a category" }];
    }
};

selector.defaultAssignmentGrp = ( department ) => {

    switch ( department ) {
        case "IT":
            return [
                { wAssignGroup: "IT Operations" },
                { wAssignGroup: "IT Development" },
            ];
        case "Finance":
            return [
                { wAssignGroup: "Accounting" },
                { wAssignGroup: "Expense" },
                { wAssignGroup: "Insurance" },
            ];
        case "Sales":
            return [
                { wAssignGroup: "BAG" },
                { wAssignGroup: "Sales" },
            ];
        case "HR":
            return [
                { wAssignGroup: "Benefits" },
                { wAssignGroup: "HR Operations" },
                { wAssignGroup: "Payroll" },
                { wAssignGroup: "Timesheet" },
                { wAssignGroup: "Travel" },
            ];
        case "Management":
            return [
                { wAssignGroup: "Leadership Team" },
            ];
        case "Marketing":
            return [
                { wAssignGroup: "Marketing and branding" },
            ];
        default:
            return [{ wAssignGroup: "Please select a Department" }];
    }
};

export default selector;
