export const CountryInfo = [
    {
        "id": 1,
        "name": "Afghanistan",
        "flagPng": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.webp",
        "flagSvg": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
        "currencyName": "AFN",
        "currencyDescription": "Afghan afghani",
        "currencySymbol": "؋",
        "root": "+9",
        "suffix": "3",
        "status": 1
    },
    {
        "id": 250,
        "name": "Åland Islands",
        "flagPng": "https://flagcdn.com/w320/ax.webp",
        "flagSvg": "https://flagcdn.com/ax.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "5818",
        "status": 1
    },
    {
        "id": 2,
        "name": "Albania",
        "flagPng": "https://flagcdn.com/w320/al.webp",
        "flagSvg": "https://flagcdn.com/al.svg",
        "currencyName": "ALL",
        "currencyDescription": "Albanian lek",
        "currencySymbol": "L",
        "root": "+3",
        "suffix": "55",
        "status": 1
    },
    {
        "id": 3,
        "name": "Algeria",
        "flagPng": "https://flagcdn.com/w320/dz.webp",
        "flagSvg": "https://flagcdn.com/dz.svg",
        "currencyName": "DZD",
        "currencyDescription": "Algerian dinar",
        "currencySymbol": "د.ج",
        "root": "+2",
        "suffix": "13",
        "status": 1
    },
    {
        "id": 4,
        "name": "American Samoa",
        "flagPng": "https://flagcdn.com/w320/as.webp",
        "flagSvg": "https://flagcdn.com/as.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "684",
        "status": 1
    },
    {
        "id": 5,
        "name": "Andorra",
        "flagPng": "https://flagcdn.com/w320/ad.webp",
        "flagSvg": "https://flagcdn.com/ad.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "76",
        "status": 1
    },
    {
        "id": 6,
        "name": "Angola",
        "flagPng": "https://flagcdn.com/w320/ao.webp",
        "flagSvg": "https://flagcdn.com/ao.svg",
        "currencyName": "AOA",
        "currencyDescription": "Angolan kwanza",
        "currencySymbol": "Kz",
        "root": "+2",
        "suffix": "44",
        "status": 1
    },
    {
        "id": 7,
        "name": "Anguilla",
        "flagPng": "https://flagcdn.com/w320/ai.webp",
        "flagSvg": "https://flagcdn.com/ai.svg",
        "currencyName": "XCD",
        "currencyDescription": "Eastern Caribbean dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "264",
        "status": 1
    },
    {
        "id": 8,
        "name": "Antarctica",
        "flagPng": "https://flagcdn.com/w320/aq.webp",
        "flagSvg": "https://flagcdn.com/aq.svg",
        "currencyName": "",
        "currencyDescription": "",
        "currencySymbol": "",
        "root": "",
        "suffix": "",
        "status": 1
    },
    {
        "id": 9,
        "name": "Antigua and Barbuda",
        "flagPng": "https://flagcdn.com/w320/ag.webp",
        "flagSvg": "https://flagcdn.com/ag.svg",
        "currencyName": "XCD",
        "currencyDescription": "Eastern Caribbean dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "268",
        "status": 1
    },
    {
        "id": 10,
        "name": "Argentina",
        "flagPng": "https://flagcdn.com/w320/ar.webp",
        "flagSvg": "https://flagcdn.com/ar.svg",
        "currencyName": "ARS",
        "currencyDescription": "Argentine peso",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 11,
        "name": "Armenia",
        "flagPng": "https://flagcdn.com/w320/am.webp",
        "flagSvg": "https://flagcdn.com/am.svg",
        "currencyName": "AMD",
        "currencyDescription": "Armenian dram",
        "currencySymbol": "֏",
        "root": "+3",
        "suffix": "74",
        "status": 1
    },
    {
        "id": 12,
        "name": "Aruba",
        "flagPng": "https://flagcdn.com/w320/aw.webp",
        "flagSvg": "https://flagcdn.com/aw.svg",
        "currencyName": "AWG",
        "currencyDescription": "Aruban florin",
        "currencySymbol": "ƒ",
        "root": "+2",
        "suffix": "97",
        "status": 1
    },
    {
        "id": 13,
        "name": "Australia",
        "flagPng": "https://flagcdn.com/w320/au.webp",
        "flagSvg": "https://flagcdn.com/au.svg",
        "currencyName": "AUD",
        "currencyDescription": "Australian dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "1",
        "status": 1
    },
    {
        "id": 14,
        "name": "Austria",
        "flagPng": "https://flagcdn.com/w320/at.webp",
        "flagSvg": "https://flagcdn.com/at.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+4",
        "suffix": "3",
        "status": 1
    },
    {
        "id": 15,
        "name": "Azerbaijan",
        "flagPng": "https://flagcdn.com/w320/az.webp",
        "flagSvg": "https://flagcdn.com/az.svg",
        "currencyName": "AZN",
        "currencyDescription": "Azerbaijani manat",
        "currencySymbol": "₼",
        "root": "+9",
        "suffix": "94",
        "status": 1
    },
    {
        "id": 16,
        "name": "Bahamas",
        "flagPng": "https://flagcdn.com/w320/bs.webp",
        "flagSvg": "https://flagcdn.com/bs.svg",
        "currencyName": "BSD",
        "currencyDescription": "Bahamian dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "242",
        "status": 1
    },
    {
        "id": 17,
        "name": "Bahrain",
        "flagPng": "https://flagcdn.com/w320/bh.webp",
        "flagSvg": "https://flagcdn.com/bh.svg",
        "currencyName": "BHD",
        "currencyDescription": "Bahraini dinar",
        "currencySymbol": ".د.ب",
        "root": "+9",
        "suffix": "73",
        "status": 1
    },
    {
        "id": 18,
        "name": "Bangladesh",
        "flagPng": "https://flagcdn.com/w320/bd.webp",
        "flagSvg": "https://flagcdn.com/bd.svg",
        "currencyName": "BDT",
        "currencyDescription": "Bangladeshi taka",
        "currencySymbol": "৳",
        "root": "+8",
        "suffix": "80",
        "status": 1
    },
    {
        "id": 19,
        "name": "Barbados",
        "flagPng": "https://flagcdn.com/w320/bb.webp",
        "flagSvg": "https://flagcdn.com/bb.svg",
        "currencyName": "BBD",
        "currencyDescription": "Barbadian dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "246",
        "status": 1
    },
    {
        "id": 20,
        "name": "Belarus",
        "flagPng": "https://flagcdn.com/w320/by.webp",
        "flagSvg": "https://flagcdn.com/by.svg",
        "currencyName": "BYN",
        "currencyDescription": "Belarusian ruble",
        "currencySymbol": "Br",
        "root": "+3",
        "suffix": "75",
        "status": 1
    },
    {
        "id": 21,
        "name": "Belgium",
        "flagPng": "https://flagcdn.com/w320/be.webp",
        "flagSvg": "https://flagcdn.com/be.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "2",
        "status": 1
    },
    {
        "id": 22,
        "name": "Belize",
        "flagPng": "https://flagcdn.com/w320/bz.webp",
        "flagSvg": "https://flagcdn.com/bz.svg",
        "currencyName": "BZD",
        "currencyDescription": "Belize dollar",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "01",
        "status": 1
    },
    {
        "id": 23,
        "name": "Benin",
        "flagPng": "https://flagcdn.com/w320/bj.webp",
        "flagSvg": "https://flagcdn.com/bj.svg",
        "currencyName": "XOF",
        "currencyDescription": "West African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "29",
        "status": 1
    },
    {
        "id": 24,
        "name": "Bermuda",
        "flagPng": "https://flagcdn.com/w320/bm.webp",
        "flagSvg": "https://flagcdn.com/bm.svg",
        "currencyName": "BMD",
        "currencyDescription": "Bermudian dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "441",
        "status": 1
    },
    {
        "id": 25,
        "name": "Bhutan",
        "flagPng": "https://flagcdn.com/w320/bt.webp",
        "flagSvg": "https://flagcdn.com/bt.svg",
        "currencyName": "BTN",
        "currencyDescription": "Bhutanese ngultrum",
        "currencySymbol": "Nu.",
        "root": "+9",
        "suffix": "75",
        "status": 1
    },
    {
        "id": 26,
        "name": "Bolivia",
        "flagPng": "https://flagcdn.com/w320/bo.webp",
        "flagSvg": "https://flagcdn.com/bo.svg",
        "currencyName": "BOB",
        "currencyDescription": "Bolivian boliviano",
        "currencySymbol": "Bs.",
        "root": "+5",
        "suffix": "91",
        "status": 1
    },
    {
        "id": 27,
        "name": "Bosnia and Herzegovina",
        "flagPng": "https://flagcdn.com/w320/ba.webp",
        "flagSvg": "https://flagcdn.com/ba.svg",
        "currencyName": "BAM",
        "currencyDescription": "Bosnia and Herzegovina convertible mark",
        "currencySymbol": "",
        "root": "+3",
        "suffix": "87",
        "status": 1
    },
    {
        "id": 28,
        "name": "Botswana",
        "flagPng": "https://flagcdn.com/w320/bw.webp",
        "flagSvg": "https://flagcdn.com/bw.svg",
        "currencyName": "BWP",
        "currencyDescription": "Botswana pula",
        "currencySymbol": "P",
        "root": "+2",
        "suffix": "67",
        "status": 1
    },
    {
        "id": 29,
        "name": "Bouvet Island",
        "flagPng": "https://flagcdn.com/w320/bv.webp",
        "flagSvg": "https://flagcdn.com/bv.svg",
        "currencyName": "",
        "currencyDescription": "",
        "currencySymbol": "",
        "root": "+4",
        "suffix": "7",
        "status": 1
    },
    {
        "id": 30,
        "name": "Brazil",
        "flagPng": "https://flagcdn.com/w320/br.webp",
        "flagSvg": "https://flagcdn.com/br.svg",
        "currencyName": "BRL",
        "currencyDescription": "Brazilian real",
        "currencySymbol": "R$",
        "root": "+5",
        "suffix": "5",
        "status": 1
    },
    {
        "id": 31,
        "name": "British Indian Ocean Territory",
        "flagPng": "https://flagcdn.com/w320/io.webp",
        "flagSvg": "https://flagcdn.com/io.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+2",
        "suffix": "46",
        "status": 1
    },
    {
        "id": 32,
        "name": "British Virgin Islands",
        "flagPng": "https://flagcdn.com/w320/vg.webp",
        "flagSvg": "https://flagcdn.com/vg.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "284",
        "status": 1
    },
    {
        "id": 33,
        "name": "Brunei",
        "flagPng": "https://flagcdn.com/w320/bn.webp",
        "flagSvg": "https://flagcdn.com/bn.svg",
        "currencyName": "BND",
        "currencyDescription": "Brunei dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "73",
        "status": 1
    },
    {
        "id": 34,
        "name": "Bulgaria",
        "flagPng": "https://flagcdn.com/w320/bg.webp",
        "flagSvg": "https://flagcdn.com/bg.svg",
        "currencyName": "BGN",
        "currencyDescription": "Bulgarian lev",
        "currencySymbol": "лв",
        "root": "+3",
        "suffix": "59",
        "status": 1
    },
    {
        "id": 35,
        "name": "Burkina Faso",
        "flagPng": "https://flagcdn.com/w320/bf.webp",
        "flagSvg": "https://flagcdn.com/bf.svg",
        "currencyName": "XOF",
        "currencyDescription": "West African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "26",
        "status": 1
    },
    {
        "id": 36,
        "name": "Burundi",
        "flagPng": "https://flagcdn.com/w320/bi.webp",
        "flagSvg": "https://flagcdn.com/bi.svg",
        "currencyName": "BIF",
        "currencyDescription": "Burundian franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "57",
        "status": 1
    },
    {
        "id": 37,
        "name": "Cambodia",
        "flagPng": "https://flagcdn.com/w320/kh.webp",
        "flagSvg": "https://flagcdn.com/kh.svg",
        "currencyName": "KHR",
        "currencyDescription": "Cambodian riel",
        "currencySymbol": "៛",
        "root": "+8",
        "suffix": "55",
        "status": 1
    },
    {
        "id": 38,
        "name": "Cameroon",
        "flagPng": "https://flagcdn.com/w320/cm.webp",
        "flagSvg": "https://flagcdn.com/cm.svg",
        "currencyName": "XAF",
        "currencyDescription": "Central African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "37",
        "status": 1
    },
    {
        "id": 39,
        "name": "Canada",
        "flagPng": "https://flagcdn.com/w320/ca.webp",
        "flagSvg": "https://flagcdn.com/ca.svg",
        "currencyName": "CAD",
        "currencyDescription": "Canadian dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "",
        "status": 1
    },
    {
        "id": 40,
        "name": "Cape Verde",
        "flagPng": "https://flagcdn.com/w320/cv.webp",
        "flagSvg": "https://flagcdn.com/cv.svg",
        "currencyName": "CVE",
        "currencyDescription": "Cape Verdean escudo",
        "currencySymbol": "Esc",
        "root": "+2",
        "suffix": "38",
        "status": 1
    },
    {
        "id": 41,
        "name": "Caribbean Netherlands",
        "flagPng": "https://flagcdn.com/w320/bq.webp",
        "flagSvg": "https://flagcdn.com/bq.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "99",
        "status": 1
    },
    {
        "id": 42,
        "name": "Cayman Islands",
        "flagPng": "https://flagcdn.com/w320/ky.webp",
        "flagSvg": "https://flagcdn.com/ky.svg",
        "currencyName": "KYD",
        "currencyDescription": "Cayman Islands dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "345",
        "status": 1
    },
    {
        "id": 43,
        "name": "Central African Republic",
        "flagPng": "https://flagcdn.com/w320/cf.webp",
        "flagSvg": "https://flagcdn.com/cf.svg",
        "currencyName": "XAF",
        "currencyDescription": "Central African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "36",
        "status": 1
    },
    {
        "id": 44,
        "name": "Chad",
        "flagPng": "https://flagcdn.com/w320/td.webp",
        "flagSvg": "https://flagcdn.com/td.svg",
        "currencyName": "XAF",
        "currencyDescription": "Central African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "35",
        "status": 1
    },
    {
        "id": 45,
        "name": "Chile",
        "flagPng": "https://flagcdn.com/w320/cl.webp",
        "flagSvg": "https://flagcdn.com/cl.svg",
        "currencyName": "CLP",
        "currencyDescription": "Chilean peso",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "6",
        "status": 1
    },
    {
        "id": 46,
        "name": "China",
        "flagPng": "https://flagcdn.com/w320/cn.webp",
        "flagSvg": "https://flagcdn.com/cn.svg",
        "currencyName": "CNY",
        "currencyDescription": "Chinese yuan",
        "currencySymbol": "¥",
        "root": "+8",
        "suffix": "6",
        "status": 1
    },
    {
        "id": 47,
        "name": "Christmas Island",
        "flagPng": "https://flagcdn.com/w320/cx.webp",
        "flagSvg": "https://flagcdn.com/cx.svg",
        "currencyName": "AUD",
        "currencyDescription": "Australian dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "1",
        "status": 1
    },
    {
        "id": 48,
        "name": "Cocos (Keeling) Islands",
        "flagPng": "https://flagcdn.com/w320/cc.webp",
        "flagSvg": "https://flagcdn.com/cc.svg",
        "currencyName": "AUD",
        "currencyDescription": "Australian dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "1",
        "status": 1
    },
    {
        "id": 49,
        "name": "Colombia",
        "flagPng": "https://flagcdn.com/w320/co.webp",
        "flagSvg": "https://flagcdn.com/co.svg",
        "currencyName": "COP",
        "currencyDescription": "Colombian peso",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "7",
        "status": 1
    },
    {
        "id": 50,
        "name": "Comoros",
        "flagPng": "https://flagcdn.com/w320/km.webp",
        "flagSvg": "https://flagcdn.com/km.svg",
        "currencyName": "KMF",
        "currencyDescription": "Comorian franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "69",
        "status": 1
    },
    {
        "id": 51,
        "name": "Cook Islands",
        "flagPng": "https://flagcdn.com/w320/ck.webp",
        "flagSvg": "https://flagcdn.com/ck.svg",
        "currencyName": "CKD",
        "currencyDescription": "Cook Islands dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "82",
        "status": 1
    },
    {
        "id": 52,
        "name": "Costa Rica",
        "flagPng": "https://flagcdn.com/w320/cr.webp",
        "flagSvg": "https://flagcdn.com/cr.svg",
        "currencyName": "CRC",
        "currencyDescription": "Costa Rican colón",
        "currencySymbol": "₡",
        "root": "+5",
        "suffix": "06",
        "status": 1
    },
    {
        "id": 53,
        "name": "Croatia",
        "flagPng": "https://flagcdn.com/w320/hr.webp",
        "flagSvg": "https://flagcdn.com/hr.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "85",
        "status": 1
    },
    {
        "id": 54,
        "name": "Cuba",
        "flagPng": "https://flagcdn.com/w320/cu.webp",
        "flagSvg": "https://flagcdn.com/cu.svg",
        "currencyName": "CUC",
        "currencyDescription": "Cuban convertible peso",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "3",
        "status": 1
    },
    {
        "id": 55,
        "name": "Curaçao",
        "flagPng": "https://flagcdn.com/w320/cw.webp",
        "flagSvg": "https://flagcdn.com/cw.svg",
        "currencyName": "ANG",
        "currencyDescription": "Netherlands Antillean guilder",
        "currencySymbol": "ƒ",
        "root": "+5",
        "suffix": "99",
        "status": 1
    },
    {
        "id": 56,
        "name": "Cyprus",
        "flagPng": "https://flagcdn.com/w320/cy.webp",
        "flagSvg": "https://flagcdn.com/cy.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "57",
        "status": 1
    },
    {
        "id": 57,
        "name": "Czechia",
        "flagPng": "https://flagcdn.com/w320/cz.webp",
        "flagSvg": "https://flagcdn.com/cz.svg",
        "currencyName": "CZK",
        "currencyDescription": "Czech koruna",
        "currencySymbol": "Kč",
        "root": "+4",
        "suffix": "20",
        "status": 1
    },
    {
        "id": 59,
        "name": "Denmark",
        "flagPng": "https://flagcdn.com/w320/dk.webp",
        "flagSvg": "https://flagcdn.com/dk.svg",
        "currencyName": "DKK",
        "currencyDescription": "Danish krone",
        "currencySymbol": "kr",
        "root": "+4",
        "suffix": "5",
        "status": 1
    },
    {
        "id": 60,
        "name": "Djibouti",
        "flagPng": "https://flagcdn.com/w320/dj.webp",
        "flagSvg": "https://flagcdn.com/dj.svg",
        "currencyName": "DJF",
        "currencyDescription": "Djiboutian franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "53",
        "status": 1
    },
    {
        "id": 61,
        "name": "Dominica",
        "flagPng": "https://flagcdn.com/w320/dm.webp",
        "flagSvg": "https://flagcdn.com/dm.svg",
        "currencyName": "XCD",
        "currencyDescription": "Eastern Caribbean dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "767",
        "status": 1
    },
    {
        "id": 62,
        "name": "Dominican Republic",
        "flagPng": "https://flagcdn.com/w320/do.webp",
        "flagSvg": "https://flagcdn.com/do.svg",
        "currencyName": "DOP",
        "currencyDescription": "Dominican peso",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "809",
        "status": 1
    },
    {
        "id": 58,
        "name": "DR Congo",
        "flagPng": "https://flagcdn.com/w320/cd.webp",
        "flagSvg": "https://flagcdn.com/cd.svg",
        "currencyName": "CDF",
        "currencyDescription": "Congolese franc",
        "currencySymbol": "FC",
        "root": "+2",
        "suffix": "43",
        "status": 1
    },
    {
        "id": 63,
        "name": "Ecuador",
        "flagPng": "https://flagcdn.com/w320/ec.webp",
        "flagSvg": "https://flagcdn.com/ec.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "93",
        "status": 1
    },
    {
        "id": 64,
        "name": "Egypt",
        "flagPng": "https://flagcdn.com/w320/eg.webp",
        "flagSvg": "https://flagcdn.com/eg.svg",
        "currencyName": "EGP",
        "currencyDescription": "Egyptian pound",
        "currencySymbol": "£",
        "root": "+2",
        "suffix": "0",
        "status": 1
    },
    {
        "id": 65,
        "name": "El Salvador",
        "flagPng": "https://flagcdn.com/w320/sv.webp",
        "flagSvg": "https://flagcdn.com/sv.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "03",
        "status": 1
    },
    {
        "id": 66,
        "name": "Equatorial Guinea",
        "flagPng": "https://flagcdn.com/w320/gq.webp",
        "flagSvg": "https://flagcdn.com/gq.svg",
        "currencyName": "XAF",
        "currencyDescription": "Central African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "40",
        "status": 1
    },
    {
        "id": 67,
        "name": "Eritrea",
        "flagPng": "https://flagcdn.com/w320/er.webp",
        "flagSvg": "https://flagcdn.com/er.svg",
        "currencyName": "ERN",
        "currencyDescription": "Eritrean nakfa",
        "currencySymbol": "Nfk",
        "root": "+2",
        "suffix": "91",
        "status": 1
    },
    {
        "id": 68,
        "name": "Estonia",
        "flagPng": "https://flagcdn.com/w320/ee.webp",
        "flagSvg": "https://flagcdn.com/ee.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "72",
        "status": 1
    },
    {
        "id": 69,
        "name": "Eswatini",
        "flagPng": "https://flagcdn.com/w320/sz.webp",
        "flagSvg": "https://flagcdn.com/sz.svg",
        "currencyName": "SZL",
        "currencyDescription": "Swazi lilangeni",
        "currencySymbol": "L",
        "root": "+2",
        "suffix": "68",
        "status": 1
    },
    {
        "id": 70,
        "name": "Ethiopia",
        "flagPng": "https://flagcdn.com/w320/et.webp",
        "flagSvg": "https://flagcdn.com/et.svg",
        "currencyName": "ETB",
        "currencyDescription": "Ethiopian birr",
        "currencySymbol": "Br",
        "root": "+2",
        "suffix": "51",
        "status": 1
    },
    {
        "id": 71,
        "name": "Falkland Islands",
        "flagPng": "https://flagcdn.com/w320/fk.webp",
        "flagSvg": "https://flagcdn.com/fk.svg",
        "currencyName": "FKP",
        "currencyDescription": "Falkland Islands pound",
        "currencySymbol": "£",
        "root": "+5",
        "suffix": "00",
        "status": 1
    },
    {
        "id": 72,
        "name": "Faroe Islands",
        "flagPng": "https://flagcdn.com/w320/fo.webp",
        "flagSvg": "https://flagcdn.com/fo.svg",
        "currencyName": "DKK",
        "currencyDescription": "Danish krone",
        "currencySymbol": "kr",
        "root": "+2",
        "suffix": "98",
        "status": 1
    },
    {
        "id": 73,
        "name": "Fiji",
        "flagPng": "https://flagcdn.com/w320/fj.webp",
        "flagSvg": "https://flagcdn.com/fj.svg",
        "currencyName": "FJD",
        "currencyDescription": "Fijian dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "79",
        "status": 1
    },
    {
        "id": 74,
        "name": "Finland",
        "flagPng": "https://flagcdn.com/w320/fi.webp",
        "flagSvg": "https://flagcdn.com/fi.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "58",
        "status": 1
    },
    {
        "id": 75,
        "name": "France",
        "flagPng": "https://flagcdn.com/w320/fr.webp",
        "flagSvg": "https://flagcdn.com/fr.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "3",
        "status": 1
    },
    {
        "id": 76,
        "name": "French Guiana",
        "flagPng": "https://flagcdn.com/w320/gf.webp",
        "flagSvg": "https://flagcdn.com/gf.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+5",
        "suffix": "94",
        "status": 1
    },
    {
        "id": 77,
        "name": "French Polynesia",
        "flagPng": "https://flagcdn.com/w320/pf.webp",
        "flagSvg": "https://flagcdn.com/pf.svg",
        "currencyName": "XPF",
        "currencyDescription": "CFP franc",
        "currencySymbol": "₣",
        "root": "+6",
        "suffix": "89",
        "status": 1
    },
    {
        "id": 78,
        "name": "French Southern and Antarctic Lands",
        "flagPng": "https://flagcdn.com/w320/tf.webp",
        "flagSvg": "https://flagcdn.com/tf.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+2",
        "suffix": "62",
        "status": 1
    },
    {
        "id": 79,
        "name": "Gabon",
        "flagPng": "https://flagcdn.com/w320/ga.webp",
        "flagSvg": "https://flagcdn.com/ga.svg",
        "currencyName": "XAF",
        "currencyDescription": "Central African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "41",
        "status": 1
    },
    {
        "id": 80,
        "name": "Gambia",
        "flagPng": "https://flagcdn.com/w320/gm.webp",
        "flagSvg": "https://flagcdn.com/gm.svg",
        "currencyName": "GMD",
        "currencyDescription": "dalasi",
        "currencySymbol": "D",
        "root": "+2",
        "suffix": "20",
        "status": 1
    },
    {
        "id": 81,
        "name": "Georgia",
        "flagPng": "https://flagcdn.com/w320/ge.webp",
        "flagSvg": "https://flagcdn.com/ge.svg",
        "currencyName": "GEL",
        "currencyDescription": "lari",
        "currencySymbol": "₾",
        "root": "+9",
        "suffix": "95",
        "status": 1
    },
    {
        "id": 82,
        "name": "Germany",
        "flagPng": "https://flagcdn.com/w320/de.webp",
        "flagSvg": "https://flagcdn.com/de.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+4",
        "suffix": "9",
        "status": 1
    },
    {
        "id": 83,
        "name": "Ghana",
        "flagPng": "https://flagcdn.com/w320/gh.webp",
        "flagSvg": "https://flagcdn.com/gh.svg",
        "currencyName": "GHS",
        "currencyDescription": "Ghanaian cedi",
        "currencySymbol": "₵",
        "root": "+2",
        "suffix": "33",
        "status": 1
    },
    {
        "id": 84,
        "name": "Gibraltar",
        "flagPng": "https://flagcdn.com/w320/gi.webp",
        "flagSvg": "https://flagcdn.com/gi.svg",
        "currencyName": "GIP",
        "currencyDescription": "Gibraltar pound",
        "currencySymbol": "£",
        "root": "+3",
        "suffix": "50",
        "status": 1
    },
    {
        "id": 85,
        "name": "Greece",
        "flagPng": "https://flagcdn.com/w320/gr.webp",
        "flagSvg": "https://flagcdn.com/gr.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "0",
        "status": 1
    },
    {
        "id": 86,
        "name": "Greenland",
        "flagPng": "https://flagcdn.com/w320/gl.webp",
        "flagSvg": "https://flagcdn.com/gl.svg",
        "currencyName": "DKK",
        "currencyDescription": "krone",
        "currencySymbol": "kr.",
        "root": "+2",
        "suffix": "99",
        "status": 1
    },
    {
        "id": 87,
        "name": "Grenada",
        "flagPng": "https://flagcdn.com/w320/gd.webp",
        "flagSvg": "https://flagcdn.com/gd.svg",
        "currencyName": "XCD",
        "currencyDescription": "Eastern Caribbean dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "473",
        "status": 1
    },
    {
        "id": 88,
        "name": "Guadeloupe",
        "flagPng": "https://flagcdn.com/w320/gp.webp",
        "flagSvg": "https://flagcdn.com/gp.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+5",
        "suffix": "90",
        "status": 1
    },
    {
        "id": 89,
        "name": "Guam",
        "flagPng": "https://flagcdn.com/w320/gu.webp",
        "flagSvg": "https://flagcdn.com/gu.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "671",
        "status": 1
    },
    {
        "id": 90,
        "name": "Guatemala",
        "flagPng": "https://flagcdn.com/w320/gt.webp",
        "flagSvg": "https://flagcdn.com/gt.svg",
        "currencyName": "GTQ",
        "currencyDescription": "Guatemalan quetzal",
        "currencySymbol": "Q",
        "root": "+5",
        "suffix": "02",
        "status": 1
    },
    {
        "id": 91,
        "name": "Guernsey",
        "flagPng": "https://flagcdn.com/w320/gg.webp",
        "flagSvg": "https://flagcdn.com/gg.svg",
        "currencyName": "GBP",
        "currencyDescription": "British pound",
        "currencySymbol": "£",
        "root": "+4",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 92,
        "name": "Guinea",
        "flagPng": "https://flagcdn.com/w320/gn.webp",
        "flagSvg": "https://flagcdn.com/gn.svg",
        "currencyName": "GNF",
        "currencyDescription": "Guinean franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "24",
        "status": 1
    },
    {
        "id": 93,
        "name": "Guinea-Bissau",
        "flagPng": "https://flagcdn.com/w320/gw.webp",
        "flagSvg": "https://flagcdn.com/gw.svg",
        "currencyName": "XOF",
        "currencyDescription": "West African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "45",
        "status": 1
    },
    {
        "id": 94,
        "name": "Guyana",
        "flagPng": "https://flagcdn.com/w320/gy.webp",
        "flagSvg": "https://flagcdn.com/gy.svg",
        "currencyName": "GYD",
        "currencyDescription": "Guyanese dollar",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "92",
        "status": 1
    },
    {
        "id": 95,
        "name": "Haiti",
        "flagPng": "https://flagcdn.com/w320/ht.webp",
        "flagSvg": "https://flagcdn.com/ht.svg",
        "currencyName": "HTG",
        "currencyDescription": "Haitian gourde",
        "currencySymbol": "G",
        "root": "+5",
        "suffix": "09",
        "status": 1
    },
    {
        "id": 96,
        "name": "Heard Island and McDonald Islands",
        "flagPng": "https://flagcdn.com/w320/hm.webp",
        "flagSvg": "https://flagcdn.com/hm.svg",
        "currencyName": "",
        "currencyDescription": "",
        "currencySymbol": "",
        "root": "",
        "suffix": "",
        "status": 1
    },
    {
        "id": 97,
        "name": "Honduras",
        "flagPng": "https://flagcdn.com/w320/hn.webp",
        "flagSvg": "https://flagcdn.com/hn.svg",
        "currencyName": "HNL",
        "currencyDescription": "Honduran lempira",
        "currencySymbol": "L",
        "root": "+5",
        "suffix": "04",
        "status": 1
    },
    {
        "id": 98,
        "name": "Hong Kong",
        "flagPng": "https://flagcdn.com/w320/hk.webp",
        "flagSvg": "https://flagcdn.com/hk.svg",
        "currencyName": "HKD",
        "currencyDescription": "Hong Kong dollar",
        "currencySymbol": "$",
        "root": "+8",
        "suffix": "52",
        "status": 1
    },
    {
        "id": 99,
        "name": "Hungary",
        "flagPng": "https://flagcdn.com/w320/hu.webp",
        "flagSvg": "https://flagcdn.com/hu.svg",
        "currencyName": "HUF",
        "currencyDescription": "Hungarian forint",
        "currencySymbol": "Ft",
        "root": "+3",
        "suffix": "6",
        "status": 1
    },
    {
        "id": 100,
        "name": "Iceland",
        "flagPng": "https://flagcdn.com/w320/is.webp",
        "flagSvg": "https://flagcdn.com/is.svg",
        "currencyName": "ISK",
        "currencyDescription": "Icelandic króna",
        "currencySymbol": "kr",
        "root": "+3",
        "suffix": "54",
        "status": 1
    },
    {
        "id": 101,
        "name": "India",
        "flagPng": "https://flagcdn.com/w320/in.webp",
        "flagSvg": "https://flagcdn.com/in.svg",
        "currencyName": "INR",
        "currencyDescription": "Indian rupee",
        "currencySymbol": "₹",
        "root": "+9",
        "suffix": "1",
        "status": 1
    },
    {
        "id": 102,
        "name": "Indonesia",
        "flagPng": "https://flagcdn.com/w320/id.webp",
        "flagSvg": "https://flagcdn.com/id.svg",
        "currencyName": "IDR",
        "currencyDescription": "Indonesian rupiah",
        "currencySymbol": "Rp",
        "root": "+6",
        "suffix": "2",
        "status": 1
    },
    {
        "id": 103,
        "name": "Iran",
        "flagPng": "https://flagcdn.com/w320/ir.webp",
        "flagSvg": "https://flagcdn.com/ir.svg",
        "currencyName": "IRR",
        "currencyDescription": "Iranian rial",
        "currencySymbol": "﷼",
        "root": "+9",
        "suffix": "8",
        "status": 1
    },
    {
        "id": 104,
        "name": "Iraq",
        "flagPng": "https://flagcdn.com/w320/iq.webp",
        "flagSvg": "https://flagcdn.com/iq.svg",
        "currencyName": "IQD",
        "currencyDescription": "Iraqi dinar",
        "currencySymbol": "ع.د",
        "root": "+9",
        "suffix": "64",
        "status": 1
    },
    {
        "id": 105,
        "name": "Ireland",
        "flagPng": "https://flagcdn.com/w320/ie.webp",
        "flagSvg": "https://flagcdn.com/ie.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "53",
        "status": 1
    },
    {
        "id": 106,
        "name": "Isle of Man",
        "flagPng": "https://flagcdn.com/w320/im.webp",
        "flagSvg": "https://flagcdn.com/im.svg",
        "currencyName": "GBP",
        "currencyDescription": "British pound",
        "currencySymbol": "£",
        "root": "+4",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 107,
        "name": "Israel",
        "flagPng": "https://flagcdn.com/w320/il.webp",
        "flagSvg": "https://flagcdn.com/il.svg",
        "currencyName": "ILS",
        "currencyDescription": "Israeli new shekel",
        "currencySymbol": "₪",
        "root": "+9",
        "suffix": "72",
        "status": 1
    },
    {
        "id": 108,
        "name": "Italy",
        "flagPng": "https://flagcdn.com/w320/it.webp",
        "flagSvg": "https://flagcdn.com/it.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "9",
        "status": 1
    },
    {
        "id": 109,
        "name": "Ivory Coast",
        "flagPng": "https://flagcdn.com/w320/ci.webp",
        "flagSvg": "https://flagcdn.com/ci.svg",
        "currencyName": "XOF",
        "currencyDescription": "West African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "25",
        "status": 1
    },
    {
        "id": 110,
        "name": "Jamaica",
        "flagPng": "https://flagcdn.com/w320/jm.webp",
        "flagSvg": "https://flagcdn.com/jm.svg",
        "currencyName": "JMD",
        "currencyDescription": "Jamaican dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "876",
        "status": 1
    },
    {
        "id": 111,
        "name": "Japan",
        "flagPng": "https://flagcdn.com/w320/jp.webp",
        "flagSvg": "https://flagcdn.com/jp.svg",
        "currencyName": "JPY",
        "currencyDescription": "Japanese yen",
        "currencySymbol": "¥",
        "root": "+8",
        "suffix": "1",
        "status": 1
    },
    {
        "id": 112,
        "name": "Jersey",
        "flagPng": "https://flagcdn.com/w320/je.webp",
        "flagSvg": "https://flagcdn.com/je.svg",
        "currencyName": "GBP",
        "currencyDescription": "British pound",
        "currencySymbol": "£",
        "root": "+4",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 113,
        "name": "Jordan",
        "flagPng": "https://flagcdn.com/w320/jo.webp",
        "flagSvg": "https://flagcdn.com/jo.svg",
        "currencyName": "JOD",
        "currencyDescription": "Jordanian dinar",
        "currencySymbol": "د.ا",
        "root": "+9",
        "suffix": "62",
        "status": 1
    },
    {
        "id": 114,
        "name": "Kazakhstan",
        "flagPng": "https://flagcdn.com/w320/kz.webp",
        "flagSvg": "https://flagcdn.com/kz.svg",
        "currencyName": "KZT",
        "currencyDescription": "Kazakhstani tenge",
        "currencySymbol": "₸",
        "root": "+7",
        "suffix": "6",
        "status": 1
    },
    {
        "id": 115,
        "name": "Kenya",
        "flagPng": "https://flagcdn.com/w320/ke.webp",
        "flagSvg": "https://flagcdn.com/ke.svg",
        "currencyName": "KES",
        "currencyDescription": "Kenyan shilling",
        "currencySymbol": "Sh",
        "root": "+2",
        "suffix": "54",
        "status": 1
    },
    {
        "id": 116,
        "name": "Kiribati",
        "flagPng": "https://flagcdn.com/w320/ki.webp",
        "flagSvg": "https://flagcdn.com/ki.svg",
        "currencyName": "AUD",
        "currencyDescription": "Australian dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "86",
        "status": 1
    },
    {
        "id": 117,
        "name": "Kosovo",
        "flagPng": "https://flagcdn.com/w320/xk.webp",
        "flagSvg": "https://flagcdn.com/xk.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "83",
        "status": 1
    },
    {
        "id": 118,
        "name": "Kuwait",
        "flagPng": "https://flagcdn.com/w320/kw.webp",
        "flagSvg": "https://flagcdn.com/kw.svg",
        "currencyName": "KWD",
        "currencyDescription": "Kuwaiti dinar",
        "currencySymbol": "د.ك",
        "root": "+9",
        "suffix": "65",
        "status": 1
    },
    {
        "id": 119,
        "name": "Kyrgyzstan",
        "flagPng": "https://flagcdn.com/w320/kg.webp",
        "flagSvg": "https://flagcdn.com/kg.svg",
        "currencyName": "KGS",
        "currencyDescription": "Kyrgyzstani som",
        "currencySymbol": "с",
        "root": "+9",
        "suffix": "96",
        "status": 1
    },
    {
        "id": 120,
        "name": "Laos",
        "flagPng": "https://flagcdn.com/w320/la.webp",
        "flagSvg": "https://flagcdn.com/la.svg",
        "currencyName": "LAK",
        "currencyDescription": "Lao kip",
        "currencySymbol": "₭",
        "root": "+8",
        "suffix": "56",
        "status": 1
    },
    {
        "id": 121,
        "name": "Latvia",
        "flagPng": "https://flagcdn.com/w320/lv.webp",
        "flagSvg": "https://flagcdn.com/lv.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "71",
        "status": 1
    },
    {
        "id": 122,
        "name": "Lebanon",
        "flagPng": "https://flagcdn.com/w320/lb.webp",
        "flagSvg": "https://flagcdn.com/lb.svg",
        "currencyName": "LBP",
        "currencyDescription": "Lebanese pound",
        "currencySymbol": "ل.ل",
        "root": "+9",
        "suffix": "61",
        "status": 1
    },
    {
        "id": 123,
        "name": "Lesotho",
        "flagPng": "https://flagcdn.com/w320/ls.webp",
        "flagSvg": "https://flagcdn.com/ls.svg",
        "currencyName": "LSL",
        "currencyDescription": "Lesotho loti",
        "currencySymbol": "L",
        "root": "+2",
        "suffix": "66",
        "status": 1
    },
    {
        "id": 124,
        "name": "Liberia",
        "flagPng": "https://flagcdn.com/w320/lr.webp",
        "flagSvg": "https://flagcdn.com/lr.svg",
        "currencyName": "LRD",
        "currencyDescription": "Liberian dollar",
        "currencySymbol": "$",
        "root": "+2",
        "suffix": "31",
        "status": 1
    },
    {
        "id": 125,
        "name": "Libya",
        "flagPng": "https://flagcdn.com/w320/ly.webp",
        "flagSvg": "https://flagcdn.com/ly.svg",
        "currencyName": "LYD",
        "currencyDescription": "Libyan dinar",
        "currencySymbol": "ل.د",
        "root": "+2",
        "suffix": "18",
        "status": 1
    },
    {
        "id": 126,
        "name": "Liechtenstein",
        "flagPng": "https://flagcdn.com/w320/li.webp",
        "flagSvg": "https://flagcdn.com/li.svg",
        "currencyName": "CHF",
        "currencyDescription": "Swiss franc",
        "currencySymbol": "Fr",
        "root": "+4",
        "suffix": "23",
        "status": 1
    },
    {
        "id": 127,
        "name": "Lithuania",
        "flagPng": "https://flagcdn.com/w320/lt.webp",
        "flagSvg": "https://flagcdn.com/lt.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "70",
        "status": 1
    },
    {
        "id": 128,
        "name": "Luxembourg",
        "flagPng": "https://flagcdn.com/w320/lu.webp",
        "flagSvg": "https://flagcdn.com/lu.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "52",
        "status": 1
    },
    {
        "id": 129,
        "name": "Macau",
        "flagPng": "https://flagcdn.com/w320/mo.webp",
        "flagSvg": "https://flagcdn.com/mo.svg",
        "currencyName": "MOP",
        "currencyDescription": "Macanese pataca",
        "currencySymbol": "P",
        "root": "+8",
        "suffix": "53",
        "status": 1
    },
    {
        "id": 130,
        "name": "Madagascar",
        "flagPng": "https://flagcdn.com/w320/mg.webp",
        "flagSvg": "https://flagcdn.com/mg.svg",
        "currencyName": "MGA",
        "currencyDescription": "Malagasy ariary",
        "currencySymbol": "Ar",
        "root": "+2",
        "suffix": "61",
        "status": 1
    },
    {
        "id": 131,
        "name": "Malawi",
        "flagPng": "https://flagcdn.com/w320/mw.webp",
        "flagSvg": "https://flagcdn.com/mw.svg",
        "currencyName": "MWK",
        "currencyDescription": "Malawian kwacha",
        "currencySymbol": "MK",
        "root": "+2",
        "suffix": "65",
        "status": 1
    },
    {
        "id": 132,
        "name": "Malaysia",
        "flagPng": "https://flagcdn.com/w320/my.webp",
        "flagSvg": "https://flagcdn.com/my.svg",
        "currencyName": "MYR",
        "currencyDescription": "Malaysian ringgit",
        "currencySymbol": "RM",
        "root": "+6",
        "suffix": "0",
        "status": 1
    },
    {
        "id": 133,
        "name": "Maldives",
        "flagPng": "https://flagcdn.com/w320/mv.webp",
        "flagSvg": "https://flagcdn.com/mv.svg",
        "currencyName": "MVR",
        "currencyDescription": "Maldivian rufiyaa",
        "currencySymbol": ".ރ",
        "root": "+9",
        "suffix": "60",
        "status": 1
    },
    {
        "id": 134,
        "name": "Mali",
        "flagPng": "https://flagcdn.com/w320/ml.webp",
        "flagSvg": "https://flagcdn.com/ml.svg",
        "currencyName": "XOF",
        "currencyDescription": "West African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "23",
        "status": 1
    },
    {
        "id": 135,
        "name": "Malta",
        "flagPng": "https://flagcdn.com/w320/mt.webp",
        "flagSvg": "https://flagcdn.com/mt.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "56",
        "status": 1
    },
    {
        "id": 136,
        "name": "Marshall Islands",
        "flagPng": "https://flagcdn.com/w320/mh.webp",
        "flagSvg": "https://flagcdn.com/mh.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "92",
        "status": 1
    },
    {
        "id": 137,
        "name": "Martinique",
        "flagPng": "https://flagcdn.com/w320/mq.webp",
        "flagSvg": "https://flagcdn.com/mq.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+5",
        "suffix": "96",
        "status": 1
    },
    {
        "id": 138,
        "name": "Mauritania",
        "flagPng": "https://flagcdn.com/w320/mr.webp",
        "flagSvg": "https://flagcdn.com/mr.svg",
        "currencyName": "MRU",
        "currencyDescription": "Mauritanian ouguiya",
        "currencySymbol": "UM",
        "root": "+2",
        "suffix": "22",
        "status": 1
    },
    {
        "id": 139,
        "name": "Mauritius",
        "flagPng": "https://flagcdn.com/w320/mu.webp",
        "flagSvg": "https://flagcdn.com/mu.svg",
        "currencyName": "MUR",
        "currencyDescription": "Mauritian rupee",
        "currencySymbol": "₨",
        "root": "+2",
        "suffix": "30",
        "status": 1
    },
    {
        "id": 140,
        "name": "Mayotte",
        "flagPng": "https://flagcdn.com/w320/yt.webp",
        "flagSvg": "https://flagcdn.com/yt.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+2",
        "suffix": "62",
        "status": 1
    },
    {
        "id": 141,
        "name": "Mexico",
        "flagPng": "https://flagcdn.com/w320/mx.webp",
        "flagSvg": "https://flagcdn.com/mx.svg",
        "currencyName": "MXN",
        "currencyDescription": "Mexican peso",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "2",
        "status": 1
    },
    {
        "id": 142,
        "name": "Micronesia",
        "flagPng": "https://flagcdn.com/w320/fm.webp",
        "flagSvg": "https://flagcdn.com/fm.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "91",
        "status": 1
    },
    {
        "id": 143,
        "name": "Moldova",
        "flagPng": "https://flagcdn.com/w320/md.webp",
        "flagSvg": "https://flagcdn.com/md.svg",
        "currencyName": "MDL",
        "currencyDescription": "Moldovan leu",
        "currencySymbol": "L",
        "root": "+3",
        "suffix": "73",
        "status": 1
    },
    {
        "id": 144,
        "name": "Monaco",
        "flagPng": "https://flagcdn.com/w320/mc.webp",
        "flagSvg": "https://flagcdn.com/mc.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "77",
        "status": 1
    },
    {
        "id": 145,
        "name": "Mongolia",
        "flagPng": "https://flagcdn.com/w320/mn.webp",
        "flagSvg": "https://flagcdn.com/mn.svg",
        "currencyName": "MNT",
        "currencyDescription": "Mongolian tögrög",
        "currencySymbol": "₮",
        "root": "+9",
        "suffix": "76",
        "status": 1
    },
    {
        "id": 146,
        "name": "Montenegro",
        "flagPng": "https://flagcdn.com/w320/me.webp",
        "flagSvg": "https://flagcdn.com/me.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "82",
        "status": 1
    },
    {
        "id": 147,
        "name": "Montserrat",
        "flagPng": "https://flagcdn.com/w320/ms.webp",
        "flagSvg": "https://flagcdn.com/ms.svg",
        "currencyName": "XCD",
        "currencyDescription": "Eastern Caribbean dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "664",
        "status": 1
    },
    {
        "id": 148,
        "name": "Morocco",
        "flagPng": "https://flagcdn.com/w320/ma.webp",
        "flagSvg": "https://flagcdn.com/ma.svg",
        "currencyName": "MAD",
        "currencyDescription": "Moroccan dirham",
        "currencySymbol": "د.م.",
        "root": "+2",
        "suffix": "12",
        "status": 1
    },
    {
        "id": 149,
        "name": "Mozambique",
        "flagPng": "https://flagcdn.com/w320/mz.webp",
        "flagSvg": "https://flagcdn.com/mz.svg",
        "currencyName": "MZN",
        "currencyDescription": "Mozambican metical",
        "currencySymbol": "MT",
        "root": "+2",
        "suffix": "58",
        "status": 1
    },
    {
        "id": 150,
        "name": "Myanmar",
        "flagPng": "https://flagcdn.com/w320/mm.webp",
        "flagSvg": "https://flagcdn.com/mm.svg",
        "currencyName": "MMK",
        "currencyDescription": "Burmese kyat",
        "currencySymbol": "Ks",
        "root": "+9",
        "suffix": "5",
        "status": 1
    },
    {
        "id": 151,
        "name": "Namibia",
        "flagPng": "https://flagcdn.com/w320/na.webp",
        "flagSvg": "https://flagcdn.com/na.svg",
        "currencyName": "NAD",
        "currencyDescription": "Namibian dollar",
        "currencySymbol": "$",
        "root": "+2",
        "suffix": "64",
        "status": 1
    },
    {
        "id": 152,
        "name": "Nauru",
        "flagPng": "https://flagcdn.com/w320/nr.webp",
        "flagSvg": "https://flagcdn.com/nr.svg",
        "currencyName": "AUD",
        "currencyDescription": "Australian dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "74",
        "status": 1
    },
    {
        "id": 153,
        "name": "Nepal",
        "flagPng": "https://flagcdn.com/w320/np.webp",
        "flagSvg": "https://flagcdn.com/np.svg",
        "currencyName": "NPR",
        "currencyDescription": "Nepalese rupee",
        "currencySymbol": "₨",
        "root": "+9",
        "suffix": "77",
        "status": 1
    },
    {
        "id": 154,
        "name": "Netherlands",
        "flagPng": "https://flagcdn.com/w320/nl.webp",
        "flagSvg": "https://flagcdn.com/nl.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "1",
        "status": 1
    },
    {
        "id": 155,
        "name": "New Caledonia",
        "flagPng": "https://flagcdn.com/w320/nc.webp",
        "flagSvg": "https://flagcdn.com/nc.svg",
        "currencyName": "XPF",
        "currencyDescription": "CFP franc",
        "currencySymbol": "₣",
        "root": "+6",
        "suffix": "87",
        "status": 1
    },
    {
        "id": 156,
        "name": "New Zealand",
        "flagPng": "https://flagcdn.com/w320/nz.webp",
        "flagSvg": "https://flagcdn.com/nz.svg",
        "currencyName": "NZD",
        "currencyDescription": "New Zealand dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 157,
        "name": "Nicaragua",
        "flagPng": "https://flagcdn.com/w320/ni.webp",
        "flagSvg": "https://flagcdn.com/ni.svg",
        "currencyName": "NIO",
        "currencyDescription": "Nicaraguan córdoba",
        "currencySymbol": "C$",
        "root": "+5",
        "suffix": "05",
        "status": 1
    },
    {
        "id": 158,
        "name": "Niger",
        "flagPng": "https://flagcdn.com/w320/ne.webp",
        "flagSvg": "https://flagcdn.com/ne.svg",
        "currencyName": "XOF",
        "currencyDescription": "West African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "27",
        "status": 1
    },
    {
        "id": 159,
        "name": "Nigeria",
        "flagPng": "https://flagcdn.com/w320/ng.webp",
        "flagSvg": "https://flagcdn.com/ng.svg",
        "currencyName": "NGN",
        "currencyDescription": "Nigerian naira",
        "currencySymbol": "₦",
        "root": "+2",
        "suffix": "34",
        "status": 1
    },
    {
        "id": 160,
        "name": "Niue",
        "flagPng": "https://flagcdn.com/w320/nu.webp",
        "flagSvg": "https://flagcdn.com/nu.svg",
        "currencyName": "NZD",
        "currencyDescription": "New Zealand dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "83",
        "status": 1
    },
    {
        "id": 161,
        "name": "Norfolk Island",
        "flagPng": "https://flagcdn.com/w320/nf.webp",
        "flagSvg": "https://flagcdn.com/nf.svg",
        "currencyName": "AUD",
        "currencyDescription": "Australian dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "72",
        "status": 1
    },
    {
        "id": 162,
        "name": "North Korea",
        "flagPng": "https://flagcdn.com/w320/kp.webp",
        "flagSvg": "https://flagcdn.com/kp.svg",
        "currencyName": "KPW",
        "currencyDescription": "North Korean won",
        "currencySymbol": "₩",
        "root": "+8",
        "suffix": "50",
        "status": 1
    },
    {
        "id": 163,
        "name": "North Macedonia",
        "flagPng": "https://flagcdn.com/w320/mk.webp",
        "flagSvg": "https://flagcdn.com/mk.svg",
        "currencyName": "MKD",
        "currencyDescription": "denar",
        "currencySymbol": "den",
        "root": "+3",
        "suffix": "89",
        "status": 1
    },
    {
        "id": 164,
        "name": "Northern Mariana Islands",
        "flagPng": "https://flagcdn.com/w320/mp.webp",
        "flagSvg": "https://flagcdn.com/mp.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "670",
        "status": 1
    },
    {
        "id": 165,
        "name": "Norway",
        "flagPng": "https://flagcdn.com/w320/no.webp",
        "flagSvg": "https://flagcdn.com/no.svg",
        "currencyName": "NOK",
        "currencyDescription": "Norwegian krone",
        "currencySymbol": "kr",
        "root": "+4",
        "suffix": "7",
        "status": 1
    },
    {
        "id": 166,
        "name": "Oman",
        "flagPng": "https://flagcdn.com/w320/om.webp",
        "flagSvg": "https://flagcdn.com/om.svg",
        "currencyName": "OMR",
        "currencyDescription": "Omani rial",
        "currencySymbol": "ر.ع.",
        "root": "+9",
        "suffix": "68",
        "status": 1
    },
    {
        "id": 167,
        "name": "Pakistan",
        "flagPng": "https://flagcdn.com/w320/pk.webp",
        "flagSvg": "https://flagcdn.com/pk.svg",
        "currencyName": "PKR",
        "currencyDescription": "Pakistani rupee",
        "currencySymbol": "₨",
        "root": "+9",
        "suffix": "2",
        "status": 1
    },
    {
        "id": 168,
        "name": "Palau",
        "flagPng": "https://flagcdn.com/w320/pw.webp",
        "flagSvg": "https://flagcdn.com/pw.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "80",
        "status": 1
    },
    {
        "id": 169,
        "name": "Palestine",
        "flagPng": "https://flagcdn.com/w320/ps.webp",
        "flagSvg": "https://flagcdn.com/ps.svg",
        "currencyName": "EGP",
        "currencyDescription": "Egyptian pound",
        "currencySymbol": "E£",
        "root": "+9",
        "suffix": "70",
        "status": 1
    },
    {
        "id": 170,
        "name": "Panama",
        "flagPng": "https://flagcdn.com/w320/pa.webp",
        "flagSvg": "https://flagcdn.com/pa.svg",
        "currencyName": "PAB",
        "currencyDescription": "Panamanian balboa",
        "currencySymbol": "B/.",
        "root": "+5",
        "suffix": "07",
        "status": 1
    },
    {
        "id": 171,
        "name": "Papua New Guinea",
        "flagPng": "https://flagcdn.com/w320/pg.webp",
        "flagSvg": "https://flagcdn.com/pg.svg",
        "currencyName": "PGK",
        "currencyDescription": "Papua New Guinean kina",
        "currencySymbol": "K",
        "root": "+6",
        "suffix": "75",
        "status": 1
    },
    {
        "id": 172,
        "name": "Paraguay",
        "flagPng": "https://flagcdn.com/w320/py.webp",
        "flagSvg": "https://flagcdn.com/py.svg",
        "currencyName": "PYG",
        "currencyDescription": "Paraguayan guaraní",
        "currencySymbol": "₲",
        "root": "+5",
        "suffix": "95",
        "status": 1
    },
    {
        "id": 173,
        "name": "Peru",
        "flagPng": "https://flagcdn.com/w320/pe.webp",
        "flagSvg": "https://flagcdn.com/pe.svg",
        "currencyName": "PEN",
        "currencyDescription": "Peruvian sol",
        "currencySymbol": "S/ ",
        "root": "+5",
        "suffix": "1",
        "status": 1
    },
    {
        "id": 174,
        "name": "Philippines",
        "flagPng": "https://flagcdn.com/w320/ph.webp",
        "flagSvg": "https://flagcdn.com/ph.svg",
        "currencyName": "PHP",
        "currencyDescription": "Philippine peso",
        "currencySymbol": "₱",
        "root": "+6",
        "suffix": "3",
        "status": 1
    },
    {
        "id": 175,
        "name": "Pitcairn Islands",
        "flagPng": "https://flagcdn.com/w320/pn.webp",
        "flagSvg": "https://flagcdn.com/pn.svg",
        "currencyName": "NZD",
        "currencyDescription": "New Zealand dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 176,
        "name": "Poland",
        "flagPng": "https://flagcdn.com/w320/pl.webp",
        "flagSvg": "https://flagcdn.com/pl.svg",
        "currencyName": "PLN",
        "currencyDescription": "Polish złoty",
        "currencySymbol": "zł",
        "root": "+4",
        "suffix": "8",
        "status": 1
    },
    {
        "id": 177,
        "name": "Portugal",
        "flagPng": "https://flagcdn.com/w320/pt.webp",
        "flagSvg": "https://flagcdn.com/pt.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "51",
        "status": 1
    },
    {
        "id": 178,
        "name": "Puerto Rico",
        "flagPng": "https://flagcdn.com/w320/pr.webp",
        "flagSvg": "https://flagcdn.com/pr.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "787",
        "status": 1
    },
    {
        "id": 179,
        "name": "Qatar",
        "flagPng": "https://flagcdn.com/w320/qa.webp",
        "flagSvg": "https://flagcdn.com/qa.svg",
        "currencyName": "QAR",
        "currencyDescription": "Qatari riyal",
        "currencySymbol": "ر.ق",
        "root": "+9",
        "suffix": "74",
        "status": 1
    },
    {
        "id": 180,
        "name": "Republic of the Congo",
        "flagPng": "https://flagcdn.com/w320/cg.webp",
        "flagSvg": "https://flagcdn.com/cg.svg",
        "currencyName": "XAF",
        "currencyDescription": "Central African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "42",
        "status": 1
    },
    {
        "id": 184,
        "name": "Réunion",
        "flagPng": "https://flagcdn.com/w320/re.webp",
        "flagSvg": "https://flagcdn.com/re.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+2",
        "suffix": "62",
        "status": 1
    },
    {
        "id": 181,
        "name": "Romania",
        "flagPng": "https://flagcdn.com/w320/ro.webp",
        "flagSvg": "https://flagcdn.com/ro.svg",
        "currencyName": "RON",
        "currencyDescription": "Romanian leu",
        "currencySymbol": "lei",
        "root": "+4",
        "suffix": "0",
        "status": 1
    },
    {
        "id": 182,
        "name": "Russia",
        "flagPng": "https://flagcdn.com/w320/ru.webp",
        "flagSvg": "https://flagcdn.com/ru.svg",
        "currencyName": "RUB",
        "currencyDescription": "Russian ruble",
        "currencySymbol": "₽",
        "root": "+7",
        "suffix": "3",
        "status": 1
    },
    {
        "id": 183,
        "name": "Rwanda",
        "flagPng": "https://flagcdn.com/w320/rw.webp",
        "flagSvg": "https://flagcdn.com/rw.svg",
        "currencyName": "RWF",
        "currencyDescription": "Rwandan franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "50",
        "status": 1
    },
    {
        "id": 185,
        "name": "Saint Barthélemy",
        "flagPng": "https://flagcdn.com/w320/bl.webp",
        "flagSvg": "https://flagcdn.com/bl.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+5",
        "suffix": "90",
        "status": 1
    },
    {
        "id": 186,
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "flagPng": "https://flagcdn.com/w320/sh.webp",
        "flagSvg": "https://flagcdn.com/sh.svg",
        "currencyName": "GBP",
        "currencyDescription": "Pound sterling",
        "currencySymbol": "£",
        "root": "+2",
        "suffix": "90",
        "status": 1
    },
    {
        "id": 187,
        "name": "Saint Kitts and Nevis",
        "flagPng": "https://flagcdn.com/w320/kn.webp",
        "flagSvg": "https://flagcdn.com/kn.svg",
        "currencyName": "XCD",
        "currencyDescription": "Eastern Caribbean dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "869",
        "status": 1
    },
    {
        "id": 188,
        "name": "Saint Lucia",
        "flagPng": "https://flagcdn.com/w320/lc.webp",
        "flagSvg": "https://flagcdn.com/lc.svg",
        "currencyName": "XCD",
        "currencyDescription": "Eastern Caribbean dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "758",
        "status": 1
    },
    {
        "id": 189,
        "name": "Saint Martin",
        "flagPng": "https://flagcdn.com/w320/mf.webp",
        "flagSvg": "https://flagcdn.com/mf.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+5",
        "suffix": "90",
        "status": 1
    },
    {
        "id": 190,
        "name": "Saint Pierre and Miquelon",
        "flagPng": "https://flagcdn.com/w320/pm.webp",
        "flagSvg": "https://flagcdn.com/pm.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+5",
        "suffix": "08",
        "status": 1
    },
    {
        "id": 191,
        "name": "Saint Vincent and the Grenadines",
        "flagPng": "https://flagcdn.com/w320/vc.webp",
        "flagSvg": "https://flagcdn.com/vc.svg",
        "currencyName": "XCD",
        "currencyDescription": "Eastern Caribbean dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "784",
        "status": 1
    },
    {
        "id": 192,
        "name": "Samoa",
        "flagPng": "https://flagcdn.com/w320/ws.webp",
        "flagSvg": "https://flagcdn.com/ws.svg",
        "currencyName": "WST",
        "currencyDescription": "Samoan tālā",
        "currencySymbol": "T",
        "root": "+6",
        "suffix": "85",
        "status": 1
    },
    {
        "id": 193,
        "name": "San Marino",
        "flagPng": "https://flagcdn.com/w320/sm.webp",
        "flagSvg": "https://flagcdn.com/sm.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "78",
        "status": 1
    },
    {
        "id": 217,
        "name": "São Tomé and Príncipe",
        "flagPng": "https://flagcdn.com/w320/st.webp",
        "flagSvg": "https://flagcdn.com/st.svg",
        "currencyName": "STN",
        "currencyDescription": "São Tomé and Príncipe dobra",
        "currencySymbol": "Db",
        "root": "+2",
        "suffix": "39",
        "status": 1
    },
    {
        "id": 194,
        "name": "Saudi Arabia",
        "flagPng": "https://flagcdn.com/w320/sa.webp",
        "flagSvg": "https://flagcdn.com/sa.svg",
        "currencyName": "SAR",
        "currencyDescription": "Saudi riyal",
        "currencySymbol": "ر.س",
        "root": "+9",
        "suffix": "66",
        "status": 1
    },
    {
        "id": 195,
        "name": "Senegal",
        "flagPng": "https://flagcdn.com/w320/sn.webp",
        "flagSvg": "https://flagcdn.com/sn.svg",
        "currencyName": "XOF",
        "currencyDescription": "West African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "21",
        "status": 1
    },
    {
        "id": 196,
        "name": "Serbia",
        "flagPng": "https://flagcdn.com/w320/rs.webp",
        "flagSvg": "https://flagcdn.com/rs.svg",
        "currencyName": "RSD",
        "currencyDescription": "Serbian dinar",
        "currencySymbol": "дин.",
        "root": "+3",
        "suffix": "81",
        "status": 1
    },
    {
        "id": 197,
        "name": "Seychelles",
        "flagPng": "https://flagcdn.com/w320/sc.webp",
        "flagSvg": "https://flagcdn.com/sc.svg",
        "currencyName": "SCR",
        "currencyDescription": "Seychellois rupee",
        "currencySymbol": "₨",
        "root": "+2",
        "suffix": "48",
        "status": 1
    },
    {
        "id": 198,
        "name": "Sierra Leone",
        "flagPng": "https://flagcdn.com/w320/sl.webp",
        "flagSvg": "https://flagcdn.com/sl.svg",
        "currencyName": "SLL",
        "currencyDescription": "Sierra Leonean leone",
        "currencySymbol": "Le",
        "root": "+2",
        "suffix": "32",
        "status": 1
    },
    {
        "id": 199,
        "name": "Singapore",
        "flagPng": "https://flagcdn.com/w320/sg.webp",
        "flagSvg": "https://flagcdn.com/sg.svg",
        "currencyName": "SGD",
        "currencyDescription": "Singapore dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "5",
        "status": 1
    },
    {
        "id": 200,
        "name": "Sint Maarten",
        "flagPng": "https://flagcdn.com/w320/sx.webp",
        "flagSvg": "https://flagcdn.com/sx.svg",
        "currencyName": "ANG",
        "currencyDescription": "Netherlands Antillean guilder",
        "currencySymbol": "ƒ",
        "root": "+1",
        "suffix": "721",
        "status": 1
    },
    {
        "id": 201,
        "name": "Slovakia",
        "flagPng": "https://flagcdn.com/w320/sk.webp",
        "flagSvg": "https://flagcdn.com/sk.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+4",
        "suffix": "21",
        "status": 1
    },
    {
        "id": 202,
        "name": "Slovenia",
        "flagPng": "https://flagcdn.com/w320/si.webp",
        "flagSvg": "https://flagcdn.com/si.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "86",
        "status": 1
    },
    {
        "id": 203,
        "name": "Solomon Islands",
        "flagPng": "https://flagcdn.com/w320/sb.webp",
        "flagSvg": "https://flagcdn.com/sb.svg",
        "currencyName": "SBD",
        "currencyDescription": "Solomon Islands dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "77",
        "status": 1
    },
    {
        "id": 204,
        "name": "Somalia",
        "flagPng": "https://flagcdn.com/w320/so.webp",
        "flagSvg": "https://flagcdn.com/so.svg",
        "currencyName": "SOS",
        "currencyDescription": "Somali shilling",
        "currencySymbol": "Sh",
        "root": "+2",
        "suffix": "52",
        "status": 1
    },
    {
        "id": 205,
        "name": "South Africa",
        "flagPng": "https://flagcdn.com/w320/za.webp",
        "flagSvg": "https://flagcdn.com/za.svg",
        "currencyName": "ZAR",
        "currencyDescription": "South African rand",
        "currencySymbol": "R",
        "root": "+2",
        "suffix": "7",
        "status": 1
    },
    {
        "id": 206,
        "name": "South Georgia",
        "flagPng": "https://flagcdn.com/w320/gs.webp",
        "flagSvg": "https://flagcdn.com/gs.svg",
        "currencyName": "SHP",
        "currencyDescription": "Saint Helena pound",
        "currencySymbol": "£",
        "root": "+5",
        "suffix": "00",
        "status": 1
    },
    {
        "id": 207,
        "name": "South Korea",
        "flagPng": "https://flagcdn.com/w320/kr.webp",
        "flagSvg": "https://flagcdn.com/kr.svg",
        "currencyName": "KRW",
        "currencyDescription": "South Korean won",
        "currencySymbol": "₩",
        "root": "+8",
        "suffix": "2",
        "status": 1
    },
    {
        "id": 208,
        "name": "South Sudan",
        "flagPng": "https://flagcdn.com/w320/ss.webp",
        "flagSvg": "https://flagcdn.com/ss.svg",
        "currencyName": "SSP",
        "currencyDescription": "South Sudanese pound",
        "currencySymbol": "£",
        "root": "+2",
        "suffix": "11",
        "status": 1
    },
    {
        "id": 209,
        "name": "Spain",
        "flagPng": "https://flagcdn.com/w320/es.webp",
        "flagSvg": "https://flagcdn.com/es.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 210,
        "name": "Sri Lanka",
        "flagPng": "https://flagcdn.com/w320/lk.webp",
        "flagSvg": "https://flagcdn.com/lk.svg",
        "currencyName": "LKR",
        "currencyDescription": "Sri Lankan rupee",
        "currencySymbol": "Rs  රු",
        "root": "+9",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 211,
        "name": "Sudan",
        "flagPng": "https://flagcdn.com/w320/sd.webp",
        "flagSvg": "https://flagcdn.com/sd.svg",
        "currencyName": "SDG",
        "currencyDescription": "Sudanese pound",
        "currencySymbol": "",
        "root": "+2",
        "suffix": "49",
        "status": 1
    },
    {
        "id": 212,
        "name": "Suriname",
        "flagPng": "https://flagcdn.com/w320/sr.webp",
        "flagSvg": "https://flagcdn.com/sr.svg",
        "currencyName": "SRD",
        "currencyDescription": "Surinamese dollar",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "97",
        "status": 1
    },
    {
        "id": 213,
        "name": "Svalbard and Jan Mayen",
        "flagPng": "https://flagcdn.com/w320/sj.webp",
        "flagSvg": "https://flagcdn.com/sj.svg",
        "currencyName": "NOK",
        "currencyDescription": "krone",
        "currencySymbol": "kr",
        "root": "+4",
        "suffix": "779",
        "status": 1
    },
    {
        "id": 214,
        "name": "Sweden",
        "flagPng": "https://flagcdn.com/w320/se.webp",
        "flagSvg": "https://flagcdn.com/se.svg",
        "currencyName": "SEK",
        "currencyDescription": "Swedish krona",
        "currencySymbol": "kr",
        "root": "+4",
        "suffix": "6",
        "status": 1
    },
    {
        "id": 215,
        "name": "Switzerland",
        "flagPng": "https://flagcdn.com/w320/ch.webp",
        "flagSvg": "https://flagcdn.com/ch.svg",
        "currencyName": "CHF",
        "currencyDescription": "Swiss franc",
        "currencySymbol": "Fr.",
        "root": "+4",
        "suffix": "1",
        "status": 1
    },
    {
        "id": 216,
        "name": "Syria",
        "flagPng": "https://flagcdn.com/w320/sy.webp",
        "flagSvg": "https://flagcdn.com/sy.svg",
        "currencyName": "SYP",
        "currencyDescription": "Syrian pound",
        "currencySymbol": "£",
        "root": "+9",
        "suffix": "63",
        "status": 1
    },
    {
        "id": 218,
        "name": "Taiwan",
        "flagPng": "https://flagcdn.com/w320/tw.webp",
        "flagSvg": "https://flagcdn.com/tw.svg",
        "currencyName": "TWD",
        "currencyDescription": "New Taiwan dollar",
        "currencySymbol": "$",
        "root": "+8",
        "suffix": "86",
        "status": 1
    },
    {
        "id": 219,
        "name": "Tajikistan",
        "flagPng": "https://flagcdn.com/w320/tj.webp",
        "flagSvg": "https://flagcdn.com/tj.svg",
        "currencyName": "TJS",
        "currencyDescription": "Tajikistani somoni",
        "currencySymbol": "ЅМ",
        "root": "+9",
        "suffix": "92",
        "status": 1
    },
    {
        "id": 220,
        "name": "Tanzania",
        "flagPng": "https://flagcdn.com/w320/tz.webp",
        "flagSvg": "https://flagcdn.com/tz.svg",
        "currencyName": "TZS",
        "currencyDescription": "Tanzanian shilling",
        "currencySymbol": "Sh",
        "root": "+2",
        "suffix": "55",
        "status": 1
    },
    {
        "id": 221,
        "name": "Thailand",
        "flagPng": "https://flagcdn.com/w320/th.webp",
        "flagSvg": "https://flagcdn.com/th.svg",
        "currencyName": "THB",
        "currencyDescription": "Thai baht",
        "currencySymbol": "฿",
        "root": "+6",
        "suffix": "6",
        "status": 1
    },
    {
        "id": 222,
        "name": "Timor-Leste",
        "flagPng": "https://flagcdn.com/w320/tl.webp",
        "flagSvg": "https://flagcdn.com/tl.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "70",
        "status": 1
    },
    {
        "id": 223,
        "name": "Togo",
        "flagPng": "https://flagcdn.com/w320/tg.webp",
        "flagSvg": "https://flagcdn.com/tg.svg",
        "currencyName": "XOF",
        "currencyDescription": "West African CFA franc",
        "currencySymbol": "Fr",
        "root": "+2",
        "suffix": "28",
        "status": 1
    },
    {
        "id": 224,
        "name": "Tokelau",
        "flagPng": "https://flagcdn.com/w320/tk.webp",
        "flagSvg": "https://flagcdn.com/tk.svg",
        "currencyName": "NZD",
        "currencyDescription": "New Zealand dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "90",
        "status": 1
    },
    {
        "id": 225,
        "name": "Tonga",
        "flagPng": "https://flagcdn.com/w320/to.webp",
        "flagSvg": "https://flagcdn.com/to.svg",
        "currencyName": "TOP",
        "currencyDescription": "Tongan paʻanga",
        "currencySymbol": "T$",
        "root": "+6",
        "suffix": "76",
        "status": 1
    },
    {
        "id": 226,
        "name": "Trinidad and Tobago",
        "flagPng": "https://flagcdn.com/w320/tt.webp",
        "flagSvg": "https://flagcdn.com/tt.svg",
        "currencyName": "TTD",
        "currencyDescription": "Trinidad and Tobago dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "868",
        "status": 1
    },
    {
        "id": 227,
        "name": "Tunisia",
        "flagPng": "https://flagcdn.com/w320/tn.webp",
        "flagSvg": "https://flagcdn.com/tn.svg",
        "currencyName": "TND",
        "currencyDescription": "Tunisian dinar",
        "currencySymbol": "د.ت",
        "root": "+2",
        "suffix": "16",
        "status": 1
    },
    {
        "id": 228,
        "name": "Turkey",
        "flagPng": "https://flagcdn.com/w320/tr.webp",
        "flagSvg": "https://flagcdn.com/tr.svg",
        "currencyName": "TRY",
        "currencyDescription": "Turkish lira",
        "currencySymbol": "₺",
        "root": "+9",
        "suffix": "0",
        "status": 1
    },
    {
        "id": 229,
        "name": "Turkmenistan",
        "flagPng": "https://flagcdn.com/w320/tm.webp",
        "flagSvg": "https://flagcdn.com/tm.svg",
        "currencyName": "TMT",
        "currencyDescription": "Turkmenistan manat",
        "currencySymbol": "m",
        "root": "+9",
        "suffix": "93",
        "status": 1
    },
    {
        "id": 230,
        "name": "Turks and Caicos Islands",
        "flagPng": "https://flagcdn.com/w320/tc.webp",
        "flagSvg": "https://flagcdn.com/tc.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "649",
        "status": 1
    },
    {
        "id": 231,
        "name": "Tuvalu",
        "flagPng": "https://flagcdn.com/w320/tv.webp",
        "flagSvg": "https://flagcdn.com/tv.svg",
        "currencyName": "AUD",
        "currencyDescription": "Australian dollar",
        "currencySymbol": "$",
        "root": "+6",
        "suffix": "88",
        "status": 1
    },
    {
        "id": 232,
        "name": "Uganda",
        "flagPng": "https://flagcdn.com/w320/ug.webp",
        "flagSvg": "https://flagcdn.com/ug.svg",
        "currencyName": "UGX",
        "currencyDescription": "Ugandan shilling",
        "currencySymbol": "Sh",
        "root": "+2",
        "suffix": "56",
        "status": 1
    },
    {
        "id": 233,
        "name": "Ukraine",
        "flagPng": "https://flagcdn.com/w320/ua.webp",
        "flagSvg": "https://flagcdn.com/ua.svg",
        "currencyName": "UAH",
        "currencyDescription": "Ukrainian hryvnia",
        "currencySymbol": "₴",
        "root": "+3",
        "suffix": "80",
        "status": 1
    },
    {
        "id": 234,
        "name": "United Arab Emirates",
        "flagPng": "https://flagcdn.com/w320/ae.webp",
        "flagSvg": "https://flagcdn.com/ae.svg",
        "currencyName": "AED",
        "currencyDescription": "United Arab Emirates dirham",
        "currencySymbol": "د.إ",
        "root": "+9",
        "suffix": "71",
        "status": 1
    },
    {
        "id": 235,
        "name": "United Kingdom",
        "flagPng": "https://flagcdn.com/w320/gb.webp",
        "flagSvg": "https://flagcdn.com/gb.svg",
        "currencyName": "GBP",
        "currencyDescription": "British pound",
        "currencySymbol": "£",
        "root": "+4",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 236,
        "name": "United States",
        "flagPng": "https://flagcdn.com/w320/us.webp",
        "flagSvg": "https://flagcdn.com/us.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "201",
        "status": 1
    },
    {
        "id": 237,
        "name": "United States Minor Outlying Islands",
        "flagPng": "https://flagcdn.com/w320/um.webp",
        "flagSvg": "https://flagcdn.com/um.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+2",
        "suffix": "68",
        "status": 1
    },
    {
        "id": 238,
        "name": "United States Virgin Islands",
        "flagPng": "https://flagcdn.com/w320/vi.webp",
        "flagSvg": "https://flagcdn.com/vi.svg",
        "currencyName": "USD",
        "currencyDescription": "United States dollar",
        "currencySymbol": "$",
        "root": "+1",
        "suffix": "340",
        "status": 1
    },
    {
        "id": 239,
        "name": "Uruguay",
        "flagPng": "https://flagcdn.com/w320/uy.webp",
        "flagSvg": "https://flagcdn.com/uy.svg",
        "currencyName": "UYU",
        "currencyDescription": "Uruguayan peso",
        "currencySymbol": "$",
        "root": "+5",
        "suffix": "98",
        "status": 1
    },
    {
        "id": 240,
        "name": "Uzbekistan",
        "flagPng": "https://flagcdn.com/w320/uz.webp",
        "flagSvg": "https://flagcdn.com/uz.svg",
        "currencyName": "UZS",
        "currencyDescription": "Uzbekistani soʻm",
        "currencySymbol": "so'm",
        "root": "+9",
        "suffix": "98",
        "status": 1
    },
    {
        "id": 241,
        "name": "Vanuatu",
        "flagPng": "https://flagcdn.com/w320/vu.webp",
        "flagSvg": "https://flagcdn.com/vu.svg",
        "currencyName": "VUV",
        "currencyDescription": "Vanuatu vatu",
        "currencySymbol": "Vt",
        "root": "+6",
        "suffix": "78",
        "status": 1
    },
    {
        "id": 242,
        "name": "Vatican City",
        "flagPng": "https://flagcdn.com/w320/va.webp",
        "flagSvg": "https://flagcdn.com/va.svg",
        "currencyName": "EUR",
        "currencyDescription": "Euro",
        "currencySymbol": "€",
        "root": "+3",
        "suffix": "906698",
        "status": 1
    },
    {
        "id": 243,
        "name": "Venezuela",
        "flagPng": "https://flagcdn.com/w320/ve.webp",
        "flagSvg": "https://flagcdn.com/ve.svg",
        "currencyName": "VES",
        "currencyDescription": "Venezuelan bolívar soberano",
        "currencySymbol": "Bs.S.",
        "root": "+5",
        "suffix": "8",
        "status": 1
    },
    {
        "id": 244,
        "name": "Vietnam",
        "flagPng": "https://flagcdn.com/w320/vn.webp",
        "flagSvg": "https://flagcdn.com/vn.svg",
        "currencyName": "VND",
        "currencyDescription": "Vietnamese đồng",
        "currencySymbol": "₫",
        "root": "+8",
        "suffix": "4",
        "status": 1
    },
    {
        "id": 245,
        "name": "Wallis and Futuna",
        "flagPng": "https://flagcdn.com/w320/wf.webp",
        "flagSvg": "https://flagcdn.com/wf.svg",
        "currencyName": "XPF",
        "currencyDescription": "CFP franc",
        "currencySymbol": "₣",
        "root": "+6",
        "suffix": "81",
        "status": 1
    },
    {
        "id": 246,
        "name": "Western Sahara",
        "flagPng": "https://flagcdn.com/w320/eh.webp",
        "flagSvg": "https://flagcdn.com/eh.svg",
        "currencyName": "DZD",
        "currencyDescription": "Algerian dinar",
        "currencySymbol": "دج",
        "root": "+2",
        "suffix": "125288",
        "status": 1
    },
    {
        "id": 247,
        "name": "Yemen",
        "flagPng": "https://flagcdn.com/w320/ye.webp",
        "flagSvg": "https://flagcdn.com/ye.svg",
        "currencyName": "YER",
        "currencyDescription": "Yemeni rial",
        "currencySymbol": "﷼",
        "root": "+9",
        "suffix": "67",
        "status": 1
    },
    {
        "id": 248,
        "name": "Zambia",
        "flagPng": "https://flagcdn.com/w320/zm.webp",
        "flagSvg": "https://flagcdn.com/zm.svg",
        "currencyName": "ZMW",
        "currencyDescription": "Zambian kwacha",
        "currencySymbol": "ZK",
        "root": "+2",
        "suffix": "60",
        "status": 1
    },
    {
        "id": 249,
        "name": "Zimbabwe",
        "flagPng": "https://flagcdn.com/w320/zw.webp",
        "flagSvg": "https://flagcdn.com/zw.svg",
        "currencyName": "ZWL",
        "currencyDescription": "Zimbabwean dollar",
        "currencySymbol": "$",
        "root": "+2",
        "suffix": "63",
        "status": 1
    }
];