import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Switch } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import useCertification from "./useCertification";

export default function NewCertificationDialog( { talent_id, showModal, hideModal, certData, editMode, reloadData } ) {
	
    const {
        certifications,
        organizations,
        handleChange,
        certification,
        setCertification,
        saveNewCertification,
        updateCertification,
        getCertifications,
        getOrganizations,
        setCertifications,
        setOrganizations
    } = useCertification();
	
    useEffect( () => {
        getCertifications( "" );
        getOrganizations( "" );
    }, [] );
	
    useEffect( () => {
        if ( editMode ) {
            // console.log( 'isEdit mode' );
            setCertification( certData );
            setCertifications( [certData.certification] );
            setOrganizations( [certData.organization] );
        } else {
            // console.log( "npormal flow" );
        }
		
    }, [editMode] );
	
	
    return (
        <Dialog
            open={showModal}
            onClose={hideModal}
        >
            <DialogTitle id="alert-dialog-title">
                {editMode ? "Edit " : "Add a New"} Certification
            </DialogTitle>
            <DialogContent>
                <table className="table">
                    <thead/>
                    <tbody>
                        <tr>
                            <th className="text-right">Certification Title</th>
                            <td>
                                <Autocomplete
                                    size="small"
                                    onInputChange={( event, newInputValue ) => {
                                        getCertifications( newInputValue );
                                    }}
                                    onChange={( e, v ) => setCertification( { ...certification, certification: v } )}
                                    getOptionLabel={option => option.name}
                                    getOptionSelected={( option, value ) => option.id === value.id}
                                    options={certifications}
                                    value={certification.certification ? certification.certification : ""}
                                    style={{ width: 300 }}
                                    renderInput={( params ) => <TextField {...params} variant="outlined"/>}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Organization</th>
                            <td>
                                <Autocomplete
                                    size="small"
                                    onInputChange={( event, newInputValue ) => {
                                        getOrganizations( newInputValue );
                                    }}
                                    onChange={( e, v ) => setCertification( { ...certification, organization: v } )}
                                    getOptionLabel={option => option.name ? option.name: ""}
                                    getOptionSelected={( option, value ) => option.id === value.id}
                                    options={organizations}
                                    value={certification.organization}
                                    style={{ width: 300 }}
                                    renderInput={( params ) => <TextField {...params} variant="outlined"/>}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Issue Date</th>
                            <td>
                                <util.InputSlim
                                    name="issueDate"
                                    onChange={handleChange}
                                    type="date"
                                    value={certification.issueDate ? certification.issueDate : ""}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Credential does not expire</th>
                            <td>
                                <Switch
                                    color="primary"
                                    checked={certification.notExpire === 1}
                                    onChange={( e ) => setCertification( {
                                        ...certification,
                                        notExpire: e.target.checked ? 1 : 0
                                    } )}
                                />
                            </td>
                        </tr>
                        {
                            // Only display expiration day if checkbox is unchecked
                            certification.notExpire === 0 ?
                                <tr>
                                    <th className="text-right">Expiration Date</th>
                                    <td>
                                        <util.InputSlim
                                            name="expirationDate"
                                            onChange={handleChange}
                                            type="date"
                                            value={certification.expirationDate ? certification.expirationDate : ""}
                                        />
                                    </td>
                                </tr> :
                                null
                        }
                        <tr>
                            <th className="text-right">Credential ID</th>
                            <td>
                                <util.InputSlim
                                    name="credential_id"
                                    onChange={handleChange}
                                    value={certification.credential_id ? certification.credential_id : ""}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Credential URL</th>
                            <td>
                                <util.InputSlim
                                    name="credential_url"
                                    onChange={handleChange}
                                    value={certification.credential_url ? certification.credential_url : ""}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-right">
                    <span
                        onClick={hideModal}
                        className="text-danger mr-2 isPointer">
                        <FontAwesomeIcon icon={faTimes} className="mr-1"/>
								Cancel
                    </span>
					
                    {
                        editMode ?
                            <button onClick={() => updateCertification( talent_id, reloadData, reloadData, hideModal )}
							        className="btn-sm btn-success btn">
                                <FontAwesomeIcon icon={faSave} className="mr-1"/>
								Update
                            </button>
                            :
                            <button
                                onClick={() => saveNewCertification( talent_id, certification, reloadData, hideModal )}
                                className="btn-sm btn-success btn">
                                <FontAwesomeIcon icon={faSave} className="mr-1"/>
								Save
                            </button>
                    }
				
                </div>
            </DialogContent>
        </Dialog>
    );
}