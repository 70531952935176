import React, { Fragment, useEffect, useState } from "react";
import util from "../utils/miniUtils";
import ImageComponent from '../utils/ImageComponent';

import { Paper } from "@material-ui/core";

export default function QuestionOfTest( { question, index } ) {
    const [answer, setAnswer] = useState( question.answer );
	
	
    useEffect( () => {
        if ( !util.noValues.includes( answer ) && answer !== question.answer ){
            saveAnswer().then( null );
            validateIsChecked( answer );
        }
    }, [answer] );
	
    function validateIsChecked( a ) {
        return a === answer;
    }
	
    async function saveAnswer() {
        try {
            const data = { id: question.id ,question_id: question.question_id,answer: answer };
            const response = await util.axios.post( `/tests_user/save_answer`, data );
            const { error, msg } = response.data;
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    }
    const [imageUrl, setImageUrl] = useState( null );

    useEffect( () => {
        const fetchImage = async () => {
            const url = await util.fetchImageUrl( `src/public/test/${question.test_id}/${question.filename}` );
            setImageUrl( url );
        };

        if ( question && question.test_id && question.filename ) {
            fetchImage();
        }
    }, [question] ); // Dependency array, re-run the effect if `question` changes

	
    return(
        <div className="row mb-5">
            <Paper className="w-100">
                <div className="col-md-12">
                    <h5>Question #{index + 1}</h5>
                </div>
                <div className="col-md-12" dangerouslySetInnerHTML={{ __html: question.question }}/>
                {
                    question.type ===2 ?
                        <div className="col-md-12">
                            <img width="100%" src={imageUrl} alt=""/>
                        </div>
                        :
                        null
                }
                <div className="col-md-12">
                    <h6>Select your answer</h6>
                    {  question.type === 1 ? (

                        <Fragment>
                            <table className="table">
                                <tbody>
                                    <RowInput answer={answer} question={question} number={1} validateIsChecked={validateIsChecked} setAnswer={setAnswer}/>
                                    <RowInput answer={answer} question={question} number={2} validateIsChecked={validateIsChecked} setAnswer={setAnswer}/>
                                    <RowInput answer={answer} question={question} number={3} validateIsChecked={validateIsChecked} setAnswer={setAnswer}/>
                                    <RowInput answer={answer} question={question} number={4} validateIsChecked={validateIsChecked} setAnswer={setAnswer}/>
                                </tbody>
                            </table>
                        </Fragment>
                    ) : question.type === 2 ? (
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="radio" checked={validateIsChecked( "A" )} onChange={()=> setAnswer( "A" )} id={answer + question.id+"A"} name={answer + question.id} value={answer}/>
                                        <label className="ml-3" htmlFor={answer + question.id+ "A"}>A</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="radio" checked={validateIsChecked( "B" )} onChange={()=> setAnswer( "B" )} id={answer + question.id+"B"} name={answer + question.id} value={answer}/>
                                        <label className="ml-3" htmlFor={answer + question.id+ "B"}>B</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="radio" checked={validateIsChecked( "C" )} onChange={()=> setAnswer( "C" )} id={answer + question.id+"C"} name={answer + question.id} value={answer}/>
                                        <label className="ml-3" htmlFor={answer + question.id+ "C"}>C</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="radio" checked={validateIsChecked( "D" )} onChange={()=> setAnswer( "D" )} id={answer + question.id+"D"} name={answer + question.id} value={answer}/>
                                        <label className="ml-3" htmlFor={answer + question.id+ "D"}>D</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="radio" checked={validateIsChecked( "AllOfTheAbove" )} onChange={()=> setAnswer( "AllOfTheAbove" )} id={answer + question.id+"AllOfTheAbove"} name={answer + question.id} value={answer}/>
                                        <label className="ml-3" htmlFor={answer + question.id+ "AllOfTheAbove"}>All of the above</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="radio" checked={validateIsChecked( "NoneOfTheAbove" )} onChange={()=> setAnswer( "NoneOfTheAbove" )} id={answer + question.id+"NoneOfTheAbove"} name={answer + question.id} value={answer}/>
                                        <label className="ml-3" htmlFor={answer + question.id+ "NoneOfTheAbove"}>None of the above</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : question.type === 3 ? (
                    // Input for text for question.type === 3
                        <Fragment>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label htmlFor={`textAnswer${question.id}`}>Answer</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`textAnswer${question.id}`}
                                                value={answer}
                                                onChange={( e ) => setAnswer( e.target.value )}
                                                placeholder="Enter text here"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Fragment>
                    ) : null 
                    }
                </div>
            </Paper>
        </div>
    );
}

function RowInput( { question, validateIsChecked, setAnswer, number, answer } ) {
    return(
        <tr>
            <td>
                <input
                    type="radio"
                    checked={validateIsChecked( question[`answer${number}`] )}
                    onChange={()=> setAnswer( question[`answer${number}`] )}
                    id={`q${number}${question.id}`}
                    name={answer + question.id}
                    value={answer}
                />
                <label className="ml-3" htmlFor={`q${number}${question.id}`}>{question[`answer${number}`]}</label>
            </td>
        </tr>
    );
}