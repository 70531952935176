import { GET_CTC_STATE, UPDATE_CTC_STATE, UPDATE_TALENT_CTC_STATE, UPDATE_TALENT_CTC_STATE_COMPLETE } from "../actions/ctcDataAction";

const initialState = {
    job_id:0,
    job: null,
    jobCtcSetting:  [
        {
            "employmentType": "Fulltime Employee",
            "markUp": "15",
            "billableDaysInYear": "220",
            "workingHoursInDay": "8.5",
            "averageBillableDaysInMonth": "18.33",
            "defaulteJOfferUnitToTalent": "Year",
            "defaultSellUnit": "Hour"
        },
        {
            "employmentType": "Contractor",
            "markUp": "0",
            "billableDaysInYear": "220",
            "workingHoursInDay": "8.5",
            "averageBillableDaysInMonth": "18.33",
            "defaulteJOfferUnitToTalent": "Hour",
            "defaultSellUnit": "Hour"
        },
        {
            "employmentType": "Corp to Corp",
            "markUp": "0",
            "billableDaysInYear": "220",
            "workingHoursInDay": "8.5",
            "averageBillableDaysInMonth": "18.33",
            "defaulteJOfferUnitToTalent": "Hour",
            "defaultSellUnit": "Hour"
        }
    ],
    loading: true,
    forceRender: 0,
    talent:{
        ejOffer:0,
        ctcPerHour:0,
        ctcPerDay:0,
        ctcPerMonth:0,
        ctcPerAnnum:0,
        mode:0,
        employmentType: "Contractor",
        sell_margin:0,
        sellRatePerHour:0,
        sellRatePerDay:0,
        sellRatePerMonth:0,
        sellRatePerAnnum:0,
        offerType: "Day",
        sellRateValue:"",
        offerTypeEstimate: "Day"
    }
};

const CtcDataReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case UPDATE_TALENT_CTC_STATE:
            const talent = { ...state.talent };
            talent[action.payload.field] = action.payload.value;
            return Object.assign( {}, {
                ...state,
                talent
            } );
        case UPDATE_TALENT_CTC_STATE_COMPLETE:
            // const t = { ...state.talent };
            let t = action.payload.value;
            return Object.assign( {}, {
                ...state,
                t
            } );
        case UPDATE_CTC_STATE:
            return {
                ...action.payload,
            };
        case GET_CTC_STATE:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default CtcDataReducer;
