import React from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { Container, Button, Row, Col, Image } from 'react-bootstrap';
// import '../style.css';
// import loginimage from './en.webp';
import ImageComponent from "../../utils/ImageComponent";
import microsoft from '../../../assets/images/microsoft.webp';

function MicrosoftLoginButton( { ejLogin } ) {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup( loginRequest ) // Using loginPopup for demonstration; you can use loginRedirect as well
            .then( response => {
                console.log( "Login response:", response );
                // Here, you have access to the response which includes the ID token
                // You can make your API call here or set state to trigger it elsewhere
                ejLogin( response );
            } )
            .catch( error => {
                console.error( error );
            } );
    };

    return ( 
    // <button onClick={handleLogin}>Sign In with Microsoft</button>
        <div>
            <button className='border p-2 m-2 login-btns' href="" onClick={handleLogin}>
                <img src={microsoft} alt="microsoft" width={20} height={20} /> Sign in with Microsoft
            </button>
            {/* <Image src={loginimage} alt="loginimage" width={400} height={400} /> */}
        </div> );
}

export default MicrosoftLoginButton;
