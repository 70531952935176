import React, { useEffect } from 'react';
import { PositionsStore } from '../stores/PositionsStore';
import JobSearchFilterCheckboxSelector from '../../LandingPage/common/JobSearchFilterCheckboxSelector';
// import util from '../../utils/miniUtils';
// import { WelcomePage_SkillSelector } from '../../LandingPage/common/welcomeSidebarFilter';
import useJobSearch from './useJobSearch';

const JobSearchFilter = () => {
    const filter = PositionsStore.useState( s => s.filterState );
    const { handleSearch } = useJobSearch();

    useEffect( () => {
        handleSearch();
    },[filter] );

    return (
        <div style={{ background: "#EFF0F9", width: "100%", maxHeight: "calc(100vh - 152px)", overflowY: "scroll", padding:"0 10px"  }}>
            <div style={{ padding: "15px" }}>
                <h4 >Filters</h4>
            </div>

            { filter.job_title.length ? <JobSearchFilterCheckboxSelector
                isOpenByDeafult={true}
                dataArray={filter.job_title}
                title="Role"
                name="job_title"
            /> : ""}

            {/* <WelcomePage_SkillSelector
                getSkillList={getListofSkills}
                list={skillsList}
                filteredSkills={filter.skills}
                setSkillsList={setSkillsList}

            /> */}
            { filter.experience.length ? <JobSearchFilterCheckboxSelector
                isOpenByDeafult={true}
                dataArray={filter.experience}
                title="Experience level"
                name="experience"
            /> : ""}

            { filter.employmentTypes.length ? <JobSearchFilterCheckboxSelector
                isOpenByDeafult={true}
                dataArray={filter.employmentTypes}
                title="Employment Type"
                name="employmentTypes"
            /> : ""}

            { filter.education.length ? <JobSearchFilterCheckboxSelector
                isOpenByDeafult={true}
                dataArray={filter.education}
                title="Basic Education"
                name="education"
            /> : ""}

            {/* { filter.country.length ? <JobSearchFilterCheckboxSelector
                isOpenByDeafult={true}
                dataArray={filter.country}
                title="Country"
                name="country"
            /> : ""} */}

        </div>

    );
};

export default JobSearchFilter;
