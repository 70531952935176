import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import ImageComponent from '../../../utils/ImageComponent';

import SocialMediaPopUp from "./SocialMediaPopUp";
import { TalentStore } from "../TalentStore";

export default function SocialMediaContainer( { talent_id, isEditing } ) {

    const initialUrls = {
        facebook: "",
        github: "",
        linkedin: "",
        twitter: "",
        web: "",
    };
    const [urls, setUrls] = useState( initialUrls );
    const socialMediaPlatforms = [
        { name: 'LinkedIn', image: 'LinkedIn.webp', url: 'linkedin' },
        { name: 'Facebook', image: 'Facebook.webp', url: 'facebook' },
        { name: 'Twitter', image: 'Twitter.webp', url: 'twitter' },
        { name: 'Github', image: 'github.webp', url: 'github' },
        { name: 'Web', image: 'Web.webp', url: 'web' },
    ];

    const [loading, setLoading] = useState( false );
    const [showModal, setShowModal] = useState( false );

    const toggleModal = () => {
        setShowModal( !showModal );
    };

    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );

    useEffect( () => {
        getSocialMedia(); // Fetch social media links when the component mounts
    }, [] );

    const getSocialMedia = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get(
        `talent/social_media_list/${talent_id}/`
            );
            const { error, msg, data } = response.data;
            if ( error ) throw new Error( msg );
            if( data.length != 0 ){
                setUrls( data[0] );
            }
            TalentStore.update( t => {
                t.talentSocialMedia = data ;
            } );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };

    const handleChanges = ( e ) => {
        const { name, value } = e.target;
        setUrls( ( prevUrls ) => ( {
            ...prevUrls,
            [name]: value,
        } ) );
    };

    const updateUrls = async () => {
        try {
            startLoading();
            const response = await util.axios.put(
        `talent/social_media_update/${talent_id}/`,
        urls
            );
            const { error, msg, data } = response.data;
            if ( error ) throw new Error( msg );
            util.toast().success( "Social Media Updated" );
            setUrls( data[0] );
            stopLoading();
            setShowModal( false );
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    const hasSocialMediaLinks = () => {
       
        let Links = Object.values( urls );
        return Object.values( Links ).some( ( url ) => url !== "" );
    };

    const tooltip = (
        <Tooltip id="tooltip">
      Add Social Media Links
        </Tooltip>
    );

    return (
        <div className="container-fluid mt-3 " style={{ backgroundColor: "#F4F4F4" }}>
            {isEditing ? (
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <FontAwesomeIcon
                        className="custom-icon ml-1"
                        icon={faPlus}
                        onClick={toggleModal}
                    />
                </OverlayTrigger>
            ) : (
                ""
            )}
            {hasSocialMediaLinks() || isEditing ? (
                <div className="d-row">
                    <b
                        className="custom-title "
                        style={{
                            flex: "1",
                            width: "200px",
                            color: hasSocialMediaLinks() ? "black" : "grey",
                        }}
                    >
            Social Media
                    </b>
                   
                    <div className="col-md-12">
                    
                        <div>
                            {socialMediaPlatforms.map( ( platform ) => {
                                const link = urls[platform.url];
                                if ( link ) {
                                    return (
                                        <div key={platform.url} className="row">
                                            <div className="col-md-1 text-center">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={link}
                                                >
                                                    <img
                                                        width="30px"
                                                        src={`/images/${platform.image}`}
                                                        alt={`${platform.name} icon`}
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-md-7 p-2">
                                                <div>
                                                    <b>{platform.name}:</b>{" "}
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={link}
                                                    >
                                                        {link}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                return null; // Hide the row if there is no link for this platform
                            } )}
                        </div>
                       
                    </div>
                </div>
            ) : null}
            {showModal && (
                <SocialMediaPopUp
                    showModal={showModal}
                    toggleModal={toggleModal}
                    handleChanges={handleChanges}
                    updateUrls={updateUrls}
                    urls={urls}
                />
            )}
        </div>
    );
}
