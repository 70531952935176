import React, { useState } from 'react';
import { Paper, Tab, Tabs } from "@material-ui/core";
// import { TemplateList } from './Components/TemplateList';
import QuestionsLibrary from './Components/QuestionsLibrary';
import QuestionsListForm from './Components/QuestionsListForm';

const CreateScreeningQuestions = ( { job, sqSave, cancel } ) => {
    const [tabValue, setTabValue] = useState( "questions" );

    // function for manipulating tabs 
    const  handleTabChange = ( event, newValue ) => {
        setTabValue( newValue );
    };
    //Custom tabpanel to show the selected tab value
    const TabPanel = ( { children, selectedTab, tabValue } ) => {
        if( selectedTab !== tabValue ) {
            return "";
        }
        return children;
    };
    
    return (
        <div className="container ejContainer">
            <Paper className="p-2">
                <h3>{`Screening Questions -- `}<span>{job ? job.job_title : ''}</span></h3>
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                        <QuestionsListForm save={sqSave} cancel={cancel} isModal={false} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <Tabs
                            className="mb-2"
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary">
                            <Tab value="questions" label="Questions Library" />
                        </Tabs>
                        {/* <TabPanel selectedTab={tabValue} tabValue="questions"> */}
                        <Paper className="p-2">
                            <QuestionsLibrary/>
                        </Paper>
                        {/* </TabPanel> */}
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default CreateScreeningQuestions;