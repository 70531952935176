import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import util from "../../utils/miniUtils";
import ImageComponent from "../../utils/ImageComponent";


const CustomerCompanyLogo = () => {
    const location = useLocation();
    const [imageSrc, setImageSrc] = useState( null ); // Initialize without default image
    useEffect( () => {
        const fetchLogo = async () => {
            if ( util.user && util.user.customer_company_id ) {
                try {
                    const response = await util.axios.get( `/customer_companies/${util.user.customer_company_id}` );
                    const { error, msg, data } = response.data;
                    if ( error ) throw new Error( msg );

                    const logoURL = data.customer_Logo 
                        ? `src/public/customer_company/${util.user.customer_company_id}/${data.customer_Logo}`
                        : null; // Set to null instead of defaultImage
                    setImageSrc( await util.fetchImageUrl( logoURL ) );

                    if ( logoURL ) {
                        util.toast().success( msg );
                    }
                } catch ( error ) {
                    util.handleError( error );
                    setImageSrc( null ); // Don't fallback to default image on error, use null
                }
            }
        };

        if ( location.pathname === '/support' ) {
            fetchLogo();
        }
    }, [location.pathname, util.user.customer_company_id] );

    const handleError = () => {
        setImageSrc( null ); // Don't use any image on error
    };
    if ( util.user && util.user.customer_company_id && location.pathname === '/support' && imageSrc ) {
        return (
            <div>
                <img src={ imageSrc} height="40" alt="Company Logo" onError={handleError}/>
            </div>
        );
    }

    return null; // Render nothing if there's no image
};

export default CustomerCompanyLogo;
