import React from 'react';

const ImageComponent = ( { src, alt, width, height, style, className, id, onClick, onError, ...otherProps } ) => {
    // Check if the image is an SVG and determine the webpSrc if applicable
    const isSvg = src.endsWith( '.svg' );
    const webpSrc = !isSvg && src ? src.replace( /\.\w+$/, '.webp' ) : '';

    // Construct the img element only with provided props
    const imgElement = src && (
        <img
            src={src}
            alt={alt}
            id={id}
            className={className}
            style={style}
            onClick={onClick}
            onError={( e ) => {
                console.error( `Error loading image: ${src}` );
                if ( onError ) onError( e );
                else e.target.src = "/images/avatar.svg"; // Default fallback if onError is not provided
            }}
            {...otherProps}
        />
    );

    // If webpSrc is available, use <picture> to include webp and fallback to original src
    if ( webpSrc ) {
        return (
            <picture>
                <source srcSet={webpSrc} type="image/webp" />
                {imgElement}
            </picture>
        );
    }

    // Return just the imgElement if no webpSrc is available
    return imgElement;
};

export default ImageComponent;
