import { Card, CardContent, CircularProgress, styled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addQuestionFromLibrary } from '../../../redux/actions/JobSqActions';
import util from '../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const QuestionsLibrary = () => {
    const dispatch = useDispatch();
    const [questionsList, setQuestionsList] = useState( [] );
    const [page, setPage] = useState( 0 );
    const [total, setTotal] = useState( 0 );
    const [search, setSearch] = useState( "" );
    const [loading, setLoading] = useState( false );
    
    function addQuestionToForm( value ){
        dispatch( addQuestionFromLibrary( value ) );
    }

    const getQuestionsList = async ( pageNo, search="" ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_all_questions?page=${pageNo}&search=${search}` );
            const { error, msg, data, page, total } = request.data;
            if( error ) throw msg;
            setQuestionsList( data );
            setPage( page );
            setTotal( total );
            setLoading( false );
        } catch ( error ) {
            util.handleError( error );
            setLoading( false );
        }
    };

    const CustomCardContent = styled( CardContent )( {
        marginBottom: '4px',
        '&:last-child': {
            padding: '6px'
        }
    } );

    const handleChange = ( e ) => {
        // searchRef.current = e.target.value;
        setSearch( e.target.value );
    };

    const handleSearch = ( searchTerm ) => {
        getQuestionsList( 0, searchTerm );
    };

    const handlePageChange = ( add=false ) => {
        if( add ) {
            setPage( page + 1 );
        } else {
            setPage( page - 1 );
        }
    };

    const isLastPage = ( total, page, limit ) => {
        const totalPages = Math.ceil( total / limit );
        return ( page + 1 ) === totalPages;
    };

    const isNextDisabled = isLastPage( total, page, 10 );

    const handleQuestionType = ( type ) => {
        switch( type ) {
            case 1: 
                return "Multiple Choice";
            case 2:
                return "Single Choice";
            case 3:
                return "Number";
            case 4:
                return "Text";
            default:
                return "";
        }
    };

    useEffect( () => {
        const timeoutId = setTimeout( () => {
            if( search.length ) 
                handleSearch( search );
            else
                handleSearch( "" );
        }, 2000 );

        return () => clearTimeout( timeoutId );
    },[search] );


    useEffect( () => {
        if( page > -1 ) {
            getQuestionsList( page );
        }
    },[page] );

    return loading ? <CircularProgress /> : <div>
        <input type="text"
            className="form-control" 
            placeholder="Search..." 
            value={search}
            onChange={handleChange} />
        <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
            {!!questionsList && questionsList.map( ( item, index ) => <Card key={index} className="my-1 p-0" onClick={() => addQuestionToForm( item )}>
                <CustomCardContent>
                    <div>{item.question}</div>
                    <div className="xs-fontSize">
                        <i className="mr-1 badge badge-light">{handleQuestionType( item.type )}</i>
                        {( item.type === 1 || item.type === 2 ) ? item.options.map( ( el, idx ) =><span key={el + idx} className="badge badge-secondary mr-1">{el}</span> ) : ""}
                    </div>
                </CustomCardContent>
            </Card> )}
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center">
            <button 
                className="btn btn-outline-light text-secondary" 
                onClick={() => handlePageChange()}
                disabled={page === 0}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <div>{`Page - ${page + 1}`}</div>
            <button 
                className="btn btn-outline-light text-secondary" 
                onClick={() => handlePageChange( true )} 
                disabled={isNextDisabled}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
    </div>;
};

export default QuestionsLibrary;

