import { Accordion, Button, Card, ListGroup, Modal } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import { PositionsStore } from "../stores/PositionsStore";
import util from "../../utils/miniUtils";
import { Link } from "react-router-dom";

const TalentGreetingPopup = () => {

    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const position = PositionsStore.useState(state => state);


    const handleClose = () => {


        PositionsStore.update(s => {
            s.firstTime = false;
            s.isModalVisible = false
            s.isTalent = false

        });

    };
    async function getNotifications() {
        try {
            setIsLoading(true);
            const request = await util.axios.get(`/notifications/get_notifications`);
            const { error, msg, data, notRead } = request.data;


            if (error) throw msg;
            setNotifications(data);
            console.log(data, notRead, "hello");
            setIsLoading(false);
        } catch (e) {
            util.handleError(e);
        }
    }
    useEffect(() => {

        getNotifications();

    }, []);




    return (
        <div>
            <Modal show={position.isModalVisible} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome to eJRekruit.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Header>
                            Notifications
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {notifications.length > 0 ? (
                                    notifications.map((notification) => {
                                        if (notification.model === 'talent') {
                                            return (
                                                <ListGroup.Item key={notification.notification_id}>
                                                    <Link
                                                        to='/appliedJobs'

                                                    >
                                                        <div>{notification.message}</div>

                                                    </Link>



                                                </ListGroup.Item>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                ) : (
                                    <ListGroup.Item>No notifications available</ListGroup.Item>
                                )}
                            </ListGroup>
                        </Card.Body>
                    </Card>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default TalentGreetingPopup;