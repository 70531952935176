
const initialState = {
    isTalentAdded:false
    
};
  

const isTalentAddedReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case "SET_IS_TALENT":
            return {
                ...state,
                isTalentAdded: action.payload,
            };
        default:
            return state;
    }
};

  
export default isTalentAddedReducer;
