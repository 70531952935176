import React, { useState, useEffect } from 'react';
import image1 from '../../../../assets/images/imagefront11.webp';
import image2 from '../../../../assets/images/imagefront22.webp';
import image3 from '../../../../assets/images/imagefront33.webp';
import image4 from '../../../../assets/images/imagefront44.webp';
import image5 from '../../../../assets/images/imagefront55.webp';
import image6 from '../../../../assets/images/imagefront66.webp';
import image7 from '../../../../assets/images/imagefront77.webp';
import image8 from '../../../../assets/images/imagefront88.webp';
import image9 from '../../../../assets/images/imagefront99.webp';
import image10 from '../../../../assets/images/imagefront101.webp';
import ImageComponent from "../../../utils/ImageComponent";


import "./hero.css";


const ImageSlider = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState( 0 );
    const images = [ image1, image3,image4,image2, image5, image7, image8, image10];

    useEffect( () => {
        const intervalId = setInterval( () => {
            setCurrentImageIndex( prevIndex => ( prevIndex + 1 ) % images.length );
        }, 3500 );

        return () => clearInterval( intervalId );
    }, [images.length] );

    return (
        <div className="slider">
            {images.map( ( image, index ) => (
                <img
                    key={index}
                    src={image}
                    alt="Image"
                    className={index === currentImageIndex ? 'active' : 'inactive'}
                />
            ) )}
        </div>
    );
};

export default ImageSlider;