import React from 'react';
import { Modal } from "react-bootstrap";

const DeleteWorkloadModal = ( { show, handleClose, id, title, deleteWorkload, url="",  workloads = [] } ) => {
    return (
        <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
            <Modal.Header><h3>Are you sure you want to delete workload ?</h3></Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    <table className="table w-75">
                        <thead>
                            <tr className="table-primary">
                                <th>ID</th>
                                <th>Workload</th>
                            </tr>
                        </thead>
                        {workloads.length > 0 ? <tbody>
                            {workloads.map( item => <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.short_description}</td>
                            </tr> )}
                        </tbody> : <tbody>
                            <tr>
                                <td>{id}</td>
                                <td>{title}</td>
                            </tr>
                        </tbody>
                        }
                        
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" onClick={handleClose}>Cancel</button>
                <button className=" btn btn-primary" onClick={() => {
                    if( workloads.length > 0 ) {
                        deleteWorkload( workloads );
                    } else {
                        deleteWorkload( id, url );
                    }
                    // handleClose();
                }}>Confirm</button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteWorkloadModal;