import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React, { useState } from "react";

const WorkHistory = ( { workHistory, statusList, employeeList, entry, setEntry } ) => {
    console.log( workHistory );
    const getDate = ( value ) => {
        let d = new Date( +value );
        return d;
    };

    function handleChange( e ) {
        const value = e.toString( "html" );
        setEntry( value );
    }

    const getStatusName = ( value ) => {
        const statusName = value && statusList.find( el => {
            if( el.wl_status_id == ( value ) ) {
                return el.name;
            } 
        } );
        return statusName && statusName.name !== undefined ? statusName.name : 'Error Loading';
    };

    const getEmployeeName = ( value ) => {
        const employeeName = value && employeeList.find( el => {
            if( el.account_id == Number( value ) ) {
                return el.name;
            } 
        } );
        return  employeeName && employeeName.name !== undefined ? employeeName.name : 'Error Loading';
    };

    return (
        <div className="row">
            <div className="col mb-1 p-2">
                <div className="row">
                    <span className="col-xl-2 col-lg-12 p-0 font-weight-bold">Work History:</span>
                    <span className="col-xl-10 col-lg-12 p-0">
                        {/* <Editor
                            className="editor"
                            apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                            value={entry || ""}
                            init={{
                                // height: 175,
                                menubar: false,
                                branding: false,
                                statusbar: false,
                                toolbar: "formatselect | bold italic| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
                            }}
                            onEditorChange={handleChange}
                        /> */}
                        <ReactQuill
                            value={entry || ""}
                            onChange={handleChange}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['bold', 'italic', 'underline'],
                                    [{ 'align': [] }],
                                    ['clean']
                                ],
                            }}
                            formats={[
                                'header', 'font',
                                'bold', 'italic', 'underline',
                                'list', 'bullet',
                                'align'
                            ]}
                        />
                        {workHistory && <div className="wl_historyDiv">
                            <ul className="p-0">
                                {workHistory && workHistory.split( "|,|" ).map(
                                    ( item ) =>
                                        item && (
                                            <li key={item.split( ", " )[0]} className="m-1">
                                                <ListItem
                                                    timestamp={item && getDate( item.split( ", " )[0] ).toLocaleString( "US" )}
                                                    status={getStatusName( item.split( ", " )[1] )}
                                                    employeeName={item && getEmployeeName( item.split( ", " )[2] )}
                                                    content={item}
                                                />
                                            </li> )
                                )}
                            </ul>
                        </div>}
                    
                    </span>
                </div>
            </div>
        </div>
    );
};

export default WorkHistory;

const ListItem = ( { timestamp, status, employeeName, content } ) => {
    const [expand, setExpand] = useState( false );

    const getContent = ( content ) => {
        let result = content.split( ', ' );
        result.shift();
        result.shift();
        result.shift();
        return result.join( ', ' ); 
    };

    return (
        <div className="workHistory_item">
            <div className="workHistory_subtext">
                <span>{timestamp}{": "}</span>
                <span className="workHistory_status">{status}{": "}</span>
                <span className="workHistory_subtext">Updated by {employeeName + ": "}</span>
            </div>
            <div
                className={!expand ? "workHistory_contentHidden" : ""}
                dangerouslySetInnerHTML={{ __html: getContent( content ) }}></div>
            {content && content.length > 50 && 
            <span
                className="text-primary xs-fontSize"
                onClick={() => setExpand( !expand )}>
                {expand ? " Read Less" : " Read More"}
            </span>
            }
        </div>
    );
};