import { createTheme, MuiThemeProvider, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

const CustomerSelector = ( { list, value, setData, customerName } ) => {
    const [searchInput, setSearchInput] = useState( "" );
    const [suggestions, setSuggestions] = useState( [...list] );
    const [displayValue, setDisplayValue] = useState( customerName );
    const wrapperRef = useRef( null );

    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    useEffect( () => {
        document.addEventListener( "click", handleClickOutside, true );
    }, [] );

    const handleClickOutside = ( e ) => {
        if( !wrapperRef?.current?.contains( e.target ) ) {
            handleCancel();
        }
    };

    const handleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };

    const handleSearchChange = ( e ) => {
        setSearchInput( e.target.value );
        if ( e.target.value.length > 0 ) {
            const suggestions = list.filter( ( item ) =>
                item.name.toLowerCase().match( e.target.value.toLowerCase() )
            );
            setSuggestions( suggestions );
        } else {
            setSuggestions( [] );
        }
    };

    const handleSelect = ( e ) => {
        const { value } = e.target;
        let selectedCustomer = list.filter( item => item.account_id == value );
        console.log( selectedCustomer );
        // console.log( value, selectedCustomer );
        setDisplayValue( selectedCustomer[0].name );
        setData( ( prev ) => {
            return {
                ...prev,
                customer_id: selectedCustomer[0].account_id,
                customer_name: selectedCustomer[0].name
            };
        } );
        handleCancel();
    };

    const handleCancel = () => {
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    function editValue() {
        if( open ) {
            return <div className='d-flex wl_customerPopover' ref={wrapperRef}>
                <div className='w-100 p-1'>
                    <div className="d-flex position-relative">
                        <input
                            type="search"
                            className="form-control w-100"
                            placeholder="Search here"
                            onChange={handleSearchChange}
                            value={searchInput}
                            autoFocus
                        />
                        {/* <span className='btn btn-info' onClick={handleClear}>Clear</span> */}
                    </div>
                </div>
                <div className="wl_customerSearchResult">
                    {( suggestions && suggestions.length > 0 ) && suggestions.map( item => <li key={item.account_id + "customer"} value={item.account_id} className={item.account_id == Number( value ) ? "selected" : "notSelected"} onClick={handleSelect}>{item.name}</li> )}
                    {!searchInput && list.map( item => <li key={item.account_id} value={item.account_id} className={item.account_id == Number( value ) ? "selected" : "notSelected"} onClick={handleSelect}>{item.name}</li> )}
                </div>
            </div>;
        }
    }

    useEffect( () => {
        if( list.length > 0 && value ) {
            const currentValue = list.find( item => item.account_id === value );
            setDisplayValue( currentValue.name );
        }
    },[list, value] );

    useEffect( () => {
        if( !value ) {
            setDisplayValue( '' );
        }
    }, [value] );

    return <>
        <MuiThemeProvider theme={theme}>
            {editValue()}
            <Tooltip title={displayValue ? displayValue : ""}>
                <div className='customerSelect' onClick={handleClick}>
                    {displayValue ? <span className="form-control" onClick={handleClick}>{displayValue}</span>
                        :  !open ? <button className="btn btn-outline-primary w-100">Select Workload Customer</button> : "" }
                </div>
            </Tooltip>
        </MuiThemeProvider>
    </>;
};

export default CustomerSelector;