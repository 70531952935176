import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import React from "react";

export default class TalentSkillsEvaluation {
	
	
    AddSkillShow ( state, setState ) {
        return(
            <Button
                size={"small"}
                color={"primary"}
                variant={"contained"}
                onClick={()=> setState( { ...state, showAddSkill: true } )}
            >
                <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="mr-2"
                />
				Add a Skill to Evaluate
            </Button>
        );
    }
	
    AddSkillHidden ( state, setState, resetNewSkill ) {
        return(
            <Button
                size={"small"}
                color={"secondary"}
                variant={"contained"}
                onClick={()=> {
                    resetNewSkill();
                    setState( { ...state, showAddSkill: false } );
                }}
            >
                <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="mr-2"
                />
				Cancel
            </Button>
        );
    }
	
	
}