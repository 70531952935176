import React, { useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { Paper } from "@material-ui/core";
import TableOfNotes from './TableOfNotes';

export default function NotesContainer( { talent_id,isEditing } ) {

    const [notesList, setNoteList] = useState( [] );
    const [loading, setLoading] = useState( false );
	
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );


    useEffect( () => {
        getNotes().then( null );
    }, [] );


    // get all available skills
    const getNotes = async () => {
        try {
            startLoading();
            const response = await util.axios.get( `talent/note_list/${talent_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            setNoteList( Object.assign( [], data ) );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    return (
        <div className="container-fluid mt-3 p-0" style={{ backgroundColor: "#F4F4F4" }}>
          {notesList.length || isEditing?  <div className="row">
                <div className="col-md-12">
                    <div elevation={1} className="mb-3" >
                        <TableOfNotes title="Primary" reloadData={setNoteList} data={notesList} talent_id={talent_id} isEditing={isEditing}/>
                    </div>
                </div>
            </div>:null
            }
            {util.LOADING_SCREEN( loading )}
        </div>
    );
}

