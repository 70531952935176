import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';


const WorkflowLog = ( { workflowItem } ) => {
    const [open, setOpen] = useState( false );

    return <div>
        <div className="d-flex justify-content-between w-100 isCursor p-1 btn" onClick={() => setOpen( !open )}>
            <span>Log</span>
            <FontAwesomeIcon className="text-secondary ml-1" icon={faChevronDown} />
        </div>
        <Collapse in={open}>
            <div>
                <ul className="xs-fontSize">
                    {workflowItem.log.split( '|' ).map( ( el, index ) => <li key={index} className="workflow-log-item">{el !== "null" ? el : ""}</li> )}
                </ul>
            </div>
        </Collapse>
    </div>
    ;
};

export default WorkflowLog;