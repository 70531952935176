import React, { useEffect, useState } from "react";
import { PRIMARY, SECONDARY, SOFT } from "../../../../constants";
import TableOfSkills from "./TableOfSkills";
import util from "../../../utils/miniUtils";
import { Paper } from "@material-ui/core";

export default function SkillsContainer( { talent_id ,isEditing } ) {

    const [dataList, setDataList] = useState( [] );
    const [primary, setPrimary] = useState( [] );
    const [secondary, setSecondary] = useState( [] );
    const [soft, setSoft] = useState( [] );
    const [loading, setLoading] = useState( false );

    useEffect( () => {
        getSkills().then( null );
    }, [] );

    useEffect( () => {
        updateSkillsLists();
    }, [dataList] );

    const updateSkillsLists = () => {
        setPrimary( () => dataList.filter( s => parseInt( s.skill_type ) === 1 ) );
        setSecondary( () => dataList.filter( s => parseInt( s.skill_type ) === 2 ) );
        setSoft( () => dataList.filter( s => parseInt( s.skill_type ) === 3 ) );
    };

    // get all available skills
    const getSkills = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `talent/skill_list/${talent_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            setDataList( Object.assign( [], data ) );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };

    return (
        <div className='container-fluid' style={{ backgroundColor: "#F4F4F4" }}>

            <div className="mt-3 ">
                {primary.length || isEditing ?  
                    <div className="row " style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        <TableOfSkills title="Primary Skills" reloadData={setDataList} data={primary} talent_id={talent_id} skillType={PRIMARY} isEditing={isEditing} />
                    </div>
                    : null}
                {secondary.length || isEditing ?  
                    <div className="row " style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        <TableOfSkills title="Secondary Skills" reloadData={setDataList} data={secondary} talent_id={talent_id} skillType={SECONDARY} isEditing={isEditing}/>
                    </div>
                    : null}
                {soft.length || isEditing ?   
                    <div className="row " style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        <TableOfSkills title="Soft Skills" reloadData={setDataList} data={soft} talent_id={talent_id} skillType={SOFT} isEditing={isEditing} />
                    </div>
                    : null}
                {util.LOADING_SCREEN( loading )}
            </div>
        </div>
    );
}

