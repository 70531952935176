import React from "react";
import Heading from "../../common/Heading";
import "./hero.css";
import ImageSlider from "./imageSlider";
import bluefront from '../../../../assets/images/bluefront.webp';

const Hero = () => {
    return (
        <>
            <section className='hero'>
                <div className='ej_container'>
                    <Heading title='Connecting The Right Talent With The Right Opportunity ' subtitle='Explore the exciting worlds of creative career and find the job of your dreams.' />
                   
                </div>
                <div className="blueCircleBackground">
                    <ImageSlider />
                </div>
            </section>
        </>
    );
};

export default Hero;
