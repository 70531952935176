import React, { Fragment, useState } from 'react';
import ImageComponent from '../utils/ImageComponent';


const CodingAssessmentForm = () => {
    const [githubLink, setGithubLink] = useState( "" );

    return (
        <Fragment>
            <div className="mt-5">
                <div className="text-center">
                    <img  width="250px" src="/images/Group 18.svg" className="desktop" alt="logo"/>
                    <div className='form-group mb-2' style={{ maxWidth: "500px", margin: "0 auto" }}>
                        <label htmlFor='githubLink'>Github Repo link for the Test</label>
                        <input 
                            id="githubLink" 
                            value={githubLink} 
                            className='form-control' 
                            onChange={( e ) => setGithubLink( e.target.value )} />
                        <button className='btn btn-sm btn-primary mt-1'>Submit</button>
                    </div>
                    <h4><a href="/">eJAmerica</a></h4>
                </div>
            </div>
        </Fragment>      
    );
};

export default CodingAssessmentForm;