import MaterialTable from "material-table";
import React, { Fragment, useState } from "react";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import NewSkillDialog from "./NewSkillDialog";
import { Chip, MuiThemeProvider } from "@material-ui/core";
import { Delete, Edit,CloseRounded, Close } from "@material-ui/icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function TableOfSkills( { data, title, talent_id, skillType, reloadData,isEditing } ) {
	
    const [show, setShow] = useState( false );
    const [editMode, setEditMode] = useState( false );
    const [skill, setSkill] = useState( { catalog_skill_id: null, skill_name: "", years_experience: 0, skill_type: 0 } );
    const [loading, setLoading] = useState( false );
	
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
	
    const deleteSkill = async ( row ) => {
        try {
            startLoading();
            let { candidate_id, catalog_skill_id } = row;
            const response = await util.axios.delete( `talent/skill_delete/${talent_id}/${catalog_skill_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Skill Deleted" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const saveNewSkill = async ( talent_id = 0, skill_type = "", hideSkill ) => {
        try {
            startLoading();
            let temp = { ...skill };
            temp.skill_type = util.typeSkill( skill_type );
            const response = await util.axios.post( `talent/skill_add/${talent_id}/`, temp );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Skill Created" );
            setSkill( { catalog_skill_id: null, skill_name: "", years_experience: 0, skill_type: 0 } );
            setShow( false );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const updateSkill = async ( talent_id = 0, skill_type = "" ) => {
        try {
            startLoading();
            const x = { skill_name: "", years_experience: 0, skill_type: 0 };
            x.skill_name = skill.skill_name;
            x.years_experience = skill.years_experience;
            x.skill_name = skill.skill_name;
            x.skill_type = skill.skill_type;
            const response = await util.axios.put( `talent/skill_update/${talent_id}/${skill.catalog_skill_id}/`, x );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Skill Updated" );
            setSkill( { catalog_skill_id: null, skill_name: "", years_experience: 0, skill_type: 0 } );
            setShow( false );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
    const handleChangeNewSkill = ( e ) => {
        const { name, value } = e.target;
        setSkill( { ...skill, [name]: value } );
    };
	
    function editSkill( row ) {
        setSkill( row );
        setEditMode( true );
        setShow( true );
    }
    function handleAddSkills(){
        setEditMode( false );
        setShow( true );
    }
    const titleColor = data.length === 0 ? 'gray' : 'black';
    const tooltip = (
        <Tooltip id="tooltip">
            {`Add new ${skillType} skill`}
        </Tooltip>
    );
    return (
        <Fragment>
           
            <div className="container-fluid mt-3 mb-4">
               
                {isEditing ? (
                      <OverlayTrigger  overlay={tooltip}>
                        <FontAwesomeIcon className="custom-icon text-primary" icon={faPlus} onClick={() => handleAddSkills()} />
                    </OverlayTrigger>
                ) : ""}
                
                    <b className="custom-title" style={{ color: titleColor }}>{title}</b>
                   
                <div className="chips-container">
                    {data.map( ( row, index ) => (
                        <Chip className="ml-1 mt-1 px-1 py-4" style={{ backgroundColor:'white' }} 
                            key={index}
                            label={
                                <div className="chip-content ">
                                    <div className="row">
                                        <span className="text-primary pt-2 mr-1 " style={{ fontSize:'15px' }}>{row.skill_name}</span>
                                        <Edit  onClick={() => editSkill( row )} className="iconGray " style={{ width:'15px',marginLeft:'5px',marginTop:"7px", cursor: "pointer"}}/>
                                        <Close onClick={() => deleteSkill( row )} className="iconGray" style={{ width:'15px',marginLeft:'4px',marginTop:"7px", cursor: "pointer" }}/>

                                    </div>
                                    
                                    <span  >{row.years_experience} Years of Exp</span>
                                   
                                    {/* <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Delete onClick={() => deleteSkill( row )} className="iconGray" style={{ width:'15px' }}/>
                                        <Edit onClick={() => editSkill( row )} className="iconGray" style={{ width:'15px' }}/>
                                    </div> */}
                                </div>
                                
                            }
                            // onDelete={() => deleteSkill( row )}
                            // avatar={<Edit onClick={() => editSkill( row )} />}
                            // deleteIcon={<Delete onClick={() => deleteSkill( row )} />}
                        />
                    ) )}
                </div>
               
            </div>
            <NewSkillDialog
                talent_id={talent_id}
                show={show}
                hideSkill={() => setShow( false )}
                saveNewSkill={saveNewSkill}
                skillType={skillType}
                skill={skill}
                editMode={editMode}
                handleChangeNewSkill={handleChangeNewSkill}
                updateSkill={updateSkill}
                setSkill={setSkill}
            />
        </Fragment>
    );
}