import Modal from "react-bootstrap/Modal";
import React, { Fragment, useState, useEffect, useMemo } from "react";
import { PositionsStore } from "../../stores/PositionsStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import ApplyToJob from "./ApplyToJob";
import util from "../../../utils/miniUtils";
import ScreeningQuestionModal from "../ScreeningQuestionModal";

export default function ApplyModal() {

    const show = PositionsStore.useState(state => state.applyModal);
    const j = PositionsStore.useState(state => state.job);
    const handleClose = () => { PositionsStore.update(s => { s.applyModal = false; }); };
    const [showScreening, setShowScreening] = useState(false);
    // state related to screening questions
    const [response, setResponse] = useState([]);
    const [sqData, setSqData] = useState([]);

    // Create a screening questions map
    function handleIntialSqData() {
        const responseMap = {};
        sqData.forEach(el => {
            return responseMap[el.id] = { questionId: el.id, answer: "" };
        });
        return responseMap;
    }

    async function getSqData() {
        try {
            const request = await util.axios.get(`/get_job_questions/${j.id}`);
            const { data, error, msg } = request.data;
            if (error) throw msg;
            setSqData(data);
        } catch (error) {
            util.handleError(error);
        }
    }

    async function applyToJob() {
        try {
            const data = {
                id: util.user.candidate_id,
                fromTalent: true
            };
            const request = await util.axios.post(`/requirements/add_candidate_new_method/${j.id}`, { data, sqResponse: Object.values(response) });
            const { error, msg } = request.data;
            util.toast().success(msg);
            setTimeout(() => {
                window.location.reload();
            }, 1500);
            if (error) throw msg;

        } catch (e) {
            util.handleError(e);
        }
        handleClose();
    }

    useEffect(() => {
        getSqData();
    }, []);

    useMemo(() => {
        if (showScreening) {
            setResponse(handleIntialSqData());
        }
    }, [showScreening]);
    return (showScreening ?
        <ScreeningQuestionModal
            show={show}
            handleClose={handleClose}
            sqData={sqData}
            applyToJob={applyToJob}
            response={response}
            setResponse={setResponse}
        /> :
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            size='lg'>
            <Modal.Header closeButton={showScreening ? false : true} className={showScreening ? 'screening-modal-header' : null}>
                <Modal.Title>{j.job_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!util.user.name ?
                    <Fragment>
                        <p>You need to Login/Register before apply to this position</p>
                        <p className="d-flex justify-content-around">
                            <button
                                onClick={() => {
                                    PositionsStore.update(s => {
                                        s.applyModal = false;
                                        s.loginModal = true;
                                        s.createAccount = false;
                                    });
                                }}
                                className="btn btn-sm btn-info">
                                <FontAwesomeIcon icon={faUser} className="mr-2" />
                                Login
                            </button>
                            <button
                                onClick={() => {
                                    PositionsStore.update(s => {
                                        s.createAccount = true;
                                        s.applyModal = false;
                                        s.loginModal = false;
                                    });
                                }}
                                className="btn btn-sm btn-primary">
                                <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                                Register
                            </button>
                        </p>
                    </Fragment>
                    :
                    <Fragment>

                        <ApplyToJob job={j} setShowScreening={setShowScreening} showScreening={showScreening} jobSq={sqData.length} />

                    </Fragment>
                }
            </Modal.Body>
        </Modal>
    );
}


