import { faBell, faEllipsisV, faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import NotificationModal from '../../shared/CustomizeNotificactionModal/NotificationModal';
import useNotifications from '../../shared/CustomizeNotificactionModal/useNotifications';
import { PositionsStore } from '../stores/PositionsStore';
import ImageComponent from '../../utils/ImageComponent';

import './talent.css';

const TalentNotification = () => {
    const {
        showDropdown,
        notRead,
        selectedOption,
        filteredNotifications,
        handleOptionChange,
        handleMarkAsRead,
        handleMarkAsUnread,
        handleMarkAsAllRead,
        handleMarkAsAllUnRead,
        handleDropdownClick,
        getNotifications,
        deleteNotification,
        getModelImage
    } = useNotifications();
    const [showNotificationModal, setNotificationModal] = useState( false );

    function handleCloseNotificationModal() {
        setNotificationModal( false );
    }

    useEffect( () => {
        getNotifications();
    }, [] );

    return (
        <div className="notification-dropdown">
            <button className={` ${notRead >= 0 ? 'notification-dropdown-button' : 'additional'}`} onClick={handleDropdownClick}>
                <FontAwesomeIcon icon={faBell} className='iconGray' />
                {notRead >= 0 && (
                    <span className="notification-count">
                        {filteredNotifications.filter( notification => notification.model === 'talent' && !notification.read ).length}
                    </span>
                )}
            </button>
            {showDropdown && (
                <>
                    <div className="notification-dropdown-content">
                        <div className='row d-flex justify-content-between p-1 mt-1 border-bottom'>
                            <b className='col-3'>Notifications</b>
                            <div className='col-3'>
                                <select className='drp_dwn' value={selectedOption} onChange={handleOptionChange}>
                                    <option value="All">All</option>
                                    <option value="Read">Read</option>
                                    <option value="Unread">Unread</option>
                                </select>
                            </div>
                            <div className="dropdown col-4 mr-4">
                                <button type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="true" aria-expanded="true">
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </button>
                                <div className="dropdown-menu my_dp" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" onClick={() => handleMarkAsAllRead()} >
                                        Mark all as read
                                    </button>
                                    <button className="dropdown-item" onClick={() => handleMarkAsAllUnRead()}>
                                        Mark all as unread
                                    </button>
                                    <button className="dropdown-item" onClick={() => setNotificationModal( true )}>
                                        Settings
                                    </button>
                                </div>
                            </div>
                        </div>
                        {filteredNotifications && filteredNotifications.length > 0 ? (
                            filteredNotifications.map( ( notification ) => {
                                if ( notification.model === 'talent' ) {
                                    return (
                                        <div
                                            key={notification.notification_id}
                                            className={`notification-item row ${notification.read ? 'read' : 'unread'}`}
                                        >
                                            <div className="notification-item-icon col-md-1">
                                                <img src={getModelImage( notification.model )} alt="img" className="mdl_img" />
                                            </div>

                                            <Link
                                                to='/appliedJobs'
                                                className="notification-item-message col-md-7 custom-link"
                                                onClick={() => {
                                                    // PositionsStore.update((s) => {
                                                    //     s.sectionSelected = "JobsAppliedTalent";
                                                    //     s.firstTime = false;
                                                    // });
                                                    // window.open( notification.link ); // Open the provided link
                                                    if ( !notification.read ) {
                                                        handleMarkAsRead( notification.notification_id ); // Mark the notification as read if it's unread
                                                    }
                                                }}
                                            >
                                                <div className="notification-item-title">{notification.title}</div>
                                                <div className="notification-item-body">{notification.message}</div>
                                                <div style={{ fontSize: '10px', float: 'right' }}>
                                                    <Moment format="MMMM Do, YYYY [@] h:mm A">
                                                        {moment( notification.createdAt )}
                                                    </Moment>
                                                </div>
                                            </Link> {/* Closing tag for <Link> */}

                                            <Tooltip title={notification.read ? 'Mark as Unread' : 'Mark as Read'}>
                                                <div className="col-md-1">
                                                    <FontAwesomeIcon
                                                        icon={notification.read ? faEyeSlash : faEye}
                                                        onClick={() =>
                                                            notification.read
                                                                ? handleMarkAsUnread( notification.notification_id )
                                                                : handleMarkAsRead( notification.notification_id )
                                                        }
                                                        className="iconGray"
                                                        style={{ width: '13px' }}
                                                    />
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Remove Notifications">
                                                <div className="col-1">
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        onClick={() => {
                                                            deleteNotification( notification.notification_id );
                                                        }}
                                                        className="iconGray"
                                                        style={{ width: '10px' }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            } )
                        ) : (
                            <div className="notification-empty">No notifications</div>
                        )}
                    </div>
                </>
            )}
            <NotificationModal
                show={showNotificationModal}
                handleClose={handleCloseNotificationModal}
            />
        </div>
    );
};

export default TalentNotification;
