import React, { useEffect } from "react";
import CompanyPartner from "./companyPartners/CompanyPartner";
import Featured from "./featured/Featured";
import Hero from "./hero/Hero";
import '../../../App.css';
import HomePageSearchForm from "./HomePageSearchForm";
import { PositionsStore } from "../../positions/stores/PositionsStore";
import util from "../../utils/miniUtils";
import TalentGreetingPopup from "../../positions/modals/TalentGreetingPopup";


const Home = () => {
    const position = PositionsStore.useState(state => state);

    useEffect(() => {
        const hasEffectRun = localStorage.getItem('effectRun');

        if (!hasEffectRun && util.user.role === 2) {
            PositionsStore.update(s => {
                s.isTalent = true;
                s.isModalVisible = true;
            });
            localStorage.setItem('effectRun', 'true');
        }
    }, [])

    return (

        <>
            {position.isModalVisible ? <TalentGreetingPopup /> : ""}

            <Hero />
            <HomePageSearchForm />
            <Featured />
            <CompanyPartner />
        </>
    );
};

export default Home;
