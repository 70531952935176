import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import WorkflowLog from './WorkflowLog';
import WorkflowItem from './WorkflowItem';

const WorkflowDetail = ( { workload_id, setWorkflowStatus } ) => {
    const [userGroups, setUserGroups] = useState( [] );
    const [workflowItem, setWorkflowItem] = useState( null );

    const getWorkflowItem = async () => {
        try {
            const request = await util.axios.get( `workflowItem?workload=${workload_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setWorkflowItem( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const updateApprovalItem = async ( updateData ) => {
        try {
            const request = await util.axios.patch( `workflowItem/${workflowItem.workflow_item_id}`, updateData );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setWorkflowItem( data );
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getEmployeeGroups = async () => {
        try {
            const request = await util.axios.get( `groups/byEmpId/${util.user.employee_id}` );
            const { error, msg, data } = request.data;
            setUserGroups( data );
            if( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const approvalRequired = ( workflowItem ) => {
        const { approvals_required } = workflowItem;
        
        let approvalCount = 0;
        let approvalStatus = 0;
        for( let i = 1; i <=3; i++ ) {
            if( workflowItem[`approver${i}_status`] !== null ) {
                if( workflowItem[`approver${i}_status`] === 1 ) 
                    approvalStatus++;
                approvalCount++;
            }
        }
        if( approvalCount >= approvals_required ) {
            if( approvalStatus >= approvals_required ) {
                setWorkflowStatus( "Approved" );
                return { msg: "success", status: "Approved" };
            } else { 
                setWorkflowStatus( "Declined" );
                return { msg: "danger", status: "Declined" };
            }
        } else {
            return { msg: "warning", status: "Pending" };
        }
    };

    useEffect( ()=> {
        getWorkflowItem();
        getEmployeeGroups();
    },[] );

    if( !workflowItem ) {
        return "";
    }

    return (
        workflowItem ? <div className='workflow-section p-2'>
            <div className={`mb-2 text-${approvalRequired( workflowItem ).msg}`}>{approvalRequired( workflowItem ).status}</div>

            <WorkflowItem 
                workflowItem={workflowItem}
                approvalStatus={approvalRequired( workflowItem )}
                approver="approver1"
                userGroups={userGroups}
                updateApprovalItem={updateApprovalItem} />

            <WorkflowItem 
                workflowItem={workflowItem}
                approvalStatus={approvalRequired( workflowItem )}
                approver="approver2"
                userGroups={userGroups}
                updateApprovalItem={updateApprovalItem} />

            {workflowItem.approver3 ? <WorkflowItem 
                workflowItem={workflowItem}
                approvalStatus={approvalRequired( workflowItem )}
                approver="approver3"
                userGroups={userGroups}
                updateApprovalItem={updateApprovalItem} /> : ""}

            {workflowItem.log && <WorkflowLog workflowItem={workflowItem}  />}
        </div> : ""
    );
};

export default WorkflowDetail;






// const approvalRequired = ( workflowItem ) => {
//     const { approvals_required } = workflowItem;

//     let approvalCount = 0;
//     let approvalStatus = 0;
//     for ( let i = 1; i <= 3; i++ ) {
//         if ( workflowItem[`approver${i}_status`] !== null ) {
//             if( workflowItem[`approver${i}_status`] === 1 )
//                 approvalStatus++;
//             approvalCount++;
//         }
//     }
//     const pendingApprovals = approvals_required - approvalCount;
//     if( pendingApprovals === 0 || pendingApprovals < 0 ) {
//         if( approvalStatus > 1 ) {
//             return { msg: "success", status: "Approved" };
//         } else {
//             return { msg: "danger", status: "Declined" };
//         }
//     }

//     if( pendingApprovals > 0 ) {
//         return { msg: "warning", status: "Pending" };
//     }

//     return "";
// };