import React, { useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { Paper } from "@material-ui/core";
import TableOfEducation from "./TableOfEducation";
import { TalentStore } from "../TalentStore";

export default function EducationContainer( { talent_id,isEditing } ) {
    const [educationList, setEducationList] = useState( [] );
    const [loading, setLoading] = useState( false );
	
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
	
	
    useEffect( () => {
        getEducation().then( null );
    }, [] );
	
	
    // get all available skills
    const getEducation = async () => {
        try {
            startLoading();
            const response = await util.axios.get( `talent/education_list/${talent_id}/` );
            const { error, msg, data } = response["data"];
           
            if ( error ) throw new Error( msg );
            setEducationList( Object.assign( [], data ) );
            TalentStore.update( t => {
                t.talentEducation = data ;
            } );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    return (
        <>
            <div className="container-fluid mt-3"  style={{ backgroundColor: "#F4F4F4" }}>
                <TableOfEducation
                    title="Primary"
                    reloadData={setEducationList}
                    data={educationList}
                    talent_id={talent_id}
                    isEditing={isEditing}
                />
            </div>
            {util.LOADING_SCREEN( loading )}
        </>
    );
}