import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import './VideoPlayer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import IntroVideo from "../IntroVideo/IntroVideo";
import { CircularProgress } from "@material-ui/core";
import util from "../../../utils/miniUtils";

function VideoPlayer( { talent_id, talentState, setTalentState,open,handleClose,handleOpen } ) {
    const [loading, setLoading] = useState( false );
    const [isPlaying, setIsPlaying] = useState( false );
    const handlePlayPause = () => {
        setIsPlaying( !isPlaying );
    };
    
    const videoHeight = isPlaying ? '360px' : '180px';
    useEffect( () => {
        // If there's a video URL and we're in a loading state,
        // start a timer for 60 seconds before showing the video.
        if ( talentState.video_profile && loading ) {
            const timer = setTimeout( () => {
                setLoading( false );
            }, 30000 );
            return () => clearTimeout( timer );
        }
    }, [talentState.video_profile, loading] );

    

    const updateVideoProfile = ( newVideoUrl ) => {
        setTalentState( {
            ...talentState,
            video_profile: newVideoUrl
        } );
        setLoading( true );
    };
    const handleDeleteVideo = async ( candidateId ) => {
        try {
            const request = await util.axios.delete( `${util.url}/delete-video/${candidateId}` );
            const { error, msg } = request.data;
           
            if ( !error ) {
                setTalentState( {
                    ...talentState,
                    video_profile: null, 
                } );
            }
    
            util.toast().success( msg ); 
    
            if ( error ) throw msg; 
        } catch ( error ) {
            util.handleError( error ); 
        }
    };
    useEffect( () => {
        if ( talentState.video_profile === null ) {
            
            setTalentState( { ...talentState } );
        }
    }, [talentState.video_profile] );
    
    

    return (
        <div className="video-container mb-4">
            {talentState.video_profile && !loading ? (
                <div style={{ height: videoHeight, transition: 'height 0.5s ease' }}>
                    <ReactPlayer
                        url={talentState.video_profile}
                        width="100%"
                        height="100%"
                        controls
                        playing={isPlaying}
                        onPlay={handlePlayPause}
                        onPause={handlePlayPause}
                    />
                </div>
            ) : (
                <>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <p className="video-description">An intro video would be a valuable addition</p>
                    )}
                    <ReactPlayer
                        url={loading ? undefined : "https://www.youtube.com/watch?v=LOS5WB75gkY"}
                        controls={loading ? false : true}
                        width="100%"
                        height={loading ? "0" : "180px"}
                        style={{ display: loading ? "none" : "block" }}
                    />
                </>
            )}
            <div className="d-flex">
                 <div className="video-edit">
                    <FontAwesomeIcon className="editIcon" icon={faPen} onClick={handleOpen}/>
                </div >
                {talentState.video_profile?<div className="video-delete">
                    <FontAwesomeIcon className="editIcon"  icon={faTrash} onClick={()=>handleDeleteVideo( talent_id )}/>
                </div>:""}</div>
            
            
            <Modal size="lg" show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><span>Please upload a 2 minutes intro video about yourself.</span></Modal.Title>
                </Modal.Header>
             
                <Modal.Body>
                    <IntroVideo talent_id={talent_id} handleClose={handleClose} updateVideoProfile={updateVideoProfile} setLoading={setLoading}/>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    );
}

export default VideoPlayer;
