import React, { Fragment, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import util from "../../../utils/miniUtils";
import selector from "../../../utils/selector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import {
    Button,
    Grid,
    // Collapse,
    InputAdornment,
    TextField,
    Typography,
    // FormControl,InputLabel, Select
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { currency } from '../../../utils/currencies';
import usePersonalInformation from "./usePersonalInformation";
import { TalentStore } from "../TalentStore";
import ResumeContainer from "./upload_resume/ResumeContainer";
import CountryAutoFill from "../../../talents/components/listComponents/CountryAutoFill";
import Location from "../../../talents/components/listComponents/Location";
import { setDefaultLocale } from "javascript-time-ago";
import LocationView from "../../../talents/components/listComponents/LocationView";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import DocumentViewer from "../../../utils/DocumentViewer";

export default function ProfileTalent( { isResumeAvailable,talentState,setTalentState } ) {

    const {
        // handleChanges,
        setData,
        getListOfJobTitles,
        jobTitles,
        selected,
        setSelected,
        updateJobTitles
    } = usePersonalInformation();

    // const [talentState, setTalentState] = useState( {} );
    const [showVideo, setShowVideo] = useState( false );
    const [viewResume, setViewResume] = useState( false );

    const handleToggleResume = () => {
        if ( isResumeAvailable( talentState ) ) {
            setViewResume( prev => !prev ); // Toggle the visibility
        } else {
            console.log( 'No resume uploaded' );
        }
    };

    const handleLinkClick = () => {
        setShowVideo( true );
    };
    

    function handleChanges( e ){
        const { name, value } = e.target;
        setTalentState( prevState => ( {
            ...prevState,
            [name]: value
        } ) );
    }
    
    // const style = {
    //     maxWidth: 120
    // };
	
    const talent = TalentStore.useState( state => state.talent );
    // const [showMoreFields, setShowMoreFields] = useState( false );
	
    useEffect( () => {
        setSelected( talent.jobTitles );
        // eslint-disable-next-line
    }, [] );
	
    useEffect( () => {
        iconDependingCurrencySelected();
        // eslint-disable-next-line
    }, [talent.currency] );
	
    useEffect( () => {
        getListOfJobTitles();
        // eslint-disable-next-line
    }, [] );
	
    useEffect( () => {
        updateJobTitles();
        // eslint-disable-next-line
    }, [selected] );
	
    useMemo( () => {
        setTalentState( talent );
    },[talent] );

    if ( talent === null ) {
        return "loading...";
    }
   
    function iconDependingCurrencySelected() {
        for ( let x = 0; x < currency.length; x++ ) {
            const c = currency[x];
            if ( c.code === talent.currency || c.symbol === talent.currency ) {
                return c.symbol;
            }
        }
        return `$`;
    }
   
    return (
        <Fragment>
            {/* <div className="d-flex">
                <div className="col-md-6 mt-2">
                    <div className="mt-3">
                       
                        <div className="col-md-12">
                           
                            <span className="d-flex"><span>Resume:</span> <a
                                target="new"
                                href={isResumeAvailable( talent )}
                                // className={"btn btn-secondary sizeBtn activeBtn"}
                                className="isCursor"
                                style={{ marginLeft:'5.6rem' }}
                            ><p>{talentState.resume ? 'View Resume' : "No resume uploaded"}</p></a></span>
                            <p><span>Job Title:</span> <span style={{ marginLeft:'4.3rem' }}>{selected.map( job => job.name ).join( ", " )}</span></p>
                            <p>
                                <LocationView
                                    data={talentState} // contains everything
                                    onChange={handleChanges}
                                /></p>
                            <span className="d-flex">Phone (Home): <p><span style={{ marginLeft:'2.67rem' }}>+{talentState?.phone_home || ""}</span></p></span>
                       
                            <span className="d-flex">Phone (Other):  <p><span style={{ marginLeft:'2.9rem' }}>+{talentState?.phone_other || ""}</span></p></span>
                            <p><span>Relocation:</span> <span style={{ marginLeft:'4.3rem' }}>{talentState?.relocation === "1" ? "Yes" : "No"}</span></p>
                            <p><span>Work Authorization:</span><span className="ml-2"> {talent.working_status !== "other" ? talent.working_status : "Other"}</span></p>
                            {talent.working_status === "other" ? (
                                <p><span>Work Authorization (Others):</span><span className="ml-2"> {talentState?.working_status_other || ""}</span></p>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        
                    </div>
                   
                </div>


                <div className="col-md-6 mt-4">
                   
                   
                    <div className="mt-3">
                        <div className="col-md-12">
                            <p><span>Highest Education:</span> <span style={{ marginLeft:'2rem' }}>{talentState?.highestEducation || ""}</span></p>
                            <p ><span>Last Job Title:</span> <span style={{ marginLeft:'4.1rem' }}>{talentState?.wh_position || ""}</span></p>
                        
                            <p><span>Currency:</span> <span style={{ marginLeft:'6.2rem' }}>{talentState?.currency}</span></p>
                        
                            <p><span>Current Salary:</span><span style={{ marginLeft:'3.8rem' }}> {talentState?.current_salary ? `${talentState.current_salary} ${iconDependingCurrencySelected()}` : "0"}</span></p>
                            <p ><span>Notice Period:</span> <span style={{ marginLeft:'4.1rem' }}>{talentState?.noticePeriod || "2 Weeks"}</span></p>
                            <p ><span>Expected Salary:</span> <span style={{ marginLeft:'3.0rem' }}>{talentState?.ctc ? `${talentState.ctc} ${iconDependingCurrencySelected()}` : "0"}/ {util.handleValueByRecruiterCountry( talentState?.salary_mode, 'salary_mode' )}</span></p>
                            <p ><span>Employment Type:</span><span style={{ marginLeft:'2.3rem' }}> {talentState?.salary_type || ""}</span></p>
                            <p ><span>How did you find us:</span><span style={{ marginLeft:'1.6rem' }}> {talentState?.platform || ""}</span></p>
                        </div>
                       
                    </div>
                    <div className=" mt-3">
                       
                    </div>
                   
                   
                </div>

                
            </div> */}
            <div style={{ margin: '20px 0' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggleResume}
                    disabled={!isResumeAvailable( talent )}  // Disable if no resume
                >
                    {viewResume ? 'Hide Resume' : 'View Resume'}
                </Button>
                {viewResume && ( util.getResume( talent ).split( '.' ).pop().toLowerCase() === "pdf" ? 
                    <DocumentViewer
                        fileType="pdf"
                        filePath={util.getResume( talent )}
                    /> : 
                    <DocumentViewer
                        fileType="docx"
                        filePath={util.getResume( talent )}
                    />
                )}
            </div>
            <Grid container spacing={2}>
                {/* Left Column */}
                <Grid item xs={12} md={6} className="mt-3">
                    <Grid container spacing={2}>
                        {/* Resume */}
                        <Grid item xs={12} className="ml-3 ">
                            <Typography>
                                <a
                                    href={isResumeAvailable( talent )}
                                    className="isCursor"
                                    style={{
                                        display: 'inline-block',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={( e ) => {
                                        e.preventDefault();

                                        if ( talentState.resume ) {
                                            window.location.href = isResumeAvailable( talent );
                                        } else {
                                            console.log( 'No resume uploaded' );
                                        }
                                    }}
                                >
            Resume : <span style={{ marginLeft: '5.2rem' }}>{talentState.resume ? 'View Resume' : 'No resume uploaded'}</span>
                                </a>
                            </Typography>
                        </Grid>
                        {talentState?.phone_home? <Grid item xs={12} className="ml-3">
                            <Typography>
          Phone (Home) : <span className=" mb-2" style={{ marginLeft: '2.2rem' }}>{talentState?.phone_home ? '+' : ''}
                                    {talentState?.phone_home || ''}</span>
                            </Typography>
                        </Grid>:null}
                        {/* Phone (Other) */}
                        {talentState?.phone_other?    <Grid item xs={12} className="ml-3 ">
                            <Typography>
          Phone (Other) :<span style={{ marginLeft: '2.4rem' }}> {talentState?.phone_other ? '+' : ''}
                                    {talentState?.phone_other || ''}</span>
                            </Typography>
                        </Grid> : null }
                        <Grid item xs={12} className="ml-3 ">
                            <Typography>
          Relocation: <span style={{ marginLeft: '4.3rem' }}>{talentState?.relocation === 1 ? 'Yes' : 'No'}</span>
                            </Typography>
                        </Grid>
                        {/* Job Title */}
                        { selected.length?   <Grid item xs={12} className="ml-3 ">
                            <Typography  >
                                <div className="d-flex"> <div style={ { display:"inline",width:'100px' }}> Job Title :</div>
                                    <div style={{ marginLeft: '0.8rem' }}>  <ul style={{ listStyleType: 'none',  }}>
                                        {selected.map( ( job, index ) => (
                                            <li key={index}   style={{ marginLeft:"40px" }}>{job.name}</li>
                                        ) )}
                                    </ul></div> </div>
                            </Typography>
                        </Grid>:null}
                        {/* Phone (Home) */}
                       
                        {/* Relocation */}
                      
                        {/* Location */}
                        <Grid item xs={12} className="ml-3">
                            <Typography>
                                <LocationView data={talentState} onChange={handleChanges} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={6} className="mt-3">
                    <Grid container spacing={2} >
                        {/* Work Authorization */}
                        { talent.working_status ?  <Grid item xs={12}>
                            <Typography>
          Work Authorization:<span className="ml-5"> {talent.working_status !== 'other' ? talent.working_status : 'Other'}</span>
                            </Typography>
                        </Grid>:null }
                        {talent.working_status === 'other' ? (
                        // Work Authorization (Others)
                            <Grid item xs={12}>
                                <Typography>
            Work Authorization (Others): <span style={{ marginLeft: '2.9rem' }}></span> {talentState?.working_status_other || ''}
                                </Typography>
                            </Grid>
                        ) : null}
                        {/* Highest Education */}
                        {talentState?.highestEducation  ?     <Grid item xs={12}>
                            <Typography>
          Highest Education: <span style={{ marginLeft: '3.4rem' }}>{talentState?.highestEducation || ''}</span>
                            </Typography>
                        </Grid> : null  }
                        {/* Last Job Title */}
                        {talentState?.wh_position ?   <Grid item xs={12}>
                            <Typography>
          Last Job Title: <span style={{ marginLeft: '5.5rem' }}>{talentState?.wh_position || ''}</span>
                            </Typography>
                        </Grid>:null }
                        {/* Currency */}
                        {talentState?.currency ?       <Grid item xs={12}>
                            <Typography>
          Currency: <span style={{ marginLeft: '7.7rem' }}>{talentState?.currency}</span>
                            </Typography>
                        </Grid>: null }
                        {/* Current Salary */}
                        {talentState?.current_salary ?    <Grid item xs={12}>
                            <Typography>
          Current Salary: <span style={{ marginLeft: '5.3rem' }}>{talentState?.current_salary ? `${talentState.current_salary} ${iconDependingCurrencySelected()}` : '0'}</span>
                            </Typography>
                        </Grid>   : null }
                        {/* Notice Period */}
                        {talentState?.noticePeriod ?     <Grid item xs={12}>
                            <Typography>
          Notice Period: <span style={{ marginLeft: '5.7rem' }}>{talentState?.noticePeriod || '2 Weeks'}</span>
                            </Typography>
                        </Grid> : null   }
                        {/* Expected Salary */}
                        {talentState?.ctc  ?     <Grid item xs={12}>
                            <Typography>
          Expected Salary: <span style={{ marginLeft: '4.5rem' }}>{talentState?.ctc ? `${talentState.ctc} ${iconDependingCurrencySelected()}` : '0'} / {util.handleValueByRecruiterCountry( talentState?.salary_mode, 'salary_mode' )}</span>
                            </Typography>
                        </Grid> : null }
                        {/* Employment Type */}
                        {talentState?.salary_type  ?    <Grid item xs={12}>
                            <Typography>
          Employment Type: <span style={{ marginLeft: '3.6rem' }}>{talentState?.salary_type || ''}</span>
                            </Typography>
                        </Grid>  : null }
                        {/* How did you find us */}
                        {talentState?.platform ?    <Grid item xs={12}>
                            <Typography>
          How did you find us: <span style={{ marginLeft: '2.7rem' }}>{talentState?.platform || ''}</span>
                            </Typography>
                        </Grid>  : null }
                    </Grid>
                </Grid>
            </Grid>

            
            
        </Fragment>
    );
	
}
