import { useState } from 'react';
import util from '../../utils/miniUtils';
import { useDispatch } from 'react-redux';
import { setIsCustomWorkload } from '../../../redux/actions/customWorkload';

const useManageWorkload = () => {
    /***************************************
    *                                      *
    *                States                *
    *                                      *
    ***************************************/
    const [tableStatus, setTableStatus] = useState( "working" );
    const [loading, setLoading] = useState( false );
    const [workloadData, setWorkloadData] = useState( [] );
    const [departmentList, setDepartmentList] = useState( [] );
    const [contactData, setContactData] = useState( [] );
    const [groupList, setGroupsList] = useState( [] );
    const [membersOfGroup, setMembersOfGroup] = useState( [] );
    const [categoriesByGroup, setCategoriesByGroup] = useState( [] );
    const [statusListByCategory, setStatusListByCategory] = useState( [] );
    const [workloadSimple, setWorkloadSimple] = useState( [] );
    const [existingAttachments, setExistingAttachments] = useState( [] );
    const [attachmentFiles, setAttachmentFiles] = useState( [] );
    const [customerList, setCustomerList] = useState( [] );
    const [workLoadCreated, setWorkLoadCreated] = useState( false );
    const [categoryPrefix, setCategoryPrefix] = useState( [] );
    const [isChildTicket, setIsChildTicket] = useState( false );
    const [reopened, setReopened] = useState( false );
    const [workflowList, setWorkflowList] = useState( [] );
    const [workload, setWorkload] = useState( {
        id: null,
        department_id: null,
        wl_category_id: null,
        parent_ticket_id: null,
        customer_id: undefined,
        resolver_id: null,
        group_id: null,
        created_by: null,
        updated_by: null,
        status: null,
        status_from_categories: null,
        ticket_number: null,
        short_description: null,
        long_description: null,
        ticket_category: null,
        ticket_type: null,
        item: null,
        reference_number: null,
        priority: "Medium",
        region: "Americas",
        country: util.user.country ? util.user.country:"United States",
    } );
    let dispatch = useDispatch();
    /***************************************
    *                                      *
    *               REQUESTS               *
    *                                      *
    ***************************************/

    // This request provides information for all the selectors required in create Workload
    const getInitialRequest = async ( ) => {
        try {
            getWrokloadList();
            setLoading( true );
            const request = await util.axios.get( `/workload/get_create_initial_request` );
            const { error, msg, customerList, grpList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setCustomerList( customerList );
            setGroupsList( grpList );

            // setDepartmentList( depList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    const getWrokloadList = async ( ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/WorloadList` );
            const { error, msg, workLoadList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setWorkload( workLoadList );
            setWorkloadSimple( workLoadList );
            // setDepartmentList( depList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };



    // Detail of an specific workload
    const getWorkLoadDetail = async ( workload_id = null ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/get_workload_detail/${workload_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            if ( data.RO_wl_log ) {
                if( ( ( data.RO_wl_log ).split( "Reopen" ).length - 1 ) != 0 ) {
                    setReopened( true );
                }
            }
            setWorkload( data );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    
    // List of all workload alloted to the logged user
    const getWorkLoad = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_all_workload` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            if( tableStatus === "working" ) {
                setWorkloadData( data.filter( item => item.statusName !== 'Closed' ) );
            } else {
                setWorkloadData( data.filter( item => item.statusName === 'Closed' ) ); 
            }
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Provide list of possibles user who will create the workload
    const getCustomerList = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/customer_employee_list` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setCustomerList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    
    // Retrieve list of all available groups
    const getGroupList = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( '/groups/simple-list' );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setGroupsList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    
    // After we select a category, we can get the workload status that belong to the selected category
    const getStatusListToCategory = async ( category_id, reopen=reopened ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_all_status_category/by_category/${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            checkStatus( data, workload.status, reopen );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // After selecting a category get the list of workflows
    const getWorkflowsToCategory = async ( category_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/workflow?category=${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setWorkflowList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // After we select a group, we can get the members that belong to the selected group
    const getAssignedMembersToGroup = async ( group_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/groups/get_assigned_members/${group_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setMembersOfGroup( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // After we select a group, we can get the categories that belong to the selected group
    const getCategories = async ( group_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/groups/get_categories/${group_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCategoriesByGroup( data.filter( el => el.active === 1 ) );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Get Category detail from category id
    const getCategoryPrefix = async ( category_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/wl_categories/detail/${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCategoryPrefix( data[0].prefix );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Retrieve a list of all available workloads for parent selection
    const getWorkLoadSimpleList = async ( ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/workload/list_simple` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setWorkloadSimple( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Get the list of all available departments
    const getDepartments = async () => {
        try{
            setLoading( true );
            const request = await util.axios.get( '/department/simple-list' );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setDepartmentList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Get List of all department by user or employeeId
    const getDepartmentsByUser = async ( id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/department/workload_list/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setDepartmentList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    const getContactInfo = async ( id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/account/contact_info/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setContactData( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    // Save/Create new Workload
    const saveNewWorkload = async ( newWorkload ) => {
        try {
            setLoading( true );
            const request = await util.axios.post( `/workload/new_workload`, { ...newWorkload } );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            attachmentFiles.length > 0 && saveAttachments( data.id );
            util.toast().success( msg );
            setLoading( false );
            setWorkLoadCreated( true );
            dispatch( setIsCustomWorkload( true ) );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    // Update changes on Workload
    const updateWorkLoad = async ( temp_workload ) => {
        try {
            
            setLoading( true );
            const { id } = temp_workload;
            const request = await util.axios.put( `/workload/update_workload/${id}`, { ...temp_workload } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            attachmentFiles.length > 0 && saveAttachments();
            util.toast().success( msg );
            setLoading( false );
            setWorkLoadCreated( true );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    // Delete Workload
    const deleteWorkload = async ( id ) => {
        try {
            setLoading( true );
            const request = await util.axios.delete( `/delete_workload/${id}` );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg + " with id:" + id );
            // getWorkLoad();
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Get attachments
    const getAttachments = async ( id ) => {
        try {
            const request = await util.axios.get( `/workload/get_attachment/${id}` );
            const { error, msg, attachments } = request.data;
            if ( error ) throw msg;
            setExistingAttachments( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Save Attachments
    const saveAttachments = async ( id = workload.id ) => {
        try {
            const data = new FormData();
            [...attachmentFiles].map( file => {
                data.append( "file", file );
            } );
            const request = await util.axios.post( `/workload/upload_attachment/${id}`, data );
            const { error, msg, attachments } = request.data;
            if ( error ) throw msg;
            setExistingAttachments( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Delete attachment
    const deleteAttachment = async ( id ) => {
        try {
            const request = await util.axios.delete( `/workload/delete_attachment/${id}` );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            // setExistingAttachments( attachments );
            getAttachments( workload.id );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    /***************************************
    *                                      *
    *            BUSINESS LOGIC            *
    *                                      *
    ***************************************/
    function handleChange( e ) {
        const { name, value } = e.target;
        setWorkload( ( prev )=>{
            return {
                ...prev,
                [name]: value === "" ? null : value,
            };
        } );
        // console.log( name, value );
    }

    // Add new files to the array
    const addAttachment = async ( e ) => {
        let temp = [...e.target.files];
        let attachment = [...temp];

        if( existingAttachments ) {
            existingAttachments.forEach( ( item, idx ) => {
                for( let i = 0; i < temp.length ; i++ ) {
                    if( item.title === temp[i].name ) {
                        util.toast().warn( "File is already UPLOADED" );
                        attachment.splice( idx, 1 );
                    }
                }
            } );
        }

        if( attachmentFiles ) {
            attachmentFiles.forEach( ( item, idx ) => {
                for( let i = 0; i < temp.length ; i++ ) {
                    if( item.name === temp[i].name ) {
                        util.toast().warn( "File is in the Upload Queue" );
                        attachment.splice( idx, 1 );
                    }
                }
            } );
        }
        setAttachmentFiles( prev => [...prev, ...attachment] );
    };

    const removeAttachmentfile = ( name ) => {
        if( attachmentFiles.length ) {
            let temp = attachmentFiles;
            attachmentFiles.forEach( ( item, index ) => {
                if( item.name === name ) {
                    temp.splice( index,1 );
                }
            } );
            setAttachmentFiles( [...temp] );
        }
    };

    const checkStatus = ( data, currentStatus, reopened ) => {
        let currentStatusName = data?.find( item => item.wl_status_id === currentStatus )?.name;
        let currentStatusOrder = data?.find( item => item.wl_status_id === currentStatus )?.status_order;
        switch ( currentStatusName ) {
            case "Open": {
                let temp = data.map( item => {
                    if ( item.name === "Acknowledged" ) {
                        return {
                            ...item,
                            disabled: false
                        };
                    } else {
                        return {
                            ...item,
                            disabled: true
                        };
                    }
                } );
                setStatusListByCategory( temp );
                return temp;
            }

            case "New": {
                let temp = data.map( item => {
                    if ( item.name === "Acknowledged" ) {
                        return {
                            ...item,
                            disabled: false
                        };
                    } else {
                        return {
                            ...item,
                            disabled: true
                        };
                    }
                } );
                setStatusListByCategory( temp );
                return temp;
            }
      
            // When the status is closed
            case "Closed": {
                if( reopened ) {
                    let temp = data.map( item => {
                        return {
                            ...item,
                            disabled: true
                        };
                    } );
                    setStatusListByCategory( temp );
                    return temp;
                } else {
                    let temp = data.map( item => {
                        if( item.status_order >= currentStatusOrder ) {
                            return {
                                ...item,
                                disabled: false
                            };
                        } else {
                            return {
                                ...item,
                                disabled: true
                            };
                        }
                    } );
                    setStatusListByCategory( temp );
                    return temp;
                }
            }
            
            default: {
                let temp = data.map( item => {
                    if ( item.name === "Acknowledged"  || item.name === "Open" || item.name === "New" ) {
                        return {
                            ...item,
                            disabled: true
                        };
                    } else if( reopened ){
                        if( item.name === "Reopen" ) {
                            return {
                                ...item,
                                disabled: true
                            };
                        } else {
                            return {
                                ...item,
                                disabled: false
                            };
                        }
                    } else  {
                        return {
                            ...item,
                            disabled: false
                        };
                    }
                } );
                setStatusListByCategory( temp );
                return temp;
            }
        }
        // console.log( statusListByCategory );
    };

    const handleWorkHistory = ( obj, inputData, setInputData ) => {
        if( inputData && inputData.length ) {
            let d = Date.now();
            if( obj.work_history ) {
                let prevWorkHistory = obj.work_history;
                obj.work_history = `${d}, ${workload.status}, ${util.user.id}, ${inputData}|,|${prevWorkHistory}`;
            } else {
                obj.work_history = `${d}, ${workload.status}, ${util.user.id}, ${inputData}`;
            }
        }
        setInputData( "" );
    };
    
    return {
        loading, 
        setLoading,
        workloadData, 
        setWorkloadData,
        departmentList, 
        setDepartmentList,
        getWorkLoad,
        getDepartments,
        saveNewWorkload,
        groupList,
        membersOfGroup,
        statusListByCategory,
        setStatusListByCategory,
        getGroupList,
        getAssignedMembersToGroup,
        getCategories,
        categoriesByGroup,
        getStatusListToCategory,
        workloadSimple,
        getWorkLoadSimpleList,
        getWorkLoadDetail,
        workLoadCreated,
        workload, 
        handleChange, 
        setWorkload, 
        updateWorkLoad,
        addAttachment, 
        deleteAttachment, 
        getCustomerList,
        customerList,
        getInitialRequest,
        getWrokloadList,
        deleteWorkload,
        getAttachments,
        existingAttachments,
        categoryPrefix,
        getCategoryPrefix,
        getDepartmentsByUser,
        tableStatus,
        setTableStatus,
        isChildTicket, 
        setIsChildTicket,
        checkStatus,
        reopened,
        attachmentFiles,
        removeAttachmentfile,
        handleWorkHistory,
        workflowList,
        getWorkflowsToCategory,
        contactData, setContactData,
        getContactInfo
        // postAttachment
    };
};

export default useManageWorkload;