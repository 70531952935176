const initialState = {};
/**
 * This reducer handles the information of the
 * Job Description
* */

const positionInformation = ( state = initialState, action ) => {
    switch ( action.type ) {
        case "SET_POSITION":
            return action.payload;
        default:
            return state;
    }
};

export default positionInformation;
