import React, { useEffect, useState } from "react";
// import Header from "../common/header/Header";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../home/Home";
import Footer from "../common/footer/Footer";
import '../../../App.css';
import ScrollToTop from "react-scroll-to-top";
import LoginEncore from "../../customers/ticketSolution/EncoreLanding/LoginEncore";


const Pages = () => {
    const [isMobile, setIsMobile] = useState(false);

    // Use an effect to check the screen width on initial load and resize
    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the width as needed
        };

        // Check on initial load
        checkIsMobile();

        // Listen for window resize events
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);
    return (
        <>

            <Home />
            <Footer />
            {!isMobile && (
                <ScrollToTop smooth color="white" style={{ backgroundColor: '#007bff', borderRadius: '80px' }} />
            )}
            {/* <LoginEncore /> */}
        </>
    );
};

export default Pages;
