import React, { useEffect, useState } from "react";
import { PositionsStore } from "../stores/PositionsStore";
import util from "../../utils/miniUtils";
import MaterialTable from "material-table";
import { MuiThemeProvider } from "@material-ui/core";
import JobSingle from "./JobSingle";

export default function JobsResult() {
	
    const state = PositionsStore.useState( state => state );
    const [jobs, setJobs] = useState( [] );
	
    // Display the selected Jon
    function displayJob( job ) {
        // When we receive a object from Material table, this should be a copy write mode
        const t = { ...job };
    
        PositionsStore.update( s => {
            s.job =  t;
         
        } );

        // Get the current URL
        const currentURL = window.location.href;

        // Parse the current URL to extract existing query parameters
        const url = new URL( currentURL );
        const existingParams = url.searchParams;

        // Update or add the 'job' query parameter
        existingParams.set( 'job', t.id ); // Replace 'newJobValue' with your desired job value

        // Reconstruct the URL with the updated parameters
        const newURL = `${url.origin}${url.pathname}?${existingParams.toString()}${url.hash}`;

        // Update the browser's URL without reloading the page
        window.history.pushState( { path: newURL }, '', newURL );
    }

    if( state.first_job ){
        const t = { ...state.first_job };
        // Every click on a job, will update the URL params
        const a = t.job_title.replaceAll( /\s/g, "-" );
        const pageUrl = `?search=${state.job_position}&job=${t.id}&title=${a}`;
        window.history.pushState( '', '', pageUrl );
    }
    
    // Fill table with search result of Jobs found in the Server
    useEffect( () => {
        if( state?.jobs ) {
            const editable = state?.jobs.map( o => ( { ...o } ) );
            setJobs( editable );
        }
    },[state.jobs] );
	
    return(
        <MuiThemeProvider theme={util.defaultTableThemeSlim}>
            <MaterialTable
                columns={[
                    {
                        field: "job_title",
                        render: p => {
                            return <JobSingle job={p}/>;
                        }
                    }
                ]}
                totalCount={state.total}
                page={state.page}
                data={jobs}
                options={{
                    pageSize: 10,
                    sorting: false,
                    showFirstLastPageButtons: true,
                    pageSizeOptions: [],
                    tableLayout:"fixed",
                    padding: "dense",
                    search:false,
                    paginationType: "normal",
                    showTitle:false,
                    toolbar:false,
                    emptyRowsWhenPaging:false,
                    paging:true,
                    paginationPosition:"bottom",
                    maxBodyHeight:'calc(100vh - 204px)',
                }}
                onRowClick={( event, rowData ) =>{
                    displayJob( rowData );
                }}
                onChangePage={page => {
                    PositionsStore.update( s => { s.page = page;} );
                }}
            />
        </MuiThemeProvider>
    );
}

