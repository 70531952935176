import React from 'react';
import FileViewer from 'react-file-viewer';

const DocumentViewer = ( { fileType, filePath } ) => {
    const onError = ( e ) => {
        console.log( e, 'Error occurred while loading the document' );
    };

    return (
        <div style={{ width: '100%', height: '500px', border: "1px solid #f0f0f0" }}> {/* Adjust the size as necessary */}
            <FileViewer
                fileType={fileType}
                filePath={filePath}
                onError={onError}
            />
        </div>
    );
};

export default DocumentViewer;
