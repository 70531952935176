import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faSave } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@material-ui/core/Checkbox";
import LocationCX from "../../LocationCX";
import SAlert from "../../../microComponents/AlertSA";
import util from "../../../utils/miniUtils";
import Modal from "react-bootstrap/Modal";

export default function AddWorkHistory( { show, talent_id, reloadData, hideModal } ) {
    // TODO validate fields before save it
    const [showConfirmation, setShowConfirmation] = useState( false );
    const [workHistoryNew, setWorkHistoryNew] = useState( {
        company: "",
        position: "",
        desc: "",
        from: "",
        to: "",
        country: "",
        state: "",
        city: "",
        actual_job: 0,
    } );

    const [loading, setLoading] = useState( false );

    const startLoading = () => setLoading( true );

    const stopLoading = () => setLoading( false );

    const hasChanges = () => {
        if ( workHistoryNew.company !== "" ) return true;
        if ( workHistoryNew.position !== "" ) return true;
        if ( workHistoryNew.desc !== "" ) return true;
        if ( workHistoryNew.from !== "" ) return true;
        if ( workHistoryNew.to !== "" ) return true;
        if ( workHistoryNew.country !== undefined ) return true;
        if ( workHistoryNew.state !== "" ) return true;
        if ( workHistoryNew.city !== "" ) return true;
        if ( workHistoryNew.actual_job !== 0 ) return true;
        return false;
    };

    // const isInvalidateWorkHistory = () => {
    //     if ( workHistoryNew.company !== "" ) return true;
    //     if ( workHistoryNew.position !== "" ) return true;
    //     if ( workHistoryNew.desc !== "" ) return true;
    //     if ( workHistoryNew.from !== "" ) return true;
    //     if ( workHistoryNew.actual_job === 0 ){
    //         if ( workHistoryNew.to !== "" ) return true;
    //     }
    //     if ( workHistoryNew.country !== undefined ) return true;
    //     if ( workHistoryNew.state !== "" ) return true;
    //     if ( workHistoryNew.city !== "" ) return true;
    //     return false;
    // };

    function onChangeHandler( e ) {
        let { name, value } = e.target;
        if ( name === "actual_job" ) {
            value = e.target.checked ? 1 : 0;
        }
        let tempData = { ...workHistoryNew };
        tempData[name] = value;
        setWorkHistoryNew( tempData );
    }

    const saveNewWorkHistory = async () => {
        try {
            // if (isInvalidateWorkHistory()) throw new Error("Missing data")
            startLoading();
            const response = await util.axios.post( `talent/work_history_add/${talent_id}/`, workHistoryNew );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Work History Created" );
            stopLoading();
            hideModal();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };


    return (
        <Modal
            show={show}
            onHide={hideModal}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Create Work History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12 mb-2 ">
                    <div className="">
                        <div className="isCursor text-black-50"  >
                            <b>{workHistoryNew.position} at {workHistoryNew.company} from {workHistoryNew.from} {workHistoryNew.actual_job === 1 ? "until today" : `to ${workHistoryNew.to}`}</b>
                        </div>
                        <div className="form-row align-items-center"  >
                            <util.RowComponent
                                c={12}
                                t={"Company"}
                                n={"company"}
                                m={( e ) => onChangeHandler( e )}
                                v={workHistoryNew.company}
                            />
                            <util.RowComponent
                                c={12}
                                t={"Job Title"}
                                n={"position"}
                                m={( e ) => onChangeHandler( e )}
                                v={workHistoryNew.position}
                            />
                            <util.RowComponent
                                c={4}
                                t={"Start date"}
                                n={"from"}
                                ty="date"
                                m={( e ) => onChangeHandler( e )}
                                v={workHistoryNew.from}
                            />
                            {workHistoryNew.actual_job ? "" :
                                <util.RowComponent
                                    c={4}
                                    t={"End date"}
                                    n={"to"}
                                    ty="date"
                                    m={( e ) => onChangeHandler( e )}
                                    v={workHistoryNew.to}
                                />
                            }

                            {/* // Checkbox if candidate still working */}
                            <div className={`form-group col-md-4`}>
                                <div className="text-center">
                                    <label htmlFor="checkBoxStillWorking">Current Job</label>
                                    <br />
                                    <Checkbox
                                        id="checkBoxStillWorking"
                                        checked={workHistoryNew.actual_job === 1}
                                        name="actual_job"
                                        onChange={( e ) => onChangeHandler( e )}
                                        style={{ color: "dodgerblue" }}
                                    />
                                </div>
                            </div>

                            <div className="container">
                                <div className="row">
                                    <LocationCX
                                        cols={4}
                                        data={workHistoryNew} // contains everything
                                        onChange={onChangeHandler}
                                    />
                                </div>
                            </div>


                            <util.RowComponent
                                c={12}
                                mul={true}
                                t={"Job Responsibilities"}
                                n={"desc"}
                                m={( e ) => onChangeHandler( e )}
                                v={workHistoryNew.desc}
                            />
                        </div>

                        <div className="text-right">
                            <div>
                                <button onClick={() => {
                                    if ( hasChanges() ) {
                                        setShowConfirmation( true );
                                    } else {
                                        hideModal();
                                    }
                                }}
                                className="btn btn-sm text-danger ">
                                    <FontAwesomeIcon icon={faBan} className="mr-2" />
								Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        saveNewWorkHistory();
                                        hideModal();
                                    }}
                                    className="btn btn-sm btn-success ">
                                    <FontAwesomeIcon icon={faSave} className="mr-2" />
								Save
                                </button>
                            </div>
                        </div>


                    </div>


                    {showConfirmation ?
                        <SAlert
                            show={showConfirmation}
                            confirmText="Yes"
                            typeButton="danger"
                            msg="You will lost this Work History?"
                            hideAlert={() => setShowConfirmation( false )}
                            onConfirm={() => {
                                hideModal();
                            }}
                        /> : ""}
                </div>

                {util.LOADING_SCREEN( loading )}
            </Modal.Body>
        </Modal>
    );
}
