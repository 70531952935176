import util from "../../../utils/miniUtils";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Collapse from '@material-ui/core/Collapse';
import { toast } from "react-toastify";

export default function ApplyToJob( {
    job,
    // showScreening,
    jobSq,
    setShowScreening
} ) {

    const [loading, setLoading] = useState( false );
    const [showMore, setShowMore] = useState( false );
    const [showResume, setShowResume] = useState( true );
    const [candidateInfo, setCandidateInfo] = useState( { resume: null } );
    const [resumeFile, setResumeFile] = useState( null );

    const handleShow = () => setShowScreening( true );

    const applyJob = async () => {
        try {
            setLoading( true );
            const data = {
                id: util.user.candidate_id,
                fromTalent: true
            };
            const request = await util.axios.post( `/requirements/add_candidate_new_method/${job.id}`, { data: data, sqResponse: [] } );
            const { error, msg } = request["data"];
            util.toast().success( msg );
            setTimeout( () => {
                window.location.reload();
            }, 1500 );
            if ( error ) throw msg;

        } catch ( e ) {
            util.handleError( e );
        }
    };

    const getCandidateInfo = async () => {
        try {
            const request = await util.axios.get( `/candidates/${util.user.candidate_id}` );
            const { msg, error, candidate } = request.data;
            if( error ) throw msg;
            setCandidateInfo( candidate );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const uploadResume = async() => {
        try {
            const data = new FormData();
            data.append( "file", resumeFile );
            const request = await util.axios.post( `/candidates/upload_resume/${util.user.candidate_id}`, data );
            const { error, msg } = request.data;
            if ( error )  throw msg;
            toast.success( msg );
            setShowResume( false );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const onFileSelect = ( e ) => {
        setResumeFile( e.target.files[0] );
    };

    const handleUpload = ( e ) => {
        e.preventDefault();
        uploadResume();
    };


    function handleApplyToJob() {
        if ( jobSq ) {
            handleShow();
        } else {
            applyJob();
        }
    }

    useEffect( () => {
        if( candidateInfo.resume ) {
            setShowResume( false );
        }
    },[candidateInfo] );

    useEffect( () => {
        if( util.user.candidate_id ) {
            getCandidateInfo();
        }
    },[] );

    return (
        <Fragment>
            <p className="detailLocation">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1 iconGray" />
                {util.location( job.country, job.state, job.city )}
            </p>
            <p className="detailDuration">
                <FontAwesomeIcon icon={faClock} className="mr-1 iconGray" />
                Duration: <u>{job.duration}</u>
            </p>
            <p className="detailDuration">
                Job ID: <b>{util.zeroPad( job.id, 5 )}</b>
            </p>
            <div>
                <Collapse in={showMore} collapsedSize={100}>
                    <div className="text-justify" dangerouslySetInnerHTML={{ __html: job.job_description }} />
                    {/* <div>{job.job_description}</div> */}
                </Collapse>
                <div className="text-right">
                    <span
                        onClick={() => setShowMore( !showMore )}
                        className="likeLink ">
                        {showMore ? "Show less" : "Show More"}
                    </span>
                </div>
            </div>

            {showResume ? <div className="card p-2">
                <i className="xs-fontSize">Please upload resume to apply for the position.</i>
                <div className="flex justify-content-between">
                    <input type="file" accept="application/msword, application/pdf" onChange={onFileSelect}/>
                    <button className="btn btn-sm btn-secondary" onClick={handleUpload}>Upload</button>
                </div>
            </div> : <div className="d-flex justify-content-center">
                <button
                    onClick={handleApplyToJob}
                    className="btn btn-sm btn-primary"
                >
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {jobSq ? "Proceed" : "Apply"}
                </button>
            </div>}

            <p className="text-center mt-2">
                By applying to this job you are accepting the <b>terms and conditions</b>
            </p>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}