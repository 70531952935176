import { useState } from "react";
import util from "../../../utils/miniUtils";

const usePipeView = () => {
    const [workloadData, setWorkloadData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [workloadDetail, setWorkloadDetail] = useState({
        show: false,
        workload_id: null,
        permission_level: null,
    });

    const showWorkLoadDetail = (e, id) => {
        console.log(id)
        setWorkloadDetail((prev) => {
            return {
                ...prev,
                workload_id: id,
                permission_level: 1,
                show: true,
            };
        });

    };

    const closeWorkLoadDetail = () => {
        setWorkloadDetail((prev) => {
            return {
                ...prev,
                show: false
            };
        });

    };

    // request to get workload for the status pipeView
    const getWorkloadStatusView = async (getPath) => {
        try {
            setLoading(true);
            const request = await util.axios.get(getPath);
            const { error, msg, data, statusAvailable } = request.data;
            if (error) throw msg;
            setCategories(statusAvailable);
            sortStatusWorkload(statusAvailable, data);
        } catch (error) {
            util.handleError(error);
        }
        setLoading(false);
    };

    // Sort workload on the basis of wl_category Status
    // const sortStatusWorkload = ( categories, data ) => {
    //     const workloadGroupByStatus = {};
    //     data.forEach( item => {
    //         if( workloadGroupByStatus[item.status_from_categories] ) {
    //             workloadGroupByStatus[item.status_from_categories].push( item );
    //         } else {
    //             workloadGroupByStatus[item.status_from_categories] = [item];
    //         }
    //     } );

    //     let result = categories.map( ( item ) => {
    //         return {
    //             title: item.nameCategory,
    //             columns: item.status.map( ( a ) => { return {
    //                 name: a.name,
    //                 wl_status_category: a.wl_status_category,
    //                 wl_status_id: a.wl_status_id,
    //                 listItems: workloadGroupByStatus[a.wl_status_category]?  workloadGroupByStatus[a.wl_status_category]: []
    //             }; } )
    //         };
    //     } );
    //     console.log( result );
    //     setWorkloadData( result );
    // };
    const sortStatusWorkload = (categories, data) => {
        const workloadGroupByStatus = {};

        data.forEach(item => {
            if (workloadGroupByStatus[item.status_from_categories]) {
                workloadGroupByStatus[item.status_from_categories].push(item);
            } else {
                workloadGroupByStatus[item.status_from_categories] = [item];
            }
        });

        let result = categories.map(item => {
            return {
                title: item.nameCategory,
                columns: item.status.map(a => {
                    const listItems = workloadGroupByStatus[a.wl_status_category] || [];
                    listItems.sort((item1, item2) => {
                        // Convert the createdAt strings to Date objects for comparison
                        const date1 = new Date(item1.createdAt);
                        const date2 = new Date(item2.createdAt);

                        // Sort in descending order
                        return date2 - date1;
                    });

                    return {
                        name: a.name,
                        wl_status_category: a.wl_status_category,
                        wl_status_id: a.wl_status_id,
                        listItems
                    };
                })
            };
        });

        setWorkloadData(result);
    };


    const getWorkloadPriorityView = async (getPath) => {
        try {
            setLoading(true);
            const request = await util.axios.get(`${getPath}&page=${page}&limit=20`);
            const { error, msg, data, total } = request.data;
            if (error) throw msg;
            sortPriorityWorkload(data);
            setTotal(total);
        } catch (error) {
            util.handleError(error);
        }
        setLoading(false);
    };

    const sortPriorityWorkload = (data) => {
        // Create an object to group workload items by priority
        const workloadGroupByPriority = {};

        // Define the priorities in the desired order
        const priorities = ["Critical", "High", "Medium", "Low"];

        // Group the workload items by priority
        data.forEach((item) => {
            if (workloadGroupByPriority[item.priority]) {
                workloadGroupByPriority[item.priority].push(item);
            } else {
                workloadGroupByPriority[item.priority] = [item];
            }
        });

        // Define a custom comparator function to sort by priority and createdAt
        const customComparator = (a, b) => {
            // Compare by priority first
            const priorityComparison = priorities.indexOf(a.priority) - priorities.indexOf(b.priority);

            // If priorities are the same, compare by createdAt
            if (priorityComparison === 0) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }

            return priorityComparison;
        };

        // Create the result array by sorting items using the custom comparator
        const result = priorities.map((item) => {
            return {
                title: item,
                id: priorities.indexOf(item) + 1,
                listItems: workloadGroupByPriority[item] ? workloadGroupByPriority[item].sort(customComparator) : [],
            };
        });

        setWorkloadData(result);
    };


    // const sortPriorityWorkload = ( data ) => {
    //     const workloadGroupByPriority = {};
    //     const priorities = ["Critical","High", "Medium", "Low"];
    //     console.log( data );
    //     data.forEach( item => {
    //         if( workloadGroupByPriority[item.priority] ) {
    //             workloadGroupByPriority[item.priority].push( item );
    //         } else {
    //             workloadGroupByPriority[item.priority] = [item];
    //         }
    //     } );

    //     const result = priorities.map( ( item, index ) => {
    //         return {
    //             title: item,
    //             id: index + 1,
    //             listItems: workloadGroupByPriority[item] ? workloadGroupByPriority[item] : []
    //         };
    //     } );
    //     setWorkloadData( result );
    // };

    const updateWorkload = async (workloadId, itemName, updateValue, getPath) => {
        try {
            setLoading(true);
            const request = await util.axios.put(`/workload/update_workload/${workloadId}`, updateValue);
            const { error, msg } = request.data;
            if (error) throw msg;
            itemName === "priority" ? getWorkloadPriorityView(getPath) : getWorkloadStatusView(getPath);
        } catch (error) {
            util.handleError(error);
        }
        setLoading(false);
    };

    const onDragEnd = (result, itemName, getPath) => {
        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (source.droppableId !== destination.droppableId) {
            const id = draggableId;
            if (itemName === "priority") {
                updateWorkload(id, itemName, { [itemName]: destination.droppableId }, getPath);
            } else {
                let status = destination.droppableId.split(',');
                updateWorkload(id, itemName, { [itemName]: Number(status[1]), status_from_categories: Number(status[0]) }, getPath);
            }
        }
    };

    return {
        loading,
        workloadData,
        getWorkloadStatusView,
        getWorkloadPriorityView,
        onDragEnd,
        categories,
        workloadDetail,
        showWorkLoadDetail,
        closeWorkLoadDetail,
        total, page, setPage
    };
};

export default usePipeView;