/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import FeaturedCard from "./FeaturedCard";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "react-multi-carousel/lib/styles.css";
import eclipse from '../../../../assets/images/elips.webp';
import { PositionsStore } from "../../../positions/stores/PositionsStore";
import util from "../../../utils/miniUtils";
import ImageComponent from "../../../utils/ImageComponent";

import "./Featured.css";

const Featured = () => {
    const position = PositionsStore.useState( state => state );
    const [newjobs, setNewjobs] = useState();
    const [activejobs, setActivejobs] = useState();
    const [hotjobs, setHotjobs] = useState();
    // const [appliedJobs, setAppliedJobs] = useState( [] );
    const [recommendedJobs, setRecommendedJobs] = useState( [] );


    const getJobs = async ( statusCode ) => {
        try {
            let p = { ...position };

            let data = `page=${p.page}
                        &limit=${p.limit}
                        &status=${statusCode}
						&postedDaysAgo=${p.postedDaysAgo}`;

            const request = await util.axios.get( `${util.url}/jobs/position1?${data}` );
            const { error, msg, jobPositions } = request["data"];
           
          
            if ( statusCode == "0" ) {
                console.log( jobPositions );
                setNewjobs( jobPositions );
            }
            else if ( statusCode == "1" ) {
                setActivejobs( jobPositions );
            }
            else if ( statusCode == "5" ) {
                setHotjobs( jobPositions );
            }

            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const getRecommendedJobs = async () => {
        try {
            const request = await util.axios.get( `jobs/position/recommended/${util.user.candidate_id}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setRecommendedJobs( data );
        } catch ( error ) {
            util.handleError( error ); 
        }
    };

    useEffect( () => {
        getJobs( 0 );
        getJobs( 1 );
        getJobs( 5 );
        if( util.user.name ) {
            getRecommendedJobs();      
        }
    }, [] );

    return (
        <>
            <div className='ej-carouselContainer'>
                <img src={eclipse} alt='eclipse' className='form-imag' />
                {
                    util.user.name ? <div className='ej_container mb-5'>
                        <Carousel list={recommendedJobs} title='Recommended Jobs' />
                    </div> : null
                }

                <div className='ej_container mb-5'>
                    <Carousel list={hotjobs} title='Hot Jobs' />
                </div>

                <div className='ej_container mb-5'>
                    <Carousel list={newjobs} title='New Jobs' />

                </div>
                <div className='ej_container mb-5'>
                    <Carousel list={activejobs} title='Active Jobs' />
                </div>
            </div>
        </>
    );
};

export default Featured;

const Carousel = ( { list=[], title } ) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: list.length > 4 ? 4 : list.length,
        slidesToScroll: list.length > 4 ? 4 : list.length,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: list.length > 2 ? 2 : list.length,
                    slidesToScroll: list.length > 2 ? 2 : list.length,
                    infinite: true,
                    dots: true,
                    centerMode: true,
                    speed: 500,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: list.length > 2 ? 2 : list.length,
                    slidesToScroll: list.length > 2 ? 2 : list.length,
                    dots: true,
                   
                    speed: 500,
                }
            },
            {
                breakpoint: 464,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };
    return list.length > 0 ? <>
        <div className='headings'>
            <h1>{title}</h1>

        </div>
        <Slider {...settings}>
            {list.map( ( item, index ) => <FeaturedCard
                job_array={item}
                index={index}
                id={item.id}
                key={item.id}
                name={item.job_title}
                location={`${item.city}, ${item.country}`}
                skills={item.primary_skill}
                salary={item.buy_from !="0"?`${item.buy_from}$ to  ${item.buy_to}$`:"Not Disclosed"}
            /> )}
        </Slider>
    </> : "";
};



