import React, { useState, useEffect } from 'react';
import util from '../../utils/miniUtils';

const ExtraFields = ( { category, handleChange, workload } ) => {
    const [state, setState] = useState( [] );

    const getFields = async ( categoryId ) => {
        try {
            const request = await util.axios.get( `/wl_fieldCategory/by_category/${categoryId}` );  
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setState( data );   
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleInputType = ( inputType ) => {
        switch( inputType ) {
            case "num":
                return "number";
            case "dateTime":
                return "datetime-local";
            default:
                return "text";
        }
    };

    useEffect( () => {
        getFields( category );
    },[category] );

    return state.length > 0 && <>
        {state.map( el => <div key={el.id} className="row">
            <div className="col mb-1">
                <div className="row">
                    <span className="col-xl-3 col-lg-12 font-weight-bold">{el.label}:</span>
                    <span className="col-xl-9 col-lg-12">
                        <input
                            className="form-control" 
                            type={handleInputType( el.field_type )} 
                            name={el.field_name} 
                            value={workload[el.field_name]}
                            onChange={handleChange}/>
                    </span>
                </div>
            </div>
        </div> )}
    </>;
};

export default ExtraFields;