export const PROFILE_SET_CANDIDATE = "PROFILE_SET_CANDIDATE";
export const PROFILE_SET_CANDIDATE_FIELD = "PROFILE_SET_CANDIDATE_FIELD";
export const ADMIN_ROLE = 1;
export const RECRUITER_ROLE = 1;
export const RECRUITER_MANAGER = 6;
export const TALENT_ROLE = 2;
export const CLIENT_ROLE = 3;
export const PARTNER_ROLE = 4;
export const EMPLOYEE_ROLE = 7;
export const EMPLOYEE_MANAGER = 8;
export const CUSTOMER = 9 ;
export const ANNOUNCEMENT_MANAGER = 10;
export const ANNOUNCEMENT_VIEWER = 11;
export const CUSTOMOER_RESOLVER = 12;
export const HIDE_SWEET_ALERT_DUPLICATE = 1;
export const HIDE_SWEET_ALERT_STOP_PROCESS = 2;
export const HIDE_SWEET_ALERT_CHANGE_STATUS = 3;
export const HIDE_SWEET_ALERT_DELETE_JOB_POSITION = 4;


export const EVALUATOR = 5;

export const SELECT_EMPLOYMENT_TYPE = 1;
export const INPUT_TALENT_EXPECTATION = 2;
export const INPUT_BUY_RATE = 3;
export const INPUT_TALENT_SELL_RATE = 4;

export const EDIT = "EDIT";
export const ADD = "ADD";

export const ALL       = "All";
export const PRIMARY   = "Primary";
export const SECONDARY = "Secondary";
export const SOFT      = "Soft";
export const CERTIFICATION = "Certification";
export const SKILLS        = "SKILLS";


// COLABORATIONS
export const JOB_COLLABORATION        = 1;

export const COUNTRY_SETTINGS = [
    {
        country: "",
        employmentType: "Full time",
        markUp: "20",
        billableDaysInYear: "236",
        workingHoursInDay: "8",
        averageBillableDaysInMonth: "236",
    },
    {
        country: "",
        employmentType: "Contractor",
        markUp: "20",
        billableDaysInYear: "236",
        workingHoursInDay: "8",
        averageBillableDaysInMonth: "236",
    },
    {
        country: "",
        employmentType: "c2c",
        markUp: "20",
        billableDaysInYear: "236",
        workingHoursInDay: "8",
        averageBillableDaysInMonth: "236",
    },
];
export const APPRAISEE = null;
export const L1_EVALUATOR = null;
export const L2_EVALUATOR = null;
export const L3_EVALUATOR = null;
export const HR_EVALUATOR = null;
export const NO_APPRAISAL = null;
export const MANAGER_WORKING_ON_APPRAISAL = 0;
export const WAITING_FOR_EMPLOYEE_APPROVAL = 1;
export const EMPLOYEE_ACCEPTED = 2;
export const EMPLOYEE_DECLINED = 3;
export const L1_CHECK_TARGETS = 2;
export const L1_ACCEPTED = 4;
export const L1_DECLINED = 5;
export const L2_CHECK_TARGETS = 4;
export const L2_ACCEPTED = 6;
export const L2_DECLINED = 7;
export const L3_CHECK_TARGETS = 6;
export const L3_ACCEPTED = 8;
export const L3_DECLINED = 9;
export const HR_CHECK_TARGETS = 8;
export const HR_ACCEPTED = 10;
export const HR_DECLINED = 11;
export const WAITING_TIME = 12;
export const EMPLOYEE_START_REVIEW = 13;
export const L1_START_REVIEW = 14;
export const L1_REVIEW_DETAILS = 15;
export const L2_START_REVIEW = 15;
export const L3_START_REVIEW = 16;
export const HR_START_REVIEW = 17;
export const COMPLETED = 18;

export const WAITING_FOR_L1_APPROVAL = 1;
export const WAITING_FOR_L2_APPROVAL = 1;
export const WAITING_FOR_L3_APPROVAL = 1;
export const WAITING_FOR_HR_APPROVAL = 1;


export const APPRAISALSTATUS = {
    waiting : null,
    acceptance : 0,
    employee_accepted : 1,
    employee_rejected : 2,
    l1_accepted : 3,
    l1_rejected:4,
    l2_accepted : 5,
    l2_rejected : 6,
    l3_accepted : 7,
    l3_rejected : 8,
    hr_accepted : 9,
    hr_rejected : 10,
    closed : 11,
    open : 12,
    employee_completed : 13,
    l1_completed : 14,
    l2_completed : 15,
    l3_completed : 16,
    hr_completed : 17,
    completed : 18,
};

export const WL_SAVEFIELD = '/workload/add/';