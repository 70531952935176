import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

export default function NewSkillDialog( { show, hideSkill, skillType, talent_id, saveNewSkill, skill, handleChangeNewSkill, editMode, updateSkill, setSkill } ) {
    const [skillList,setSkillList] = useState( [] );
    const [skillName, setSkillName] = useState( skill.skill_name || '' );
    const [selectedValue, setSelectedValue] = useState( skill.skill_name ? { id: skill.catalog_skill_id, name: skill.skill_name } : null );

    const [selectedSkills, setSelectedSkills] = useState( [] );

    const handleChange = ( event, value ) => {
        if ( value ) {
            const selectedSkillId = value.id;
            if ( selectedSkills.includes( selectedSkillId ) ) {
                // Skill already selected, do not update
                return;
            }

            setSelectedSkills( [...selectedSkills, selectedSkillId] );
            setSkill( { ...skill, catalog_skill_id: value.id, skill_name: value.name } );
            setSelectedValue( value );
        }
    };

      
    const getListofSkills = async ( search ) => {
        try {
            search.replace( /\//g, "" );
            const response = await util.axios.get( `catalog/catalog_skills_search/${search}` );
            const { error, msg, data } = response.data;
            if ( error ) return util.handleError( msg );
            setSkillList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    useEffect( () =>{
        if( skillName ) {
            getListofSkills( skillName );
        }
    },[skillName] );
    // Filter out the selected skill from the available options
    const filteredSkillList = skillList.filter( ( option ) => option.id !== selectedValue?.id );


    return (
        <Dialog
            open={show}
            onClose={hideSkill}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>
                {editMode ? "Edit ": "Add a New" } {skillType} Skill
            </DialogTitle>
            <DialogContent>
                <div className="form-group">
                    <label htmlFor="skillName">Skill Name</label>
                    {editMode ? (
                        <input
                            className="form-control"
                            disabled={editMode}
                            name="skill_name"
                            placeholder="Search Skill name"
                            value={skill.skill_name}
                        />
                    ) : (
                        <Autocomplete
                            options={filteredSkillList}
                            value={selectedValue}
                            getOptionLabel={( option ) => option.name}
                            inputValue={skillName}
                            onInputChange={( e, inputValue ) => setSkillName( inputValue )}
                            onChange={handleChange}
                            renderInput={( params ) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Search Skill name"
                                />
                            )}
                        />
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="yearsExperience">Years of Experience</label>
                    <input
                        className="form-control"
                        name="years_experience"
                        placeholder="Years of Experience"
                        value={skill.years_experience}
                        type="number"
                        onChange={handleChangeNewSkill}
                    />
                </div>
                {editMode && (
                    <div className="form-group">
                        <label htmlFor="skillType">Skill Type</label>
                        <select
                            className="form-control"
                            onChange={handleChangeNewSkill}
                            value={skill.skill_type}
                            name="skill_type"
                        >
                            <option value="1">Primary</option>
                            <option value="2">Secondary</option>
                            <option value="3">Soft</option>
                        </select>
                    </div>
                )}
                
                <div className="text-right">
                    <span onClick={hideSkill} className="text-danger mr-2 isPointer">
                        <FontAwesomeIcon icon={faTimes} className="mr-1" />
            Cancel
                    </span>
                    {editMode ? (
                        <button onClick={() => updateSkill( talent_id, skillType, hideSkill )} className="btn-sm btn-success btn">
                            <FontAwesomeIcon icon={faSave} className="mr-1" />
              Update
                        </button>
                    ) : (
                        <button onClick={() => saveNewSkill( talent_id, skillType, hideSkill )} className="btn-sm btn-success btn">
                            <FontAwesomeIcon icon={faSave} className="mr-1" />
              Save
                        </button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}