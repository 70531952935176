import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, TextField } from '@material-ui/core';
import { Collapse } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import util from '../../../utils/miniUtils';
import selector from '../../../utils/selector';
import moment from 'moment';
import { EMPLOYEE_ROLE } from '../../../../constants';
// import EmpDocuments from './EmpDocuments';
import FileManagementTable from './EmployeeFileManagementTable';


const Employee = ( { employee_id } ) => {
    const isEmployee = util.user.role === EMPLOYEE_ROLE;
    const [employee, setEmployee] = useState( { loading: true } ); // Employee state
    const [employeeBase, setEmployeeBase] = useState( { loading: true } ); // base Data for the employee form to compare that is the data is changed or not
    const [departmentList, setDepartmentList] = useState( [] ); // Departmnent list for the department selector
    const [groupList, setGroupList] = useState( [] ); // Group List for group selector
    const [hrManagers, setHrManagers] = useState( [] );
    const [active, setActive] = useState( false );
    const [collapsable, setCollapsable] = useState( { 
        showLog: false,
        showEmergencyContacts: false,
        showWorkingHours: false,
    } );
    const [isInfoModified, setIsInfoModified] = useState( false );
    // const [managers, setManagers] = useState( [] );
    const [l1_managers, setL1Managers] = useState( [] );
    const [l2_managers, setL2Managers] = useState( [] );
    const [l3_managers, setL3Managers] = useState( [] );

    const [access, setAccess] = useState( false );
    const [fileUploads, setFileUploads] = useState( {} );


    function isActiveEmploye( termination_date ) {
        if( !termination_date ) {
            return true;
        }

        let date1 = moment().format( "YYYY-MM-DD" ).split( "-" );
        let date2 = termination_date.split( "-" );
        let result = false;
      
        if ( Number( date1[0] ) < Number( date2[0] ) ) { // Check if year2 is greater than year1
            return result = true;
        } else if ( Number( date1[0] ) === Number( date2[0] ) ) { // Check if year2 equals year1
        // Check if month2 is greater than month1
            if ( Number( date1[1] ) < Number( date2[1] ) ) { 
                return result = true;
            } else if ( Number( date1[1] ) === Number( date2[1] ) ) { // Check if month2 equals month1
                // Check if day2 is greater than day1
                if ( Number( date1[2] ) < Number( date2[2] ) ) {
                    return result = true;
                }
            }
        }
        return result;
    }

    const getEmployeeData = async () => {
        try {
            const request = await util.axios.get( `/employee/detail/${employee_id}` );
            const { error, msg, data, employees, access } = request.data;
            if ( error ) throw msg;
            setEmployeeBase( { ...employee, loading:false, ...data } );
            setEmployee( { ...employee, loading:false, ...data } );
            // setManagers( employees );

            setAccess( access );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getMemberDepartment = async ( id ) => {
        try {
            const request = await util.axios.get( `/department/list/${id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setDepartmentList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    }; 
    
    const getMemberGroup = async ( id ) => {
        try {
            const request = await util.axios.get( `/get_groups_by_employee/${id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setGroupList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getHRmanagers = async () => {
        try {
            // const request = await util.axios.get( `/get_groups_members_by_group_name?groupName=${encodeURIComponent( "HR Manager" )}` );
            const request = await util.axios.get( `/get_managers` );

            const { error, msg, hr_managers,l1_managers, l2_managers,l3_managers } = request.data;
            if ( error ) throw msg;
            setHrManagers( hr_managers );
            setL1Managers( l1_managers );
            setL2Managers( l2_managers );
            setL3Managers( l3_managers );

        } catch ( error ) {
            util.handleError( error ); 
        }
    };

    // const saveChanges = async () => {
    //     try {
    //         // Create FormData object
    //         const formData = new FormData();
    //         const empDetails=JSON.stringify( employee );
    //         formData.append( 'employee',empDetails );
    //         // Append each file from fileUploads to formData
    //         for ( const key in fileUploads ) {
    //             if ( fileUploads[key] ) {
    //                 formData.append( 'files', fileUploads[key], fileUploads[key].name );
    //                 formData.append( 'fileTypes', key );
    //             }
    //         }

    //         // // Append each piece of employee data to formData
    //         // for ( const key in employee ) {
    //         //     if ( employee[key] ) {
    //         //         formData.append( key, employee[key] );
    //         //     }
    //         // }
           

    //         // Modify the request to send formData
    //         // Note: No need to set 'Content-Type' explicitly, it will be set automatically when using FormData
    //         console.log( formData );
    //         const request = await util.axios.put( `/employee/update/${employee.employee_id}`, formData );
    //         const { error, msg } = request.data;
    //         if ( error ) throw msg;
    //         updateGroupandDepartment();
    //         getEmployeeData(); 
    //         setIsInfoModified( false );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }

    // };
    const saveChanges = async () => {
        try {
            const request = await util.axios.put( `/employee/update/${employee.employee_id}`, employee );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            updateGroupandDepartment();
            getEmployeeData(); 
            setIsInfoModified( false );
        } catch ( error ) {
            util.handleError( error );
        }

    };

    const handleChange = ( e ) => {
        let { name, value, checked } = e.target;
        if ( name === "elegible_for_bonus" || name === "is_billable" ) {
            value = checked ? 1:0; 
        } else if( value === "" || value === "--" ) {
            value = null;
        }
        setEmployee( { ...employee, [name]: value } );
    };

    const handleEmergencyContact = ( e ) => {
        let { name, value } = e.target;
        let temp = {
            "emergency_contact_name": employee.emergency_contact ? employee.emergency_contact.split( ":" )[0] : "",
            "emergency_contact_number": employee.emergency_contact ? employee.emergency_contact.split( ":" )[1] : "",
        };
        if( name === "emergency_contact_name" ) {
            setEmployee( { ...employee, "emergency_contact": `${value}:${temp.emergency_contact_number}` } );
        } else {
            setEmployee( { ...employee, "emergency_contact": `${temp.emergency_contact_name}:${value}` } );
        }
    };

    const handleMultiSelect = ( e ) => {
        const { name, value, checked } = e.target;
        if( name === "department" ) {
            setDepartmentList( departmentList.map( item => {
                if( item.id == value ) {
                    return {
                        ...item,
                        checked: checked
                    };
                } else {
                    return {
                        ...item,
                    }; 
                }
            } ) );
        } else if( name === "group" ) {
            setGroupList( groupList.map( item => {
                if( item.id == value ) {
                    return {
                        ...item,
                        checked: checked
                    };
                } else {
                    return {
                        ...item,
                    }; 
                }
            } ) );
        }
        setIsInfoModified( true );
    };

    const updateGroupandDepartment = async () => {
        try {
            let temp = { departmentList: departmentList, groupList: groupList };
            const request = await util.axios.put( `/employee/update/department/group/${employee.employee_id}`, temp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( ()=>{
        const { engagement_type, annual_bonus, elegible_for_bonus,evaluation_frequency, hr_manager,is_billable, job_class, hr_notes,
            job_role, joining_date, l1_manager, l2_manager, l3_manager, probation_period, salary, tax_id, termination_date, time_zone, emergency_contact, job_id } = employee;
            
        if ( engagement_type !== employeeBase.engagement_type  ||
            annual_bonus !== employeeBase.annual_bonus  ||
            elegible_for_bonus !== employeeBase.elegible_for_bonus  ||
            evaluation_frequency !== employeeBase.evaluation_frequency  ||
            hr_manager !== employeeBase.hr_manager  ||
            is_billable !== employeeBase.is_billable  ||
            job_class !== employeeBase.job_class  ||
            job_role !== employeeBase.job_role  ||
            joining_date !== employeeBase.joining_date  ||
            l1_manager !== employeeBase.l1_manager  ||
            l2_manager !== employeeBase.l2_manager  ||
            l3_manager !== employeeBase.l3_manager  ||
            probation_period !== employeeBase.probation_period  ||
            tax_id !== employeeBase.tax_id  ||
            salary !== employeeBase.salary  ||
            termination_date !== employeeBase.termination_date  ||
            time_zone !== employeeBase.time_zone ||
            emergency_contact !== employeeBase.emergency_contact ||
            hr_notes !== employeeBase.hr_notes || job_id !== employeeBase.job_id ) {
            setIsInfoModified( true );
        }else{
            setIsInfoModified( false );
        }

        setActive( isActiveEmploye( termination_date ) );
    },[employee] );

    useEffect( () => {
        getEmployeeData();
        getHRmanagers();
        getMemberDepartment( employee_id );
        getMemberGroup( employee_id );
    },[] );

    if ( employee.loading ) {
        return "loading...";
    }

    return ( 
        <div style={{ backgroundColor: "#F4F4F4" }} className='mt-3' id='employee' >
            {/* <button onClick={getEmployeeData}>reload</button> */}
            <b className='custom-title ml-3'>Employee Information</b>
            <div className="container-fluid ">
                
                <div className='row p-1 d-flex align-items-center'>
                    <div className="col-md-6 d-flex flex-column">
                        {/* <label className="employeeSettings_label">Job Position</label>
                        <span className="employeeSettings_jobId">
                            {employee.job?.id ?
                                ( <a href={`/jobs/position/detail/${employee.job.id}`} target="_blank" rel="noopener noreferrer">
                                    {util.zeroPad( employee.job.id, 5 )}
                                </a> ):""}
                            {` - ${employee.job.job_title}`}
                        </span> */}
                        <label className="employeeSettings_label">Job Position</label>
                        <span className="employeeSettings_jobId">
                            {employee.job?.id ? (
                                <a
                                    href={`/jobs/position/detail/${employee.job.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {util.zeroPad( employee.job.id, 5 )}
                                </a>
                            ) : (
                                ""
                            )}
                            {employee.job?.job_title ? ` - ${employee.job.job_title}` : ""}
                        </span>

                        {/* <label className='fontSize11' htmlFor="job_position">Change Job Position</label>
                        <input type="number" className="form-control" id="job_id" name="job_id" value={employee.job_id}  onChange={handleChange}/> */}
                        <TextField className='mt-3' label="Change Job Position" type="number" size='small' variant='outlined' id="job_id" name="job_id" value={employee.job_id} 
                            onChange={handleChange}/>

                    </div>

                    {isEmployee && <>
                        <div className="col-md-3 mt-1">
                            {/* <label className='fontSize11' htmlFor="joining_dateId">Joining Date</label>
                            <input type="date" className="form-control" id="joining_dateId" name="joining_date" value={employee.joining_date}  onChange={handleChange}/> */}
                            <TextField label="Joining Date" type="date" size='small' variant='outlined' id="joining_dateId" name="joining_date" value={employee.joining_date}  onChange={handleChange}/>
                        </div>

                        <div className="col-md-3 mt-1">
                            {/* <label className='fontSize11' htmlFor="termination_dateId">Termination Date</label>
                            <input type="date" className="form-control" id="termination_dateId" name="termination_date" value={employee.termination_date} onChange={handleChange}/> */}
                            {/* <TextField label="Termination Date" type="date" size='small' variant='filled' d="termination_dateId" name="termination_date" value={employee.termination_date} onChange={handleChange}/> */}
                            <util.RowComponent
                                t={"Termination Date"}
                                n={"termination_date"}
                                ty="date"
                                m={( e ) => handleChange( e )}
                                v={employee.termination_date}
                            />
                        </div>
                    </>}

                </div>
                
                {!isEmployee && <div className="row">

                    <div className="col-md-3 mt-1">
                        {/* <label className='fontSize11' htmlFor="joining_dateId">Joining Date</label> */}
                        {/*<input type="date" className="form-control" id="joining_dateId" name="joining_date" value={employee.joining_date}  onChange={handleChange}/> */}
                        {/* <TextField  type="date" size='small' variant='outlined' id="joining_dateId" name="joining_date" value={employee.joining_date}  onChange={handleChange}/> */}
                        <util.RowComponent
                            t={"Joining Date"}
                            n={"joining_date"}
                            ty="date"
                            m={( e ) => handleChange( e )}
                            v={employee.joining_date}
                        />
                    </div>

                    { access &&<div className="col-md-3 mt-1">
                        {/* <label className='fontSize11' htmlFor="termination_dateId">Termination Date</label>
                        <input type="date" className="form-control" id="termination_dateId" name="termination_date" value={employee.termination_date} onChange={handleChange}/> */}
                        {/* <TextField label="Termination Date" type="date" size='small' variant='outlined' id="termination_dateId" name="termination_date" value={employee.termination_date} onChange={handleChange}/> */}
                        <util.RowComponent
                            t={"Termination Date"}
                            n={"termination_date"}
                            ty="date"
                            m={( e ) => handleChange( e )}
                            v={employee.termination_date}
                        />
                    </div> 
                    }
                        
                    {access && <div className="col-md-3 d-flex align-items-center" >
                        {/* <label className='fontSize11' htmlFor="salaryId">Salary</label>
                        <input type="number" className="form-control" id="salaryId" name="salary" value={employee.salary} onChange={handleChange}/> */}
                        <TextField label='Salary' type="number" size='small' variant='outlined' id="salaryId" name="salary" value={employee.salary} onChange={handleChange}/> 
                    </div>
                    }   

                    <div className='col-md-3 employeeSettings_checkBoxDiv'>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="isActive" name="is_active" checked={active}  value={active}/>
                            <label className="form-check-label" htmlFor="isActive" >Active</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="isBillable" name="is_billable" checked={employee.is_billable ===  1}  value={employee.is_billable ===  1} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="isBillable" >Billable</label>
                        </div>

                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="elegible_for_bonus" name="elegible_for_bonus" checked={employee.elegible_for_bonus ===  1}  value={employee.elegible_for_bonus ===  1} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="elegible_for_bonus" >Eligible for bonus</label>
                        </div>
                    </div>

                </div>}

                <div className="row p-1">
                    <div className={`col-md-4 mt-3`}>
                        {/* <label className='fontSize11' htmlFor="timeZoneID">Time Zone</label>
                        <select
                            id='timeZoneID'
                            className="form-control"
                            value={employee.time_zone}
                            name="time_zone"
                            onChange={handleChange}
                        >
                            {
                                util.timeZones.map( ( o,i )=>{
                                    return <option key={i} value={o.value}>{o.time_zone}</option>;
                                } )
                            }
                        </select> */}
                        <TextField
                            select
                            fullWidth
                            label="Select Option"
                            name="time_zone"
                            value={employee.time_zone}
                            onChange={handleChange}
                            variant="outlined"
                            size='small'
                        >
                            { util.timeZones.map( ( o,i ) => (
                                <MenuItem key={i} value={o.value}>
                                    {o.time_zone}
                                </MenuItem>
                            ) )}
                        </TextField>
                    </div>

                    <div className={`col-md-3 mt-3 ml-2`}>
                        {/* <label className='fontSize11' htmlFor="engagementTypeId">Engagement Type</label>
                        <select
                            id='engagementTypeId'
                            className="form-control"
                            value={employee.engagement_type}
                            name="engagement_type"
                            onChange={handleChange}
                        >
                            {
                                selector.engagement_type.map( ( o,i )=>{
                                    return <option key={i} value={o.engagement_type}>{o.engagement_type}</option>;
                                } )
                            }
                        </select> */}
                        <TextField
                            select
                            fullWidth
                            label="Engagement Type"
                            name="engagement_type"
                            value={employee.engagement_type}
                            onChange={handleChange}
                            variant="outlined"
                            size='small'
                        >
                            { selector.engagement_type.map( ( o,i ) => (
                                <MenuItem key={i} value={o.engagement_type}>
                                    {o.engagement_type}
                                </MenuItem>
                            ) )}
                        </TextField>
                    </div>

                    {access &&<div className={`col-md-2 mt-3`}>
                        {/* <label className='fontSize11' htmlFor="taxId">Tax ID</label>
                        <input className="form-control" type="text" name="tax_id" value={employee.tax_id} onChange={handleChange} /> */}
                        <TextField size='small' variant='outlined' label="Tax ID"  type="text" name="tax_id" value={employee.tax_id} onChange={handleChange} />
                    </div>
                    }

                    <div className={`col-md-3 mt-3`}>
                        {/* <label className='fontSize11' htmlFor="evaluationFrequencyId">Evaluation Frequency</label>
                        <select
                            id='evaluationFrequencyId'
                            className="form-control"
                            value={employee.evaluation_frequency}
                            name="evaluation_frequency"
                            onChange={handleChange}
                        >
                            {
                                selector.evaluationFrequency.map( ( d,i )=>{
                                    return <option key={i} value={d}>{d}</option>;
                                } )
                            }
                        </select> */}
                        <TextField
                            select
                            fullWidth
                            size='small'
                            label="Evaluation Frequency"
                            value={employee.evaluation_frequency}
                            name="evaluation_frequency"
                            onChange={handleChange}
                            variant="outlined"
                        >
                            {selector.evaluationFrequency.map( ( d,i ) => (
                                <MenuItem key={i} value={d}>
                                    {d}
                                </MenuItem>
                            ) )}
                        </TextField>
                    </div>
                </div>

                {/* <div className="row p-1">
                    <div className="col-md-12">
                    Working Hours 
                    </div>
                </div> */}

                <div className="row p-1">

                    <div className={`col-md-3 mt-3`}>
                        <div className="row mb-2">
                            <div className="col">
                                {/* <label className='fontSize11' htmlFor="jobRoleId">Job Role</label>
                                <input className="form-control" id="jobRoleId" type="text" name="job_role" value={employee.job_role} onChange={handleChange} /> */}
                                <TextField fullWidth size='small' variant='outlined' label='Job Role' id="jobRoleId" type="text" name="job_role" value={employee.job_role} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="row">
                            <Selector col={12} data={[]} disabled={true} handleChange={handleChange} id="job_classId" name="job_class" title="Job Class" value={employee.job_class}/>
                        </div>
                        
                    </div>

                    <MultiSelect label="Department" list={departmentList} onChange={handleMultiSelect} name={"department"} />

                    <MultiSelect label="Group" list={groupList} onChange={handleMultiSelect} name={"group"} />

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col">
                                <label className='fontSize11' htmlFor="emergency_contact">Emergency Contact</label>
                                <div className="border rounded p-1">
                                    <div className="row mt-2 mb-4">
                                        <div className="col">
                                            <div className="employeeSettings_emergencyContactNameDiv">
                                                {/* <label className="contactLabel" htmlFor="emergency_contact">Name</label>
                                                <input className="contactInput" id="emergency_contactId" type="text" name="emergency_contact_name" value={employee.emergency_contact ? employee.emergency_contact.split( ':' )[0] : ""} onChange={handleEmergencyContact} />
                                                 */}
                                                <TextField label='Name' size='small' fullWidth variant='outlined' id="emergency_contactId" type="text" name="emergency_contact_name" value={employee.emergency_contact ? employee.emergency_contact.split( ':' )[0] : ""} onChange={handleEmergencyContact}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col">
                                            <PhoneInput
                                                country={'us'}
                                                name={"emergency_contact_number"}
                                                value={employee.emergency_contact ? employee.emergency_contact.split( ':' )[1] : ""}
                                                onChange={phone => {
                                                    let e = { target: { name: "emergency_contact_number", value: phone } };
                                                    handleEmergencyContact( e );
                                                }}
                                                inputStyle={{ background: 'none' }}
                                            />
                                            {/* <input className="form-control" id="emergency_contactId" type="number" name="emergency_contact_number" value={employee.emergency_contact ? employee.emergency_contact.split( ':' )[1] : ""} onChange={handleEmergencyContact} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="row p-1">
                    
                    <LevelManagerSelector data={l1_managers} handleChange={handleChange} id="l1ManagerID" name="l1_manager" title="L1 Manager" value={employee.l1_manager}/>
                    <LevelManagerSelector data={l2_managers} handleChange={handleChange} id="l2ManagerID" name="l2_manager" title="L2 Manager" value={employee.l2_manager}/>
                    <LevelManagerSelector data={l3_managers} handleChange={handleChange} id="l3ManagerID" name="l3_manager" title="L3 Manager" value={employee.l3_manager}/>
                    <LevelManagerSelector data={hrManagers} handleChange={handleChange} id="hrManagerID" name="hr_manager" title="HR Manager" value={employee.hr_manager}/>

                </div>
                

                <div className="row p-1">
                    <div className="col-md-12">
                        {/* <label className='fontSize11' htmlFor="hrNotesId">HR Notes</label> */}
                        <TextField fullWidth multiline variant='outlined' label='HR Notes' rows="3"  name="hr_notes" value={employee.hr_notes} onChange={handleChange}/>
                    </div>
                </div>
                {/* <div><EmpDocuments setIsInfoModified={setIsInfoModified}/></div> */}
                <div><FileManagementTable employee={employee}/></div>

                <div className="row p-1 d-flex justify-content-end">
                    {
                        isInfoModified ?
                            <button onClick={saveChanges} className='btn btn-secondary sizeBtn activeBtn'>Save Changes</button>
                            :
                            null
                    }
                </div>
                {access &&
                <div className="row p-1">
                    
                    <div className="col-md-12">
                        <b className='isCursor likeLink' onClick={()=> setCollapsable( { ...collapsable, showLog:!collapsable.showLog } )}>Employee LOG</b>
                        <Collapse in={collapsable.showLog} collapsedHeight={100}>
                        
                            <div>
                                {console.log( employee )}

                                {
                                    employee.emp_log !== null ?
                                        <Fragment>
                                            {
                                                employee.emp_log.split( "|" ).map( ( l, i )=>{
                                                    return <div className='fontSize12' key={i}>{l}</div>;
                                                } )
                                            }
                                        </Fragment>
                                        : null
                                }
                            </div>
                        </Collapse>
                    </div>
                    
                </div>
                }
            </div>
        </div>
    );
};
 
export default Employee;


const Selector = ( { col=3, id, title, name, value, handleChange, data, disabled=false } ) => {
    return (
        <div className={`col-md-${col} mt-2`}>
            {/* <label className='fontSize11' htmlFor={id}>{title}</label>
            <select
                id={id}
                className="form-control"
                value={value}
                name={name}
                onChange={handleChange}
                disabled={disabled}
            >
                <option value={null}> -- </option>;
                {
                    data.map( ( item,i )=>{
                        return <option key={i} value={item.account_id}>{item.name}</option>;
                    } )
                }
            </select> */}
            <TextField
                select
                label={title}
                value={value}
                name={name}
                onChange={handleChange}
                disabled={disabled}
                variant="outlined"
                size='small'
                fullWidth
            >
                {data.map( ( item,i ) => (
                    <MenuItem key={i} value={item.account_id}>
                        {item.name}
                    </MenuItem>
                ) )}
            </TextField>
        </div>
    );
};

const LevelManagerSelector = ( { col=3, id, title, name, value, handleChange, data, disabled=false } ) => {
    return (
        <div className={`col-md-${col}`}>
            {/* <label className='fontSize11' htmlFor={id}>{title}</label>
            <select
                id={id}
                className="form-control"
                value={value}
                name={name}
                onChange={handleChange}
                disabled={disabled}
            >
                <option value={null}> -- </option>;
                {
                    data.map( ( item,i )=>{
                        return <option key={i} value={item.employee_id}>{item.name}</option>;
                    } )
                }
            </select> */}
            <TextField
                select
                label={title}
                value={value}
                name={name}
                onChange={handleChange}
                disabled={disabled}
                variant="outlined"
                size='small'
                fullWidth
            >
                {data.map( ( item,i ) => (
                    <MenuItem key={i} value={item.employee_id}>
                        {item.name}
                    </MenuItem>
                ) )}
            </TextField>
        </div>
    );
};

const MultiSelect = ( { label, list, onChange, name } ) => {
   
    return (
        <div className='col-md-3'>
            {/* <label>{label}</label>
            <div className='employeeSettings_multiSelect form-control'>
                {list.map( item => <div key={item.id}>
                    <input type="checkbox" name={name} className="mr-1" value={item.id} checked={item.checked ? item.checked : false} onChange={onChange} />
                    <label>{item.name}</label>
                </div> )}
            </div> */}
            <FormControl component="fieldset" className='employeeSettings_multiSelect' style={{ border:'1px solid lightGray' ,borderRadius:'5px' }}>
                <FormGroup>
                    <label className='p-2'>{label}</label>
                    {list.map( ( item ) => (
                        <FormControlLabel
                            key={item.id}
                            control={
                                <Checkbox
                                    className='ml-1'
                                    name={name}
                                    checked={item.checked ? item.checked : false}
                                    value={item.id}
                                    onChange={onChange}
                                    color='primary'
                                />
                            }
                            label={item.name}
                        />
                    ) )}
                </FormGroup>
            </FormControl>
        </div>
    );
};