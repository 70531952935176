import React, { useEffect, useState } from "react";
import WorkHistorySingle from "./WorkHistorySingle";
import AddWorkHistory from "./AddWorkHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import { Paper } from '@material-ui/core';
import { TalentStore } from "../TalentStore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { data } from "jquery";

export default function WorkHistoryContainer( { talent_id,isEditing } ) {

    const [dataList, setDataList] = useState( [] );
    const [showModal, setShowModal] = useState( false );

    const [loading, setLoading] = useState( false );

    useEffect( () => {
        getWorkHistory().then( null );
    }, [] );


    // get all available work history
    const getWorkHistory = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `talent/work_history_list/${talent_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            setDataList( Object.assign( [], data ) );
            setLoading( false );
            TalentStore.update( t => {
                t.talent.workHistory =   data;
            } );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };
    const titleColor = dataList.length === 0 ? 'gray' : 'black';
    const tooltip = (
        <Tooltip id="tooltip">
            Add Work Experience
        </Tooltip>
    );

    return (
        <div className="container-fluid mt-3" style={{ backgroundColor: "#F4F4F4" }}>
            {isEditing ? (
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <FontAwesomeIcon
                        className="custom-icon ml-1"
                        icon={faPlus}
                        onClick={() => setShowModal( true )}
                    />
                </OverlayTrigger>
            ) : (
                ""
            )}
            {dataList.length || isEditing?  <div >
                <b className="custom-title" style={{ color:titleColor }}>Work History</b>
            
            </div>:null
            }
           
            
            {/* <div className="d-flex justify-content-end mt-2">
                        <button onClick={() => setShowModal( true )}
                            className="btn btn-sm btn-primary ">
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
						Work History
                        </button>
                    </div> */}
            {dataList.length === 0 ? "" : <table className="custom-table">
                <tr>
                    <th style={{ width:'10%' ,fontSize:'14px' }}>Position</th>
                    <th style={{ width:'15%',fontSize:'14px' }}>Company</th>
                    <th style={{ width:'5%' ,fontSize:'14px' }}>From</th>
                    <th style={{ width:'10%',fontSize:'14px' }}>To</th>
                    <th style={{ width:'10%',fontSize:'14px' }}>Country</th>
                    <th style={{ width:'10%',fontSize:'14px' }}>State</th>
                    <th style={{ width:'10%',fontSize:'14px' }}>City</th>
                    <th style={{ width:'20%',fontSize:'14px' }}>Job Responsibilities</th>
                    <th style={{ width:'5%',fontSize:'14px' }}></th>
                    <th style={{ width:'5%',fontSize:'14px' }}></th>
                </tr></table> }
            {
                dataList.map( ( wh, i ) => {
                    return (
                        <div key={i} className='mt-2'>
                            <WorkHistorySingle
                                dataX={wh}
                                reloadData={( d ) => getWorkHistory()}
                                showModal={showModal}
                                hideModal={() => setShowModal( false )}
                                talent_id={talent_id}
                            />
                        </div>
                    );
                } )
            } 
            {
                showModal ?
                    <AddWorkHistory
                        show={showModal}
                        hideModal={() => setShowModal( false )}
                        reloadData={( d ) => getWorkHistory()}
                        talent_id={talent_id}
                    />
                    :
                    null
            }

            {util.LOADING_SCREEN( loading )}
        </div>
    );
}

