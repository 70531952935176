import util from "../../../utils/miniUtils";
import { useState } from "react";

export default function useEducation() {
	
    const [schoolOptions, setSchoolOptions] = useState( [] );
    const [degreesOptions, setDegreesOptions] = useState( [] );
    const [fieldsOfStudyOptions, setFieldsOfStudyOptions] = useState( [] );
    const [educationTemp, setEducationTemp] = useState( {} );
	
    const getSchools = async ( search = "" ) => {
        try {
            const response = await util.axios.get( `/catalog/schools_search?search=${search}` );
            const { error, msg, data } = response['data'];
            setSchoolOptions( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    const getDegrees = async ( search = "" ) => {
        try {
            const response = await util.axios.get( `/catalog/degree_search?search=${search}` );
            const { error, msg, data } = response['data'];
            setDegreesOptions( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    const getFieldOfStudy = async ( search = "" ) => {
        try {
            const response = await util.axios.get( `/catalog/field_of_study_search?search=${search}` );
            const { error, msg, data } = response['data'];
            setFieldsOfStudyOptions( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    const handleChanges = ( e ) => {
        const { name, value } = e.target;
        setEducationTemp( { ...educationTemp, [name]: value } );
    };
	
    return {
        getSchools,
        getDegrees,
        getFieldOfStudy,
        schoolOptions,
        degreesOptions,
        fieldsOfStudyOptions,
        educationTemp,
        setEducationTemp,
        handleChanges
    };
}