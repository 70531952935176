import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';

const useNotifications = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [unReadNotifications, setUnReadNotifications] = useState([]);
    const [notRead, setNotRead] = useState([]);
    const [readNotifications, setReadNotifications] = useState([]);
    const [selectedOption, setSelectedOption] = useState('All');
    const [filteredNotifications, setFilteredNotifications] = useState([]);

    // ...

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleMarkAsRead = (id) => {
        readNotification(id);
    };

    const handleMarkAsUnread = (id) => {
        unReadNotification(id);
    };
    const handleMarkAsAllRead = (id) => {
        handleAllRead(id);
    };
    const handleMarkAsAllUnRead = (id) => {
        handleAllUnRead(id);
    };

    useEffect(() => {
        filterNotifications();
    }, [unReadNotifications, selectedOption]);

    const filterNotifications = () => {
        if (selectedOption === 'All') {

            setFilteredNotifications([...unReadNotifications, ...readNotifications]);
        } else if (selectedOption === 'Read') {
            setFilteredNotifications(readNotifications);
        } else if (selectedOption === 'Unread') {
            setFilteredNotifications(unReadNotifications);
        }
    };
    // console.log( filteredNotifications );
    const handleDropdownClick = () => {
        setShowDropdown(!showDropdown);
        if (showDropdown) {
            getNotifications();
        }
    };

    async function getNotifications() {
        try {
            const request = await util.axios.get(`/notifications/get_notifications`);
            const { error, msg, data, notRead } = request.data;
            // console.log( data,notRead );
            const readData = data.filter(item => item.read);
            const unReadData = data.filter(item => !item.read);
            setReadNotifications(readData);
            setUnReadNotifications(unReadData);
            setNotRead(notRead);
            if (error) throw msg;
        } catch (e) {
            util.handleError(e);
        }
    }
    async function deleteNotification(id) {
        try {
            const request = await util.axios.put(`/notifications/delete/${id}`);
            const { error, msg } = request.data;
            getNotifications();
            // readNotification();
            if (error) throw msg;
        } catch (e) {
            util.handleError(e);
        }
    }
    async function readNotification(id) {
        try {
            const request = await util.axios.put(`/notifications/read/${id}`);
            const { error, msg } = request.data;
            getNotifications();
            if (error) throw msg;
        } catch (e) {
            util.handleError(e);
        }
    }
    async function unReadNotification(id) {
        try {
            const request = await util.axios.put(`/notifications/un_read/${id}`);
            const { error, msg } = request.data;
            getNotifications();
            if (error) throw msg;
        } catch (e) {
            util.handleError(e);
        }
    }

    async function handleAllRead() {
        try {
            const temp = unReadNotifications.map((ele) => (
                ele.notification_id
            )
            );
            const request = await util.axios.put(`/notifications/mark-all-read`, temp);
            const { error, msg } = request.data;
            getNotifications();
            if (error) throw msg;
        } catch (e) {
            util.handleError(e);
        }
    }

    async function handleAllUnRead() {
        try {
            const temp = readNotifications.map((ele) => (
                ele.notification_id
            )
            );
            const request = await util.axios.put(`/notifications/mark-all-unread`, temp);
            const { error, msg } = request.data;
            getNotifications();
            if (error) throw msg;
        } catch (e) {
            util.handleError(e);
        }
    }
    // const getModelImage = (model) => {
    //     if (model === 'workload') {
    //         return process.env.PUBLIC_URL + '/images/workload.svg';
    //     } else if (model === 'job') {
    //         return process.env.PUBLIC_URL + '/images/jobs.svg';
    //     } else if (model === 'appraisal') {
    //         return process.env.PUBLIC_URL + '/images/appr.svg';
    //     } else if (model === 'talent') {
    //         return process.env.PUBLIC_URL + '/images/talent.svg';
    //     } else if (model === 'asset') {
    //         return process.env.PUBLIC_URL + '/images/myAssets.svg';
    //     }
    //     return null;
    // };
    const getModelImage = (model) => {
        if (model === 'workload') {
            return '/images/workload.svg';
        } else if (model === 'job') {
            return '/images/Jobs.svg';
        } else if (model === 'appraisal') {
            return '/images/appr.svg';
        } else if (model === 'talent') {
            return '/images/talent.svg';
        } else if (model === 'asset') {
            return '/images/myAssets.svg';
        }
        return null;
    };


    return (
        {
            showDropdown, setShowDropdown, unReadNotifications, setUnReadNotifications,
            notRead, setNotRead, readNotifications, setReadNotifications,
            selectedOption, setSelectedOption, filteredNotifications, setFilteredNotifications,
            handleOptionChange, handleMarkAsRead, handleMarkAsUnread, handleMarkAsAllRead,
            handleMarkAsAllUnRead, handleDropdownClick, getNotifications, deleteNotification,
            readNotification, unReadNotification, handleAllRead, handleAllUnRead, getModelImage



        }
    );
};

export default useNotifications;