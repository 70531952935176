const initialState = {
    filter:{
        from: "",
        to: "",
        applyFilter: false,

    }
    
};
  

const filterReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case "SET_FROM":
            return {
                ...state,
                from: action.payload,
            };
        case "SET_TO":
            return {
                ...state,
                to: action.payload,
            };
        case "SET_FILTER":
            return {
                ...state,
                applyFilter: action.payload,
            };
        default:
            return state;
    }
};

  
export default filterReducer;
