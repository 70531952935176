import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paper } from '@material-ui/core';
import { TalentStore } from "../TalentStore";

export default function JobsApplied( { talent_id ,isEditing } ) {
    const [statusList, setStatusList] = useState( [] );
    const [jobs, setJobs] = useState( [] );
    const [loading, setLoading] = useState( false );

    useEffect( () => {
        getJobsApplied().then( null );
    }, [] );

    const getJobsApplied = async () => {
        try {
            // if (!isValid) return
            setLoading( true );
            const request = await util.axios.get( `${util.url}/talent/applied_jobs/${talent_id}` );
            const { error, msg, jobsApplied } = request.data;
            if ( error ) throw msg;
            setJobs( jobsApplied );
            TalentStore.update( t => {
                t.talentJobsApplied = jobsApplied ;
            } );
            setLoading( false );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    const getStatusList = async () => {
        try{
            const request = await util.axios.get( 'job_talent_status/list' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
           
            setStatusList( data );
          
        } catch ( e ) {
            util.handleError( e );
        }
    };

    useEffect( ()=>{
        getStatusList();
    },[] );

    return (
        <div className="container-fluid mt-3" style={{ backgroundColor: "#F4F4F4" }}>
           {jobs.length || isEditing ?  <b className="custom-title " style={{ color:jobs.length === 0 ?'gray':'black' }}>Jobs Applied</b>:null }
            {/* <table className="table text9em">
                <thead>
                    <tr>
                        <th>JobID</th>
                        <th>Job Status</th>
                        <th>Talent Status</th>
                        <th>Job Title</th>
                        <th>Location</th>
                        <th>Talent Expectation</th>
                        <th>Buy Rate</th>
                        <th>Talent Sell Rate</th>
                        <th>Job Sell Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.map( ( j, i ) => {

                        let location = j.city + " - " + j.state + " - " + j.country;

                        return (
                            <Fragment key={i}>
                                <tr>
                                    <td>
                                        <Link target="new" to={`/jobs/position/detail/${j["jobId"]}`}>
                                            {util.zeroPad( j["jobId"], 5 )}
                                        </Link>
                                    </td>
                                    <td>{util.jobStatusOnlyIcons( parseInt( j["jobStatus"] ) )}</td>
                                    <td>{util.talentStatus( parseInt( j.talentStatus ) )}</td>
                                    <td>
                                        <Link target="new" to={`/jobs/position/detail/${j["jobId"]}`}>
                                            {j["job_title"]}
                                        </Link>
                                    </td>
                                    <td><Tooltip title={location}><span>{location.substring( 0, 25 )}</span></Tooltip></td>
                                    <td>{util.currencyFormat( j["tExpectation"] )}</td>
                                    <td>{util.currencyFormat( j["buyRate"] )}</td>
                                    <td>{util.currencyFormat( j["tSellRate"] )}</td>
                                    <td>{util.currencyFormat( parseFloat( j["jobSellRate"] ) )}</td>
                                </tr>
                            </Fragment>
                        );
                    } )}
                </tbody>
            </table> */}
            {jobs.length === 0 ? " " : <table className="custom-table">
                <thead>
                    <tr>
                        <th style={{ fontSize:'14px' }}>JobID</th>
                        <th style={{ fontSize:'14px' }}>Job Status</th>
                        <th style={{ fontSize:'14px' }}>Talent Status</th>
                        <th style={{ fontSize:'14px' }}>Job Title</th>
                        <th style={{ fontSize:'14px' }}>Location</th>
                        <th style={{ fontSize:'14px' }}>Talent Expectation</th>
                        <th style={{ fontSize:'14px' }}>Buy Rate</th>
                        <th style={{ fontSize:'14px' }}>Talent Sell Rate</th>
                        <th style={{ fontSize:'14px' }}>Job Sell Rate</th>
                    </tr>
                </thead>
                <tbody>     
                    {jobs.map( ( job, index ) => {
                        let location = `${job.city} - ${job.state} - ${job.country}`;
                     
                        const Talent_Updated_Status = statusList.filter( item => {
                            return item.jts_id == job.talentStatus;
                        } );

                        const jobLink = () => {
                            if( util.user.role === 2 ) {
                                return `/careers?job=${job.jobId}`;
                            } else {
                                return `/jobs/position/detail/${job.jobId}`;
                            }
                        };
                        return (
                            <tr key={index}>
                                <td style={{ fontSize:'14px' }}>
                                    <Link target="_blank" to={jobLink()}>
                                        {util.zeroPad( job.jobId, 5 )}
                                    </Link>
                                </td>
                                <td style={{ fontSize:'14px' }}>{util.jobStatusOnlyIcons( parseInt( job.jobStatus ) )}</td>
                                <td style={{ fontSize:'14px' }}>{util.talentStatus( parseInt( job.talentStatus ), null,Talent_Updated_Status )}</td>
                                <td style={{ fontSize:'14px' }}>
                                    <Link target="_blank" to={jobLink()}>
                                        {job.job_title}
                                    </Link>
                                </td>
                                <td style={{ fontSize:'14px' }}>
                                    <Tooltip title={location}>
                                        <span>{location.substring( 0, 25 )}</span>
                                    </Tooltip>
                                </td>
                                <td style={{ fontSize:'14px' }}>{util.currencyFormat( job.tExpectation )}</td>
                                <td style={{ fontSize:'14px' }}>{util.currencyFormat( job.buyRate )}</td>
                                <td style={{ fontSize:'14px' }}>{util.currencyFormat( job.tSellRate )}</td>
                                <td style={{ fontSize:'14px' }}>{util.currencyFormat( parseFloat( job.jobSellRate ) )}</td>
                            </tr>
                        );
                    } )}
                </tbody>
            </table>}

            {util.LOADING_SCREEN( loading )}
        </div>
    );
}