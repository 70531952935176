import { useEffect } from "react";
import util from "../../utils/miniUtils";
import { PositionsStore } from "../stores/PositionsStore";
import queryString from 'query-string';

const useJobSearch = () => {
    const state = PositionsStore.useState( s => s );
    
    const getJobs = async ( urlSearch ) => {
        let data = [];
        function updateUrl( name, value ) {
            data.push( `${name}=${value}` );
        }
        try {              
            let p = { ...state };
            PositionsStore.update( s => { s.loading = true;} );
            
            const queryParams = queryString.parse( urlSearch );

            updateUrl( "page", queryParams.page ? queryParams.page: 0 );
            updateUrl( "limit", p.limit );
            // updateUrl( "job_position", queryParams.job || "" );
            updateUrl( "advanceFilter", p.showFilter );
            updateUrl( "duration", p.duration );
            updateUrl( "postedDaysAgo", p.postedDaysAgo );
            updateUrl( "skills", queryParams.filterskills || "" );
            updateUrl( "role", queryParams.filterRole || "" );
            updateUrl( "experience", queryParams.filterExperience || "" );
            updateUrl( "education", queryParams.filterEducation || "" );
            updateUrl( "employmentType", queryParams.filterEmploymentType || "" );
            updateUrl( "search", queryParams.search || "" );
            // updateUrl( "location", queryParams.location || "" );
            updateUrl( "city", queryParams.city || "" );
            updateUrl( "state", queryParams.state || "" );
            updateUrl( "country", queryParams.country || "" );
            updateUrl( "latitude", queryParams.latitude || "" );
            updateUrl( "longitude", queryParams.longitude || "" );
            updateUrl( "radius", queryParams.radius || "" );
            updateUrl( "radiusUnit", queryParams.radiusUnit || "" );
			
            const request = await util.axios.get( `${util.url}/jobs/position1?${data.join( "&" )}` );
            const { error, msg, jobPositions, total, page } = request.data;

            if( p.first_job ){
                jobPositions.unshift( p.first_job );
            }
            // Save new parameters into Store
            PositionsStore.update( s => {
                s.jobs = jobPositions;
                // s.job       = p.first_job;
                s.firstTime =   false;
                s.total     =   total;
                s.page      =   page;
                s.loading   =   false;
                // Values for advanced search
                s.job_position  = p.job_position;
                s.advanceFilter = p.advanceFilter;
                // s.country       = p.country;
                // s.state         = p.state;
                // s.city          = p.city;
                s.postedDaysAgo = p.postedDaysAgo;
            
            } );
            if ( error ) throw  msg;
        }catch ( e ) {
            util.handleError( e );
        }
    };

    const getJobDetails = async ( id ) => {
        if( id ) {
            try {
                if ( !id ){
                    return;
                }
                const request = await util.axios.get( `${util.url}/job/position_information_public/${id}` );
                const { error, msg, position } = request["data"];
                if ( error ) throw msg;
                PositionsStore.update( s => {
                    s.job = position;
               
                } );
                PositionsStore.update( s => s.job = position );
           
            } catch ( e ) {
                util.handleError( e );
            }
        }
    };

    const handleSearch = async () => {
        console.log( "calling handle search" );
        let data = [];
        function updateUrl( name, value ) {
            data.push( `${name}=${value}` );
        }
        try{
            let p = { ...state };
            PositionsStore.update( s => { s.loading = true;} );
            const filterState = { ...state.filterState };

            updateUrl( "page", p.page ? p.page : 0 );
            updateUrl( "limit", p.limit );
            updateUrl( "advanceFilter", p.showFilter );
            updateUrl( "duration", p.duration );
            updateUrl( "postedDaysAgo", p.postedDaysAgo );
            updateUrl( "skills", filterState.skills.join ( "," ) );
            updateUrl( "role", filterState.job_title.filter( el => el.checked ).map( el => el.value ).join ( "," ) );
            updateUrl( "experience", filterState.experience.filter( el => el.checked ).map( el => el.value ).join ( "," ) );
            updateUrl( "education", filterState.education.filter( el => el.checked ).map( el => el.value ).join ( "," ) );
            updateUrl( "employmentType", filterState.employmentTypes.filter( el => el.checked ).map( el => el.value ).join ( "," ) );
            updateUrl( "search", p.job_position );
            // updateUrl( "location", p.location );
            updateUrl( "country", p.country );
            updateUrl( "state", p.state );
            updateUrl( "city", p.city );
            updateUrl( "latitude", p.latitude );
            updateUrl( "longitude", p.longitude );
            updateUrl( "radius", p.radius );
            updateUrl( "radiusUnit", p.radiusUnit );

            const request = await util.axios.get( `${util.url}/jobs/position1?${data.join( "&" )}` );
            const { error, msg, jobPositions, total, page } = request.data;
            if( error ) throw msg;
            PositionsStore.update( s => {
                s.jobs = jobPositions;
                s.total = total;
                s.page = page;
                s.loading = false;
            } );
        } catch( error ) {
            util.handleError( error );
        }
    };
    
    const updateSearchUrl = () => {
        const urlParams = new URLSearchParams( window.location.search );
        const filterState = { ...state.filterState };
      
        // Define a function to update filters
        const updateFilter = ( filterName, filterValues, isFilter ) => {
            if( isFilter ) {
                const filteredValues = ( filterName === "skills" ) ? filterValues.length > 0 ? filterValues : [] : filterValues.filter( ( c ) => c.checked ).map( ( c ) => c.value );
                if ( filteredValues.length > 0 ) {
                    urlParams.set( `filter${filterName}`, filteredValues );
                } else {
                    urlParams.delete( `filter${filterName}` );
                }
            } else {
                if( filterValues || filterValues === 0 ) {
                    urlParams.set( filterName, filterValues );
                } else {
                    urlParams.delete( filterName );
                }
            }
        };
        
        // List of filters to update
        const filtersToUpdate = [
            { name: 'Role', values: filterState.job_title, isFilter: true },
            { name: 'Experience', values: filterState.experience, isFilter: true },
            { name: 'EmploymentType', values: filterState.employmentTypes, isFilter: true },
            { name: 'Education', values: filterState.education, isFilter: true },
            { name: 'Skills', values: filterState.skills, isFilter: true },
            { name: "page", values: state.page, isFilter: false },
            { name: "search", values: state.job_position, isFilter: false },
            { name: "location", values: state.location, isFilter: false },
            { name: "country", values: state.country, isFilter: false },
            { name: "city", values: state.city, isFilter: false },
            { name: "state", values: state.state, isFilter: false },
            { name: "latitude", values: state.latitude, isFilter: false },
            { name: "longitude", values: state.longitude, isFilter: false },
            { name: "radius", values: state.radius, isFilter: false },
            { name: "radiusUnit", values: state.radiusUnit, isFilter: false },
        ];
      
        // Update filters
        filtersToUpdate.forEach( ( filter ) => {
            updateFilter( filter.name, filter.values, filter.isFilter );
        } );
      
        // Replace the URL with updated parameters
        window.history.replaceState( null, '', `?${urlParams.toString()}` );
    };
      
    const updateFilterStateFromUrl = () => {
        const urlParams = new URLSearchParams( window.location.search );
        const filterState = { ...state.filterState };
      
        // Define a function to update the filter state
        const updateFilterState = ( filterName, paramName, isFilter ) => {
            const paramValue = urlParams.get( paramName );
            if ( paramValue ) {
                if( isFilter ) {
                    const values = paramValue.split( ',' );
                    let filter = filterState[filterName];
                    if( filterName === 'skills' || filterName === 'page' ){
                        filter = values;
                    }  else {
                        values.forEach( val => {
                            filter = filter.map( item => {
                                if( item.value === val ){
                                    return { ...item, checked: true };
                                } else {
                                    return { ...item };
                                }
                            } );
                        } );
                    }
                    PositionsStore.update( s => {
                        s.filterState[filterName] = filter;
                    } );
                } else {
                    PositionsStore.update( s => {
                        s[filterName] = paramValue;
                    } );
                } 
            }
        };
      
        // List of filters to update
        const filtersToUpdate = [
            { name: 'job_title', paramName: 'filterRole', isFilter: true },
            { name: 'experience', paramName: 'filterExperience', isFilter: true },
            { name: 'employmentTypes', paramName: 'filterEmploymentType', isFilter: true },
            { name: 'education', paramName: 'filterEducation', isFilter: true },
            { name: 'skills', paramName: 'filterSkills', isFilter: true },
            { name: 'page', paramName: 'page', isFilter: false },
            { name: "job_position", paramName: "search", isFilter: false },
            { name: "location", paramName: "location", isFilter: false },
            { name: "country", paramName: "country", isFilter: false },
            { name: "city", paramName: "city", isFilter: false },
            { name: "state", paramName: "state", isFilter: false },
            { name: "latitude", paramName: "latitude", isFilter: false },
            { name: "longitude", paramName: "longitude", isFilter: false },
            { name: "radius", paramName: "radius", isFilter: false },
            { name: "radiusUnit", paramName: "radiusUnit", isFilter: false }
        ];
      
        // Update filter state
        filtersToUpdate.forEach( ( filter ) => {
            updateFilterState( filter.name, filter.paramName, filter.isFilter );
        } );
    };
      
    useEffect( () => {
        updateFilterStateFromUrl();
    },[] );
    
    useEffect( () => {
        updateSearchUrl();
    },[ state.filterState,
        state.page,
        state.job_position,
        state.country,
        state.city,
        state.state,
        state.radius,
        state.radiusUnit ] );

    return {
        getJobs,
        getJobDetails,
        updateSearchUrl,
        handleSearch
    };
};

export default useJobSearch;