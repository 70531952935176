import { Store, registerInDevtools } from "pullstate";
import util from "../../utils/miniUtils";

export const PositionsStore = new Store({
    applyModal: false,
    city: "",
    country: "",
    state: "",
    zipcode: "",
    longitude: "",
    latitude: "",
    createAccount: false,
    duration: "",
    education: "",
    employmentTypes: "",
    experience: "",
    filterState: {
        city: [],
        country: util.fixCountryList(),
        education: util.basicEducationList(),
        employmentTypes: util.employmentTypess(),
        experience: util.expereienceLevel(),
        job_title: util.jobRoleList(),
        skills: [],
        min: 0,
        max: 0,
        state: [],
    },
    firstTime: true,
    first_job: "",
    homePage: true,
    index: "",
    isLogged: util.user.name ? true : false,
    job: null,
    job_position: "",
    jobs: [],
    latLong: "",
    limit: 10,
    location: "",
    locations: [],
    loginModal: false,
    loading: false,
    newSearch: null,
    page: '0',
    postedDaysAgo: 3,
    profileProcess: false,
    recoveryModal: false,
    role: "",
    sectionSelected: "Main",
    skills: "",
    Start_Distance: null,
    radius: "",
    radiusUnit: "",
    microsoftLogged: util.user.name ? true : false,
    isModalVisible: false,
    onlyOne: false,
    isTalent: false
});
registerInDevtools({
    PositionsStore,
});