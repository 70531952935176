
const initialState = {
    isCustomWorkload :true
};

const isCustomWorkoadReducer = ( state = initialState,action ) => {
    switch( action.type ){
        case "SET_IS_CUSTOM":
            return {
                state,
                isCustomWorkload: action.payload
            };

        default:
            return state;
    }
};

export default isCustomWorkoadReducer;

