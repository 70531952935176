import React from 'react';
import { toast } from 'react-toastify'; // Make sure you have react-toastify properly set up
import useUploadResume from './useUploadResume'; // Update the import path

const ResumeUpload = ( { talentId } ) => {
    const {
        isResumeAvailable,
        onChangeResumeFile,
        onUploadNewResume,
        onCancelResumeUpload,
        onChangeHandler,
        resumeState,
        setResumeState
    } = useUploadResume();

    const downloadResume = () => {
        if ( resumeState.resume ) {
            // Assuming you have a proper download link or mechanism
            // For example, you can use <a> to trigger a download
            window.open( isResumeAvailable( resumeState.resume ), '_blank' );
        } else {
            toast.error( 'No resume available to download.' );
        }
    };

    const deleteResume = () => {
    // Implement your deletion logic here
    // You might want to show a confirmation dialog before deleting
    // After deleting, update the state accordingly
    // For example, reset resumeState.resume to null
        toast.success( 'Resume deleted successfully.' );
    };

    return (
        <div>
            <h2>Upload Resume</h2>
            <input type="file" onChange={onChangeHandler} />
            {resumeState.isVisible === 'displayOn' && (
                <div>
                    <button onClick={onUploadNewResume}>Upload</button>
                    <button onClick={onCancelResumeUpload}>Cancel</button>
                </div>
            )}

            <h2>Download/Delete Resume</h2>
            {resumeState.resumeLoading ? (
                <p>Loading resume...</p>
            ) : (
                <div>
                    {resumeState.resume ? (
                        <div>
                            <button onClick={downloadResume}>Download Resume</button>
                            <button onClick={deleteResume}>Delete Resume</button>
                        </div>
                    ) : (
                        <p>No resume available.</p>
                    )}
                </div>
            )}

            {/* Display uploaded resume */}
            {resumeState.resume && <iframe title="Resume" src={isResumeAvailable( resumeState.resume )} width="100%" height="600px" />}
        </div>
    );
}

export default ResumeUpload;
