import MaterialTable from "material-table";
import React, { Fragment, useState } from "react";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import NewNoteDialog from "./NewNoteDialog";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function TableOfNotes ( { data, title, talent_id, skillType, reloadData,isEditing } ){
	
    const [show, setShow] = useState( false );
    const [note, setNote] = useState( { note:"" } );
    const [loading, setLoading] = useState( false );
	
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
	
    const deleteSkill = async ( row ) => {
        try {
            startLoading();
            let { candidate_id, id } = row;
            const response = await util.axios.delete( `talent/note_delete/${candidate_id}/${id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Note Deleted" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    const saveNewNote = async ( ) => {
        try {
            startLoading();
            const response = await util.axios.post( `talent/note_add/${talent_id}/`, note );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData( data );
            util.toast().success( "Note Created" );
            setNote( { note:"" } );
            setShow( false );
            stopLoading();
        }catch ( e ){
            stopLoading();
            util.handleError( e );
        }
    };
		
    const handleChangeNewNote = ( e ) => {
        const { name, value } = e.target;
        setNote( { ...note, [name]: value } );
    };
    const tooltip = (
        <Tooltip id="tooltip">
          Add notes
        </Tooltip>
    );
	
    return (
        <Fragment>
            {/* <MaterialTable
                isLoading={loading}
                columns={[
                    {
                        title: 'Date', field: 'createdAt',
                        width:"10%",
                        render: row =>{
                            return util.humanDate( row.createdAt, true );
                        }
                    },
                    {
                        title: 'By', field: 'create_By',
                        width:"5%"
                    },
                    {
                        title: 'Description', field: 'note',
                        width:"80%",
                        render: d => <div className="text-justify">{d.note}</div>
                    },
                    {
                        title: '', sorting: false,
                        width:"5%",
                        render: row => {
                            return (
                                <util.PopOverConfirmation
                                    callBackOk={()=> deleteSkill( row )}
                                    callBackNo={()=> console.log( "" )}
                                />
							
                            );
                        }
                    },
                ]}
                data={data}
                options={{
                    pageSize: 10,
                    sorting: true,
                    search: true,
                    showFirstLastPageButtons: true,
                    pageSizeOptions: [10, 30, 50, 100],
                    padding: "dense",
                    paginationType: "normal",
                    tableLayout: "auto",
                    addRowPosition: "first",
                    emptyRowsWhenPaging:false,
                    toolbar: true,
                    showTitle: false
                }}
                actions={[
                    {
                        icon: ()=> <FontAwesomeIcon  icon={faPlus}/>,
                        isFreeAction: true,
                        tooltip: `Add new note`,
                        onClick: ( event, rowData ) => {
                            setShow( true );
                        }
                    }
                ]}
            /> */}
            <div className="custom-container ">
            {isEditing &&  ( util.user.role == "6" || util.user.role == "1" )?  <OverlayTrigger placement="top" overlay={tooltip}>
                    <FontAwesomeIcon
                        className="custom-icon"
                        icon={faPlus}
                        onClick={()=>setShow( true )}
                    />
                </OverlayTrigger> :""}
                <b className="custom-title" style={{ color:data.length === 0 ? 'gray':'black' }}>Notes</b>
             
                <table className="custom-table">
                    {data.length === 0 ? "" :  <thead>
                        <tr>
                            <th style={{ fontSize:'14px' }}>Date</th>
                            <th style={{ fontSize:'14px' }}>By</th>
                            <th style={{ fontSize:'14px' }}>Description</th>
                            <th style={{ fontSize:'14px' }}></th>
                        </tr>
                    </thead>}
                    <tbody>
                        {data.map( ( row, index ) => (
                            <tr key={index}>
                                <td style={{ fontSize:'14px' }} >{util.humanDate( row.createdAt, true )}</td>
                                <td style={{ fontSize:'14px' }}>{row.create_By}</td>
                                <td style={{ fontSize:'14px' }}>
                                    <div className="text-justify">{row.note}</div>
                                </td>
                                <td>
                                  {util.user.role == "6" || util.user.role == "1"?  <util.PopOverConfirmation
                                        callBackOk={() => deleteSkill( row )}
                                        callBackNo={() => console.log( "" )}
                                    />:null
                        }
                                </td>
                            </tr>
                        ) )}
                    </tbody>
                </table>

			
                <NewNoteDialog
                    talent_id={talent_id}
                    show={show}
                    hideNote={()=> setShow( false )}
                    saveNewNote={saveNewNote}
                    note={note}
                    handleChangeNewNote={handleChangeNewNote}
                /></div>
        </Fragment>
    );
}