import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import { Chip } from "@material-ui/core";
import { TalentStore } from "../../TalentStore";


const ResumeFile = ( { resume, onChangeResumeFile } ) => {
   
    const talent = TalentStore.useState( state => state.talent );
    
    return (
        <div className="col-md-12 ">
            <a
                target="new"
                href={resume}
                // className={"btn btn-secondary sizeBtn activeBtn"}
                className="isCursor"
            >
                
                <FontAwesomeIcon icon={faDownload} className="ml-3"/>  
            </a> 
            <span className="ml-4 isCursor"
                onClick={onChangeResumeFile}
                // className={"btn btn-secondary sizeBtn activeBtn ml-2"}
            >
                <FontAwesomeIcon icon={faSync} /> Upload New
            </span>
            {talent.resumeName? <Chip 
                label= {talent.resumeName}
                className="iconGray rounded text-primary ml-3" />:null
            }
        </div>
    );
	
};

export default ResumeFile;