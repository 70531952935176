import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Button, styled } from '@material-ui/core';


const ApproveButton = styled( Button )( {
    border: 'none',
    color: "#05943E",
    textTransform: 'none',
    '&:hover': {
        boxShadow: 'none',
        textDecoration: "underline",
    },
    '&:active': {
        boxShadow: 'none',
        textDecoration: "underline",
    },
    '&:focus': {
        boxShadow: 'none',
    } }
);

const DeclineButton = styled( Button )( {
    border: 'none',
    color: "#B40606",
    textTransform: 'none',
    '&:hover': {
        boxShadow: 'none',
        textDecoration: "underline",
    },
    '&:active': {
        boxShadow: 'none',
        textDecoration: "underline",
    },
    '&:focus': {
        boxShadow: 'none',
    } }
);

const WorkflowItem = ( { workflowItem, approver, userGroups, updateApprovalItem, approvalStatus } ) => {
    const [approverComment, setApproverComment] = useState( workflowItem[`${approver}_comment`] );
    const [isCommentUpdated, setIsCommentUpdated] = useState( false );
    const [open, setOpen] = useState( false );

    const handleApproveButton = ( comment ) => {
        const updatedObj = {};
        updatedObj[`${approver}_status`]= 1; 
        if( isCommentUpdated ) {
            updatedObj[`${approver}_comment`]= comment; 
        }
        updateApprovalItem( updatedObj );
    };

    const handleDeclineButton = ( comment ) => {
        const updatedObj = {};
        updatedObj[`${approver}_status`]= 0; 
        if( isCommentUpdated ) {
            updatedObj[`${approver}_comment`]= comment; 
        }
        updateApprovalItem( updatedObj );
    };

    return <div className="my-1">
        {approverComment || workflowItem[`${approver}_status`] === null ? 
            <div className="d-flex justify-content-between w-100 isCursor bg-light p-1" onClick={() => setOpen( !open )}>
                <div>{workflowItem[`${approver}_name`]}</div>
                <div className='d-flex justify-content-end align-items-center'>{workflowItem[`${approver}_status`] === null ?
                    <div className="text-warning font-weight-bold">Pending</div> : 
                    workflowItem[`${approver}_status`] ?  
                        <div className="text-success font-weight-bold">Approved</div> :  
                        <div className="text-danger font-weight-bold">Declined</div>}
                <FontAwesomeIcon className="text-secondary ml-1" icon={faChevronDown} />
                </div>
            </div> : 
            <div className="d-flex justify-content-between w-100 isCursor bg-light p-1">
                <div>{workflowItem[`${approver}_name`]}</div>
                <div className="mr-4">
                    {workflowItem[`${approver}_status`] === null ? 
                        <div className="text-warning font-weight-bold">Pending</div> : 
                        workflowItem[`${approver}_status`] ?  
                            <div className="text-success font-weight-bold">Approved</div> :  
                            <div className="text-danger font-weight-bold">Declined</div>}
                </div>
            </div>}
        <div>
            <Collapse in={open}>
                <div className="bg-light p-1">
                    {( userGroups.filter( el => el.group_id === workflowItem[approver] ).length > 0 ) && ( approvalStatus.status === "Pending" ) ? <input 
                        name="approver3_comment"
                        className="form-control"
                        value={approverComment} 
                        onChange={( e ) => {
                            if( e.target.value ) {
                                setIsCommentUpdated( true );
                            }
                            setApproverComment( e.target.value );
                        }} /> : <div className="xs-fontSize">{workflowItem[`${approver}_comment`]}</div>}
                    
                    {userGroups.filter( el => el.group_id === workflowItem[approver] ).length > 0  && <div className="text-right">
                        <ApproveButton
                            disabled={approvalStatus.status === "Declined" || approvalStatus.status === "Approved"}
                            onClick={() => handleApproveButton( approverComment )}>
                        Approve
                        </ApproveButton>
                        <DeclineButton 
                            disabled={approvalStatus.status === "Declined" || approvalStatus.status === "Approved"}
                            onClick={() => handleDeclineButton( approverComment )}>
                        Decline
                        </DeclineButton>
                    </div>
                    }
                </div>
            </Collapse>
        </div>
    </div>;
};

export default WorkflowItem;