import React, { Fragment } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { bake_cookie, delete_cookie, read_cookie } from "sfcookies";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import {
    Card, Chip, createTheme, FormControl, InputLabel,
    MenuItem, Paper, Select, TextField, Tooltip
} from "@material-ui/core";
import jwt_decode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faCircle,
    faClock,
    faDollarSign,
    faDownload, faEye,
    faHourglassHalf,
    faPaperPlane,
    faPauseCircle,
    faPencilAlt,
    faTimes,
    faTimesCircle,
    faTrash,
    faBan,
    faFire,
    faPlayCircle,
    faStar,
    faWindowClose,
    faAward,
    faComment
} from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import countryList from './location/country.json';
import Dropdown from "react-bootstrap/Dropdown";
import { countries, findFlagUrlByCountryName } from "country-flags-svg";
import { CLIENT_ROLE } from "../../constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SAlert from "../microComponents/AlertSA";
import SweetAlert from "react-bootstrap-sweetalert";
import AWS from 'aws-sdk';
import { CountryInfo } from './countryInfo.js';

const TOKEN = read_cookie( "tokenServer" );
const BASE_URL = process.env.REACT_APP_BASE_URL;
const AWS_URL = '/s3services/download';

AWS.config.update( {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION
} );
const s3 = new AWS.S3();

toast.configure( {
    position: toast.POSITION.BOTTOM_RIGHT
} );

let util = {};

util.fetchImageUrl = async ( key = null ) => {
    try {
        const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: key,
            Expires: 60 // The URL will expire in 60 seconds
        };
        // Use getSignedUrl to generate a signed URL for getObject operation
        const imageUrl = await new Promise( ( resolve, reject ) => {
            s3.getSignedUrl( 'getObject', params, ( error, url ) => {
                if ( error ) {
                    reject( error );
                } else {
                    resolve( url );
                }
            } );
        } );
        return imageUrl;
    } catch ( error ) {
        return null;
    }

};

util.AWSURL = () => {
    return AWS_URL;
};
util.customer_company_id = 183;

util.employmentTypes = [
    "Fulltime Employee",
    "Contractor",
    "Corp to Corp",
];

util.offerTypes = [
    "Hour",
    "Day",
    "Month"
];


util.fixCountryList = () => {
    let countries = [];
    // Add more used countries at the beginning
    countries.push( { value: "United States", label: "country", id: `id_USA` } );
    countries.push( { value: "United States", label: "country", id: `id_UnitedStates` } );
    countries.push( { value: "India", label: "country", id: `id_India` } );
    countries.push( { value: "Mexico", label: "country", id: `id_Mexico` } );
    countries.push( { value: "Canada", label: "country", id: `id_Canada` } );
    countries.push( { value: "Australia", label: "country", id: `id_Australia` } );
    countries.push( { value: "Costa Rica", label: "country", id: `id_Cosra Rica` } );
    countries.push( { value: "New Zealand", label: "country", id: `id_New Zealand` } );
    countries.push( { value: "Germany", label: "country", id: `id_Germany` } );
    countries.push( { value: "Brazil", label: "country", id: `id_Brazil` } );
    countries.push( { value: "Japan", label: "country", id: `id_Japan` } );
    countries.push( { value: "Puerto Rico", label: "country", id: `id_Puerto_Rico` } );
    let temp = countryList.map( ( d, index ) => {
        return { value: d.name, label: "country", id: index + 1, region: d.region };
    } );
    return [...countries, ...temp];
};
util.fixEmploymentTypeList = () => {
    let employmentType = [];
    employmentType.push( { value: "Any", label: "Any", id: 1 } );
    employmentType.push( { value: "Fulltime ", label: "FullTime", id: 2 } );
    employmentType.push( { value: "Contractor", label: "Contractor", id: 3 } );
    employmentType.push( { value: "Corp to Corp", label: "Corp to Corp", id: 4 } );

    return [...employmentType];
};
util.noticePeriod = () => {
    let noticePeriod = [];
    noticePeriod.push( { label: "0 Weeks", value: "0 Weeks", id: 1 } );
    noticePeriod.push( { label: "1 Weeks", value: "1 Weeks", id: 2 } );
    noticePeriod.push( { label: "2 Weeks", value: "2 Weeks", id: 3 } );
    noticePeriod.push( { label: "3 Weeks", value: "3 Weeks", id: 4 } );
    noticePeriod.push( { label: "4 Weeks", value: "4 Weeks", id: 5 } );
    noticePeriod.push( { label: "5 Weeks", value: "5 Weeks", id: 6 } );
    noticePeriod.push( { label: "6 Weeks", value: "6 Weeks", id: 7 } );
    noticePeriod.push( { label: "7 Weeks", value: "7 Weeks", id: 8 } );
    noticePeriod.push( { label: "8 Weeks", value: "8 Weeks", id: 9 } );
    noticePeriod.push( { label: "9 Weeks", value: "9 Weeks", id: 10 } );
    noticePeriod.push( { label: "10 Weeks", value: "10 Weeks", id: 11 } );
    noticePeriod.push( { label: "11 Weeks", value: "11 Weeks", id: 12 } );
    noticePeriod.push( { label: "12 Weeks", value: "12 Weeks", id: 13 } );

    return [...noticePeriod];
};
util.jobRoleList = () => {
    let jobRole = [];
    jobRole.push( { label: "Administrator", value: "Administrator", id: 1 } );
    jobRole.push( { label: "Advisory", value: "Advisory", id: 2 } );
    jobRole.push( { label: "Architect", value: "Architect", id: 3 } );
    jobRole.push( { label: "Consultant", value: "Consultant", id: 4 } );
    jobRole.push( { label: "Technician", value: "Technician", id: 5 } );
    jobRole.push( { label: "Developer", value: "Developer", id: 6 } );
    jobRole.push( { label: "Project/Program Leader", value: "Project/Program Leader", id: 7 } );
    jobRole.push( { label: "Engineer", value: "Engineer", id: 8 } );

    return [...jobRole];
};
util.basicEducationList = () => {
    let educationList = [];
    educationList.push( { label: "High School", value: "High School", id: 1 } );
    educationList.push( { label: "Bachelor's Degrees", value: "Bachelor's Degrees", id: 2 } );
    educationList.push( { label: "Master's Degree", value: "Master's Degree", id: 3 } );
    educationList.push( { label: "Education Specialist Degree", value: "Education Specialist Degree", id: 4 } );
    educationList.push( { label: "Doctorate", value: "Doctorate", id: 5 } );

    return [...educationList];
};

util.expereienceLevel = () => {
    let experienceList = [];
    experienceList.push( { label: "Basic (L1)", value: "Basic (L1)", id: 1 } );
    experienceList.push( { label: "Experienced (L2)", value: "Experienced (L2)", id: 2 } );
    experienceList.push( { label: "Senior (L3)", value: "Senior (L3)", id: 3 } );
    experienceList.push( { label: "Expert (L4)", value: "Expert (L4)", id: 4 } );
    experienceList.push( { label: "Niche (L5)", value: "Niche (L5)", id: 5 } );
    return [...experienceList];
};

util.employmentTypess = () => {
    let employmentType = [];
    employmentType.push( { label: "Fulltime ", value: "Fulltime", id: 1 } );
    employmentType.push( { label: "Contractor ", value: "Contractor", id: 2 } );
    employmentType.push( { label: "Any ", value: "Any", id: 3 } );
    return [...employmentType];
};


util.regionList = [
    'Americas',
    'Central and Eastern Europe',
    'Western Europe',
    'Asia',
    'Africa',
    'Mediterranean & Middle East'
];

util.statusList = () => {
    let statusList = [];
    statusList.push( { value: "New", label: "status", id: `id_New` } );
    statusList.push( { value: "Active", label: "status", id: `id_Active` } );
    statusList.push( { value: "Hold", label: "status", id: `id_Hold` } );
    statusList.push( { value: "Closed", label: "status", id: `id_Closed` } );
    statusList.push( { value: "Filled", label: "status", id: `id_Filled` } );
    statusList.push( { value: "Hot", label: "status", id: `id_Hot` } );
    return statusList;
};

util.countrySettings =
{
    country: "",
    data: [
        {
            employmentType: "Fulltime Employee",
            markUp: "20",
            billableDaysInYear: "236",
            workingHoursInDay: "8",
            averageBillableDaysInMonth: "19.67",
            defaulteJOfferUnitToTalent: "Year",
            defaultSellUnit: "Year",
        },
        {
            employmentType: "Contractor",
            markUp: "0",
            billableDaysInYear: "236",
            workingHoursInDay: "8",
            averageBillableDaysInMonth: "19.67",
            defaulteJOfferUnitToTalent: "Hour",
            defaultSellUnit: "Hour",
        },
        {
            employmentType: "Corp to Corp",
            markUp: "0",
            billableDaysInYear: "236",
            workingHoursInDay: "8",
            averageBillableDaysInMonth: "19.67",
            defaulteJOfferUnitToTalent: "Hour",
            defaultSellUnit: "Hour",
        },
    ]
};

util.customDropDownName = ( name ) => {
    // This method will divide the full name of the talent to avoid overlap container
    let newName = name;
    if ( util.noValues.includes( name ) ) return "";
    const n = newName.split( " " );
    if ( n.length === 1 ) return n[0];
    if ( n.length === 2 || n.length === 3 ) return n[0] + " " + n[1];
    if ( n.length === 4 ) return n[0] + " " + n[2];
    if ( n.length > 4 ) return n[0] + " " + n[2];
    return name;
};

util.stateDictionary = ( state ) => {
    switch ( state ) {
        case "Active":
            return <span className="text-primary-badge text-center text-white bg-prmary">{state}</span>;
        case "Retired":
            return <span className="text-warning-badge text-center text-white bg-warning">{state}</span>;
        case "Damaged":
            return <span className="text-danger-badge text-center text-white bg-danger">{state}</span>;
        case "In Stock":
            return <span className="text-info-badge text-center text-white bg-info">{state}</span>;
        case "Stolen":
            return <span className="text-default-badge">{state}</span>;
        default:
            break;
    }
};

util.assetStates = () => {
    return [
        { name: "Active" },
        { name: "Retired" },
        { name: "Damaged" },
        { name: "In Stock" },
        { name: "Stolen" }
    ];
};

util.ejUnit = ["Hour", "Day", "Month", "Year"];

util.countryFlag = ( country ) => {

    return ( !util.noValues.includes( country ) ) ? findFlagUrlByCountryName( country ) : 'images/country-flag/unknown-flag.webp';
};

util.buyFromBuyTo = ( from, to, country ) => {
    // No values at all
    if ( util.noValues.includes( from ) && util.noValues.includes( to ) ) {
        return "~";
    }
    // Only from
    if ( !util.noValues.includes( from ) && util.noValues.includes( to ) ) {
        return `from ${util.currencyFormat( from, country )}`;
    }
    // Only to
    if ( util.noValues.includes( from ) && !util.noValues.includes( to ) ) {
        return `up to ${util.currencyFormat( to, country )}`;
    }
    // Have both
    if ( !util.noValues.includes( from ) && !util.noValues.includes( to ) ) {
        return `from ${util.currencyFormat( from, country )} to ${util.currencyFormat( to, country )}`;
    }


};

util.assetLocation = () => {
    return [
        { location: "Irvine" },
        { location: "Mexicali" }
    ];
};

util.testStatus = ( status ) => {
    switch ( status ) {
        case 1:
            return <Badge pill variant="primary">Assigned</Badge>;
        case 2:
            return <Badge pill variant="secondary">In Process</Badge>;
        case 3:
            return <Badge pill variant="success">Completed</Badge>;
        default:
            return status;
    }
};

util.statusDocument = ( status ) => {
    switch ( status ) {
        case 1:
            return (
                <Fragment>
                    <FontAwesomeIcon icon={faClock} className="mr-2 burlywood" />
                    Not shared
                </Fragment>
            );
        case 2:
            return (
                <Fragment>
                    <FontAwesomeIcon icon={faPaperPlane} className="text-info mr-2" />
                    Sent
                </Fragment>
            );
        case 3:
            return (
                <Fragment>
                    <FontAwesomeIcon icon={faHourglassHalf} className="text-warning mr-2" />
                    Pending
                </Fragment>
            );
        case 4:
            return (
                <Fragment>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
                    Completed
                </Fragment>
            );
        default:
            break;
    }
};

util.assetOS = () => {
    return [
        { name: "Windows 10" },
        { name: "Windows 8.1" },
        { name: "Windows 8" },
        { name: "Windows 7" },
        { name: "Windows Vista" },
        { name: "Windows XP" },
        { name: "Windows Server, version 2004" },
        { name: "Windows Server, version 1909" },
        { name: "Windows Server, version 1903" },
        { name: "Windows Server 2019" },
        { name: "Windows Server 2016" },
        { name: "Windows Server 2012" },
    ];
};

util.user = read_cookie( "tokenServer" ).length > 0 ? jwt_decode( read_cookie( "tokenServer" ) ) : {};

util.baseUrl = () => {
    return BASE_URL;
};
// util.AWSURL= () => {
//     return AWS_URL;
// };

util.humanRole = ( role ) => {
    switch ( role ) {
        case 1:
            return "Recruiter";
        case 2:
            return "Talent";
        case 3:
            return "Client";
        case 4:
            return "Partner";
        case 5:
            return "Evaluator";
        case 6:
            return "Recruiter Manager";
        case 7:
            return "Employee";
        case 8:
            return "Employee Manager";
        default:
            break;
    }
};

util.detectScreenLayout = () => {
    let wind = window.innerWidth;
    if ( wind < 1060 ) return "fixed";
    return "fixed";
};

util.statusCheck = ( status ) => {
    switch ( status ) {
        case 0:
            return <FontAwesomeIcon className="text-warning" icon={faClock} />;
        case 1:
            return <FontAwesomeIcon className="text-success" icon={faCheck} />;
        case 2:
            return <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />;
        default:
            break;
    }
};

util.expectedSalaryColumn = ( { salary_mode, ctc } ) => {
    if ( ctc ) {
        // has CTC saved
        if ( salary_mode ) {
            // has salary_mode selected
            // Convert Annually to Year
            return util.currencyFormat( parseFloat( ctc ) ) + " x " + ( salary_mode === "Annually" ? "Year" : salary_mode );
        } else {
            return util.currencyFormat( parseFloat( ctc ) ) + " x --";
        }
    } else {
        return "$0.0";
    }
};

util.returnLinktoDownload = ( position_id = null ) => {
    const url = BASE_URL + "/export/" + position_id;
    let filename = position_id + "_" + new Date().toLocaleDateString();
    let link = document.createElement( 'a' );
    link.style.display = 'none';
    link.setAttribute( 'target', '_blank' );
    link.setAttribute( 'target', '_blank' );
    link.setAttribute( 'href', url );
    link.setAttribute( 'download', filename );
    document.body.appendChild( link );
    link.click();
    document.body.removeChild( link );
};

util.DownloadTableAsCsv = ( tableId, startIndex, tableName ) => {
    // Select rows from table_id
    let rows = document.querySelectorAll( 'table.' + tableId + ' tr' );
    // Construct csv
    let csv = [];
    for ( let i = 0; i < rows.length; i++ ) {
        let row = [], cols = rows[i].querySelectorAll( 'td, th' );
        for ( let j = startIndex; j < cols.length; j++ ) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            let data = cols[j].innerText.replace( /(\r\n|\n|\r)/gm, '' ).replace( /(\s\s)/gm, ' ' ).replace( "arrow_downward", '' );
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            data = data.replace( /"/g, '""' );
            // validate if column is a droplist
            if ( data === "FulltimeC2CContractor" ) {
                data = cols[j].getAttribute( 'value' );
                data = data ? data : "";
            }
            // Push escaped string
            row.push( '"' + data + '"' );
        }
        csv.push( row.join( ';' ) );
    }
    let csv_string = csv.join( '\n' );
    // Download it
    let filename = tableName + "_" + new Date().toLocaleDateString() + '.csv';
    let link = document.createElement( 'a' );
    link.style.display = 'none';
    link.setAttribute( 'target', '_blank' );
    link.setAttribute( 'href', 'data:text/csv;charset=utf-8,' + encodeURIComponent( csv_string ) );
    link.setAttribute( 'download', filename );
    document.body.appendChild( link );
    link.click();
    document.body.removeChild( link );
};

// util.downloadXLXs = ( id = null ) => {
//     let link = document.createElement( 'a' );
//     link.style.display = 'none';
//     link.setAttribute( 'target', '_blank' );
//     link.setAttribute( 'href', BASE_URL + `/export/${id}` );
//     link.setAttribute( 'download', 'export.xlsx' );
//     document.body.appendChild( link );
//     link.click();
//     document.body.removeChild( link );
// }

// util.convertJsontoExcel = ( data = [] ) => {
//     const workSheet = XLSX.utils.json_to_sheet( data );
//     const workBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet( workBook,workSheet, tabname );
//     XLSX.write( workBook,{ bookType: 'xlsx',type: "buffer" } );
//     XLSX.write( workBook,{ bookType: 'xlsx',type: "binary" } );
//     XLSX.writeFile( workBook,filename );
// };

util.handleError = ( msg ) => {
    if ( process.env["REACT_APP_ENVIROMENT"] !== "Production" ) console.log( msg );
    
    if ( msg.toString().indexOf( "Unauthorized" ) !== -1 ) {
        let e = document.body;
        e.parentNode.removeChild( e );
        delete_cookie( "tokenServer" );
        window.history.replaceState( "", "", "/" );
        window.location.reload();
    } else if ( msg.toString().indexOf( "_UNIQUE" ) !== -1 ) {
        util.toast().error( "Email must be unique" );
    } else if ( msg.toString().indexOf( "notEmpty" ) !== -1 ) {
        let emsg = msg.split( " " );
        util.toast().error( emsg[3].toUpperCase()[0] + emsg[3].substr( 1 ) + " cannot be empty" );
    } else if ( msg.toString().indexOf( "isEmail" ) !== -1 ) {
        util.toast().error( "Email is not valid" );
    } else if ( msg.message !== undefined ) {
        util.toast().error( msg.message );
    } else {
        util.toast().error( msg );
    }
};

util.url = BASE_URL;

util.noValues = [undefined, "undefined", null, "null", "", "0", 0, NaN, "-Infinity"];

util.location = ( country, state, city ) => {
    // Init string variable for all the location
    let locationString = "";
    // console.log(`country: ${country}, state: ${state}, city: ${city}`)
    // First City
    if ( city !== null && city !== "" ) {
        locationString += city ? city : " ";
    }

    // Validate first country to continue with state
    if ( !util.noValues.includes( country ) ) {
        let c = countryList.filter( f => f.name === country )[0];
        if ( !c ) {
            // Return given values due fail search of data
            locationString += util.noValues.includes( state ) ? "  " : ", " + state + util.noValues.includes( country ) ? "" : ", " + country;
        } else {
            // Found country, try search for state
            let s = c.states.filter( s => s.name === state )[0];
            if ( !s ) {
                // Can't find state, return given value
                locationString += util.noValues.includes( locationString ) ? c["code3"] : ", " + c["code3"];
            } else {
                locationString += util.noValues.includes( locationString ) ? s.code + ", " + c["code3"] : ", " + s.code + ", " + c["code3"];
            }
        }
    } else {
        // No country
        // Return given values due fail search of data
        locationString += util.noValues.includes( state ) ? "  " : ", " + state + util.noValues.includes( country ) ? "" : ", " + country;
    }
    return locationString;
};

util.axiox = () => {
    return axios.create( {
        baseURL: BASE_URL,
        timeout: 100000,
        headers: { Authorization: `${TOKEN}` },
    } );
};

util.axios = axios.create( {
    baseURL: BASE_URL,
    timeout: 100000,
    headers: { Authorization: `${TOKEN}` },
} );

util.toast = () => {
    return toast;
};

util.humanDateTime = ( date ) => {
    if ( date === null ) return date;
    if ( date === "" ) return "-";
    let d = date.split( "." )[0];
    d = d.split( " " );
    return util.humanDate( d[0] ) + " at " + d[1];
};

util.humanDate = ( date, str = true, longStr = false ) => {
    if ( date === null ) return date;
    if ( date === undefined ) return date;
    if ( date === "" ) return "-";
    let d;
    if ( date.split( "" ).includes( " " ) ) {
        d = date.split( " " )[0].split( "-" );
    } else {
        d = date.split( "T" )[0].split( "-" );
    }

    let month = d[1];
    if ( str ) {
        // Want name of the month instead of the number
        switch ( d[1] ) {
            case '01': month = longStr ? "January" : "JAN";
                break;
            case '02': month = longStr ? "February" : "FEB";
                break;
            case '03': month = longStr ? "March" : "MAR";
                break;
            case '04': month = longStr ? "April" : "APR";
                break;
            case '05': month = longStr ? "May" : "MAY";
                break;
            case '06': month = longStr ? "June" : "JUN";
                break;
            case '07': month = longStr ? "July" : "JUL";
                break;
            case '08': month = longStr ? "August" : "AUG";
                break;
            case '09': month = longStr ? "September" : "SEP";
                break;
            case '10': month = longStr ? "October" : "OCT";
                break;
            case '11': month = longStr ? "November" : "NOV";
                break;
            case '12': month = longStr ? "December" : "DEC";
                break;
            default:
                month = d[1];
                break;
        }
    }

    // return <Badge variant="info">{finalDate}</Badge>
    return `${month}/${d[2]}/${d[0]}`;
};

util.returnYear = ( value ) => {
    let date = new Date( value );
    let returnYear = date.getFullYear();
    return returnYear;
};
util.humanDateMonth = ( date, str = true, longStr = false ) => {
    if ( date === null ) return date;
    if ( date === undefined ) return date;
    if ( date === "" ) return "-";
    const d = date.split( " " )[0].split( "-" );
    let month = d[1];
    if ( str ) {
        // Want name of the month instead of the number
        switch ( d[1] ) {
            case '01': month = longStr ? "January" : "JAN";
                break;
            case '02': month = longStr ? "February" : "FEB";
                break;
            case '03': month = longStr ? "March" : "MAR";
                break;
            case '04': month = longStr ? "April" : "APR";
                break;
            case '05': month = longStr ? "May" : "MAY";
                break;
            case '06': month = longStr ? "June" : "JUN";
                break;
            case '07': month = longStr ? "July" : "JUL";
                break;
            case '08': month = longStr ? "August" : "AUG";
                break;
            case '09': month = longStr ? "September" : "SEP";
                break;
            case '10': month = longStr ? "October" : "OCT";
                break;
            case '11': month = longStr ? "November" : "NOV";
                break;
            case '12': month = longStr ? "December" : "DEC";
                break;
            default:
                month = d[1];
                break;
        }
    }

    // return <Badge variant="info">{finalDate}</Badge>
    return `${month}`;
};


util.job_position_base = () => {
    return {
        dealType: "",
        positionTitle: "",
        partnerName: "",
        partnerContact: "",
        end_client_name: "",
        sell_rate: "0",
        status: "",
        margin: "",
        reporting_manager: "",
        sales_lead: "",
        lead_recruiter: "",
        secondary_recruiter: "",
        restricted_to: "",
        job_title: "",
        job_description: "",
        role: "",
        scope: "",
        level: "",
        primary_skill: "",
        secondary_skill: "",
        location: "",
        city: "",
        state: "",
        country: "",
        index: null,
        zipCode: "",
        buy_rate: "",
        duration: "",
        priority: "",
        education: "",
        certification: "",
        employment_type: "",
        recruiter: "",
    };
};

util.restricted_to = () => {
    return [
        { restricted_to: "None" },
        { restricted_to: "US Citizen/ GC Only" },
        { restricted_to: "Security Clearance Required" },
    ];
};

util.printValue = ( value, percentage = false ) => {
    // eslint-disable-next-line
    switch (value?.toString()) {
        case '-Infinity': return `0.00${percentage ? "%" : ""}`;
        case -Infinity: return `0.00${percentage ? "%" : ""}`;
        case 'NaN': return `0.00${percentage ? "%" : ""}`;
        case NaN: return `0.00${percentage ? "%" : ""}`;
        case undefined: return `0.00${percentage ? "%" : ""}`;
        // default: "";
    }
    return `${parseFloat( value ).toFixed( 2 )}${percentage ? "%" : ""}`;

};

util.currencyFormat = ( value, country ) => {
    if ( !util.noValues.includes( value ) ) {
        let a = parseFloat( value ).toLocaleString( 'en-US', { style: 'currency', currency: 'USD' } );
        a = a.split( "$" );
        return util.symbolDependingCurrencySelected( country ) + " " + a[1];
    } else {
        return util.symbolDependingCurrencySelected( country ) + ` 0.00`;
    }
};

util.currencies = () => {
    return [
        { currency: "USD" },
        { currency: "AUD" },
        { currency: "MXN" },
        { currency: "INR" }
    ];
};

util.staticData = ( opc ) => {
    const ownerOffices = [
        { owner_office: "eJAmerica" },
        { owner_office: "eJAustralia" },
        { owner_office: "eJIndia" },
    ];

    const employmentOptions = [
        { employment_options: "Fulltime" },
        { employment_options: "Contractor" },
    ];

    const dealType = [
        { dealType: "Channel Partner" },
        { dealType: "Direct Client" },
    ];

    const priorities = [
        { priority: "Low" },
        { priority: "Mid" },
        { priority: "High" },
        { priority: "Urgent" },
    ];
    const ejdelivery = [
        { ej_delivery_lead: "Arturo Pacheco" },
        { ej_delivery_lead: "Jyoti M." },
        { ej_delivery_lead: "Erik Pacheco" },
    ];
    const ejsales = [
        { sales_lead: "Andy Sims" },
        { sales_lead: "Avinash G." },
        { sales_lead: "Brayan B." },
        { sales_lead: "Juan P." },
    ];
    const lead_recruiter = [
        { lead_recruiter: "Jyoti M." },
        { lead_recruiter: "Arturo Pacheco" },
    ];
    const secondary_recruiter = [
        { secondary_recruiter: "Jyoti M." },
        { secondary_recruiter: "Arturo Pacheco" },
    ];


    const restricted_to = [
        { restricted_to: "None" },
        { restricted_to: "US Citizen/ GC Only" },
        { restricted_to: "Security Clerance Required" },
    ];

    const role = [
        { role: "Advisory" },
        { role: "Architect" },
        { role: "Developer " },
        { role: "Engineer " },
        { role: "Project/Program Leader" },
        { role: "Technician" }

    ];

    const education = [];
    const state = [];
    const country = [
        { country: "United States" },
        { country: "Canada" },
        { country: "Mexico" },
        { country: "India" },
        { country: "Australia" },
    ];

    const scope = [{ scope: "Operation Support" }, { scope: "Project Support" }];

    const level = [
        { level: "Basic (L1)" },
        { level: "Experienced (L2)" },
        { level: "Senior (L3)" },
        { level: "Expert (L4)" },
        { level: "Niche (L5)" }
    ];

    const employment_type = [
        { employment_type: "Fulltime " },
        { employment_type: "Contractor" },
        { employment_type: "Any" },
    ];

    const status = [
        { status: "Received Request" },
        { status: "Submitted Resumes" },
        { status: "Customer Reviewed and Feedback Received" },
        { status: "Canddiate Selected" },
        { status: "Contracting Process" },
        { status: "Fully Executed Contract" },
    ];

    switch ( opc ) {
        case "deal_type":
            return dealType;
        case "restricted_to":
            return restricted_to;
        case "owner_office":
            return ownerOffices;
        case "lead_recruiter":
            return lead_recruiter;
        case "secondary_recruiter":
            return secondary_recruiter;
        case "sales_lead":
            return ejsales;
        //   case "ej_delivery_lead": return ejrecruiter
        case "employmentOptions":
            return employmentOptions;
        case "reporting_manager":
            return employmentOptions;
        case "ejdelivery":
            return ejdelivery;
        case "priorities":
            return priorities;
        case "role":
            return role;
        case "scope":
            return scope;
        case "level":
            return level;
        case "employment_type":
            return employment_type;
        case "status":
            return status;
        case "education":
            return education;
        case "state":
            return state;
        case "country":
            return country;
        default:
            break;
    }
};

util.firstContact = {
    contacted: false,
    date: "",
};

util.internalReview = {
    status: 0,
    date: new Date().toISOString().split( "T" )[0],
    notes: "",
    interviewer: "",
    recruiters: [
        { recruiter: "" },
        { recruiter: "Arturo Pacheco" },
        { recruiter: "Jyoti M." },
        { recruiter: "Ayush" },
        { recruiter: "Brayan" },
        { recruiter: "Avinash" },
        { recruiter: "Juan" },
        { recruiter: "Andi" },
    ],
};

util.clientInterviews = {
    status: 0,
    interviews: [], //{ date: "", hour: "", note: "" }]
};

function fileURL( file ) {
    return BASE_URL + "/files/" + file;
}

util.onBoardingDocs = {
    ejAmericaEmployee: { opc: 1, file: fileURL( "ej_form.docx" ), status: 0, statusFinal: 0, userFile: "" },
    bgcForm: { opc: 2, file: fileURL( "format.pdf" ), status: 0, statusFinal: 0, userFile: "" },
    directDeposit: { opc: 3, file: fileURL( "format.pdf" ), status: 0, statusFinal: 0, userFile: "" },
    contractAgreement: { opc: 4, file: fileURL( "format.pdf" ), status: 0, statusFinal: 0, userFile: "" },
    offer_letter: { opc: 5, file: fileURL( "format.pdf" ), status: 0, statusFinal: 0, userFile: "" },
    i9Form: { opc: 6, file: fileURL( "format.pdf" ), status: 0, statusFinal: 0, userFile: "" },
    w9_form: { opc: 7, file: fileURL( "format.pdf" ), status: 0, statusFinal: 0, userFile: "" },
    w4_form: { opc: 8, file: fileURL( "format.pdf" ), status: 0, statusFinal: 0, userFile: "" },
};

util.backgroundCheck = {
    status: 0,
    date: "",
    notes: "",
};

util.finalDecision = {
    status: 0,
    date: "",
    startDate: "",
    notes: "",
};

util.dateLong = ( date ) => {
    const d = new Date( date ).toString().split( "GMT" )[0];
    return d.slice( 0, d.length - 1 );
};

util.candidateStatus = ( status ) => {
    // Handles the status of the process of the candidate inside the Job Position
    switch ( status ) {
        case '1':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className={"status1"} />} Just added</span>;
        case '2.5':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className={"status2-5"} />} Interview Scheduled</span>;
        case '2':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle} className={"status2"} />} Internal Interview</span>;
        case '3':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className={"status3"} />} Evaluations</span>;
        case '4':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle} className="status4" />} Onboarding Documents</span>;
        case '5.5':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle} className="status5-5" />} Background check Started</span>;
        case '5':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle} className="status5" />} Background check</span>;
        case '6':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle} className="status6" />} Waiting Client Decision</span>;
        case '19':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle} className="status19" />} Drop Process</span>;
        case '11':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className="status11" />} Rejected</span>;
        case '12':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className="status12" />} Accepted</span>;
        case '13':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className="status13" />} Onboarded</span>;
        case '20':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className="optionTOReceived" />} TO Received</span>;
        case '21':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className="optionOfferReleased" />} Offer Released</span>;
        case '22':
            return <span className="status_fontJD">{<FontAwesomeIcon icon={faCircle}
                className="optionSubmitted" />} Submitted</span>;
        default:
            return status;
    }
};

util.candidateStatusToString = ( status ) => {
    // Handles the status of the process of the candidate inside the Job Position
    switch ( status.toString() ) {
        case '1': return "Just added";
        case '2.5': return "Interview Scheduled";
        case '2':
            return "Internal Interview";
        case '3':
            return "Evaluations";
        case '4':
            return "Onboarding Documents";
        case '5.5':
            return "Background check Started";
        case '5':
            return "Background check";
        case '6':
            return "Waiting Client Decision";
        case '19':
            return "Drop Process";
        case '11':
            return "Rejected";
        case '12':
            return "Accepted";
        case '13':
            return "Onboarded";
        case '20':
            return "TO Received";
        case '21':
            return "Offer Released";
        case '22':
            return "Submitted";
        default:
            return "Unknown";
    }
};

util.isResumeAvailable = ( d, opc = false ) => {
    if ( d.resume !== null && d.resume !== "null" && d.resume !== undefined && d.resume !== "undefined" && d.resume !== "" ) {
        const url = BASE_URL + AWS_URL + "/candidate/" + d.id + "/" + d.resume;
        if ( opc ) {
            return <a target="new" href={url}><span className='mr-2'>Download Resume</span><FontAwesomeIcon
                icon={faDownload} />
            </a>;
        } else {
            return <a target="new" href={url}><FontAwesomeIcon icon={faDownload} /></a>;
        }

    } else return "";
};
util.getResume = ( d ) => {
    if ( d.resume !== null && d.resume !== "null" && d.resume !== undefined && d.resume !== "undefined" && d.resume !== "" ) {
        const url = BASE_URL + AWS_URL + "/candidate/" + d.id + "/" + d.resume;
        return url;
    }
    else {
        return "";

    }

};

util.isProfilePicAvailable = ( d, opc = false, size = null ) => {
    let picSize = opc ? size : 35;
    if ( !util.noValues.includes( d.profile_picture ) ) {
        let url;
        if ( d.isUploading || d.profile_picture ) {
            if ( process.env.REACT_APP_ENVIROMENT !== "Production" ) {
                url = BASE_URL + "/candidate/" + d.id + "/" + d.profile_picture;
            }
            else {
                url = BASE_URL + AWS_URL + "/candidate/" + d.id + "/" + d.profile_picture;
            }
        } else {
            url = d.pictureSrc;
        }

        return <img width={`${picSize}`} height={`${picSize}`} className="rounded-circle imageZoomed"
            alt={d.name.substring( 0, 5 )} src={url} />;
    } else {
        return <img width={`${picSize}`} height={`${picSize}`} className="rounded-circle" alt={d.name}
            src="/images/avatar.svg" />;
    }
};

util.calculateCTCNew = ( data, settings ) => {
    if ( data.employment_type === "W2" || data.employment_type === "Fulltime" ) {
        return ( data.buy_rate * parseFloat( settings["te_w2"] ) ) / 1892;
    } else {
        return data.buy_rate * 1;
    }
};

util.calculateMargin = ( data, settings ) => {
    let ctc = util.calculateCTCNew( data, settings );
    let margin = ( ( data["talent_sell_rate"] - ctc ) / data["talent_sell_rate"] ) * 100;
    if ( margin === 100 ) {
        margin = 0.0;
    }
    if ( isNaN( margin ) || margin === -Infinity ) {
        return 0.0;
    } else {
        return margin;
    }
};

util.marginColors = ( margin ) => {
    let colorObject;
    if ( margin === 0 ) {
        colorObject = "marginDefault";
    } else if ( margin >= 20.0 ) {
        colorObject = "marginDarkGreen";
    } else if ( margin >= 15.0 && margin < 20.0 ) {
        colorObject = "marginLightGreen";
    } else if ( margin >= 10.0 && margin < 15.0 ) {
        colorObject = "marginYellow";
    } else {
        colorObject = "marginRed";
    }
    return <div className={`${colorObject} pl-2 pr-2`}>% {margin ? margin.toFixed( 2 ) : 0.00}</div>;
};

util.calculateCTC = ( data ) => {
    let ctc = parseFloat( data.ctc );
    switch ( data.salary_type ) {
        case "W2":
            if ( data.salary_mode === "Annually" ) {
                let a = ( ( 1.3 * ctc ) / 2080 ).toString();
                let temp = a.split( "." );
                if ( temp.length > 1 ) {
                    return temp[0] + "." + temp[1].slice( 0, 2 );
                } else {
                    return temp[0] + ".00";
                }
            } else {
                return ( 1.3 * ctc ); //Hour
            }
        case "1099":
            if ( data.salary_mode === "Annually" ) {
                let a = ( ctc / 2080 ).toString();
                let temp = a.split( "." );
                return temp[0] + "." + temp[1].slice( 0, 2 );
            } else {
                //Hour
                return ctc;
            }
        case "Fulltime":
            if ( data.salary_mode === "Annually" ) {
                let a = ( ( 1.3 * ctc ) / 2080 ).toString();
                let temp = a.split( "." );
                if ( temp.length > 1 ) {
                    return temp[0] + "." + temp[1].slice( 0, 2 );
                } else {
                    return temp[0] + ".00";
                }
            } else {
                return ( 1.3 * ctc ); //Hour
            }
        case "Contractor":
            if ( data.salary_mode === "Annually" ) {
                let a = ( ctc / 2080 ).toString();
                let temp = a.split( "." );
                return temp[0] + "." + temp[1].slice( 0, 2 );
            } else {
                //Hour
                return ctc;
            }
        default:
            return "0.0";
    }
};

util.formatPhone = ( phone ) => {
    if ( phone === null ) return "";
    let length = phone.length;
    if ( length > 10 ) {
        switch ( length ) {
            case 11:
                return "+" + phone.slice( 0, 1 ) + " (" + phone.slice( 1, 4 ) + ") " + phone.slice( 4, 7 ) + " " + phone.slice( 7, 11 );
            case 12:
                return "+" + phone.slice( 0, 2 ) + " (" + phone.slice( 2, 5 ) + ") " + phone.slice( 5, 8 ) + " " + phone.slice( 8, 12 );
            case 13:
                return "+" + phone.slice( 0, 2 ) + " (" + phone.slice( 2, 5 ) + ") " + phone.slice( 5, 8 ) + " " + phone.slice( 8, 12 );
            default:
                break;
        }
    } else {
        return phone;
    }

};

util.urlHandlerCandidates = ( query ) => {
    let orderby = ( query.orderBy ) ? query.orderBy.field : "name";
    let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
    let search = ( query.search === "" ) ? "" : query.search;
    return `${util.url}/candidates/${query.page}/${query.pageSize}?orderby=${orderby}&direction=${direction}&search=${search}`;

};

util.urlHandlerJobPositions = ( query ) => {
    let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
    let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
    let search = ( query.search === "" ) ? "" : query.search;
    return `${util.url}/requirements/list_of_requirements/${query.page}/${query.pageSize}?page=${query.page}&rows=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}`;

};

util.urlHandlerJobPositions2 = ( query ) => {
    let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
    let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
    let search = ( query.search === "" ) ? "" : query.search;
    return `${util.url}/jobs/list?page=${query.page}&rows=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}`;
};

util.urlJobPositionsTalent = ( query ) => {

    let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
    let search = ( query.search === "" ) ? "" : query.search;


    let q = `page=${query.page}
  &limit=${query.pageSize ? query.pageSize : 15}
&job_position=${search}
&orderby=${orderby}`;


    return `${util.url}/jobs/position?${q}`;
};

util.urlHandlerUsers = ( query, role, opc = false ) => {
    let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
    let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
    let search = ( query.search === "" ) ? "" : query.search;
    let options = ( opc ) ? `&opt=${opc}` : "";
    return `${util.url}/users/list?page=${query.page}&limit=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}&role=${role}${options}`;

};

util.roleToString = ( role ) => {
    let roleInt = parseInt( role );
    switch ( roleInt ) {
        case 1:
            return "Recruiter";
        case 2:
            return "Talent";
        case 3:
            return "Client";
        case 4:
            return "Partner";
        case 7:
            return "Employee";
        case 10:
            return "Admin";
        case 15:
            return "Vendor";
        default:
            break;
    }
};

util.SecondaryRoleList = ( data ) => {
    let list = [];
    list.push( data.permissions.recruiter === 1 ? "Recruiter" : "" );
    list.push( data.permissions.recruiter_manager === 1 ? "Recruiter Manager" : "" );
    list.push( data.permissions.talent === 1 ? "Talent" : "" );
    list.push( data.permissions.client === 1 ? "Client" : "" );
    list.push( data.permissions.partner === 1 ? "Partner" : "" );
    list.push( data.permissions.employee === 1 ? "Employee" : "" );
    list.push( data.permissions.employee_manager === 1 ? "Employee manager" : "" );
    list.push( data.permissions.vendor === 1 ? "Vendor" : "" );
    return list.filter( e => e );
    // return result.join( ", " );
};

util.stopProcessMotives = ( value ) => {
    let valueInt = parseInt( value );
    switch ( valueInt ) {
        case 1:
            return "Candidate No Longer Available";
        case 2:
            return "Candidate Not Cleared a client Round";
        case 3:
            return "Candidate Not Cleared an internal Round";
        case 4:
            return "Candidate BGC not cleared";
        case 5:
            return "Candidate no longer available";
        default:
            break;
    }
};

util.handleColumnsByRole = ( field ) => {
    let admin = [
        'editOptions',
        'buy_from',
        'buy_rate',
        'buy_to',
        'candidates_added',
        'certification',
        'city',
        'client_id',
        'client_requirement_id',
        'clientEmail',
        'company',
        'country',
        'createdAt',
        'deal_type',
        'duration',
        'education',
        'employment_type',
        'endClientName',
        'id',
        'job_description',
        'job_title',
        'lead_recruiter',
        'level',
        'margin',
        'owner_office',
        'partner_id',
        'personName',
        'primary_skill',
        'priority',
        'reporting_manager',
        'reporting_manager_address',
        'reporting_manager_phone',
        'restricted_to',
        'role',
        'sales_lead',
        'scope',
        'secondary_recruiter',
        'secondary_skill',
        'sell_rate',
        'state',
        'status',
        'zipCode',
    ];
    const talent = [
        'city',
        'country',
        'createdAt',
        'id',
        'job_description',
        'job_title',
        'restricted_to',
        'state',
    ];
    const partnerAndClient = [
        'id',
        'personName',
        'endClientName',
        'job_title',
        'sell_rate',
        'status',
        'country',
        'company',
        'city',
        'state',
        'createdAt',
    ];
    let result = true;
    switch ( util.user.role ) {
        case 1:
            admin.forEach( ( col ) => {
                if ( col === field ) {
                    result = false;
                }
            } );
            break;
        case 2:
            talent.forEach( ( col ) => {
                if ( col === field ) {
                    result = false;
                }
            } );
            break;
        case 3:
            partnerAndClient.forEach( ( col ) => {
                if ( col === field ) {
                    result = false;
                }
            } );
            break;
        case 4:
            partnerAndClient.forEach( ( col ) => {
                if ( col === field ) {
                    result = false;
                }
            } );
            break;
        default:
            result = false;
            break;
    }

    return result;

};

util.resumeUrl = ( d ) => {
    // retrieves the url formed to the candidate resume
    return BASE_URL + AWS_URL + "/candidate/" + d.candidate_id + "/" + d.resume;
};

util.talentStatus = ( status, status_note = null, customStatus = null ) => {
    // First, handle the customStatus if it exists and is valid
    if ( customStatus && customStatus.length > 0 && customStatus[0].name ) {
        return <Badge pill variant="primary">{customStatus[0].name}</Badge>;
    }

    // If no valid customStatus, fall back to handling with status
    switch ( status ) {
        case 1:
            return <Badge pill variant="primary">Invited to position</Badge>;
        case 2:
            return <Badge pill variant="primary">Application sent</Badge>;
        case 3:
            return <Badge pill variant="info">First Round</Badge>;
        case 4:
            return <Badge pill variant="secondary">Evaluations</Badge>;
        case 5:
            return <Badge pill variant="info">Onboarding Documents</Badge>;
        case 6:
            return <Badge pill variant="warning">Background check</Badge>;
        case 7:
            return <Badge pill variant="success">Waiting Client Decision</Badge>;
        case 19:
            return <Tooltip title={status_note || ""}>
                <Badge pill variant="danger">Not available</Badge>
            </Tooltip>;
        case 11:
            return <Badge pill variant="danger">Rejected</Badge>;
        case 12:
            return <Badge pill variant="success">Accepted</Badge>;
        case 13:
            return <Badge pill variant="primary">Onboarded</Badge>;
        case 20:
            return <Badge pill variant="primary">TO Received</Badge>;
        case 21:
            return <Badge pill variant="primary">Offer Released</Badge>;
        case 22:
            return <Badge pill variant="primary">Submitted</Badge>;
        default:
            return <Badge pill variant="warning">Unknown</Badge>;
    }
};


util.themeEvaluation = createTheme( {
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: "#007bff",
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#ff0000',
        },
        error: {
            // This is green.A700 as hex.
            main: '#ff0000',
        }
    },
} );

util.defaultTableTheme = createTheme( {
    palette: {
        primary: {
            main: '#2196F3',
        },
        secondary: {
            main: '#2196F3',
        }
    },
    overrides: {

        MuiTableCell: {
            root: {
                padding: 3,
                fontSize: 13,
                backgroundColor: "transparent"
            },
            head: {
                // textAlign:"center!important",
                textDecoration: "none",
                lineHeight: 1,
                backgroundColor: "#DFE1E0!important",
                color: "black"
            },
            body: {
                fontSize: "12px!important"
            }
        }
    }
} );

util.defaultTableThemeSlim = createTheme( {
    palette: {
        primary: {
            main: '#2196F3',
        },
        secondary: {
            main: '#2196F3',
        }
    },
    overrides: {

        MuiTableCell: {
            root: {
                padding: 2,
                fontSize: 12,
                backgroundColor: "transparent"
            },
            head: {
                textAlign: "center!important",
                textDecoration: "none",
                lineHeight: 1,
                backgroundColor: "#DFE1E0!important",
                color: "black",
            },
            body: {
                fontSize: "11px!important"
            }
        }
    }
} );

util.statusButton = ( status ) => {
    switch ( status ) {
        case 1:
            return "Hold";
        case 2:
            return "Active";
        case 3:
            return "Closed by Client";
        case 4:
            return "Filled";
        default:
            return "Unknown";
    }
};

util.confirmationAlert = ( { showCancel = false, confirmText = null, show = false, msg = null, hideAlert, onConfirm, typeButton = "danger", title = "" } ) => {
    return (
        <SweetAlert
            show={show}
            warning
            showCancel={showCancel ? showCancel : true}
            confirmBtnText={confirmText || "YES"}
            confirmBtnBsStyle={typeButton || "danger"}
            title={title ? title : "Are you sure?"}
            onConfirm={onConfirm}
            onCancel={hideAlert}
            focusCancelBtn
        >
            {msg}
        </SweetAlert>
    );
};

util.statusJP = ( status ) => {
    switch ( status ) {
        case 1:
            return "Active";
        case 2:
            return "Hold";
        case 3:
            return "Closed by Client";
        case 4:
            return "Filled";
        default:
            return "Unknown";
    }
};

util.statusDictionary = ( status ) => {
    /**
     * status
     * 1 = open
     * 2 = Hold
     * 3 = cancelled by client
     * */
    switch ( status ) {
        case 1:
            return <span className="status_font">{<FontAwesomeIcon icon={faCircle}
                className={"text-success"} />} Active</span>;
        case 2:
            return <span className="status_font">{<FontAwesomeIcon icon={faCircle}
                className={"text-warning"} />} Hold</span>;
        case 3:
            return <span className="status_font">{<FontAwesomeIcon icon={faCircle}
                className={"text-danger"} />} Closed</span>;
        case 4:
            return <span className="status_font">{<FontAwesomeIcon icon={faCircle}
                className={"text-info"} />} Filled</span>;
        default:
            return status;
    }
};

util.jobStatusOnlyIcons = ( status, showTitle = true, reasons = null ) => {

    const toolTipsMessages = {
        new: { status: 0, color: "gold", tooltip: "New Job request received from the Client and waiting for recruiter to start working on it. Can be used for eJ Hold.", icon: faStar, title: "New" },
        active: { status: 1, color: "green", tooltip: "Active Job assigned to a Recruiter and work in progress.", icon: faPlayCircle, title: "Active" },
        hold: { status: 2, color: "#d4cf05", tooltip: "Client Kept this job on Hold", icon: faClock, title: "Hold" },
        closed: { status: 3, color: "red", tooltip: "Client Closed or cancelled the Job", icon: faWindowClose, title: "Closed" },
        filled: { status: 4, color: "#17a2b8", tooltip: "eJ Filled the Job with the Talent(s) fo the Client.", icon: faCheckCircle, title: "Filled" },
        hot: { status: 5, color: "tomato", tooltip: "Active and Exclusive Job for eJ", icon: faFire, title: "Hot" },
        dropped: {
            status: 6, color: "#5c5c5c", tooltip: "Dropped or Lost", icon: faBan, title: "Dropped", reasons: [
                { text: "reason 1" },
                { text: "reason 2" },
                { text: "reason 3" },
                { text: "reason 4" },
            ]
        },
    };


    const IconStatus = ( { data, reasons } ) => {
        const { tooltip, color, icon, title } = data;

        let rsons = [];
        if ( reasons ) {
            reasons.map( r => rsons.push( r?.title ) );
        }
        let extendedToolTip = rsons.length === 0 ? tooltip : tooltip + " - REASONS: " + rsons.join( ", " );
        return (
            <Tooltip title={extendedToolTip}>
                <span>
                    <FontAwesomeIcon
                        color={color}
                        className="mr-2"
                        icon={icon} />
                    {showTitle ? title : ""}
                </span>
            </Tooltip>
        );
    };

    switch ( parseInt( status ) ) {
        case 0: return <IconStatus data={toolTipsMessages.new} />;
        case 1: return <IconStatus data={toolTipsMessages.active} />;
        case 2: return <IconStatus data={toolTipsMessages.hold} />;
        case 3: return <IconStatus data={toolTipsMessages.closed} />;
        case 4: return <IconStatus data={toolTipsMessages.filled} />;
        case 5: return <IconStatus data={toolTipsMessages.hot} />;
        case 6: return <IconStatus data={toolTipsMessages.dropped} reasons={reasons} />;
        default: return "";
    }
};

util.jobStatusOnlyText = ( status ) => {

    const toolTipsMessages = {
        new: { status: 0, color: "gold", tooltip: "New Job request received from the Client and waiting for recruiter to start working on it. Can be used for eJ Hold.", icon: faStar, title: "New" },
        active: { status: 1, color: "green", tooltip: "Active Job assigned to a Recruiter and work in progress.", icon: faPlayCircle, title: "Active" },
        hold: { status: 2, color: "#d4cf05", tooltip: "Client Kept this job on Hold", icon: faClock, title: "Hold" },
        closed: { status: 3, color: "red", tooltip: "Client Closed or cancelled the Job", icon: faWindowClose, title: "Closed" },
        filled: { status: 4, color: "#17a2b8", tooltip: "eJ Filled the Job with the Talent(s) fo the Client.", icon: faCheckCircle, title: "Filled" },
        hot: { status: 5, color: "tomato", tooltip: "Active and Exclusive Job for eJ", icon: faFire, title: "Hot" },
        dropped: { status: 6, color: "#5c5c5c", tooltip: "Dropped or Lost", icon: faBan, title: "Dropped" },
    };

    switch ( parseInt( status ) ) {
        case 0: return toolTipsMessages.new.title;
        case 1: return toolTipsMessages.active.title;
        case 2: return toolTipsMessages.hold.title;
        case 3: return toolTipsMessages.closed.title;
        case 4: return toolTipsMessages.filled.title;
        case 5: return toolTipsMessages.hot.title;
        case 6: return toolTipsMessages.dropped.title;
    }
};

util.statusDictionary2 = ( status ) => {
    /**
     * status
     * 1 = open
     * 2 = Hold
     * 3 = cancelled by client
     * */
    switch ( status ) {
        case 1:
            return <Tooltip title="Active"><span className="status_font">{<FontAwesomeIcon icon={faCircle}
                className={"text-success"} />}</span></Tooltip>;
        case 2:
            return <Tooltip title="Hold"><span className="status_font">{<FontAwesomeIcon icon={faCircle}
                className={"text-warning"} />}</span></Tooltip>;
        case 3:
            return <Tooltip title="Closed"><span className="status_font">{<FontAwesomeIcon icon={faCircle}
                className={"text-danger"} />}</span></Tooltip>;
        case 4:
            return <Tooltip title="Filled"><span className="status_font">{<FontAwesomeIcon icon={faCircle}
                className={"text-info"} />}</span></Tooltip>;
        default:
            return status;
    }
};

util.statusEvaluation = ( status ) => {
    /**
     * status
     * 1 = open
     * 2 = Hold
     * 3 = cancelled by client
     * */
    if ( status === "" ) return "Unknown status";
    switch ( parseInt( status ) ) {
        case 1:
            return <Tooltip title="Cleared Interview"><span className="status_font">{<FontAwesomeIcon
                icon={faCheckCircle} className={"text-success"} />}</span></Tooltip>;
        case 2:
            return <Tooltip title="Failed Interview"><span className="status_font">{<FontAwesomeIcon
                icon={faTimesCircle} className={"text-danger"} />}</span></Tooltip>;
        case 3:
            return <Tooltip title="On Hold"><span className="status_font">{<FontAwesomeIcon icon={faPauseCircle}
                className={"text-warning"} />}</span></Tooltip>;
        default:
            return <Tooltip title="Waiting for evaluation"><span className="status_font">{<FontAwesomeIcon
                icon={faClock} className={"text-info"} />}</span></Tooltip>;
    }
};

// This method will return the status of the EVALUATION
util.statusEvaluationSkillEvaluationResult = ( status ) => {
    if ( status === "" ) return "Unknown status";
    switch ( parseInt( status ) ) {
        case 1:
            return <Tooltip title="Selected At Interview Level"><span className="status_font">{<FontAwesomeIcon icon={faCheckDouble} className={"text-success"} />}</span></Tooltip>;
        case 2:
            return <Tooltip title="Rejected At Interview Level"><span className="status_font">{<FontAwesomeIcon icon={faTimesCircle} className={"text-danger"} />}</span></Tooltip>;
        case 3:
            return <Tooltip title="On Hold"><span className="status_font">{<FontAwesomeIcon icon={faPauseCircle} className={"text-warning"} />}</span></Tooltip>;
        case 4:
            return <Tooltip title="Selected At Resume Level"><span className="status_font">{<FontAwesomeIcon icon={faCheck} className={"text-success"} />}</span></Tooltip>;
        case 5:
            return <Tooltip title="Rejected  At Resume Level"><span className="status_font">{<FontAwesomeIcon icon={faTimes} className={"text-danger"} />}</span></Tooltip>;
        default:
            return <Tooltip title="Waiting for evaluation"><span className="status_font">{<FontAwesomeIcon icon={faClock} className={"text-info"} />}</span></Tooltip>;
    }
};

util.nameToInitials = ( d ) => {
    let letters;
    if ( d["created_by"] === null || d["created_by"] === "" ) {
        letters = "--";
    } else {
        let name = d["created_by"].split( " " );
        if ( name.length === 1 ) {
            letters = name[0][0] + name[0][1];
        } else {
            letters = name[0][0] + name[1][0];
        }
    }
    return <Tooltip title={d["created_by"] || "Unknown"}>
        <div className="text-center"><span data-letters={letters} /></div>
    </Tooltip>;
};

util.nameToInitials = ( d ) => {
    let letters;
    if ( d["created_by"] === null || d["created_by"] === "" ) {
        letters = "--";
    } else {
        let name = d["created_by"].split( " " );
        if ( name.length === 1 ) {
            letters = name[0][0] + name[0][1];
        } else {
            letters = name[0][0] + name[1][0];
        }
    }
    return <Tooltip title={d["created_by"] || "Unknown"}>
        <div className="text-center"><span data-letters={letters} /></div>
    </Tooltip>;
};

util.RowAutocomplete = ( { cols = 3, options = [], value = [], onChange = null, field = null, label = "", mulitple = true, disabled = false } ) => {

    return (
        <div className={`col-md-${cols} p-1 mt-2`}>
            <Autocomplete
                multiple={mulitple}
                size="small"
                disabled={disabled}
                options={options}
                value={value}
                limitTags={3}
                onChange={onChange}
                getOptionLabel={( option ) => option[field] || ""}
                renderInput={( params ) => (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        label={label}
                    />
                )}
            />
        </div>
    );
};
util.RowSelector = ( { cols = 3, options = [], value = [], onChange = null, field = null, label = "", mulitple = true, disabled = false } ) => {

    return (
        <div className={`col-md-${cols} p-1 mt-2`}>
            <select
                multiple={mulitple}
                disabled={disabled}
                className="form-control"
                options={options}
                value={value}
                limitTags={3}
                onChange={onChange}
            >
                {
                    options.map( ( o, i ) => {
                        return <option key={i} value={o[field]}>{o[field]}</option>;
                    } )
                }
            </select>
        </div>
    );
};

util.RowTextField = ( { cols = 3, value = "", type = null, label = "", icon = null, field = null, onChange = null, disabled = false } ) => {
    return (
        <div className={`col-md-${cols} p-1 mt-2`}>
            <TextField
                size={"small"}
                InputLabelProps={{ shrink: true }}
                value={value}
                type={type ? type : "text"}
                label={label}
                fullWidth={true}
                disabled={disabled}
                InputProps={{
                    startAdornment: icon ? (
                        <FontAwesomeIcon icon={icon} className="mr-2" />
                    ) : (
                        ""
                    )
                }}
                variant="standard"
                name={field}
                onChange={onChange}
            />
        </div>
    );
};

util.RowComponent = ( props ) => {
    if ( props.type === undefined ) {
        return (
            <Fragment>
                <div className={`col-md-${props.c} p-1 mt-2 align-self-center`}>
                    <TextField
                        size={"small"}
                        multiline={props.mul !== undefined}
                        // rows={props.mul !== undefined ? props.rows: 1}
                        className="w-100"
                        disabled={props.disabled || false}
                        InputLabelProps={{ shrink: true }}
                        value={props.v}
                        type={props.ty ? props.ty : ""}
                        label={props.t}
                        fullWidth={true}
                        placeholder={props.placeholder ? props.placeholder : ""}
                        InputProps={{
                            startAdornment: props.ic ? (
                                <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                            ) : (
                                ""
                            )
                        }}
                        variant="outlined"
                        name={props.n}
                        onChange={props.m ? props.m : null}
                        onBlur={props.onBlur ? props.onBlur : null}
                    />
                </div>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <div className={`col-md-${props.c} p-1 mt-2 align-self-center`}>
                    <TextField
                        id="standard-select-currency-native"
                        select
                        size={"small"}
                        variant="outlined"
                        disabled={props.disabled || false}
                        name={props.n}
                        className="w-100"
                        label={props.t}
                        value={props.v || "None"}
                        onChange={props.m}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                            native: true
                        }}
                        InputProps={{
                            startAdornment: props.icon ? (
                                <FontAwesomeIcon icon={props.icon} className="mr-2" />
                            ) : (
                                ""
                            )
                        }}
                    >
                        {/*<option value=""/>*/}
                        {props.d.map( option => (
                            <option key={Math.random() * Math.random()} value={option[props.n]}>
                                {option[props.n]}
                            </option>
                        ) )}
                    </TextField>
                </div>
            </Fragment>
        );
    }
};

util.validateEmail = ( email ) => {
    // eslint-disable-next-line
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test( String( email ).toLowerCase() );
};

util.employment_type = [
    { employment_type: "Fulltime" },
    { employment_type: "C2C" },
    { employment_type: "Contractor" }
];

util.BoxSimple = ( props ) => {
    const { name, email, company, phone1, phone2 } = props.data;
    const option = ( props.type === 1 ) ? 1 : 2;
    return <Fragment>
        <div className="textSmall">
            <div>{name ? name : ""} {props.disabled ? "" :
                <span className="btn btn-default" onClick={() => props.editInfo( option )}><FontAwesomeIcon
                    icon={faPencilAlt} /></span>}</div>
            {email ? <div className="text-primary"
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{email}</div> : ""}
            {company ? <div>{company}</div> : ""}
            {phone1 ? <div>{phone1}</div> : ""}
            {phone2 ? <div>{phone2}</div> : ""}
        </div>
    </Fragment>;
};

util.zeroPad = ( num, places ) => {
    return String( num ).padStart( places, "0" );
};

util.suspenseDots = ( string ) => {
    if ( string ) {
        if ( string.length > 20 ) {
            return (
                <Tooltip title={string}>
                    <span>
                        {string.substring( 0, 20 ) + "..."}
                    </span>
                </Tooltip>
            );
        } else {
            return string;
        }

    } else {
        return "";
    }
};

util.suspenseDotPopOver = ( string, search ) => {
    if ( string !== "" ) {
        if ( string?.length > 20 ) {
            const skills = string.split( "," );
            const popover = (
                <Popover id="popover-basic">
                    <Popover.Title className="titlePopoverH3" as="h3">Skills</Popover.Title>
                    <Popover.Content>
                        <div className={skills.length > 15 ? "popOverScrollable2" : "popOverScrollable1"}>
                            <ul>
                                {skills.map( ( s, i ) => {
                                    if ( !util.noValues.includes( search ) ) {
                                        if ( s.toLowerCase().includes( search.toLowerCase() ) ) {
                                            return <li key={i}><b><u>{s}</u></b></li>;
                                        } else {
                                            return <li key={i}>{s}</li>;
                                        }
                                    } else {
                                        return <li key={i}>{s}</li>;
                                    }
                                } )}
                            </ul>
                        </div>
                    </Popover.Content>
                </Popover>
            );

            return (
                <OverlayTrigger
                    rootClose={true}
                    rootCloseEvent='click'
                    trigger={['hover', 'focus']}
                    placement="right"
                    overlay={popover}
                >
                    <div>
                        {string.substring( 0, 20 ) + "..."}
                    </div>
                </OverlayTrigger>
            );
        } else {
            return string;
        }

    } else {
        return "";
    }
};

function createMarkup( job_description ) {
    return { __html: job_description };
}

util.suspenseDotPopOverJobDescription = ( row, search ) => {

    if ( row !== "" ) {
        if ( row.job_description?.length > 20 ) {
            const popover = (
                <Popover id="popover-basic" style={{ width: '32%', marginLeft: '15px' }}>
                    <Popover.Title className="titlePopoverH3 " as="h3">Job Description</Popover.Title>
                    <Popover.Content className="scrollableContent" style={{ marginLeft: '2rem' }}>
                        <div className="popOverScrollable1">
                            <ul className="ml-3">
                                <li>
                                    {
                                        <div dangerouslySetInnerHTML={createMarkup( row.job_description )}></div>
                                    }
                                </li>
                            </ul>
                        </div>
                    </Popover.Content>
                </Popover>

            );

            return (
                <OverlayTrigger
                    rootClose={true}
                    rootCloseEvent='click'
                    trigger='click'
                    placement="right"
                    overlay={popover}
                >
                    <FontAwesomeIcon icon={faEye} className="iconGray isCursor" />

                </OverlayTrigger>
            );
        } else {
            return "";
        }

    } else {
        return "";
    }
};


util.suspenseDotPopOverLastJob = ( string, search ) => {
    if ( string !== "" ) {
        if ( string?.length > 20 ) {
            const skills = string.split( "," );
            const popover = (
                <Popover id="popover-basic">
                    <Popover.Title className="titlePopoverH3" as="h3">Last Job Positions</Popover.Title>
                    <Popover.Content>
                        <div className={skills.length > 15 ? "popOverScrollable2" : "popOverScrollable1"}>
                            <ol>
                                {skills.map( ( s, i ) => {
                                    if ( !util.noValues.includes( search ) ) {
                                        if ( s.trim() === "" ) return null;
                                        if ( s.toLowerCase().includes( search.toLowerCase() ) ) {
                                            return <li key={i}><b><u>{s}</u></b></li>;
                                        } else {
                                            return <li key={i}>{s}</li>;
                                        }
                                    } else {
                                        return <li key={i}>{s}</li>;
                                    }
                                } )}
                            </ol>
                        </div>
                    </Popover.Content>
                </Popover>
            );

            return (
                <OverlayTrigger
                    rootClose={true}
                    rootCloseEvent='click'
                    trigger={['hover', 'focus']}
                    placement="right"
                    overlay={popover}
                >
                    <div>
                        {string.substring( 0, 20 ) + "..."}
                    </div>
                </OverlayTrigger>
            );
        } else {
            return string;
        }

    } else {
        return "";
    }
};

util.extractOnePositionTitle = ( string ) => {
    if ( string ) {
        let t = string.split( "," );
        if ( t.length > 1 ) {
            if ( t[0].length > 20 ) {
                return (
                    <Tooltip title={t[0]}>
                        <span>
                            {t[0].substring( 0, 20 ) + "..."}
                        </span>
                    </Tooltip>
                );
            } else {
                return t[0];
            }
        } else {
            return string;
        }
    } else {
        return "";
    }
};

util.SkillForm = ( props ) => {

    let { skill, yearsOfExperience } = props.skill;

    return (
        <tr>
            <td>
                <TextField
                    className="w-100"
                    label="Skill"
                    type="text"
                    value={skill ? skill : ""}
                    name="skill"
                    onChange={( e ) => props.onSkillChange( e, props.index )}
                />
            </td>
            <td>
                <TextField
                    label={"Yrs. of Exp."}
                    type={"number"}
                    aria-valuemin={0}
                    aria-valuemax={40}
                    value={yearsOfExperience | 0}
                    name={"yearsOfExperience"}
                    onChange={( e ) => props.onSkillChange( e, props.index )} />
            </td>
            <td>
                <button onClick={() => props.removeSkill( props.index )} className="btn btn-sm btn-light">
                    <FontAwesomeIcon icon={faTrash} /></button>
            </td>
        </tr>
    );
};
util.skillTalentForm = ( props ) => {
    return (
        <>
            <div>
                <>
                    <table>
                        {props.selectedSkills.length > 0 ?
                            <div className="row mt-2">
                                <span className="text-secondary col-6 ml-4">Skill</span>
                                <span className="text-secondary col-4 ml-3">Yrs. of Exp.</span>
                            </div> : ""}

                        <div>
                            {props.selectedSkills.map( ( skill, index ) => (

                                <div key={index} className="row mt-2 ml-3 w-100">
                                    <Card className="col-7">{skill.name}</Card>
                                    <Card className="col-2 ml-2 p-0" style={{ width: '90%' }}><input
                                        // label={"Yrs. of Exp."}
                                        style={{ border: 'none', outline: 'none', width: '100%', textAlign: 'center' }}
                                        type={"number"}
                                        aria-valuemin={0}
                                        aria-valuemax={40}
                                        value={skill.yearsOfExperience | 0}
                                        name={"yearsOfExperience"}
                                        onChange={( e ) => props.onSkillChange( e, index )} /></Card>
                                    <div className="col-1">
                                        <button onClick={() => props.removeSkill( props.index )} className="btn btn-sm removeItem">
                                            <FontAwesomeIcon icon={faTrash} /></button>
                                    </div>
                                </div>
                            ) )}

                        </div>
                    </table></>

            </div>
            <div className="col-md-12 mt-3">
                <TextField fullWidth size='small' label="Search Skill" variant="outlined" type="text" value={props.skillName} onChange={props.handleChange} />
                {props.selectedSkills.length > 0 ? <span className="text-secondary isCursor float-right ml-5" onClick={props.handleReset}>Reset</span> : ""}


            </div>
            <ul className="skillsSelectorList ml-3 mt-2">
                {props.skillsList.length ? props.skillsList.map( el => (
                    <li
                        className="skillsSelectorList-item"
                        key={el.id}
                    >
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => props.handleAddSkill( el )}
                                checked={props.selectedSkills.some( ( item ) => item.id === el.id )}
                            />
                            <span className="ml-1">{el.name}</span>
                        </label>
                    </li>
                ) ) : ''}
            </ul>
        </>
    );
};
util.skillTalentForm2 = ( props ) => {
    return (
        <>
            <div>
                <>

                    {/* {props.selectedSkills.length > 0 ?
                        <div className="row mt-2">
                            <span className="text-secondary col-6 ml-4">Skill</span>
                            <span className="text-secondary col-4 ml-3">Yrs. of Exp.</span>
                        </div> : ""} */}

                    <div className="skill-array">
                        {props.selectedSkills.map( ( skill, index ) => (

                            <div key={index} className="skill-value">
                                <span>{skill.name}</span>
                                <span className="resume-cross" onClick={props.handleRemoveSkill}>x</span>
                                {/* <Card  className="col-2 ml-2 p-0" style={{ width: '90%' }}><input
                                    // label={"Yrs. of Exp."}
                                    style={{ border:'none' ,outline: 'none',width:'100%' ,textAlign:'center' }}
                                    type={"number"}
                                    aria-valuemin={0}
                                    aria-valuemax={40}
                                    value={skill.yearsOfExperience | 0}
                                    name={"yearsOfExperience"}
                                    onChange={( e ) => props.onSkillChange( e,index )}/></Card> */}

                                {/* <button onClick={() => props.removeSkill( props.index )} className="btn btn-sm removeItem">
                                        <FontAwesomeIcon icon={faTrash}/></button> */}

                            </div>
                        ) )}

                    </div>
                </>

            </div>
            {/* <div className="col-md-12 mt-3">
                <TextField fullWidth size='small'  label="Search Skill" variant="outlined" type="text" value={props.skillName} onChange={props.handleChange}/>
                {props.selectedSkills.length > 0 ?<span className="text-secondary isCursor float-right ml-5" onClick={props.handleReset}>Reset</span>:""}
                
    
            </div>
            <ul className="skillsSelectorList ml-3 mt-2">
                {props.skillsList.length ? props.skillsList.map( el => (
                    <li
                        className="skillsSelectorList-item"
                        key={el.id}
                    >
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => props.handleAddSkill( el )}
                                checked={props.selectedSkills.some( ( item ) => item.id === el.id )}
                            />
                            <span className="ml-1">{el.name}</span> 
                        </label>
                    </li>
                ) ) : ''}
            </ul> */}
        </>
    );
};

util.LOADING_SCREEN = ( loading ) => {
    return <Backdrop open={loading ? loading : false} style={{ zIndex: 50000 }} addEndListener={null}>
        <div className="text-center"><CircularProgress /></div>
    </Backdrop>;
};

util.whatsapp = ( tel, msg ) => {
    // https://web.whatsapp.com/send?phone=&text=
    return `https://web.whatsapp.com/send?phone=${tel}&text=${encodeURI( msg )}`;
};

util.randomStringPassword = () => {
    return Math.random()
        .toString( 36 )
        .replace( /[^a-z]+/g, "" )
        .substr( 0, 12 );
};

util.parseOldJsonToNewJson = ( oldJSON ) => {
    let newJSON = {};

    function validatePhone( phone ) {
        let p = phone.replace( /\D/g, '' );
        switch ( p.length ) {
            case 10:
                switch ( util.user.country ) {
                    case 'United States':
                        return "1" + p;
                    case 'Australia':
                        return "61" + p;
                    case 'Mexico':
                        return "52" + p;
                    case 'India':
                        return "91" + p;
                    case 'Canada':
                        return "1" + p;
                    default:
                        return "1" + p;
                }
            default:
                break;
        }
        return p;
    }

    function firstLetterCap( string ) {
        if ( string !== "" ) {
            let s = string.toString().toLowerCase().split( " " );
            for ( let x = 0; x < s.length; x++ ) {
                s[x] = s[x][0].toUpperCase() + s[x].slice( 1 );
            }
            return s.join( " " );
        } else {
            return "";
        }
    }

    function getFullCountryByShort( shortCountry ) {
        for ( let x = 0; x < countryList.length; x++ ) {
            if ( country === countryList[x]["code2"] ) {
                return countryList[x];
            }
        }
    }

    function countryCodeToCountryName( countryCode ) {
        switch ( countryCode ) {
            case 'US':
                return "United States";
            case 'CA':
                return "Canada";
            case 'MX':
                return "Mexico";
            case 'IN':
                return "India";
            case 'AU':
                return "Australia";
            default:
                return 'AU';
        }
    }

    // Retrieve Personal information
    newJSON.name = oldJSON.FormattedName ? firstLetterCap( oldJSON.FormattedName ) : "";
    newJSON.email = oldJSON.Email && oldJSON.Email.length > 0 ? oldJSON.Email[0].Address.toString().toLowerCase() : "";
    newJSON.phone_mobile = oldJSON.Phone && oldJSON.Phone.length === 1 ? validatePhone( oldJSON.Phone[0].Number ) : "";
    newJSON.phone_home = oldJSON.Phone && oldJSON.Phone.length === 2 ? validatePhone( oldJSON.Phone[1].Number ) : "";
    newJSON.phone_other = oldJSON.Phone && oldJSON.Phone.length === 3 ? validatePhone( oldJSON.Phone[2].Number ) : "";
    newJSON.address = oldJSON.Address && oldJSON.Address.length > 0 ? oldJSON.Address[0]["AddressLine"] : "";
    newJSON.zip_code = oldJSON.Address && oldJSON.Address.length > 0 ? ( oldJSON.Address[0]["PostalCode"] ? oldJSON.Address[0]["PostalCode"].replace( /\D/g, '' ) : "" ) : "";
    newJSON.city = oldJSON.Address && oldJSON.Address.length > 0 ? oldJSON.Address[0].CityName : "";
    newJSON.state = "";
    // newJSON.country         = oldJSON.Address && oldJSON.Address.length > 0 ? countryCodeToCountryName(oldJSON.Address[0].CountryCode):""

    let country;
    if ( oldJSON.Address && oldJSON.Address.length > 0 && oldJSON.Address[0]["CountryCode"] ) {
        newJSON.country = getFullCountryByShort( oldJSON.Address[0]["CountryCode"]["CountryCode"] );
    } else {
        newJSON.country = util.user.country;
    }


    newJSON.salary_type = "";
    newJSON.ctc = "";

    switch ( util.user.country ) {
        case 'United States':
            newJSON.currency = "USD";
            break;
        case 'Australia':
            newJSON.currency = "AUS";
            break;
        case 'Mexico':
            newJSON.currency = "MXN";
            break;
        case 'India':
            newJSON.currency = "INR";
            break;
        case 'Canada':
            newJSON.currency = "CAD";
            break;
        default:
            newJSON.currency = "USD";
            break;
    }


    newJSON.salary_mode = "";
    newJSON.working_status = "";
    newJSON.working_status_other = "";
    newJSON.platform = "";

    // URLS
    let urls = [];
    // Website
    if ( oldJSON.Website && oldJSON.Website.length > 0 ) {
        oldJSON.Website.forEach( ( w ) => {
            urls.push( { title: "Website", url: w.Address } );
        } );
    }
    // LinkedIn
    if ( oldJSON.LinkedIn && oldJSON.LinkedIn.length > 0 ) {
        oldJSON.LinkedIn.forEach( ( w ) => {
            urls.push( { title: "LinkedIn", url: w.Address } );
        } );
    }
    // Facebook
    if ( oldJSON.Facebook && oldJSON.Facebook.length > 0 ) {
        oldJSON.Facebook.forEach( ( w ) => {
            urls.push( { title: "Facebook", url: w.Address } );
        } );
    }
    // Instagram
    if ( oldJSON.Instagram && oldJSON.Instagram.length > 0 ) {
        oldJSON.Instagram.forEach( ( w ) => {
            urls.push( { title: "Instagram", url: w.Address } );
        } );
    }
    // Github
    if ( oldJSON.Github && oldJSON.Github.length > 0 ) {
        oldJSON.Github.forEach( ( w ) => {
            urls.push( { title: "Github", url: w.Address } );
        } );
    }
    // Skype
    if ( oldJSON.Skype && oldJSON.Skype.length > 0 ) {
        oldJSON.Skype.forEach( ( w ) => {
            urls.push( { title: "Skype", url: w.Address } );
        } );
    }
    newJSON.urls = urls;

    // Parse the Skills
    let skills = [];
    if ( oldJSON.PersonCompetency && oldJSON.PersonCompetency.length > 0 ) {
        oldJSON.PersonCompetency.forEach( skill => {
            let sk = {
                skill_name: skill.CompetencyName,
                years_experience: 0,
                skill_type: 2
            };
            skills.push( sk );
        } );
    }
    // Join skills to newJSON
    newJSON.skills = skills;

    // Work History
    let positionHistory = [];
    if ( oldJSON.PositionHistory && oldJSON.PositionHistory.length > 0 ) {
        oldJSON.PositionHistory.forEach( job => {

            // Try to get the country and city
            let country;
            if ( job.ReferenceLocation ) {
                country = job.ReferenceLocation.CountryCode ?
                    job.ReferenceLocation.CountryCode
                    :
                    countryList.slice().filter( f => f.code2 === util.user.country )[0];
            }
            let city = job?.ReferenceLocation?.CityName;
            let c = countryList.filter( f => f.code2 === country )[0];
            if ( c ) {
                // console.log(country)
                // console.log(c)
                country = c.name;
            } else {
                // No country found
                country = "";
            }

            let work = {
                company: job.Employer,
                position: job.PositionTitle,
                desc: job.Description,
                from: job.StartDate,
                to: job.EndDate,
                location: job.ReferenceLocation ? countryCodeToCountryName( job.ReferenceLocation.CountryCode ) : "",
                country: country,
                state: "",
                city: city,
                actual_job: job.CurrentIndicator ? 1 : 0,
            };
            positionHistory.push( work );
        } );
    }
    // Sort wh by date
    positionHistory = positionHistory.sort( ( a, b ) => new Date( b.from ) - new Date( a.from ) );

    // Join education to newJSON
    newJSON.workHistory = positionHistory;
    return newJSON;
};

// util.symbolDependingCurrencySelected = ( country ) => {

//     const currencySymbols = [
//         { country: "United States", symbol: "$" },
//         { country: "Mexico", symbol: "MX$" },
//         { country: "Canada", symbol: "CA$" },
//         { country: "Australia", symbol: "AU$" },
//         { country: "India", symbol: "₹" },
//         { country: "Japan", symbol: "¥" },
//         { country: "Poland", symbol: "zł" }

//     ];

//     for ( let x = 0; x < currencySymbols.length; x++ ) {
//         const c = currencySymbols[x];
//         if ( c.country === country ) {
//             return c.symbol;
//         }
//     }
//     return `$`;
// };
util.symbolDependingCurrencySelected = ( country ) => {
    for ( let x = 0; x < CountryInfo.length; x++ ) {
        const c = CountryInfo[x];
        if ( c.name === country ) {
            return c.currencySymbol;
        }
    }
    return `$`;
};

util.startLoading = ( setLoading ) => setLoading( true );

util.stopLoading = ( setLoading ) => setLoading( false );

util.working_status = [
    { working_status: "US Citizen" },
    { working_status: "Australian" },
    { working_status: "Curricular Practical Training (CPT)" },
    { working_status: "Green Card" },
    { working_status: "Green Card - EAD" },
    { working_status: "H1B" },
    { working_status: "H4 - EAD" },
    { working_status: "Indian" },
    { working_status: "Mexican" },
    { working_status: "Optional Practical Training (OPT)" },
    { working_status: "Permanent Legal Resident" },
    { working_status: "Treaty NAFTA (TN)" },
    { working_status: "Other" }
];

util.stringToCap = ( name ) => {
    if ( name === "" ) return "";
    let n = name.toString().toLowerCase().split( " " );
    for ( let x = 0; x < n.length; x++ ) {
        if ( n[x][0] !== undefined ) {
            n[x] = n[x][0].toUpperCase() + n[x].slice( 1 );
        }

    }
    return n.join( " " );
};

util.handleValueByRecruiterCountry = ( value, opc ) => {
    switch ( opc ) {
        case 'country':
            if ( value === "" ) {
                return util.user.country;
            } else {
                return value;
            }
        case 'currency':
            if ( value === "" ) {
                switch ( util.user.country ) {
                    case 'United States':
                        return "USD";
                    case 'Australia':
                        return "AUD";
                    case 'Mexico':
                        return "MXN";
                    case 'India':
                        return "INR";
                    case 'Canada':
                        return "CAD";
                    default:
                        return "USD";
                }
            } else {
                return value;
            }
        case 'salary_mode':
            if ( value === "" ) {
                switch ( util.user.country ) {
                    case 'United States':
                        return "Year";
                    case 'Australia':
                        return "Day";
                    case 'Mexico':
                        return "Monthly";
                    case 'India':
                        return "Day";
                    case 'Canada':
                        return "Year";
                    default:
                        return "Year";
                }
            } else {
                return value;
            }
        case 'working_status':
            if ( value === "" ) {
                switch ( util.user.country ) {
                    case 'Australia':
                        return "Permanent Legal Resident";
                    default:
                        return "";
                }
            } else {
                return value;
            }
        case 'phone_mobile':
        case 'phone_home':
        case 'phone_other':
            if ( value === "" ) {
                switch ( util.user.country ) {
                    case 'Australia':
                        return "61" + value;
                    case 'India':
                        return "91" + value;
                    case 'Mexico':
                        return "52" + value;
                    default:
                        return value;
                }
            } else {
                return value;
            }
        default:
    }

};

util.logOut = () => {

    localStorage.removeItem( 'effectRun' );
    const urlParams = new URLSearchParams( window.location.search );
    const jobId = urlParams.get( 'job' );

    if ( jobId !== null ) {
        window.history.replaceState( "", "", `/careers?search=&job=${jobId}` );
    } else {
        window.history.replaceState( "", "", "/" );
    }
    delete_cookie( "tokenServer" );
    // Every time the talent log in, it will be redirected to the main page
    setTimeout( () => {
        window.location.reload();
    }, 1500 );
};

util.changeRole = async ( selectedRole ) => {
    try {
        let role = 2;
        switch ( selectedRole ) {
            case "recruiter":
                role = 1;
                break;
            case "talent":
                role = 2;
                break;
            case "client":
                role = 3;
                break;
            case "partner":
                role = 4;
                break;
            case "evaluator":
                role = 5;
                break;
            case "recruiter_manager":
                role = 6;
                break;
            case "employee":
                role = 7;
                break;
            case "employee_manager":
                role = 8;
                break;
            case "vendor":
                role = 15;
                break;
            default:
                break;
        }
        let u = util.user;
        u.role = role;

        const request = await axios.put( `${util.url}/auth/change_user_role`, { user: u } );
        const { error, msg, accessToken } = request.data;
        if ( error ) throw msg;
        bake_cookie( "tokenServer", accessToken );
        window.history.replaceState( "", "", "/" );
        window.location.reload();
    } catch ( e ) {
        util.handleError( e );
    }
};

util.AvailableRole = ( { selectedRole, role } ) => {
    if ( util.user?.permissions[selectedRole] === 1 ) {
        return (
            <Dropdown.Item>
                <div onClick={() => util.changeRole( selectedRole )}>{role}</div>
            </Dropdown.Item>
        );
    } else {
        return "";
    }
};

util.uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function ( c ) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
        return v.toString( 16 );
    } );
};

util.fieldToHumanWord = ( field ) => {
    switch ( field ) {
        case 'id':
            return 'Id';
        case 'createdAt':
            return 'Created At';
        case 'created_by':
            return 'Created By';
        case 'updated_by':
            return 'updated By';
        case 'company':
            return 'Company';
        case 'client_company':
            return 'Client Company';
        case 'job_title':
            return 'Job Title';
        case 'sell_rate':
            return 'Sell Rate';
        case 'status':
            return 'Status';
        case 'candidates_added':
            return 'Candidates Added';
        case 'country':
            return 'Country';
        case 'reporting_manager':
            return 'Reporting Manager';
        case 'sales_lead':
            return 'Sales Lead';
        case 'lead_recruiter':
            return 'Lead Recruiter';
        case 'secondary_recruiter':
            return 'Secondary Recruiter';
        case 'restricted_to':
            return 'Restricted To';
        default:
            return field;
    }
};

util.highestEducation = [
    { highestEducation: "High School" },
    { highestEducation: "Diploma" },
    { highestEducation: "Graduate" },
    { highestEducation: "Masters (Post Graduate)" },
    { highestEducation: "Doctoral (Ph.D)" },
];

util.isProfilePicAvailableURL = ( d, opc = false, size = null ) => {
    let picSize = opc ? size : 35;
    if ( !util.noValues.includes( d.profile_picture ) ) {
        if ( process.env.REACT_APP_ENVIROMENT !== "Production" ) {
            return BASE_URL + "/candidate/" + d.id + "/" + d.profile_picture;
        }
        else {
            return BASE_URL + AWS_URL + "/candidate/" + d.id + "/" + d.profile_picture;
        }
        // return <img width={`${picSize}`} height={`${picSize}`} className="rounded-circle imageZoomed" alt={d.name.substring(0,5)} src={url} />;
    } else {
        return "/images/avatar.svg";
    }
};

util.RowComponentClean = ( props ) => {
    if ( props.type === undefined ) {
        return (
            <Fragment>
                <div className={`col-md-${props.c}`}>
                    <TextField
                        size={"small"}
                        multiline={props.mul !== undefined}
                        // rows={props.mul !== undefined ? props.rows: 1}
                        className="w-100"
                        disabled={props.disabled || false}
                        InputLabelProps={{ shrink: true }}
                        value={props.v}
                        type={props.ty ? props.ty : ""}
                        label={props.t}
                        fullWidth={true}
                        placeholder={props.placeholder ? props.placeholder : ""}
                        InputProps={{
                            startAdornment: props.ic ? (
                                <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                            ) : (
                                ""
                            )
                        }}
                        variant="outlined"
                        name={props.n}
                        onChange={props.m ? props.m : null}
                        onBlur={props.onBlur ? props.onBlur : null}
                    />
                </div>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <div className={`col-md-${props.c}`}>
                    <TextField
                        id="standard-select-currency-native"
                        select
                        size={"small"}
                        variant='outlined'
                        disabled={props.disabled || false}
                        name={props.n}
                        className="w-100"
                        label={props.t}
                        value={props.v || "None"}
                        onChange={props.m}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                            native: true
                        }}
                        InputProps={{
                            startAdornment: props.icon ? (
                                <FontAwesomeIcon icon={props.icon} className="mr-2" />
                            ) : (
                                ""
                            )
                        }}
                    >
                        {/*<option value=""/>*/}
                        {props.d.map( option => (
                            <option key={Math.random() * Math.random()} value={option[props.n]}>
                                {option[props.n]}
                            </option>
                        ) )}
                    </TextField>
                </div>
            </Fragment>
        );
    }
};
// Check if user is trying to visit a job in fullScreen
util.isFullScreenJob = () => {
    if ( window.document.location.search !== "" ) {
        const urlParams = new URLSearchParams( window.location.search );
        const fs = urlParams.get( 'fs' );
        if ( fs ) {
            return { sidebar: '0 d-none', main: 12 };
        }
        {
            return { sidebar: 4, main: 8 };
        }
    } else {
        return { sidebar: 4, main: 8 };
    }
};

util.SelectSlim = ( { name, value, onChange, options } ) => {
    return (
        <select
            name={name}
            onChange={onChange}
            value={value}
            className="form-control"
        >
            {options.map( ( o, i ) => {
                return <option value={o} key={i}>{o}</option>;
            } )}
        </select>
    );
};

util.PopOverConfirmation = ( { callBackOk } ) => {
    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h5">Are you sure?</Popover.Title>
            <Popover.Content>
                <div className="d-flex justify-content-around">
                    <span onClick={() => document.body.click()} className="isPointer text-danger">
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                    <span onClick={() => {
                        document.body.click();
                        callBackOk();
                    }} className="isPointer text-success">
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            rootClose
            rootCloseEvent="click"
            trigger={['click']} placement="bottom" overlay={popover}>
            <FontAwesomeIcon
                icon={faTimes}
                className="iconGray isPointer"
            />
        </OverlayTrigger>
    );
};

util.InputSlim = ( { name, value, type, onChange, placeholder, disabled = false, label } ) => {
    return (
        <TextField
            className='w-100'
            size="small"
            label={label}
            fullWidth
            disabled={disabled}
            variant="outlined"
            type={type ? type : "text"}
            onChange={onChange}
            name={name}
            value={value ? value : ""}
            placeholder={placeholder}
        />
    );
};

util.fixDate = date => {
    if ( util.noValues.includes( date ) ) return "";
    let d = util.humanDate( date, true ).split( "/" );
    return `${d[0]}/${d[2]}`;
};


util.typeSkill = ( type ) => {
    const PRIMARY = "Primary";
    const SECONDARY = "Secondary";
    const SOFT = "Soft";

    switch ( type ) {
        case PRIMARY:
            return 1;
        case SECONDARY:
            return 2;
        case SOFT:
            return 3;
    }
};

util.timeZones = [
    { time_zone: "" },
    { time_zone: "(GMT-12:00) International Date Line West", value: "-12:00" },
    { time_zone: "(GMT-11:00) Midway Island, Samoa", value: "-11:00" },
    { time_zone: "(GMT-10:00) Hawaii", value: "-10:00" },
    { time_zone: "(GMT-09:00) Alaska", value: "-09:00" },
    { time_zone: "(GMT-08:00) Pacific Time (US & Canada)", value: "-08:00" },
    { time_zone: "(GMT-08:00) Tijuana, Baja California", value: "-08:00" },
    { time_zone: "(GMT-07:00) Arizona", value: "-07:00" },
    { time_zone: "(GMT-07:00) Chihuahua, La Paz, Mazatlan", value: "-07:00" },
    { time_zone: "(GMT-07:00) Mountain Time (US & Canada)", value: "-07:00" },
    { time_zone: "(GMT-06:00) Central America", value: "-06:00" },
    { time_zone: "(GMT-06:00) Central Time (US & Canada)", value: "-06:00" },
    { time_zone: "(GMT-06:00) Guadalajara, Mexico City, Monterrey", value: "-06:00" },
    { time_zone: "(GMT-06:00) Saskatchewan", value: "-06:00" },
    { time_zone: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", value: "-05:00" },
    { time_zone: "(GMT-05:00) Eastern Time (US & Canada)", value: "-05:00" },
    { time_zone: "(GMT-05:00) Indiana (East)", value: "-05:00" },
    { time_zone: "(GMT-04:00) Atlantic Time (Canada)", value: "-04:00" },
    { time_zone: "(GMT-04:00) Caracas, La Paz", value: "-04:00" },
    { time_zone: "(GMT-04:00) Manaus", value: "-04:00" },
    { time_zone: "(GMT-04:00) Santiago", value: "-04:00" },
    { time_zone: "(GMT-03:30) Newfoundland", value: "-03:30" },
    { time_zone: "(GMT-03:00) Brasilia", value: "-03:00" },
    { time_zone: "(GMT-03:00) Buenos Aires, Georgetown", value: "-03:00" },
    { time_zone: "(GMT-03:00) Greenland", value: "-03:00" },
    { time_zone: "(GMT-03:00) Montevideo", value: "-03:00" },
    { time_zone: "(GMT-02:00) Mid-Atlantic", value: "-02:00" },
    { time_zone: "(GMT-01:00) Cape Verde Is.", value: "-01:00" },
    { time_zone: "(GMT-01:00) Azores", value: "-01:00" },
    { time_zone: "(GMT+00:00) Casablanca, Monrovia, Reykjavik", value: "+00:00" },
    { time_zone: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", value: "+00:00" },
    { time_zone: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", value: "+01:00" },
    { time_zone: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", value: "+01:00" },
    { time_zone: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00" },
    { time_zone: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb", value: "+01:00" },
    { time_zone: "(GMT+01:00) West Central Africa", value: "+01:00" },
    { time_zone: "(GMT+02:00) Amman", value: "+02:00" },
    { time_zone: "(GMT+02:00) Athens, Bucharest, Istanbul", value: "+02:00" },
    { time_zone: "(GMT+02:00) Beirut", value: "+02:00" },
    { time_zone: "(GMT+02:00) Cairo", value: "+02:00" },
    { time_zone: "(GMT+02:00) Harare, Pretoria", value: "+02:00" },
    { time_zone: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", value: "+02:00" },
    { time_zone: "(GMT+02:00) Jerusalem", value: "+02:00" },
    { time_zone: "(GMT+02:00) Minsk", value: "+02:00" },
    { time_zone: "(GMT+02:00) Windhoek", value: "+02:00" },
    { time_zone: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "+03:00" },
    { time_zone: "(GMT+03:00) Moscow, St. Petersburg, Volgograd", value: "+03:00" },
    { time_zone: "(GMT+03:00) Nairobi", value: "+03:00" },
    { time_zone: "(GMT+03:00) Tbilisi", value: "+03:00" },
    { time_zone: "(GMT+03:30) Tehran", value: "+03:30" },
    { time_zone: "(GMT+04:00) Abu Dhabi, Muscat", value: "+04:00" },
    { time_zone: "(GMT+04:00) Baku", value: "+04:00" },
    { time_zone: "(GMT+04:00) Yerevan", value: "+04:00" },
    { time_zone: "(GMT+04:30) Kabul", value: "+04:30" },
    { time_zone: "(GMT+05:00) Yekaterinburg", value: "+05:00" },
    { time_zone: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: "+05:00" },
    { time_zone: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: "+05:30" },
    { time_zone: "(GMT+05:30) Sri Jayawardenapura", value: "+05:30" },
    { time_zone: "(GMT+05:45) Kathmandu", value: "+05:45" },
    { time_zone: "(GMT+06:00) Almaty, Novosibirsk", value: "+06:00" },
    { time_zone: "(GMT+06:00) Astana, Dhaka", value: "+06:00" },
    { time_zone: "(GMT+06:30) Yangon (Rangoon)", value: "+06:30" },
    { time_zone: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
    { time_zone: "(GMT+07:00) Krasnoyarsk", value: "+07:00" },
    { time_zone: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: "+08:00" },
    { time_zone: "(GMT+08:00) Kuala Lumpur, Singapore", value: "+08:00" },
    { time_zone: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "+08:00" },
    { time_zone: "(GMT+08:00) Perth", value: "+08:00" },
    { time_zone: "(GMT+08:00) Taipei", value: "+08:00" },
    { time_zone: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "+09:00" },
    { time_zone: "(GMT+09:00) Seoul", value: "+09:00" },
    { time_zone: "(GMT+09:00) Yakutsk", value: "+09:00" },
    { time_zone: "(GMT+09:30) Adelaide", value: "+09:30" },
    { time_zone: "(GMT+09:30) Darwin", value: "+09:30" },
    { time_zone: "(GMT+10:00) Brisbane", value: "+10:00" },
    { time_zone: "(GMT+10:00) Canberra, Melbourne, Sydney", value: "+10:00" },
    { time_zone: "(GMT+10:00) Hobart", value: "+10:00" },
    { time_zone: "(GMT+10:00) Guam, Port Moresby", value: "+10:00" },
    { time_zone: "(GMT+10:00) Vladivostok", value: "+10:00" },
    { time_zone: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", value: "+11:00" },
    { time_zone: "(GMT+12:00) Auckland, Wellington", value: "+12:00" },
    { time_zone: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: "+12:00" },
    { time_zone: "(GMT+13:00) Nuku'alofa", value: "+13:00" }
];

util.Select = ( { t, n, d, m, v } ) => {
    // Do something about the key
    return (
        <>
            <div className="d-flex flex-column justify-content-start">
                <label className="font-weight-bold">{t}</label>
                <select name={n} onChange={m} value={v} className="form-control">
                    <option value=""></option>
                    {d.map( ( item, i ) => (
                        <option key={item + i} value={item}>
                            {item}
                        </option>
                    ) )}
                </select>
            </div>
            <hr />
        </>
    );
};


util.tableToCSV = () => {

    // letiable to store the final csv data
    let csv_data = [];

    // Get each row data
    let rows = document.getElementById( '#exportTable' ).rows;
    for ( let i = 0; i < rows.length; i++ ) {

        // Get each column data
        let cols = rows[i].querySelectorAll( 'td,th' );

        // Stores each csv row data
        let csvrow = [];
        for ( let j = 0; j < cols.length; j++ ) {

            // Get the text data of each cell
            // of a row and push it to csvrow
            csvrow.push( cols[j].innerHTML.replace( /,/g, '-' ) );
        }

        // Combine each column value with comma
        csv_data.push( csvrow.join( "," ) );
    }

    // Combine each row data with new line character
    csv_data = csv_data.join( '\n' );

    // Call this function to download csv file 
    util.downloadCSVFile( csv_data );

};

util.downloadCSVFile = ( csv_data ) => {

    // Create CSV file object and feed
    // our csv_data into it
    let CSVFile = new Blob( [csv_data], {
        type: "text/csv"
    } );

    // Create to temporary link to initiate
    // download process
    let temp_link = document.createElement( 'a' );

    // Download csv file
    temp_link.download = "GfG.csv";
    let url = window.URL.createObjectURL( CSVFile );
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild( temp_link );

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild( temp_link );
};


util.isClient = ( role, data ) => {
    if ( role === CLIENT_ROLE ) {
        if ( data.personName ) {
            return `--`;
        } else {
            return `$${data.sell_rate}/${util.noValues.includes( data.sell_rate_type ) ? "Hour" : data.sell_rate_type}`;
        }
    } else {
        if ( role === 4 ) {
            return `$${data.sell_rate}/${util.noValues.includes( data.sell_rate_type ) ? "Hour" : data.sell_rate_type}`;
        }
    }
};

util.isGoalOrCompetence = ( type = 1 ) => type === 1 ? "Goal" : "Competency";




util.fixStatusList = () => {
    let status = [];
    // Add more used countries at the beginningstatus
    status.push( { value: 0, label: "Set Targets", id: `0` } );
    status.push( { value: 1, label: " Employee Approval", id: `1` } );
    status.push( { value: 2, label: " Employee Accepted", id: `2` } );
    status.push( { value: 3, label: "Employee Declined", id: `3` } );
    status.push( { value: 4, label: "L1 Accepted", id: `4` } );
    status.push( { value: 5, label: "L1 Declined", id: `5` } );
    status.push( { value: 6, label: "L2 Accepted", id: `6` } );
    status.push( { value: 7, label: "L2 Declined", id: `7` } );
    status.push( { value: 8, label: "L3 Accepted", id: `8` } );
    status.push( { value: 9, label: "L3 Declined", id: `9` } );
    status.push( { value: 10, label: "HR Accepted", id: `10` } );
    status.push( { value: 11, label: "HR Declined", id: `11` } );
    status.push( { value: 12, label: "Waiting Time", id: `12` } );
    status.push( { value: 13, label: "Employee Evaluation", id: `13` } );
    status.push( { value: 14, label: "L1 Evaluation", id: `14` } );
    status.push( { value: 15, label: "L2 Evaluation", id: `15` } );
    status.push( { value: 16, label: "L3 Evaluation", id: `16` } );
    status.push( { value: 17, label: "HR Evaluation", id: `17` } );
    status.push( { value: 18, label: "Appraisal Completed", id: `18` } );

    return [...status];
};
// util.jobStatusOnlyIcons = ( status, showTitle = true, reasons = null ) => {

//     const toolTipsMessages = {
//         new: { status: 0, color: "gold", tooltip: "New Job request received from the Client and waiting for recruiter to start working on it. Can be used for eJ Hold.", icon: faStar, title: "New" },
//         active:{ status: 1, color: "green", tooltip: "Active Job assigned to a Recruiter and work in progress.", icon: faPlayCircle, title: "Active" },
//         hold:{ status: 2, color: "#d4cf05", tooltip: "Client Kept this job on Hold", icon: faClock, title: "Hold" },
//         closed:{ status: 3, color: "red", tooltip: "Client Closed or cancelled the Job", icon: faWindowClose, title: "Closed" },
//         filled:{ status: 4, color: "#17a2b8", tooltip: "eJ Filled the Job with the Talent(s) fo the Client.", icon: faCheckCircle, title: "Filled" },
//         hot:{ status: 5, color: "tomato", tooltip: "Active and Exclusive Job for eJ", icon: faFire, title: "Hot" },
//         dropped:{ status: 6, color: "#5c5c5c", tooltip: "Dropped or Lost", icon: faBan, title: "Dropped", reasons:[
//             { text: "reason 1" },
//             { text: "reason 2" },
//             { text: "reason 3" },
//             { text: "reason 4" },
//         ] },
//     };


util.suspenseDotPopOverExceptionalAchievments = ( rowData ) => {

    if ( rowData !== "" ) {
        // if ( rowData.exceptional_achivement?.length > 10 ){
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title className="titlePopoverH3" as="h3">Exceptional Achievements</Popover.Title>
                <Popover.Content>
                    <div className="popOverScrollable1">
                        <ul>
                            <li>
                                {rowData.exceptional_achivement ? (
                                    <div className="p-2" dangerouslySetInnerHTML={createMarkup( rowData.exceptional_achivement )}></div>
                                ) : (
                                    <p className="p-1">There are no exceptional achievements</p>
                                )}
                            </li>
                        </ul>
                    </div>
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                rootClose={true}
                rootCloseEvent='click'
                trigger='click'
                placement="top"
                overlay={popover}
            >
                <FontAwesomeIcon size="2x" className="text-dark isCursor" icon={faAward} />

            </OverlayTrigger>
        );
    }
    else {
        return "";
    }



};
util.suspenseDotPopOverExceptionalAchievmentsFinal = ( info ) => {

    if ( info !== "" ) {
        // if ( info.exceptional_achivement?.length > 10 ){
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title className="titlePopoverH3" as="h3">Exceptional Achievements</Popover.Title>
                <Popover.Content>
                    <div className="popOverScrollable1">
                        <li>
                            {info.exceptional_achivement ? (
                                <div className="p-2" dangerouslySetInnerHTML={createMarkup( info.exceptional_achivement )}></div>
                            ) : (
                                <p className="p-2">There are no exceptional achievements</p>
                            )}
                        </li>
                    </div>
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                rootClose={true}
                rootCloseEvent='click'
                trigger='click'
                placement="top"
                overlay={popover}
            >
                <FontAwesomeIcon size="2x" icon={faAward} className="text-dark isCursor" />

            </OverlayTrigger>
        );
    }
    else {
        return "";
    }



};

util.suspenseDotPopOverSelfComment = ( state, title ) => {

    if ( state !== "" ) {
        // if ( info.exceptional_achivement?.length > 10 ){
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title className="titlePopoverH3" as="h3">{title}</Popover.Title>
                <Popover.Content>
                    <div className="popOverScrollable1">
                        <ul>
                            <li>
                                {
                                    <div dangerouslySetInnerHTML={createMarkup( state.item.employee_feedback )}></div>
                                }
                            </li>
                        </ul>
                    </div>
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                rootClose={true}
                rootCloseEvent='click'
                trigger='click'
                placement="top"
                overlay={popover}
            >
                <FontAwesomeIcon size="1x" icon={faComment} className="iconGray isCursor ml-1" />

            </OverlayTrigger>
        );
    }
    else {
        return "";
    }



};


util.suspenseDotPopOverDeclined = ( declinedMSG ) => {

    if ( declinedMSG !== "" ) {
        // if ( info.exceptional_achivement?.length > 10 ){
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title className="titlePopoverH3" as="h3">Reason for Decline Appraisal</Popover.Title>
                <Popover.Content>
                    <div className="popOverScrollable1">
                        <ul>
                            <li>
                                {
                                    <div dangerouslySetInnerHTML={createMarkup( declinedMSG.msg )}></div>
                                }
                            </li>
                        </ul>
                    </div>
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                rootClose={true}
                rootCloseEvent='click'
                trigger='click'
                placement="top"
                overlay={popover}
            >
                <FontAwesomeIcon size="1x" icon={faComment} className="iconGray isCursor" />

            </OverlayTrigger>
        );
    }
    else {
        return "";
    }
};

util.min_to_ddhhmm = value => {
    let days = 0;
    let hrs = 0;
    let mins = 0;

    if ( value ) {
        days = Math.floor( value / ( 60 * 24 ) );
        let remainingMins = value % ( 60 * 24 );
        hrs = Math.floor( remainingMins / 60 );
        mins = remainingMins % 60;
    }

    return {
        days: days,
        hrs: hrs,
        mins: mins
    };
};

util.days_to_ddhhmm = ( value ) => {
    let days = 0;
    let hrs = 0;
    let mins = 0;

    if ( value ) {
        days = Math.floor( value / 1 );
        let remainingDay = value % 1;
        hrs = Math.floor( remainingDay * 24 );
        remainingDay = ( remainingDay * 24 ) % 1;
        mins = Math.floor( remainingDay * 60 );
    }

    return {
        days: days,
        hrs: hrs,
        mins: mins
    };
};

util.ddhhmm_to_human_format = ( { days, hrs, mins }, unit = "min" ) => {
    if ( unit === "min" ) {
        if ( days > 0 ) {
            return <div>{`${days} days ${hrs} hrs ${mins} mins`}</div>;
        }

        if ( hrs > 0 ) {
            return <div>{`${hrs} hrs ${mins} mins`}</div>;
        }

        return <div>{`${mins} mins`}</div>;
    } else {
        if ( hrs > 0 ) {
            return <div>{`${days} days ${hrs} hrs`}</div>;
        }
        return <div>{`${days} days`}</div>;
    }
};

util.time_SLA_render = value => {
    return util.ddhhmm_to_human_format( util.min_to_ddhhmm( value ) );
};

util.time_SLA_days_render = ( value ) => {
    return util.ddhhmm_to_human_format( util.days_to_ddhhmm( value ), "days" );
};

util.canUseNewMethod = ( value ) => {
    const date1 = new Date( value );
    const date2 = new Date( "2023-02-01 00:00:00" );

    if ( date1 > date2 ) {
        return true;
    } else {
        return false;
    }
};

util.WorkAuthSelector = ( props ) => {
    return <div>
        {/* <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel  htmlFor="grouped-native-select">Work Authorization</InputLabel>
            <Select native defaultValue={props.value !== null ? props.value : "No Authorization for Job Site"} id="grouped-native-select" onChange={props.handleChange} name="working_status">
                <option>No Authorization for Job Site</option>
                <option>Citizen of job country</option>
                <option>Visa sponsorship needed for job site</option>
                <optgroup label="United States (US)">
                    <option>Permanent Resident (Green Card holder)</option>
                    <option>H-1B Visa (Specialty Occupations)</option>
                    <option>L-1 Visa (Intra-Company Transfers)</option>
                    <option>O-1 Visa (Extraordinary Ability)</option>
                    <option>F-1 Visa with Optional Practical Training (OPT)</option>
                    <option>Curricular Practical Training (CPT) for students</option>
                    <option>J-1 Visa (Exchange Visitors)</option>
                    <option>TN Visa (NAFTA Professionals)</option>
                    <option>E-3 Visa (Australian Professionals)</option>
                </optgroup>
                <optgroup label="United Kingdom (UK)">
                    <option>Skilled Worker Visa</option>
                    <option>Intra-company Transfer Visa</option>
                    <option>Health and Care Worker Visa</option>
                    <option>Global Talent Visa</option>
                    <option>Start-up Visa</option>
                    <option>Innovator Visa</option>
                    <option>Graduate Visa</option>
                    <option>Temporary Worker Visa</option>
                    <option>Family or Partner Visa</option>
                    <option>Visitor Visa</option>
                </optgroup>
                <optgroup label="Australia (AU)">
                    <option>Permanent Resident</option>
                    <option>Temporary Skill Shortage (TSS) Visa - Subclass 482</option>
                    <option>Employer Nomination Scheme (ENS) Visa - Subclass 186</option>
                    <option>Regional Sponsored Migration Scheme (RSMS) Visa - Subclass 187</option>
                    <option>Skilled Independent Visa - Subclass 189</option>
                    <option>Skilled Nominated Visa - Subclass 190</option>
                    <option>Skilled Work Regional (Provisional) Visa - Subclass 491</option>
                    <option>Working Holiday Visa - Subclass 417/462</option>
                </optgroup>
                <optgroup label="Mexico (MX)">
                    <option>Permanent Resident</option>
                    <option>Temporary Resident</option>
                    <option>Temporary Worker Visa (FM3)</option>
                    <option>NAFTA Professional (TN) Visa</option>
                    <option>Intra-Company Transfer (FM3)</option>
                </optgroup>
                <optgroup label="India (IN)">
                    <option>Employment Visa</option>
                    <option>Business Visa</option>
                    <option>Project Visa</option>
                    <option>Intern Visa</option>
                </optgroup>
                <optgroup label="European Union(EU)">
                    <option>EU Blue Card</option>
                    <option>National Work Permits</option>
                    <option>Temporary Worker Permits</option>
                    <option>Intra-corporate Transfers</option>
                    <option>Seasonal Worker Permits</option>
                    <option>Self-employed/Freelancer Visas</option>
                    <option>Researcher Mobility</option>
                    <option>Start-up/Innovator Visas</option>
                    <option>EU Settlement Scheme (UK nationals)</option>
                    <option>Family Reunification Visas</option>
                </optgroup>
                <option value="other">Others</option>
            </Select>
        </FormControl> */}
        <TextField
            variant="outlined"
            size="small"
            fullWidth
            select
            label="Work Authorization"
            defaultValue={props.value !== null ? props.value : "No Authorization for Job Site"}
            onChange={props.handleChange}
            name="working_status"
        >
            <MenuItem value="No Authorization for Job Site">No Authorization for Job Site</MenuItem>
            <MenuItem value="Citizen of job country">Citizen of job country</MenuItem>
            <MenuItem value="Visa sponsorship needed for job site">Visa sponsorship needed for job site</MenuItem>
            <MenuItem disabled>United States (US)</MenuItem>
            <MenuItem value="Permanent Resident (Green Card holder)">Permanent Resident (Green Card holder)</MenuItem>
            <MenuItem value="H-1B Visa (Specialty Occupations)">H-1B Visa (Specialty Occupations)</MenuItem>
            <MenuItem value="L-1 Visa (Intra-Company Transfers)">L-1 Visa (Intra-Company Transfers)</MenuItem>
            <MenuItem value="O-1 Visa (Extraordinary Ability)">O-1 Visa (Extraordinary Ability)</MenuItem>
            <MenuItem value="F-1 Visa with Optional Practical Training (OPT)">F-1 Visa with Optional Practical Training (OPT)</MenuItem>
            <MenuItem value="Curricular Practical Training (CPT) for students">Curricular Practical Training (CPT) for students</MenuItem>
            <MenuItem value="J-1 Visa (Exchange Visitors)">J-1 Visa (Exchange Visitors)</MenuItem>
            <MenuItem value="TN Visa (NAFTA Professionals)">TN Visa (NAFTA Professionals)</MenuItem>
            <MenuItem value="E-3 Visa (Australian Professionals)">E-3 Visa (Australian Professionals)</MenuItem>
            <MenuItem disabled>United Kingdom (UK)</MenuItem>
            <MenuItem value="Skilled Worker Visa">Skilled Worker Visa</MenuItem>
            <MenuItem value="Intra-company Transfer Visa">Intra-company Transfer Visa</MenuItem>
            <MenuItem value="Health and Care Worker Visa">Health and Care Worker Visa</MenuItem>
            <MenuItem value="Global Talent Visa">Global Talent Visa</MenuItem>
            <MenuItem value="Start-up Visa">Start-up Visa</MenuItem>
            <MenuItem value="Innovator Visa">Innovator Visa</MenuItem>
            <MenuItem value="Graduate Visa">Graduate Visa</MenuItem>
            <MenuItem value="Temporary Worker Visa">Temporary Worker Visa</MenuItem>
            <MenuItem value="Family or Partner Visa">Family or Partner Visa</MenuItem>
            <MenuItem value="Visitor Visa">Visitor Visa</MenuItem>
            <MenuItem disabled>Australia (AU)</MenuItem>
            <MenuItem value="Permanent Resident">Permanent Resident</MenuItem>
            <MenuItem value="Temporary Skill Shortage (TSS) Visa - Subclass 482">Temporary Skill Shortage (TSS) Visa - Subclass 482</MenuItem>
            <MenuItem value="Employer Nomination Scheme (ENS) Visa - Subclass 186">Employer Nomination Scheme (ENS) Visa - Subclass 186</MenuItem>
            <MenuItem value="Regional Sponsored Migration Scheme (RSMS) Visa - Subclass 187">Regional Sponsored Migration Scheme (RSMS) Visa - Subclass 187</MenuItem>
            <MenuItem value="Skilled Independent Visa - Subclass 189">Skilled Independent Visa - Subclass 189</MenuItem>
            <MenuItem value="Skilled Nominated Visa - Subclass 190">Skilled Nominated Visa - Subclass 190</MenuItem>
            <MenuItem value="Skilled Work Regional (Provisional) Visa - Subclass 491">Skilled Work Regional (Provisional) Visa - Subclass 491</MenuItem>
            <MenuItem value="Working Holiday Visa - Subclass 417/462">Working Holiday Visa - Subclass 417/462</MenuItem>
            <MenuItem disabled>Mexico (MX)</MenuItem>
            <MenuItem value="Permanent Resident">Permanent Resident</MenuItem>
            <MenuItem value="Temporary Resident">Temporary Resident</MenuItem>
            <MenuItem value="Temporary Worker Visa (FM3)">Temporary Worker Visa (FM3)</MenuItem>
            <MenuItem value="NAFTA Professional (TN) Visa">NAFTA Professional (TN) Visa</MenuItem>
            <MenuItem value="Intra-Company Transfer (FM3)">Intra-Company Transfer (FM3)</MenuItem>
            <MenuItem disabled>India (IN)</MenuItem>
            <MenuItem value="Employment Visa">Employment Visa</MenuItem>
            <MenuItem value="Business Visa">Business Visa</MenuItem>
            <MenuItem value="Project Visa">Project Visa</MenuItem>
            <MenuItem value="Intern Visa">Intern Visa</MenuItem>
            <MenuItem disabled>European Union (EU)</MenuItem>
            <MenuItem value="EU Blue Card">EU Blue Card</MenuItem>
            <MenuItem value="National Work Permits">National Work Permits</MenuItem>
            <MenuItem value="Temporary Worker Permits">Temporary Worker Permits</MenuItem>
            <MenuItem value="Intra-corporate Transfers">Intra-corporate Transfers</MenuItem>
            <MenuItem value="Seasonal Worker Permits">Seasonal Worker Permits</MenuItem>
            <MenuItem value="Self-employed/Freelancer Visas">Self-employed/Freelancer Visas</MenuItem>
            <MenuItem value="Researcher Mobility">Researcher Mobility</MenuItem>
            <MenuItem value="Start-up/Innovator Visas">Start-up/Innovator Visas</MenuItem>
            <MenuItem value="EU Settlement Scheme (UK nationals)">EU Settlement Scheme (UK nationals)</MenuItem>
            <MenuItem value="Family Reunification Visas">Family Reunification Visas</MenuItem>
            <MenuItem value="other">Others</MenuItem>
        </TextField>
    </div>;
};

util.arrangePermissionData = ( data = util.user.module_permission ) => {
    let result = {};

    data.forEach( el => {
        if ( result[el.module] ) {
            result[el.module].push( el.action );
        } else {
            result[el.module] = [el.action];
        }
    } );

    return result;
};

util.customerPermissions = () => {
    if ( Object.keys( util.user ).length > 0 ) {

        const permissions = util.arrangePermissionData();
        if ( permissions.Customer?.length > 0 ) {
            console.log( { msg: "User has customer permission", permissions } );
            return true;
        }
        console.log( { msg: "User do not have customer permissions", permissions } );
        return false;
    }
    console.log( "User is not authorized" );
    return false;
};

util.checkPermissions = ( permissionsToCheck, arrangePermission ) => {
    for ( let section in permissionsToCheck ) {
        const requiredPermissions = permissionsToCheck[section];
        const hasPermissions = requiredPermissions.every( permission =>
            arrangePermission[section] && arrangePermission[section].includes( permission )
        );
        if ( !hasPermissions ) {
            console.log( false );
            return false;
        }
    }
    return true;
};

util.calculateWorkdays = ( startDate ) => {
    const start = new Date( startDate );
    const end = new Date();
    let count = 0;

    for ( let d = new Date( start ); d <= end; d.setDate( d.getDate() + 1 ) ) {
        const day = d.getDay();
        if ( day !== 0 && day !== 6 ) { // 0 = Sunday, 6 = Saturday
            count++;
        }
    }

    return count;
};

export default util;
