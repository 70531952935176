import React, { useState } from "react";
import util from "../utils/miniUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, TextField } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export default function RecoverPassword( props ) {
	
    const [email, setEmail] = useState( "" );
    const [loading, setLoading] = useState( false );
    const [finish, setFinish] = useState( false );
	
    async function recoverPassword() {
        try {
            if ( email === "" ){
                util.toast().warn( "Ingress your email" );
                return;
            }
            const request = await util.axios.put( `${util.url}/users/reset_password_request`, { email } );
            const { error, msg } = request.data;
            if ( error ) throw  msg;
            setLoading( false );
            setFinish( true );
            setEmail( "" );
            setTimeout( ()=>{
                props.handleClose();
            }, 3000 );
        }catch ( e ) {
            util.handleError( e );
        }
    }
	
    function onChangeEmailHandler( e ) {
        const { value } = e.target;
        setEmail( value );
    }
	
    return(
        <Dialog
            open={props.show}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Recover password"}</DialogTitle>
            {loading ? (
                <div style={{ width:"100%", height:"100px" }}>
                    <p>Sending recovery email</p>
                    <CircularProgress />
                </div>
            ):(
                finish ? (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
							We send you a recovery email. Please check your email account.
                        </DialogContentText>
                    </DialogContent>
                ):(
                    <div>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
								This action will send you a email with a reset password link.
								We will send this email to the registered account.
                            </DialogContentText>
                            <div className="text-center">
                                <TextField id="inputEmailPasswordRecover" type="email" placeholder="user@email.com" className="w-50" name="email" value={email} onChange={onChangeEmailHandler} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button id="cancelButton" onClick={props.handleClose} color="default">
								Cancel
                            </Button>
                            <button id="resetButton" onClick={()=>recoverPassword()} className="btn btn-md btn-primary">
								Reset Password
                            </button>
                        </DialogActions>
                    </div>
                )
            )}
        </Dialog>
    );
}
