import React, { Fragment, useEffect, useState } from "react";
import countryList from '../../../utils/location/country.json';
import util from "../../../utils/miniUtils";
import axios from "axios";
import { Grid, Typography } from "@material-ui/core";


export  default function LocationView( { requiredData = null, data:{ country, state, city,phone_mobile,address,zip_code,tax_identification_number }, onChange } ) {
   
    const [states, setStates] = useState( [] );
    const [required, setRequired] = useState( {} );
    
    useEffect( () => {
        if ( requiredData !== null ) {
            setRequired( requiredData );
        }
    }, [] );
    
    const [countryState, setCountryState] = useState( {
        loading: false,
        countries: [],
        errorMessage: "",
    } );

    useEffect( () => {
        const fetchData = async () => {
            try {
                // fetch spinner
                setCountryState( {
                    ...countryState,
                    loading: true,
                } );

                //  fetch data
                const dataUrl = `https://restcountries.com/v3.1/all`;
                const response = await axios.get( dataUrl );
                setCountryState( {
                    ...countryState,
                    countries: response.data,
                    loading: false,
                } );
            } catch ( error ) {
                setCountryState( {
                    ...countryState,
                    loading: false,
                    errorMessage: "Sorry Something went wrong",
                } );
            }
        };

        fetchData();
    }, [] );
    const { loading, countries } = countryState;
    // console.log( countries );
    useEffect( () => {
        if ( util.noValues.includes( country ) ){
            let e = { target:{ value: util.user.country, name:"country" } };
            onChange( e );
        }
    },[country] );
	
    useEffect( () => {
        getStates( country );
    },[country] );
	
    // Retrieve list of countries
    const getStates = ( country ) => {
        if ( util.noValues.includes( country ) ){
            for ( let i = 0; i < countryList.length; i++ ) {
                if ( countryList[i].name === util.user.country ){
                    setStates( Object.assign( [], countryList[i].states ) );
                }
            }
        }else{
            for ( let i = 0; i < countryList.length; i++ ) {
                if ( countryList[i].name === country ){
                    setStates( Object.assign( [], countryList[i].states ) );
                }
            }
        }
        // const v = states.sort( ( a,b ) => ( a.name > b.name ) ? 1 : ( ( b.name > a.name ) ? -1 : 0 ) );
        // console.log(v)
    };
    const searchSelectedCountry = countries.find( ( obj ) => {
        if ( obj.name.common === country ) {
            return true;
        }
        return false;
    } );
    // console.log( "searchSelectedCountry", searchSelectedCountry );
    // const handleChangePhone = ( e ) => {
    //     const inputValue = e.target.value;
    //     const formattedValue = inputValue.replace( /[^0-9]/g, '' ); // Remove non-numeric characters
      
    //     if ( formattedValue.length <= 10 ) {
    //         onChange( e );
    //     }
    // };
	
    return(
		
        <Fragment>
           
            {/* <div>
               
                {country === "India" ? (
                    <div className="col-md-12 mt-3">
                        <p><span>PAN Card:</span> <span className="ml-5">{tax_identification_number}</span></p>
                    </div>
                ) : null}
               

                <div >
                    <div >
                        <div className="flex"><span>Address:</span> <span style={{ marginLeft:'5.47rem' }}>
                            <div>{address ? address : ""}</div>
                            <div>{city || ""}</div>
                            <div>{state ? state : ""}</div>
                            <div>{zip_code || ""}</div>
                        
                        </span></div>
                    </div>
                   
                    
                </div>

               
            </div> */}
            <Grid container spacing={3}>
                {country === 'India' || (
                    <Grid item xs={12} md={12}>
                      {tax_identification_number? <Typography>
                            <span>PAN Card:</span>
                            <span style={{ marginLeft:"5rem" }}>{tax_identification_number}</span>
                        </Typography>: "" }
                    </Grid>
                )}

             {address ?   <Grid item xs={12}>
                    <div>
                        <Typography style={{ display:'flex' }}>
                            <span>Address:</span>
                            <span style={{ marginLeft:"5.5rem" }}>
                                <div>{address ? address : ''}, <span>{city || ''}</span></div> 
                                
                                <div>{state ? state : ''}</div>
                                <div>{zip_code || ''}</div>
                            </span>
                        </Typography>
                    </div>
                </Grid>  :null }
            </Grid>

        </Fragment>
    );
}