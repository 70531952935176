import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { handleQuestionTypeUpdate } from "../../../redux/actions/JobSqActions";

function TypeSelector( { type, questionIndex } ) {
    const [show, setShow] = useState( false );
    const componentRef = useRef( null );
    const dispatch = useDispatch(); 

    function handleOptiontext( value ){
        switch( value ) {
            case 1:
                return 'Multiple Choice';
            case 2:
                return 'Single Choice';
            case 3:
                return 'Numeric';
            case 4:
                return 'Custom Question';
        }
    }

    function handleChange( value ) {
        dispatch( handleQuestionTypeUpdate( questionIndex, value ) );
        setShow( false );
    }

    useEffect( () => {
        const handleClickOutside = ( event ) => {
            if ( componentRef.current && !componentRef.current.contains( event.target ) ) {
                setShow( false );
            }
        };
        document.addEventListener( "click", handleClickOutside );
        return () => {
            document.removeEventListener( "click", handleClickOutside );
        };
    }, [] );

    return (
        <div className="sqTypeSelector" ref={componentRef}>
            <div className="sqTypeSelector-toggle" onClick={() => setShow( !show )}>
                <span>{handleOptiontext( type )}</span>
                <span className=""><FontAwesomeIcon icon={faChevronDown} /></span>
            </div>
            { show && <div className="sqTypeSelector-options">
                <span className="sqTypeSelector-option" onClick={() => handleChange( 1 )}>Multiple Choice</span>
                <span className="sqTypeSelector-option" onClick={() => handleChange( 2 )}>Single Choice</span>
                <span className="sqTypeSelector-option" onClick={() => handleChange( 3 )}>Numeric</span>
                <span className="sqTypeSelector-option" onClick={() => handleChange( 4 )}>Custom Question</span>
            </div>}
        </div> 
    );
}

export default TypeSelector;
