import React, { Fragment, useEffect,  } from 'react';
import { TALENT_ROLE } from '../../../constants';
import util from '../../utils/miniUtils';
import CertificationsContainer from './certifications/CertificationsContainer';
import EducationContainer from './education/EducationContainer';
import Employee from './employee/Employee';
import EvaluationsContainer from './evaluations/EvaluationsContainer';
import NotesContainer from './notes/NotesContainer';
import usePersonalInformation from './personal_information/usePersonalInformation';
import SkillsContainer from './skills/SkillsContainer';
import SocialMediaContainer from './social_media/SocialMediaContainer';
import { TalentStore } from './TalentStore';
import TestsContainer from './test_talent/TestsContainer';
import WorkHistoryContainer from './work_history/WorkHistoryContainer';
import JobsAppliedContainer from "./jobs_applied/JobsAppliedContainer";
import EmployeeView from './employee/EmployeeView';
import IntroVideo from './IntroVideo/IntroVideo';

const ProfileScroll = ( { talent_id ,isEditing } ) => {
    // Retrieve all methods and setStates you want to share
    const { getUserInformation } = usePersonalInformation( talent_id );


    const talent = TalentStore.useState( ( state ) => state.talent );

    useEffect( () => {
        getUserInformation( talent_id ).then( null );
    }, [] );

    if ( talent === null ) {
        return util.LOADING_SCREEN( true );
    }
  

    return (
        <Fragment>
            <div className="mb-5">
                   
                       
                {talent.employee_id ? 
                    ( util.user.role != TALENT_ROLE &&  isEditing === false )     ? <div id='employee'><EmployeeView employee_id={talent.employee_id} /></div>: <div id='employee'>
                        <Employee employee_id={talent.employee_id} /></div> : ""}
                {/* {( talent.employee_id && util.user.role != TALENT_ROLE && isEditing === true )  &&  <div id='employee'>
                    </div>} */}
              
                <div id="skills">
                    <SkillsContainer talent_id={talent_id} isEditing={isEditing} />
                </div>

                <div id="certification">
                    <CertificationsContainer talent_id={talent_id} isEditing={isEditing}/>
                </div>

                <div id="workhistory">
                    <WorkHistoryContainer talent_id={talent_id} isEditing={isEditing}/>
                </div>
                
                <div id="socialmedia">
                    <SocialMediaContainer talent_id={talent_id} isEditing={isEditing}/>
                </div>
                <div id="education">
                    <EducationContainer talent_id={talent_id} isEditing={isEditing}/>
                </div>

                 <div id="jobsapplied">
                    <JobsAppliedContainer talent_id={talent_id} isEditing={isEditing}/>
                </div> 

                <div id="notes">
                    <NotesContainer talent_id={talent_id} isEditing={isEditing}/>
                </div>

                <div id="test"> 
                    <TestsContainer talent_id={talent_id} isEditing={isEditing}/>
                </div>

                <div id="evaluations">
                    <EvaluationsContainer talent_id={talent_id} talent={talent} isEditing={isEditing}/>
                </div>
                {/* <div id="self-intro">
                    <IntroVideo talent_id={talent_id} isEditing={isEditing} />
                </div> */}
            </div>
        </Fragment>
    );
};

export default ProfileScroll;
