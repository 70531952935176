import util from "./miniUtils";

const routes = {};

routes.recruiter = () => {
    const roleRoutes = [
        {
            title: "My Recruitment",
            icon: "Dashboard",
            url: "/my-recruitment",
        },
        util.customerPermissions() ? {
            title: "Support",
            icon: 'Customer',
            url: '/support'
        } : "",
        {
            title: "Jobs",
            icon: "Jobs",
            url: "/jobs",
        },
        {
            title: "Talents",
            icon: "talent",
            url: "/candidates",
        },
        {
            title: "Employees",
            icon: "employee",
            url: "/employees",
        },
        {
            title: "Assets",
            icon: "laptop",
            url: "/assets",
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        util.user.employee_id !== null ? {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } : '',
        // {
        //     title:"Settings",
        //     icon:'grear',
        //     url:'/settings'
        // }
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;
};
routes.vendor = () => {
    const roleRoutes = [
        
        {
            title: "Jobs",
            icon: "Jobs",
            url: "/jobs",
        },
        {
            title: "Talents",
            icon: "talent",
            url: "/candidates",
        },
        
       
    ];
    return roleRoutes;
};

routes.talent = () => {
    const links = [
        {
            title: "Job Search",
            icon: 'Jobs',
            url: '/jobs'
        },
        {
            title: "Profile",
            icon: 'talent',
            url: '/profile/' + util.user.candidate_id
        }
    ];
    if ( util.user.permissions.employee === 1 ) {
        links.push( {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } );
    }
    return links;
};
routes.customer = () => {
    const roleRoutes = [
        {
            title: "Support",
            icon: 'Customer',
            url: '/support'
        },
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;
};

routes.employee = () => {
    const roleRoutes = [
        {
            title: "Profile",
            icon: 'talent',
            url: '/profile/' + util.user.candidate_id
        },
        {
            title: "Assets",
            icon: 'laptop',
            url: '/assets'
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        util.user.employee_id !== null ? {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } : '',
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;

};

routes.client = () => {
    const roleRoutes = [
        {
            title: "My Jobs",
            icon: 'Jobs',
            desc: "Jobs sent to eJRekruit",
            url: '/my_jobs'
        },
        {
            title: "My Talents",
            icon: 'Jobs',
            desc: "Talents assigned to you by eJRekruit",
            url: '/my_talents'
        }
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;
};
routes.evaluator = () => {
    const roleRoutes = [
        {
            title: "Evaluations by Jobs",
            icon: 'Jobs',
            url: '/evaluations'
        },
        {
            title: "Evaluations by Talents",
            icon: 'Jobs',
            url: '/evaluations_by_talents'
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        util.user.employee_id !== null ? {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } : '',
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;
};
routes.partner = () => {
    const roleRoutes = [
        {
            title: "My Jobs",
            icon: 'Jobs',
            desc: "Jobs sent to eJRekruit",
            url: '/my_jobs'
        },
        {
            title: "My Talents",
            icon: 'Jobs',
            desc: "Talents assigned to you by eJRekruit",
            url: '/my_talents'
        }
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;
};

routes.recruiter_manager = () => {
    const roleRoutes = [
        {
            title: "Dashboard",
            icon: 'Dashboard',
            url: '/dashboard'
        },
        util.customerPermissions() ? {
            title: "Support",
            icon: 'Customer',
            url: '/support'
        } : "",
        {
            title: "Jobs",
            icon: 'Jobs',
            url: '/jobs'
        },
        {
            title: "Talent",
            icon: 'talent',
            url: '/candidates'
        },
        {
            title: "Employees",
            icon: "employee",
            url: "/employees",
        },
        {
            title: "Assets",
            icon: 'laptop',
            url: '/assets'
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        util.user.employee_id !== null ? {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } : '',
        {
            title: "Settings",
            icon: 'grear',
            url: '/settings'
        },
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;
};

routes.employee_manager = () => {
    const roleRoutes = [
        {
            title: "Jobs",
            icon: 'Jobs',
            url: '/jobs'
        },
        {
            title: "Talent",
            icon: 'talent',
            url: '/candidates'
        },
        {
            title: "Employees",
            icon: "employee",
            url: "/employees",
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        util.user.employee_id !== null ? {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } : '',
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;
};

routes.admin = () => {
    const roleRoutes = [
        {
            title: "Jobs",
            icon: 'Jobs',
            url: '/jobs'
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        {
            title: "Employees",
            icon: "employee",
            url: "/employees",
        },
        {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        }
    ];
    const combinedRoutes = [...roleRoutes,...routes.custom()];
    const uniqueFilteredRoutes = combinedRoutes.filter( ( route, index, self ) =>
        typeof route === 'object' && 
    self.findIndex( r => r.url === route.url ) === index
    );

    return uniqueFilteredRoutes;
};

routes.custom = () => {
    let { Dashboard,Jobs,Talents,Assets,Workload,Appraisal,Employee,Settings,Customer } = false;
    Dashboard = util.user.module_permission.some( permission => permission.module === 'Dashboard' && permission.action === 'View' );
    Customer = util.user.module_permission.some( permission => permission.module === 'Customer' && permission.action === 'View' );
    Jobs = util.user.module_permission.some( permission => permission.module === 'Jobs' && permission.action === 'View' );
    Talents = util.user.module_permission.some( permission => permission.module === 'Talents' && permission.action === 'View' );
    Assets = util.user.module_permission.some( permission => permission.module === 'Assets' && permission.action === 'View' );
    Workload = util.user.employee !== null && util.user.module_permission.some( permission => permission.module === 'Workload' && permission.action === 'View' );
    Appraisal = util.user.employee !== null && util.user.module_permission.some( permission => permission.module === 'Appraisal' && permission.action === 'View' );
    Employee = util.user.module_permission.some( permission => permission.module === 'Employee' && permission.action === 'View' );
    Settings = util.user.module_permission.some( permission => permission.module === 'Settings' && permission.action === 'View' );
    return [
        Dashboard ?
            {
                title: "Dashboard",
                icon: 'Dashboard',
                url: '/dashboard'
            }: '',
        Talents ?
            {
                title: "Talents",
                icon: "talent",
                url: "/candidates",
            }: '',
        Assets ?
            {
                title: "Assets",
                icon: "laptop",
                url: "/assets",
            }: '',
        Workload ?
            {
                title: "Workload",
                icon: 'workload',
                url: '/workload'
            }: '',
        Appraisal ?
            {
                title: "Appraisal",
                icon: "appr",
                url: "/appraisal",
            }: '',
        Employee ?
            {
                title: "Employees",
                icon: "employee",
                url: "/employees",
            }: '',
        Settings ?
            {
                title: "Settings",
                icon: 'grear',
                url: '/settings'
            }: ''
    ];
};

export default routes;