import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import util from "../../../utils/miniUtils";
import ImageComponent from '../../../utils/ImageComponent';


const SocialMediaPopUp = ( { showModal,toggleModal,handleChanges,updateUrls,urls } ) => {
    const [validationErrors, setValidationErrors] = useState( {
        linkedin: '',
        facebook: '',
        twitter: '',
        github: '',
        web: '',
    } );
    
    // Function to validate the format of each social media link
    const validateLinks = () => {
        const errors = {};
        const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}(\/.*)?$/;
        const platformKeys =['facebook','github','linkedin','twitter','web'];
      
        platformKeys.forEach( ( key ) => {
            const value = urls[key];
            if ( typeof value === 'string' && value.trim() && !urlRegex.test( value.trim() ) ) {
                errors[key] = 'Invalid account';
            }
        } );
      
        setValidationErrors( errors );
        return Object.keys( errors ).length === 0;
    };
      
    
    // Function to handle the Save button click
    const handleSaveClick = () => {
        const isValid = validateLinks();
        if ( isValid ) {
          
            updateUrls();
        }
       
    };
    
    return (
        <div>
            <Modal show={showModal} onHide={toggleModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Social Media Links</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <SocialMediaSingle 
                            field="linkedin" 
                            handleChanges={handleChanges} 
                            image="LinkedIn"
                            updateUrls={updateUrls}
                            urls={urls}
                        />
                        <SocialMediaSingle 
                            field="facebook" 
                            handleChanges={handleChanges} 
                            image="Facebook"
                            updateUrls={updateUrls}
                            urls={urls}
                        />
                        <SocialMediaSingle 
                            field="twitter" 
                            handleChanges={handleChanges} 
                            image="Twitter"
                            updateUrls={updateUrls}
                            urls={urls}
                        />
                        <SocialMediaSingle 
                            field="github" 
                            handleChanges={handleChanges} 
                            image="github"
                            updateUrls={updateUrls}
                            urls={urls}
                        />
                        <SocialMediaSingle 
                            field="web" 
                            handleChanges={handleChanges} 
                            image="Web"
                            updateUrls={updateUrls}
                            urls={urls}
                        />
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-right mb-2">
                        <button
                            onClick={handleSaveClick}
                            className="btn btn-secondary sizeBtn activeBtn float-right"
                        >
                            <FontAwesomeIcon icon={faSave} className="mr-2" />
					Save
                        </button>
                    </div>
                    <button className='btn btn-secondary sizeBtn activeBtn' onClick={toggleModal}>
                    Close
                    </button>
                    {/* You can add more buttons as needed */}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SocialMediaPopUp;
function SocialMediaSingle( { urls, handleChanges, updateUrls, field, image } ) {
    const [validationError, setValidationError] = React.useState( '' );

    // Function to handle changes in the input field and validate the URL format
    const handleChange = ( e ) => {
        const { name, value } = e.target;
        handleChanges( e );
  
        // Validate the URL format and set the validation error message
        const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}(\/.*)?$/;
        const isValid = value.trim() === '' || urlRegex.test( value.trim() );
        setValidationError( isValid ? '' : 'Invalid account' );
    };
    return (
        <div className="row">
            <div className="col-md-1 text-center">
                {
                    urls[field] ?
                        <a target="new" href={urls[field] ? urls[field] : "#"}>
                            <img width="30px" src={`/images/${image}.webp`} alt={`Facebook icon`} />
                        </a>
                        :
                        <img width="30px" src={`/images/${image}.webp`} alt={`github icon`} />

                }

            </div>
            <div className="col-md-11 p-2">
                <util.InputSlim
                    name={field}
                    placeholder={`Your ${field} url`}
                    onChange={handleChange}
                    value={urls[field] ? urls[field] : ""}
                    label={field}
                />
                {validationError && <div className="text-danger">{validationError}</div>}
            </div>

            {/* Display the saved URL */}
            {/* <div className="col-md-4 p-2">
                {urls[field] && (
                    <div>
                        <b>Saved URL:</b>{" "}
                        <a target="_blank" rel="noopener noreferrer" href={urls[field]}>
                            {urls[field]}
                        </a>
                    </div>
                )}
            </div> */}
           
        </div>
    );
}