import React, { useEffect, useRef, useState } from 'react';
import { PositionsStore } from '../../positions/stores/PositionsStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import useJobSearch from '../../positions/main/useJobSearch';

const JobSearchForm = () => {
    const target = useRef( null );
    const urlParams = new URLSearchParams( window.location.search );
    const position = PositionsStore.useState( s => s );
    const [location, setLocation] = useState( urlParams.get( "location" ) || "" );
    const [locationResult, setLocationResult] = useState( [] );
    const { handleSearch } = useJobSearch();
    const [typingLocation, setTypingLocation] = useState( false );
    const [isMobile, setIsMobile] = useState( false );
    const [showRadiusSearch, setShowRadiusSearch] = useState( false );

    useEffect( () => {
        // Check if the screen width is smaller than 768px (typical mobile devices)
        const checkIsMobile = () => {
            setIsMobile( window.innerWidth <= 768 );
        };
  
        // Add a resize event listener to update the 'isMobile' state
        window.addEventListener( "resize", checkIsMobile );
  
        // Initial check
        checkIsMobile();
  
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener( "resize", checkIsMobile );
        };
    }, [] );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        PositionsStore.update( s => {
            s[name] = value;
        } );
    };

    const checkLocationType = async ( value ) => {
        if( typingLocation ) {
            if ( value.length < 3 ) {
                return;
            }
            try {
                const response = await fetch(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent( value )}&class=${encodeURIComponent( "boundary" )}&addressdetails=1`
                );
                const data = await response.json();
            
                if ( data.length > 0 ) {
                    setLocationResult( data );
                }
            } catch ( error ) {
                console.error( 'Error:', error );
            }
        }
    };

    const handleLocationChange = ( e ) => {
        setTypingLocation( true );
        setLocation( e.target.value );
    };

    const handleClick = ( item ) => {
        const { lat, lon, address } = item;
        PositionsStore.update( ( s ) => {
            s.longitude = lon;
            s.latitude = lat;
            s.city = address.city ? address.city : "";
            s.state = address.state ? address.state : "";
            s.country = address.country ? address.country : "";
            s.location = item.display_name;
        } );
        setLocation( item.display_name );
        setLocationResult( [] );
        setTypingLocation( false );        // Extract the relevant parts of the selected location
    };

    const openSideBarFilter = () => {
        PositionsStore.update( s=> {s.showFilter= !s.showFilter;} );
    };

    const handleSearchClick = () => {
        handleSearch().then( null );
    };

    const handleUpdateRadiusValue = ( e ) => {
        const { name, value } = e.target;
        if( name === "radius" ) {
            PositionsStore.update( s => {
                s.radius = value;
            } );
        } else if( name === "radiusUnit" ) {
            PositionsStore.update( s => {
                s.radiusUnit = value;
            } );
        }
    };

    const handleClearLocation = () => {
        setLocation( "" );
        PositionsStore.update( s => {
            s.location = "";
            s.city = "";
            s.state = "";
            s.country = "";
            s.latitude = "";
            s.longitude = "";
            s.zipcode = "";
            s.radius = "";
            s.radiusUnit = "";
        } );
    };

    useEffect( () => {
        if( position.city ) {
            setShowRadiusSearch( true );
        } else {
            setShowRadiusSearch( false );}
    },[position.city] );

    useEffect( () => {
        const delay = 1000; // Debounce delay in milliseconds
        const timerId = setTimeout( () => {
            // Perform search logic here
            checkLocationType( location );
        }, delay );

        return () => {
            clearTimeout( timerId ); // Clear the timer on component unmount or when searchQuery changes
        };
    }, [location] );

    useEffect( () => {
        const urlParams = new URLSearchParams( window.location.search );
        if( position.page == urlParams.get( "page" ) ) {
            handleSearch().then( null );
        }
    },[position.page] );

    return (
        <div className="container">
            <div className={showRadiusSearch ? "jobSearchForm-wrapperWithRadius" : "jobSearchForm-wrapperWithoutRadius"}>
                {/* Magnifying icon and job search input */}
                <div className='jobSearchForm-searchInput'>
                    <div className='jobSearchForm-searchIcon'><FontAwesomeIcon icon={faSearch} alt="logo" /></div>
                    <input className='form-control border-left-0 rounded-0'
                        onKeyDown={
                            ( key ) => {
                                if ( key.keyCode === 13 ) {
                                    handleSearch().then( null );
                                }
                            }
                        }
                        ref={target}
                        margin="normal"
                        placeholder={isMobile ? "Search a job" : "Search a Job in eJRekruit"}
                        name="job_position"
                        value={position.job_position}
                        fullwidth="true"
                        type="search"
                        onChange={handleChange} />
                </div>

                {/* Location input, miles and km selectors */}
                <div className='jobSearchForm-locationInput'>
                    <div className='form-control form-control border-left-0 rounded-0 d-flex align-items-center'>
                        <input
                            className='jobSearchForm-locationInput-input'
                            placeholder={isMobile ? "Location" : "City, state, country, zipcode"}
                            // name={locationType}
                            value={location}
                            onChange={handleLocationChange} />
                        {location.length > 0 ? <button onClick={handleClearLocation}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                            </svg>
                        </button> : ""}
                    </div>
                    {( location.length > 0 && typingLocation ) && locationResult && (
                        <div style={{ position: "relative" }}>
                            <ul
                                className="location-list"
                                style={{
                                    position: "absolute",
                                    // top: "100%",
                                    // left: "200px",
                                    // right: "-3.5rem",
                                    backgroundColor: "white",
                                    // Set a maximum width for the list
                                    minWidth: "100%", // Set a minimum width for the list
                                    maxHeight: "310px", // Set a maximum height for the list
                                    overflow: "auto",
                                    zIndex: 100
                                }}
                            >
                                {locationResult.map( ( item ) => (
                                    <li
                                        className="p-2 isCursor"
                                        key={item.place_id}
                                        onClick={() => handleClick( item )}
                                    >
                                        {item.display_name}
                                    </li>
                                ) )}
                            </ul>
                        </div>
                    )}
                </div>
                
                { showRadiusSearch ? <>
                    {/* Search button and filter button */}
                    <select className="form-control border-left-0 rounded-0 jobSearchForm-select jobSearchForm-selectValue"
                        name="radius" onChange={handleUpdateRadiusValue}>
                        <option value="">Distance</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                    </select>

                    <select className='form-control border-left-0 rounded-0 jobSearchForm-select jobSearchForm-selectMiles'
                        name="radiusUnit" onChange={handleUpdateRadiusValue}>
                        <option value="">Unit</option>
                        <option value="miles">Miles</option>
                        <option value="km">KM</option>
                    </select>
                </> : null}

                {/* <div className='jobSearchForm-buttonsDiv'> */}
                <button type="button" className="jobSearchForm-SearchButton" onClick={handleSearchClick}>
                    <FontAwesomeIcon icon={faSearch} className="mr-2" /> 
                        Search
                </button>

                <button type="button" className="jobSearchForm-FilterButton" onClick={openSideBarFilter}>
                    <FontAwesomeIcon icon={faFilter} />
                </button>
                {/* </div> */}
            </div>
        </div>
    );
};

export default JobSearchForm;