import React, { Fragment, useEffect, useState } from "react";
import util from "../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt, faSyncAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import StarRatings from "react-star-ratings";
import SweetAlert from "react-bootstrap-sweetalert";

export default function SkillRow( { deleteSkill, skillSingle, index, interviewId, isFinished } ) {
	
    const [skill, setSkill] = useState( ()=>{
        let t = { ...skillSingle };
        t.rate = t.rate * 2;
        return t;
    } );
    const [skillBase, setSkillBase] = useState( skill );
    const [hasChanged, setHasChanged] = useState( false );
    const [loading, setLoading] = useState( false );
    const [alertSA, setAlertSA] = useState( false );
    const [expandSkills, setExpandSkills] = useState( false );
	
    useEffect( () => {
	
    }, [expandSkills] );
	
    function handleChanges( e ) {
        const { name, value } = e.target;
        setSkill( { ...skill, [name]:value.length === 0 ? null: value } );
    }
	
    useEffect( () => {
        // Always check if skill feedback changed, if is, enable update button
        if ( JSON.stringify( skill.feedback )!== JSON.stringify( skillBase.feedback ) ){
            setHasChanged( true );
        }else{
            setHasChanged( false );
        }
    },[skill, skillBase] );
	
    function resetRate() {
        // Update rate of this skill
        setSkill( { ...skill, rate:skillBase.rate } );
    }
	
    async function updateSkillEvaluation() {
        try {
            let data = {
                feedback:skill.feedback,
                skill_id: skill.skillId,
                id: skill.id
            };
            setLoading( true );
            setSkillBase( skill );
            const request = await util.axios.put( `${util.url}/evaluator_jobs/skill_evaluation/feedback`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setLoading( false );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }
	
    async function handleChangesRate( rate ) {
        try {
            let data = {
                rate:rate /2,
                skill_id: skill.skillId,
                id: skill.id
            };
			
            setLoading( true );
            const request = await util.axios.put( `${util.url}/job/skill_evaluation/rate/?token=${interviewId}`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setSkill( { ...skill, rate:rate } );
            // setSkillBase(skill)
            setLoading( false );
            if ( hasChanged ) setHasChanged( true );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }
	
    return(
        <Fragment>
            <div className="row">
                <div className="col-md-4">
                    <h6>{skill.skill_name}</h6>
                    <p>{skill.yearsOfExperience} Years of Experience</p>
                    <p>Certification: <b>{skill.certification}</b></p>
                    {skill.certification ? <p>Certification: <b><button className="btn-link">{skill["certificationDoc"]}</button></b></p>:"" }
                    {isFinished ? "":
                        <p>
                            <span  className="text-danger isCursor" onClick={() => setAlertSA( true )}>
                                <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                            Remove Skill
                            </span>
                        </p>
                    }
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-inline-block iconGray">
                                <FontAwesomeIcon icon={faCommentAlt} className="mr-2" /> <span>Feedback</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-around">Rate the skill:
                                {!isFinished ?
                                    <Fragment>
                                        <StarRatings
                                            rating={skill.rate || 0}
                                            starDimension="15px"
                                            starSpacing="1px"
                                            numberOfStars={10}
                                            changeRating={( rate )=> handleChangesRate( rate )}
                                            starRatedColor="gold"
                                            starHoverColor="gold"
                                            className="fixStartRating"
                                        />
                                        {skill.rate === 0 || skill.rate === null ? ""
                                            :<FontAwesomeIcon onClick={()=> resetRate()} icon={faSyncAlt} className="isCursor text-dark" title="Reset rate" />}
                                    </Fragment>
                                    :
                                    <StarRatings
                                        rating={skill.rate || 0}
                                        starDimension="20px"
                                        starSpacing="1px"
                                        numberOfStars={10}
                                        starRatedColor="rgb(233,227,92)"
                                        className="fixStartRating"
                                    />}
							
                            </div>
                        </div>
                    </div>
                    <div>
                        <textarea disabled={isFinished} className="form-control" onChange={handleChanges} name="feedback" rows={5} value={skill.feedback ||""}/>
                    </div>
                    {hasChanged ? <div className="text-right mt-2">
                        <button onClick={()=> {
                            let t = skill;
                            t.feedback = skillBase.feedback;
                            setSkill( Object.assign( {}, t ) );
                        }} className="btn btn-sm text-danger">
							Cancel
                        </button>
                        <button onClick={()=>updateSkillEvaluation()} className="btn btn-sm btn-primary">
							Save
                        </button>
                    </div>:""}
				
                </div>
				
				
                {alertSA ?
                    <SweetAlert
                        show={alertSA}
                        warning
                        showCancel
                        confirmBtnText="Yes, Delete Skill"
                        confirmBtnBsStyle="danger"
                        title="Are you sure?"
                        onConfirm={()=>deleteSkill( index )}
                        onCancel={()=>setAlertSA( false )}
                        // focusCancelBtn
                    >
					
                    </SweetAlert>:""}
				
				
            </div>
            <hr/>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}

