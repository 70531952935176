import React, { useState } from "react";
import util from "../../../utils/miniUtils";
import { toast } from "react-toastify";
import { TalentStore } from "../TalentStore";

export default function usePersonalInformation() {
	
    const [data, setData] = useState( null );
    const [key, setKey] = useState( 'skills' );
    const [jobTitles, setJobTitles] = useState( [] );
    const [showAlert, setShowAlert] = useState( false );
    const [selected, setSelected] = React.useState( [] );
    const talent = TalentStore.useState( state => state.talent );
	
    const startLoading = () => {
        TalentStore.update( t => {
            t.loading = true;
        } );
    };
	
    const stopLoading = () => {
        TalentStore.update( t => {
            t.loading = false;
        } );
    };
	
    // Handle changes in inputs for all the talent personal information
    const handleChanges = ( e ) => {
        let { name, value } = e.target;
		
        TalentStore.update( t => {
            t.talent[name] = value;
        } );
    };
	
    const getUserInformation = async ( id ) => {
        try {
            startLoading();
            const response = await util.axios.get( `candidates/${id}` );
            let { error, msg, candidate } = response["data"];
            if ( error ) return util.handleError( msg );
            // IMPORTANT
            // ******************************
            // Add some other fields that are going to be used later
            candidate.isUploading = false;
            // ******************************
            TalentStore.update( t => {
                t.talent = candidate;
            } );
            stopLoading();
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    const updateData = async ( value=talent ) => {
        try {
            startLoading();
            const response = await util.axios.put( `candidates/update/${value.id}`, value );
            const { error, msg } = response["data"];
            if ( error ) return util.handleError( msg );
            toast.success( msg );
            getUserInformation( `${value.id}` );
            stopLoading();
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    const updateJobTitles = async () => {
        if ( selected.length === 0 ) return;
        try {
            startLoading();
            const response = await util.axios.put( `talent/update_job_titles/${talent.id}`, selected );
            const { error, msg } = response["data"];
            if ( error ) return util.handleError( msg );
            // toast.success(msg);
            stopLoading();
        } catch ( e ) {
            util.handleError( e );
        }
    };
    
	
    const getListOfJobTitles = async ( search ) => {
        try {
            startLoading();
            const response = await util.axios.get( `catalog/job_title_search?search=${search}` );
            const { error, msg, data } = response["data"];
            if ( error ) return util.handleError( msg );
            setJobTitles( data );
            stopLoading();
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
	
    // SIDEBAR
    const sendLinkToCandidate = async () => {
        const userId = talent.id;
        try {
            startLoading();
            const response = await util.axios.get( `talent/send_link_candidate/${userId}` );
            const { error, msg, notes } = response["data"];
            if ( error ) return util.handleError( msg );
            TalentStore.update( t => {
                t.talent.sendLink = 1;
                t.talent.notes = notes;
                t.talent.sendLinkDate = util.humanDate( new Date().toISOString() );
            } );
            stopLoading();
            toast.success( msg );
        } catch ( e ) {
            util.handleError( e );
        }
    };
	
    // State used in Sidebar for NOTES
    const [selectedNote, setSelectedNote] = useState( null );
	
    return {
        data, setData, key,
        selectedNote, setSelectedNote,
        setKey, showAlert, setShowAlert,
        handleChanges, getUserInformation, updateData,
        sendLinkToCandidate,
        getListOfJobTitles, jobTitles,
        selected, setSelected,
        updateJobTitles
    };
}