import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { TextField, Tooltip } from "@material-ui/core";
import { CountryInfo } from "../../../utils/countryInfo";
import ImageComponent from '../../../utils/ImageComponent';


const CountryAutoFill = ( { newState, onChangeHandler } ) => {
    const [countryState, setCountryState] = useState( {
        loading: false,
        countries: CountryInfo,
        errorMessage: "",
    } );

    const { countries } = countryState;

    // Find selected country data
    const searchSelectedCountry = countries.find( ( obj ) => {
        return obj.name === newState.country;
    } );

    const handleChangePhone = ( e ) => {
        const inputValue = e.target.value;
        const formattedValue = inputValue.replace( /[^0-9]/g, "" ); // Remove non-numeric characters

        if ( formattedValue.length <= 10 ) {
            onChangeHandler( e );
        }
    };

    return (
        <React.Fragment>
            <div className="mb-3">
                <div>
                    <div>
                        <Tooltip
                            title={newState.country ? newState.country : "No country selected"}
                        >
                            <TextField
                                select
                                size="small"
                                type="number"
                                variant="outlined"
                                name="country"
                                label="Country"
                                value={newState.country}
                                onChange={onChangeHandler}
                                className="form-control"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <optgroup label="Most Active">
                                    <option value="">--</option>
                                    <option value="United States">United States</option>
                                    <option value="Canada">Canada</option>
                                    <option value="India">India</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                </optgroup>
                                <optgroup label="All Countries">
                                    <option value="" />
                                    {countries.map( ( item ) => (
                                        <option key={uuidv4()} value={item.name}>
                                            {item.name}
                                        </option>
                                    ) )}
                                </optgroup>
                            </TextField>
                        </Tooltip>
                    </div>
                    <div className="mt-3">
                        {searchSelectedCountry && (
                            <div className="d-flex row">
                                <div className="col-md-1 mt-2 form-control ml-3">
                                    <img
                                        style={{ width: "25px", height: "20px" }}
                                        src={searchSelectedCountry.flagPng}
                                        alt={searchSelectedCountry.name}
                                    />
                                </div>
                                <div className="col-md-2 mt-2 form-control ml-1">
                                    <p>
                                        {searchSelectedCountry.root}
                                        {searchSelectedCountry.name !== "United States" &&
                      searchSelectedCountry.suffix}
                                    </p>
                                </div>
                                <div className="col-md-8 ml-1">
                                    <TextField
                                        style={{ width: "106%" }}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        placeholder="Phone"
                                        className="mt-2 form-control"
                                        name={"phone_mobile"}
                                        value={newState.phone_mobile}
                                        onChange={handleChangePhone}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CountryAutoFill;
