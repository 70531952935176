import { Accordion, Button, Card, ListGroup, Modal } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import { PositionsStore } from "../stores/PositionsStore";
import util from "../../utils/miniUtils";
import { Link } from "react-router-dom";
import ManageWorkLoadDetail from "../../workload/manage_workload/components/ManageWorkLoadDetail";
import usePipeView from "../../workload/shared/pipeview/usePipeView";

const GreetingPopup = () => {
    const {

        workloadDetail,
        showWorkLoadDetail,
        closeWorkLoadDetail,
    } = usePipeView();
    const [notifications, setNotifications] = useState([]);
    const [announcements, setAnnouncements] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    // const isModalVisible = PositionsStore.useState(state => state.isModalVisible);
    const position = PositionsStore.useState(state => state);


    const handleClose = () => {
        // if (position.isModalVisible) {
        //     const urlParams = new URLSearchParams(window.location.search);
        //     const jobId = urlParams.get('job');

        //     if (jobId !== null) {
        //         window.history.replaceState("", "", `/careers?search=&job=${jobId}`);
        //     } else {
        //         window.history.replaceState("", "", "/jobs");
        //     }
        //     // Every time the talent log in, it will be redirected to the main page
        //     setTimeout(() => {
        //         window.location.reload();
        //     }, 1500);
        // } else {
        //     PositionsStore.update(s => { s.createAccount = false; });
        // }

        PositionsStore.update(s => {
            s.firstTime = false;
            s.isModalVisible = false
            s.isTalent = false

        });

        // console.log(position.firstTime)
    };
    async function getNotifications() {
        try {
            setIsLoading(true);
            const request = await util.axios.get(`/notifications/get_notifications`);
            const { error, msg, data, notRead } = request.data;


            if (error) throw msg;
            setNotifications(data);
            console.log(data, notRead, "hello");
            setIsLoading(false);
        } catch (e) {
            util.handleError(e);
        }
    }
    useEffect(() => {

        getNotifications();

    }, []);

    const fetchAnnouncements = async () => {
        try {
            setIsLoading(true);
            const request = await util.axios.get('/customer_announcement');
            const { error, data, msg } = request.data;
            if (error) throw new Error(msg);
            setAnnouncements(data);
            // console.log(data, "announcement")
        } catch (error) {
            util.handleError(error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchAnnouncements()
    }, []);
    const shouldShowNotification = (role, model) => {
        if (role === 1 || role === 2 || role === 6) {
            return model !== 'talent';
        } else if (role === 7 || role === 8) {
            return model !== 'job' && model !== 'talent';
        }
        return false;
    };

    return (
        <div>
            <Modal show={position.isModalVisible} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome to eJRekruit.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Header>
                            Notifications
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {notifications.length > 0 ? (
                                    notifications.map((notification) => {
                                        if (shouldShowNotification(util.user.role, notification.model)) {
                                            return (
                                                <ListGroup.Item key={notification.notification_id}>
                                                    {notification.model === 'workload' ? (
                                                        <div onClick={(e) => showWorkLoadDetail(e, JSON.parse(notification.workload).id)} className="isCursor">
                                                            {notification.message}
                                                        </div>
                                                    ) : (
                                                        <Link to="#" onClick={() => window.open(notification.link)}>
                                                            <div>{notification.message}</div>
                                                        </Link>
                                                    )}
                                                </ListGroup.Item>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                ) : (
                                    <ListGroup.Item>No notifications available</ListGroup.Item>
                                )}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                    {util.customerPermissions() ? <Card>
                        <Card.Header>
                            Announcements
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                {announcements.length > 0 ? (
                                    announcements.map((announcement) => (
                                        <ListGroup.Item key={announcement.announcement_id}>
                                            {announcement.announcement_title}
                                        </ListGroup.Item>
                                    ))
                                ) : (
                                    <ListGroup.Item>No announcements available</ListGroup.Item>
                                )}
                            </ListGroup>
                        </Card.Body>
                    </Card> : ""}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            {
                workloadDetail.show &&
                <ManageWorkLoadDetail workload_id={workloadDetail.workload_id} handleClose={closeWorkLoadDetail} show={workloadDetail.show} permissionLevel={workloadDetail.permission_level} />
            }
        </div>
    );
};

export default GreetingPopup;