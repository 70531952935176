
const initialState = {
    isJobDeleted:false
    
};
  

const isJobDeletedReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case "SET_IS_DELETED":
            return {
                ...state,
                isJobDeleted: action.payload,
            };
        default:
            return state;
    }
};

  
export default isJobDeletedReducer;
