import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function NewNoteDialog( { show, hideNote, saveNewNote, note, handleChangeNewNote } ) {

    return (
        <Dialog
            open={show}
            onClose={hideNote}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
				Add a New Note
            </DialogTitle>
            <DialogContent>
                <table className="table">
                    <thead />
                    <tbody>
                        <tr>
                            <td>
                                <textarea
                                    rows={5}
                                    className="form-control"
                                    name="note"
                                    onChange={handleChangeNewNote}
                                    value={note.note}
                                >
                                </textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-right">
                    <span
                        onClick={hideNote}
                        className="text-danger mr-2 isPointer">
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
								Cancel
                    </span>

                    <button onClick={saveNewNote} className="btn-sm btn-primary btn">
                        <FontAwesomeIcon icon={faSave} className="mr-2" />
						Save
                    </button>

                </div>
            </DialogContent>
        </Dialog >
    );
}