import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import util from '../utils/miniUtils';

const EmailModal = ( { show, handleClose, contactInfo, workloadData } ) => {
    const [emailTemplates, setEmailTemplates] = useState( [] );
    const [email, setEmail] = useState( {
        cc: "",
        subject: "",
        description: "",
        templateName: ""
    } );
    const textAreaRef = useRef();
    const [selectedFile, setSelectedFile] = useState( null );

    useEffect( () => {
        if ( show ) {
            setEmail( {
                cc: contactInfo.email || "",
                subject: workloadData.short_description || "",
                description: workloadData.long_description || "",
                templateName: ""
            } );
            getEmailTemplates();
        }
    }, [show, contactInfo, workloadData] );

    const sendEmailHandler = async () => {
        if ( email.subject && email.description ) {
            try {
                const formData = new FormData();

                // Append candidates and email body as JSON strings
                formData.append( 'data', JSON.stringify( {
                    email: contactInfo.email,
                    emailBody: email
                } ) );

                // Append the attachment file if available
                if ( selectedFile ) {
                    formData.append( 'attachment', selectedFile );
                }

                const request = await util.axios.post( '/workload/send_email', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                } );

                const { msg, error } = request.data;
                if ( error ) throw msg;

                util.toast().success( msg );
                handleClose();
            } catch ( error ) {
                util.handleError( error );
            }
        } else {
            util.toast().error( "Email and Subject cannot be empty" );
        }
    };

    const handleTemplateSelector = ( e ) => {
        const email = emailTemplates.find( el => el.title === e.target.value );
        if ( email ) {
            setEmail( prev => ( { ...prev, description: email.description, templateName: email.title } ) );
        }
    };

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        setEmail( prev => ( { ...prev, [name]: value } ) );
    };

    function variableSelected( e ) {
        let VARIABLE = " [" + e.target.innerText + "] ";
        const textarea = textAreaRef.current;
        if ( email.description ) {
            if ( !textarea ) return; // If ref is null or empty or unattached

            const start = textarea.selectionStart; // from start of text to the cursor
            const end = textarea.selectionEnd; // from cursor to the end of text
            // separating the text into before and after cursor
            const textBefore = email.description.substring( 0, start );
            const textAfter = email.description.substring( end );

            setEmail( prev => ( { ...prev, description: textBefore + VARIABLE + textAfter } ) );

            // Reason for setTimeout: React does not guarantee DOM update immediately so using callback in setTimeout
            setTimeout( () => {
                textarea.focus(); // Focus the referenced element
                const cursorPos = start + VARIABLE.length; // calculate the expected position of cursor.
                textarea.setSelectionRange( cursorPos, cursorPos );
            }, 0 );
        } else {
            setEmail( prev => ( { ...prev, description: prev.description + VARIABLE } ) );
            textarea.focus();
        }
    }

    const getEmailTemplates = async () => {
        try {
            const request = await util.axios.get( 'app_resources/email_templates' );
            const { emails, error, msg } = request.data;
            if ( error ) throw msg;
            setEmailTemplates( emails );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleFileChange = ( event ) => {
        setSelectedFile( event.target.files[0] );
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Send Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="border-top my-1 pt-1">
                    <div className='d-flex mb-1 flex-wrap'>
                        <label htmlFor='templateSelect' className="col-lg-3 col-md-12 text-muted">Email Template</label>
                        <select id="templateSelect" className='form-control col-lg-9 col-md-12' onChange={handleTemplateSelector}>
                            <option></option>
                            {emailTemplates.map( el => <option key={el.id + el.title} value={el.title}>{el.title}</option> )}
                        </select>
                    </div>

                    <div className="d-flex mb-1 flex-wrap">
                        <label className="col-lg-3 col-md-12 text-muted" htmlFor="emailCCInput">CC:</label>
                        <input className="form-control col-lg-9 col-md-12" id="emailCCInput" value={email.cc} name="cc" onChange={handleChange} />
                    </div>

                    <div className="d-flex mb-1 flex-wrap">
                        <label className="col-lg-3 col-md-12 text-muted" htmlFor="emailSubjectInput">Subject:</label>
                        <input className="form-control col-lg-9 col-md-12" id="emailSubjectInput" value={email.subject} name="subject" onChange={handleChange} />
                    </div>
                    <div className="d-flex flex-wrap mb-1">
                        <div className="col-lg-3 col-md-12 bg-white p-2">
                            <label>You can use these variables</label>
                            <ul onClick={( e ) => variableSelected( e )}>
                                <li className="btn btn-sm btn-light w-100 mb-2">WORKLOAD_USER</li>
                                <li className="btn btn-sm btn-light w-100 mb-2">MY_NAME</li>
                                <li className="btn btn-sm btn-light w-100 mb-2">MY_EMAIL</li>
                                <li className="btn btn-sm btn-light w-100 mb-2">MY_PHONE</li>
                                <li className="btn btn-sm btn-light w-100 mb-2">EJ_AMERICA_LINK</li>
                                <li className="btn btn-sm btn-light w-100 mb-2">WORKLOAD_CUSTOMER</li>
                                <li className="btn btn-sm btn-light w-100 mb-2">WORKLOAD_ID</li>
                                <li className="btn btn-sm btn-light w-100 mb-2">WORKLOAD_STATUS</li>
                            </ul>
                        </div>
                        <div className="col-lg-9 col-md-12 p-0">
                            <textarea
                                ref={textAreaRef}
                                className="form-control"
                                placeholder='Email Content'
                                rows={16}
                                value={email.description || ""}
                                name="description"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap">
                        <label className="col-lg-3 col-md-12 text-muted" htmlFor="attachmentUpload">Attachment:</label>
                        <div className='col-lg-9 col-md-12'>
                            <input type="file" accept=".pdf" onChange={handleFileChange} id="attachmentUpload" />
                            {selectedFile && <div className="mb-1">
                                Selected file: <span className="btn btn-sm btn-light">
                                    {selectedFile.name}
                                </span></div>}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="secondary" size="sm" onClick={sendEmailHandler}>
                    Send
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EmailModal;
