import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { PositionsStore } from "../stores/PositionsStore";
import { toast } from "react-toastify";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import util from "../../utils/miniUtils";
import { Checkbox, FormControlLabel, MenuItem, TextField } from "@material-ui/core";
import SkillsContainer from "./profile_steps/review_resume_information/SkillsContainer";
import { Button } from "react-bootstrap";
import countryList from '../../utils/location/country.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import selector from "../../utils/selector";

export default function FillProfile() {

    const show = PositionsStore.useState(state => state.profileProcess);
    const isLogged = PositionsStore.useState(state => state.isLogged);
    const user = PositionsStore.useState(s => s.user);
    const [step] = useState(1);
    const [section, setSection] = useState("Location");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [countries] = useState(countryList);
    const [loading, setLoading] = useState(false);

    const [dataResume, setDataResume] = useState({
        relocation: 0,
        country: "",
        salary_type: "Fulltime",
        salary_mode: "Yearly",
        ctc: "",
        currency: "",
        working_status: "No Authorization for Job Site",
        working_status_other: "",
        platform: "",
        platform_other: "",
        skills: [],
        noticePeriod: "2 Weeks",
        tax_identification_number: "",
        current_salary: ""
    });
    const [skillsList, setSkillsList] = useState([]);
    const [skillName, setSkillName] = useState('');
    const [selectedSkills, setSelectedSkills] = useState([]);
    const options = [{ value: "Fulltime", salary_type: "Fulltime" }, { value: "Part-time", salary_type: "Part-time" }, { value: "Contractor", salary_type: "Contractor" }];
    const noticePeriod = [
        { value: "0 Weeks", noticePeriod: "0 Weeks" },
        { value: "1 Weeks", noticePeriod: "1 Weeks" },
        { value: "2 Weeks", noticePeriod: "2 Weeks" },
        { value: "3 Weeks", noticePeriod: "3 Weeks" },
        { value: "4 Weeks", noticePeriod: "4 Weeks" },
        { value: "5 Weeks", noticePeriod: "5 Weeks" },
        { value: "6 Weeks", noticePeriod: "6 Weeks" },
        { value: "7 Weeks", noticePeriod: "7 Weeks" },
        { value: "8 Weeks", noticePeriod: "8 Weeks" },
        { value: "9 Weeks", noticePeriod: "9 Weeks" },
        { value: "10 Weeks", noticePeriod: "10 Weeks" },
        { value: "11 Weeks", noticePeriod: "11 Weeks" },
        { value: "12 Weeks", noticePeriod: "12 Weeks" },
    ];
    const currencies = [{ value: "USD", currency: "USD" },
    { value: "AUD", currency: "AUD" },
    { value: "MXN", currency: "MXN" },
    { value: "INR", currency: "INR" }];



    const platform = [
        { value: ' ', platform: ' ' },
        { value: 'Bayt', platform: 'Bayt' },
        { value: 'Bumeran', platform: 'Bumeran' },
        { value: 'CareerBuilder', platform: 'CareerBuilder' },
        { value: 'CareerJet', platform: 'CareerJet' },
        { value: 'Computrabajo', platform: 'Computrabajo' },
        { value: 'Dice', platform: 'Dice' },
        { value: 'Empleos Maquila', platform: 'Empleos Maquila' },
        { value: 'Empleos Petroleros', platform: 'Empleos Petroleros' },
        { value: 'Empleos TI', platform: 'Empleos TI' },
        { value: 'Facebook', platform: 'Facebook' },
        { value: 'Glassdoor', platform: 'Glassdoor' },
        { value: 'Indeed', platform: 'Indeed' },
        { value: 'InfoJobs', platform: 'InfoJobs' },
        { value: 'JobStreet', platform: 'JobStreet' },
        { value: 'Konzerta', platform: 'Konzerta' },
        { value: 'LinkedIn', platform: 'LinkedIn' },
        { value: 'Monster', platform: 'Monster' },
        { value: 'Naukri', platform: 'Naukri' },
        { value: 'Naukri Resdex', platform: 'Naukri Resdex' },
        { value: 'OCC Mundial', platform: 'OCC Mundial' },
        { value: 'Reed', platform: 'Reed' },
        { value: 'Refferal', platform: 'Refferal' },
        { value: 'Seek', platform: 'Seek' },
        { value: 'SimplyHired', platform: 'SimplyHired' },
        { value: 'StepStone', platform: 'StepStone' },
        { value: 'TechFetch', platform: 'TechFetch' },
        { value: 'Trabajando.com', platform: 'Trabajando.com' },
        { value: 'Upwork', platform: 'Upwork' },
        { value: 'www.foundit.in', platform: 'www.foundit.in' },
        { value: 'Xing', platform: 'Xing' },
        { value: 'Ziprecruiter', platform: 'Ziprecruiter' },
        { value: 'Other', platform: 'Other' },
    ];

    const handleClose = () => {
        if (isLogged) {
            const urlParams = new URLSearchParams(window.location.search);
            const jobId = urlParams.get('job');

            if (jobId !== null) {
                window.history.replaceState("", "", `/careers?search=&job=${jobId}`);
            } else {
                window.history.replaceState("", "", "/");
            }
            // Every time the talent log in, it will be redirected to the main page
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } else {
            PositionsStore.update(s => { s.createAccount = false; });
        }
    };

    useEffect(() => {
        switch (step) {
            case 1: setSection('Location'); break;
            case 2: setSection('SalaryExpectation'); break;
            case 3: setSection('WorkHistory'); break;
            default: break;
        }
    }, [step]);

    useEffect(() => {
    }, [section]);


    const updateData = async () => {
        try {
            const response = await util.axios.put(`candidates/update/${user?.candidate_id ? user.candidate_id : util.user.candidate_id}`, dataResume);
            const { error, msg } = response.data;
            if (error) throw msg;
            if (selectedFileName && await onUploadNewResume(user?.candidate_id ? user.candidate_id : util.user.candidate_id));
            toast.success(msg);
            handleClose();
        } catch (e) {
            util.handleError(e);
        }
    };

    const onUploadNewResume = async (talent_id) => {
        try {
            const data = new FormData();
            data.append("file", selectedFile);
            // eslint-disable-next-line no-restricted-globals
            data.set("name", selectedFile.name);
            const request = await util.axios.post(`/candidates/upload_resume/${talent_id}`, data);
            const { error, msg, resume } = request.data;
            if (error) throw msg;
            setSelectedFileName(resume);
            toast.success(msg);
        } catch (e) {
            util.handleError(e);
        }
    };
    // const onDeleteResume = async ( talent_id ) => {
    //     try {
    //         const request = await util.axios.delete( `/candidates/delete_resume/${talent_id}` );
    //         const { error, msg } = request.data;
    //         if ( error ) throw msg;
    //         setSelectedFileName( null );
    //         toast.success( msg );
    //     } catch ( e ) {
    //         util.handleError( e );
    //     }
    // };


    // Handle changes in inputs for all the talent profile
    const handleChanges = e => {
        let { name, value, checked } = e.target;
        // console.log( name, value, checked );

        if (name === "relocation") {
            value = checked ? 1 : 0;
        }

        setDataResume({ ...dataResume, [name]: value });
    };

    // Listen when input file in changed
    const onChangeHandler = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
            if (fileSize > 2) {
                toast.error("File size is exceeded, max 2 MB");
                return;
            }
            setSelectedFile(file);

            // Proceed with the file upload
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            try {
                // Replace '/gpt/completeProfile' with your actual API endpoint
                const response = await util.axios.post('/gpt/completeProfile', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // Assuming the server responds with updated data for dataResume
                const updatedData = response.data;
                setDataResume({ ...dataResume, ...updatedData });
            } catch (error) {
                console.error('Error uploading file:', error);
                // Handle error
            }
            setLoading(false);

        }
    };


    // const saveSkill = ( skillsArray ) => {
    //     setDataResume( { ...dataResume, skills: skillsArray } );
    // };


    useEffect(() => {
        if (selectedFile) {
            setSelectedFileName(selectedFile.name);
        }
    }, [selectedFile]);


    const getListofSkills = async (search) => {
        try {
            search.replace(/\//g, "");
            const encodedSearch = encodeURIComponent(search);
            const response = await util.axios.get(`catalog/catalog_skills_search/${encodedSearch}`);
            const { error, msg, data } = response.data;
            if (error) return util.handleError(msg);
            setSkillsList(data);
        } catch (e) {
            util.handleError(e);
        }
    };
    const handleChange = (e) => {
        setSkillName(e.target.value);
    };

    const onExpChange = (e, index) => {
        const { name, value } = e.target;
        let t = dataResume.skills;
        t[index][name] = value;
        setDataResume({ ...dataResume, skills: t });
    };

    const addSkill = (skill) => {
        let t = dataResume.skills;

        // Check if the skill already exists in the skills list
        const skillIndex = t.findIndex((item) => item.id === skill.id);

        if (skillIndex === -1) {
            // Skill does not exist, add it to the list
            t.push({ ...skill, yearsOfExperience: '0' });
        } else {
            // Skill exists, remove it from the list
            t.splice(skillIndex, 1);
        }
        setDataResume({ ...dataResume, skills: t });
    };

    const removeSkill = (index) => {
        let t = dataResume.skills;
        t.splice(index, 1);
        setDataResume({ ...dataResume, skills: t });
    };

    const handleAddSkill = (skill) => {
        addSkill(skill);
        setSkillName('');
    };
    const handleRemoveSkill = (index) => {
        const updatedSkills = [...selectedSkills];
        updatedSkills.splice(index, 1);
        setSelectedSkills(updatedSkills);
    };

    const handleReset = () => {

        setSkillName('');
        setSkillsList([]);

    };

    const clearSelectedFile = () => {
        setSelectedFileName(null);
        setSelectedFile(null);
    };

    useEffect(() => {
        if (skillName) {
            getListofSkills(skillName);
        }
    }, [skillName]);
    console.log(dataResume);
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            size="md"
            onExiting={handleClose}
            onExit={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Complete your profile</Modal.Title>
                {util.LOADING_SCREEN(loading)}

            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <p className="col-md-5">Upload your resume :</p>
                        <label htmlFor="resumeInput" className="btn btn-secondary sizeBtn activeBtn col-md-3">
                            Select file
                        </label>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            id="resumeInput"
                            onChange={onChangeHandler}
                            name="file"
                            accept=".pdf,.doc,.docx"
                        />
                    </div>
                    {selectedFileName ? (
                        <div className="file-name-display">
                            <span className="btn btn-outline-secondary">{selectedFileName}</span>
                            <button onClick={clearSelectedFile} className="clear-file-btn">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                    ) : ""}
                    <div className="row mt-2 align-items-center">
                        {/* Employmenttype */}
                        {/* <util.RowComponent
                            c={6}
                            t={"Employment Type"}
                            n={"salary_type"}
                            m={handleChanges}
                            v={dataResume.salary_type || ""}
                            type={1}
                            d={[{ salary_type: "Fulltime" }, { salary_type: "Part-time" }, { salary_type: "Contractor" }]}
                        /> */}
                        <div className="col-md-12">
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label="Select Employment Type"
                                variant="outlined"
                                name={"salary_type"}
                                value={dataResume.salary_type || ""} // Set the initial selected value here
                                onChange={handleChanges}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.salary_type}
                                    </MenuItem>
                                ))}
                            </TextField></div>
                        {/* notice period */}
                        {/* <util.RowComponent
                            c={3}
                            t={"Notice Period"}
                            n={"noticePeriod"}
                            m={handleChanges}
                            v={dataResume.noticePeriod}
                            type={1}
                            d={selector.AvailabilityToStartObj}
                        /> */}
                        <div className="col-md-9 mt-3">
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label={"Notice Period"}
                                variant="outlined"
                                name={"noticePeriod"}
                                value={dataResume.noticePeriod} // Set the initial selected value here
                                onChange={handleChanges}
                            >
                                {noticePeriod.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.noticePeriod}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        {/* specify relocation */}
                        <div className="col-md-3">
                            <div className="text-center">
                                <FormControlLabel control={<Checkbox onChange={handleChanges} name="relocation" />} label="Open To Relocate" />
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">

                            <TextField
                                fullWidth
                                size="small"
                                label={"Current Salary"}
                                type='number'
                                variant="outlined"
                                name={"current_salary"}
                                value={dataResume.current_salary || ""}// Set the initial selected value here
                                onChange={handleChanges}
                            />
                        </div>
                        {/* expected salary */}
                        <div className="col-md-12 mt-3">
                            {/* <util.RowComponent
                                c={4}
                                t={"Expected Salary"}
                                ty="number"
                                n={"ctc"}
                                m={handleChanges}
                                v={dataResume.ctc || ""}
                            /> */}
                            <TextField
                                fullWidth
                                size="small"
                                label={"Expected Salary"}
                                type='number'
                                variant="outlined"
                                name={"ctc"}
                                value={dataResume.ctc || ""}// Set the initial selected value here
                                onChange={handleChanges}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            {/* <util.RowComponent
                                c={4}
                                t={"Currency"}
                                n={"currency"}
                                m={handleChanges}
                                v={dataResume.currency}
                                type={1}
                                d={[{ currency: "" }, ...util.currencies()]}
                            />*/}
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label={"Currency"}
                                variant="outlined"
                                name={"currency"}
                                value={dataResume.currency} // Set the initial selected value here
                                onChange={handleChanges}
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.currency}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div className="col-md-12 mt-3">
                            {/* <util.RowComponent
                                    c={4}
                                    t={"Per"}
                                    n={"salary_mode"}
                                    m={handleChanges}
                                    v={dataResume.salary_mode}
                                    type={1}
                                    d={selector.sell_rate_type} /> */}
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label={"Per"}
                                variant="outlined"
                                name={"salary_mode"}
                                value={dataResume.salary_mode} // Set the initial selected value here
                                onChange={handleChanges}
                            >
                                {selector.sell_rate_type.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.salary_mode}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        {/* work authorisation */}
                        <div className="col-md-12 mt-3">
                            <TextField
                                select
                                variant="outlined"
                                size="small"
                                name="country"
                                className="w-100"
                                label="Country (of Citizenship) *"
                                value={dataResume.country}
                                onChange={handleChanges}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true
                                }}
                                fullWidth
                            >
                                <optgroup label="Most Active">
                                    <option value="United States">United States</option>
                                    <option value="Canada">Canada</option>
                                    <option value="India">India</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                </optgroup>
                                <optgroup label="All Countries">
                                    <option value="" />
                                    {countries?.map((option, i) => (
                                        <option key={i} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </optgroup>
                            </TextField>
                        </div>
                        {dataResume.country === "India" ?
                            <div className="col-md-12 mt-3"><TextField
                                fullWidth
                                size="small"
                                label={"PAN Card"}
                                variant="outlined"
                                name={"tax_identification_number"}
                                value={dataResume.tax_identification_number} // Set the initial selected value here
                                onChange={handleChanges}
                            /></div> : ""}
                        <div className="col-md-12 mt-3">

                            <util.WorkAuthSelector value={dataResume.working_status} handleChange={handleChanges} />
                        </div>
                        {dataResume.working_status === "other" ?
                            <div className="col-md-12 mt-3">
                                {/* <util.RowComponent
                                    c={12}
                                    t={"Work Authorization (Others)"}
                                    n={"working_status_other"}
                                    m={handleChanges}
                                    v={dataResume.working_status_other || ""} /> */}
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={"Work Authorization (Others)"}
                                    variant="outlined"
                                    name={"working_status_other"}
                                    value={dataResume.working_status_other || ""} // Set the initial selected value here
                                    onChange={handleChanges}
                                />

                            </div> : ""}
                        {/* how did you hear about us */}
                        <div className='col-md-12 mt-3'>
                            {/* <util.RowComponent
                                c={dataResume.platform  === "Other" ? 6 : 12}
                                t={"How did you find us?"}
                                n={"platform"}
                                m={handleChanges}
                                v={dataResume.platform}
                                type={1}
                                d={[
                                    { platform: " " },
                                    { platform: "Indeed" },
                                    { platform: "LinkedIn" },
                                    { platform: "Monster" },
                                    { platform: "ZipRecruiter" },
                                    { platform: "Other" }
                                ]}
                            /> */}
                            <TextField
                                select
                                fullWidth
                                size="small"
                                type={1}
                                label={"How did you find us?"}
                                variant="outlined"
                                name={"platform"}
                                value={dataResume.platform} // Set the initial selected value here
                                onChange={handleChanges}
                            >
                                {platform.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.platform}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        {dataResume.platform === "Other" ?
                            <div className="col-md-12 mt-3">
                                {/* <util.RowComponent
                                        c={6}
                                        t={"Other"}
                                        n={"platform_other"}
                                        m={handleChanges}
                                        v={dataResume["platform_other"] || ""}
                                    /> */}
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={"Other"}
                                    variant="outlined"
                                    name={"platform_other"}
                                    value={dataResume["platform_other"] || ""} // Set the initial selected value here
                                    onChange={handleChanges}
                                />
                            </div>
                            : ""}

                        {/* update skills */}
                        {/* <SkillsContainer
                            data={dataResume}
                            onChange={handleChanges}
                            updateData={saveSkill}
                        /> */}
                        <util.skillTalentForm removeSkill={removeSkill} onSkillChange={onExpChange}
                            handleAddSkill={handleAddSkill} handleChange={handleChange} handleRemoveSkill={handleRemoveSkill} handleReset={handleReset}
                            selectedSkills={dataResume.skills} skillName={skillName} skillsList={skillsList}
                        />

                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={updateData}> <FontAwesomeIcon icon={faSave} /> Save</Button>
            </Modal.Footer>
        </Modal>
    );
}